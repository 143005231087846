import React, {useState} from 'react';
import clx from 'classnames';
import axios from 'axios';
import {Box, Button, Stack, Typography} from '@mui/material';
import Select, {components} from 'react-select';
import {useAuth0} from '@auth0/auth0-react';

import {createRequestOptions} from 'util/network';
import {paths} from 'constant/api';
import {CopytLabel, CopytStyledButton} from 'views_v2/lib/snippets';

const MdlSendConnectionRequest = p => {
  const {onSaveChanges, onCloseModal} = p;
  const {getAccessTokenSilently} = useAuth0();
  const [value, setValue] = useState();
  const [options, setOptions] = useState([]);
  const [email, setEmail] = useState();

  const onSubmit = () => {
    onSaveChanges({applyApplication: value});
  };

  const onChange = async () => {
    const token = await getAccessTokenSilently();
    const connect = await axios.get(`${paths.connection}/search?email=${email}&excludeConnection=true`, createRequestOptions(token));
    const connectData = connect?.data?.data || [];
    let arr = [];
    if (connectData?.length) {
      arr = connectData?.map(s => {
        return {
          label: s?.email,
          value: s?.id,
        };
      });
    }
    setOptions(arr);
  };

  const NoOptionsMessage = props => {
    return (
      <components.NoOptionsMessage {...props}>
        <span>No Available Data</span>
      </components.NoOptionsMessage>
    );
  };

  return (
    <Box padding="0 !important">
      <Box className="modal-title">
        <Box className="modal__heading" mx="0px !important">
          <Typography variant="h1">Send Connection Request</Typography>
        </Box>
      </Box>
      <Box className="modal-body" padding={0} my={2}>
        <Box mx={4}>
          <CopytLabel>Copyt User’s Email:</CopytLabel>
          <Select
            {...{value, options}}
            className="react-select info"
            classNamePrefix="react-select"
            placeholder="Enter Email"
            openMenuOnClick={options?.length > 0}
            components={{NoOptionsMessage}}
            onInputChange={e => {
              setEmail(e);
              const emailLength = email?.length || 0;
              if (emailLength > 0) onChange(email);
            }}
            closeMenuOnSelect
            isClearable
            onChange={e => setValue(e)}
          />
        </Box>

        <Stack direction="row" justifyContent="flex-end" pr={1} pt={1} mt={5} borderTop="1px solid #eee" spacing={2}>
          <CopytStyledButton variant="outline" color="primary" onClick={() => onCloseModal()}>
            Cancel
          </CopytStyledButton>
          <CopytStyledButton variant="contained" onClick={onSubmit}>
            Apply
          </CopytStyledButton>
        </Stack>
      </Box>
    </Box>
  );
};

export default MdlSendConnectionRequest;
