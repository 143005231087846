import axios from 'axios'
import {useAuth0} from '@auth0/auth0-react'
import {useCallback, useEffect, useState} from 'react'
import {createRequestOptions} from 'util/network'
import {paths} from 'constant/api'
import {useToastMessage} from 'components'
import {useUser} from 'service/hook'
import * as Sentry from '@sentry/react'

export const useSmartPricing = () => {
  const [value, setValue] = useState('')
  const [loading, setLoading] = useState('')
  const {getAccessTokenSilently} = useAuth0()
  const {showToast} = useToastMessage()
  const {user} = useUser()

  useEffect(() => {
    // initialize default value for percentage input field
    ;(async () => {
      try {
        setLoading(true)
        const token = await getAccessTokenSilently()
        const response = await axios.get(paths.user, createRequestOptions(token))
        setValue(response.data.data.private.lowestStockXAskPercentage || '')
      } catch (e) {
        Sentry.captureException(e)
      } finally {
        setLoading(false)
      }
    })()
  }, [setValue])

  const onSave = useCallback(async () => {
    try {
      setLoading(true)

      // Segment Track call - Smart Pricing Update
      if (window.analytics) {
        window.analytics.track('SMART_PRICING_UPDATE_CLICKED')
      }

      // todo: scope should be update:user
      const token = await getAccessTokenSilently()
      const response = await axios.patch(
        `${paths.userPrivate}/${user?.private?.id}`,
        {lowestStockXAskPercentage: value},
        createRequestOptions(token),
      )

      if (response.status === 200) {
        showToast({variant: 'success', body: 'Successfully Updated!'})
      }
    } catch (e) {
      Sentry.captureException(e)
    } finally {
      setLoading(false)
    }
  }, [getAccessTokenSilently, value])

  const onChange = useCallback(
    (e) => {
      setValue(e.target.value)
    },
    [setValue],
  )

  return {value, loading, onChange, onSave}
}

export default useSmartPricing