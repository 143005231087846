import React from 'react'
import { CircularProgress, LinearProgress, Skeleton } from '@mui/material'
import './scss/loadingBar.scss'
import CopytCard from 'components/CopytCard'

const LoadingBar = ({ type, color = 'secondary', text = "Please wait while we load the data...." }) => {
  if (type === 'circular') {
    return <CircularProgress {...{ color }} />
  } else if (type === 'linear') {
    return <LinearProgress {...{ color }} />
  } else if (type === 'skeleton') {
    return (
      <CopytCard>
        <CopytCard.Header>
          <Skeleton animation="wave" variant="circular" width={40} height={40} />
        </CopytCard.Header>
        <CopytCard.Body>
          <Skeleton
            animation="wave"
            height={10}
            width="80%"
            style={{ marginBottom: 6 }}
          />
        </CopytCard.Body>
      </CopytCard>
    )
  }
  return (
    <div className="loader">
      <div id="box" />
      <div className="bar1" />
      <div className="bar2" />
      <div className="bar3" />
      <div className="bar4" />
      <div className="bar5" />
      <div className="bar6" />
      <p>{text}</p>
    </div>
  )
}

export default LoadingBar