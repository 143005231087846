import {useQuery, useMutation} from 'react-query'
import {useAuth0} from '@auth0/auth0-react'
import {apiConfig} from 'config'
import axios from 'axios'
import {useToastMessage} from 'components/index'
import {createRequestOptions} from 'util/network'
import {paths} from 'constant/api'
import {useUser} from 'service/hook'

const getUserPrivate = async (getAccessTokenSilently, user) => {
  try {
    const token = await getAccessTokenSilently()
    const response = await axios.get(
      `${paths.userPrivate}/${user?.private?.id}`,
      createRequestOptions(token),
    )
    return response?.data?.data
  } catch (error) {
    console.log(error)
  }
}

export const useUserPrivate = () => {
  const {getAccessTokenSilently} = useAuth0()
  const {user} = useUser()
  const {showToast} = useToastMessage()
  const {
    isLoading,
    isError,
    isFetched,
    refetch,
    data: userPrivate = {},
    error,
  } = useQuery('userPrivate', () => getUserPrivate(getAccessTokenSilently, user))

  return {
    userPrivateLoading: isLoading,
    isError,
    userPrivateFetched: isFetched,
    refetchUserPrivate: refetch,
    error,
    userPrivate: userPrivate || {},
  }
}

export default useUserPrivate
