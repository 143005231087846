import {useAuth0} from '@auth0/auth0-react'
import axios from 'axios'
import {useToastMessage} from 'components'
import {apiConfig} from 'config'
import {useMutation, useQuery} from 'react-query'
import useSound from 'use-sound'
import success from 'assets/sounds/success.wav'
import error from 'assets/sounds/error.wav'
import {useBulkScanInStore} from 'views_v2/modules/BulkScanIn/useBulkScanInStore'
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min'
import useSiteSettings from './useSiteSettings'
import {useGlobalStore} from 'provider/global_store/hook'

const getProductTemplate = async (getAccessTokenSilently) => {
  try {
    const token = await getAccessTokenSilently()
    const response = await axios.get(`${apiConfig.api_url}/product-template`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return response.data.data[0]
  } catch (error) {
    throw new Error(error)
  }
}

const getConsignors = async (getAccessTokenSilently) => {
  try {
    const token = await getAccessTokenSilently()

    const response = await axios.get(`${apiConfig.api_url}/application/shop`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return response.data.data
  } catch (error) {
    throw new Error(error)
  }
}

const getLocations = async (getAccessTokenSilently) => {
  try {
    const token = await getAccessTokenSilently()

    const response = await axios.get(`${apiConfig.api_url}/location`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return response.data.data
  } catch (error) {
    throw new Error(error)
  }
}

const getSubLocations = async (getAccessTokenSilently) => {
  try {
    const token = await getAccessTokenSilently()

    const response = await axios.get(`${apiConfig.api_url}/product/sub-location`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return response.data.data.map(({subLocation}) => subLocation)
  } catch (error) {
    throw new Error(error)
  }
}

const getUpc = async (getAccessTokenSilently, upc) => {
  try {
    const token = await getAccessTokenSilently()

    const response = await axios.get(`${apiConfig.api_url}/upc-sneak/get-by-upc?upc=${upc}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    if (!response.data.length) {
      throw new Error('UPC not found')
    }

    return response.data[0]
  } catch (error) {
    throw new Error(error)
  }
}

const postCreateProducts = async (getAccessTokenSilently, products) => {
  try {
    const token = await getAccessTokenSilently()

    const response = await axios.post(
      `${apiConfig.api_url}/upc-sneak/create/product`,
      {items: products},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    return response.data
  } catch (error) {
    throw new Error(error)
  }
}

const postListProducts = async (getAccessTokenSilently, payload) => {
  try {
    const token = await getAccessTokenSilently()

    const response = await axios.post(
      `${apiConfig.api_url}/upc-sneak/create/product/list-all`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    return response.data
  } catch (error) {
    throw new Error(error)
  }
}

const postGenerateInternalSkus = async (getAccessTokenSilently, {unavailableInternalSkus, count}) => {
  try {
    const token = await getAccessTokenSilently()
    const response = await axios.post(
      `${apiConfig.api_url}/product/generate-internal-skus`,
      {unavailableInternalSkus, count},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    return response.data
  } catch (error) {
    throw new Error(error)
  }
}

const useBulkScanIn = () => {
  const {getAccessTokenSilently} = useAuth0()
  const history = useHistory()
  const bulkScanInPayload = useBulkScanInStore((state) => state.payload)
  const bulkScanInAddProduct = useBulkScanInStore((state) => state.addProduct)
  const bulkScanInClearProduct = useBulkScanInStore((state) => state.clearProducts)
  const bulkScanInDuplicateProduct = useBulkScanInStore((state) => state.duplicateProduct)
  const {showToast} = useToastMessage()
  const [onSuccess] = useSound(success)
  const [onError] = useSound(error)

  const {isEnterprise} = useGlobalStore()

  const {currentDescriptionWildcardList} = useSiteSettings()

  const productTemplate = useQuery({
    queryKey: ['useBulkScanIn', 'productTemplate'],
    queryFn: async () => getProductTemplate(getAccessTokenSilently),
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })

  const scanUpc = useMutation({
    mutationKey: ['useBulkScanIn', 'scanUpc'],
    mutationFn: async (upc) => {
      const product = await getUpc(getAccessTokenSilently, upc)
      const [internalSku] = await postGenerateInternalSkus(getAccessTokenSilently, {
        unavailableInternalSkus: bulkScanInPayload?.products.map((product) => product.internalSku) || [],
        count: 1,
      })
      return {...product, internalSku, upc}
    },
    onSuccess: (product) => {
      bulkScanInAddProduct(
        {
          upc: product?.upc,
          sku: product?.sku || product?.upc,
          title: product?.title,
          size: product?.size,
          desiredReturn: undefined,
          buyPrice: undefined,
          condition: 'Brand New',
          category: product?.category,
          internalSku: product?.internalSku,
          boxCondition: 'Original Box (Good)',
          color: product?.color || 'Multicolor',
          brand: product?.brand,
          location: '',
          subLocation: '',
          assets: product.images.map((image) => ({
            new: true,
            asset: {
              url: image.url,
            },
          })),
        },
        {descriptionWildcardList: currentDescriptionWildcardList || []},
      )
      showToast({variant: 'success', title: 'UPC Detected', body: ''})
      onSuccess()
    },
    onError: (error) => {
      showToast({variant: 'error', title: 'Invalid UPC', body: error.message})
      onError()
    },
  })

  const duplicateProduct = useMutation({
    mutationKey: ['useBulkScanIn', 'duplicateProducts'],
    mutationFn: async ({id, count}) => {
      const internalSkus = await postGenerateInternalSkus(getAccessTokenSilently, {
        unavailableInternalSkus: bulkScanInPayload?.products.map((product) => product.internalSku) || [],
        count,
      })
      bulkScanInDuplicateProduct(id, {
        internalSkus,
        count,
        descriptionWildcardList: currentDescriptionWildcardList || [],
      })
    },
    onSuccess: (product) => {
      showToast({variant: 'success', title: 'Duplicates Created', body: ''})
    },
    onError: (error) => {
      showToast({variant: 'error', title: 'Cannot Duplicate Products', body: error.message})
    },
  })

  const createProducts = useMutation({
    mutationKey: ['useBulkScanIn', 'createProducts'],
    mutationFn: async () => {
      await new Promise((resolve) => setTimeout(resolve, 2_000))
      const products = bulkScanInPayload.products.map((product) => {
        delete product.id
        delete product.createdAt

        return {
          ...product,
          quantity: 1,
          acquiredDate: new Date(),
          location: isEnterprise ? bulkScanInPayload.location : undefined,
        }
      })

      const chunkSize = 20
      const productChunks = []
      for (let i = 0; i < products.length; i += chunkSize) {
        productChunks.push(products.slice(i, i + chunkSize))
      }

      const promises = productChunks.map((chunk) => postCreateProducts(getAccessTokenSilently, chunk))
      await Promise.all(promises)

      bulkScanInClearProduct()

      return products
    },
    onSuccess: (product) => {
      showToast({variant: 'success', title: 'Products Created', body: ''})
    },
    onError: (error) => {
      showToast({variant: 'error', title: 'Cannot Create Products', body: error.message})
    },
  })

  const listProducts = useMutation({
    mutationKey: ['useBulkScanIn', 'createProducts'],
    mutationFn: async (payload) => {
      await new Promise((resolve) => setTimeout(resolve, 2_000))
      const products = bulkScanInPayload.products.map((product) => {
        delete product.id
        delete product.createdAt
        return {
          ...product,
          quantity: 1,
          acquiredDate: new Date(),
          location: isEnterprise ? bulkScanInPayload.location : undefined,
        }
      })

      const chunkSize = 30
      const productChunks = []
      for (let i = 0; i < products.length; i += chunkSize) {
        productChunks.push(products.slice(i, i + chunkSize))
      }

      const promises = productChunks.map((chunk) =>
        postListProducts(getAccessTokenSilently, {...payload, items: chunk}),
      )
      await Promise.all(promises)

      bulkScanInClearProduct()

      return products
    },
    onSuccess: (product) => {
      showToast({variant: 'success', title: 'Products Created', body: ''})
    },
    onError: (error) => {
      showToast({variant: 'error', title: 'Cannot Create Products', body: error.message})
    },
  })

  const consignors = useQuery({
    queryKey: ['useBulkScanIn', 'consignors'],
    queryFn: async () => getConsignors(getAccessTokenSilently),
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })

  const subLocations = useQuery({
    queryKey: ['useBulkScanIn', 'subLocations'],
    queryFn: async () => getSubLocations(getAccessTokenSilently),
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })

  const locations = useQuery({
    queryKey: ['useBulkScanIn', 'locations'],
    queryFn: async () => getLocations(getAccessTokenSilently),
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })

  return {
    scanUpc,
    consignors,
    locations,
    subLocations,
    createProducts,
    listProducts,
    duplicateProduct,
  }
}

export default useBulkScanIn
