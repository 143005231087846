import React from 'react'
import {Switch, Route} from 'react-router-dom'
import EbayCallback from './ebay/callback'
import ShopifyCallback from './shopify/callback'
import SquareCallback from './square/callback'
import CloverCallback from './clover/callback'
import CheckBookCallback from './checkbook/callback'
import PaypalCallback from './paypal/callback'

export function Market() {
  return (
    <Switch>
      <Route
        path="/admin/market/shopify/callback"
        component={(props) => <ShopifyCallback {...props} />}
      />
      <Route path="/admin/market/square/callback" component={(props) => <SquareCallback {...props} />} />
      <Route path="/admin/market/ebay/callback" component={(props) => <EbayCallback {...props} />} />
      <Route path="/admin/market/clover/callback" component={(props) => <CloverCallback {...props} />} />
      <Route path="/admin/market/checkbook/callback" component={(props) => <CheckBookCallback {...props} />} />
      <Route path="/admin/market/paypal/callback" component={(props) => <PaypalCallback {...props} />} />
    </Switch>
  )
}
