const onKeyPress = (e, type) => {
  if (type === 'text') {
    if (e.key.replace(/[^a-zA-Z ]/g, '') === '') e.preventDefault()
  } else if (type === 'alphanumeric') {
    if (e.key.replace(/[^a-zA-Z0-9 ]/g, '') === '') e.preventDefault()
  } else if (type === 'e') {
    if (e.key.replace(/[^0-9 ]/g, '') === '') e.preventDefault()
  } else if (type === 'num') {
    if (e.key.replace(/[^0-9. ]/g, '') === '') e.preventDefault()
  } else {
    e.preventDefault()
  }
}

export default onKeyPress
