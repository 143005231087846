import React from 'react'
import _ from 'lodash'
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import CopytCard from 'components/CopytCard'
import SimplifiedInventoryDetails from './SimplifiedInventoryDetails'
import ExpandedInventoryDetails from './ExpandedInventoryDetails'

export const CopytBox = styled(Box)(({ theme }) => ({
  ...theme,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '10px',
}))

const InventoryDetails = (props) => {
  const {
    formFields,
    isSimplified = false,
  } = props

  const forceUpdate = () => props.forceUpdate()

  const handleValueChange = (s, value) => {
    const { floatValue } = value
    Object.assign(s, { quantity: Number(floatValue) })
  }

  const handleArrow = (s, key) => {
    let quantity = s?.quantity || 0
    if (key === 'inc') {
      quantity = Number(quantity) + 1
    } else if (quantity) {
      quantity = Number(quantity) - 1
    } else {
      quantity = 0
    }
    Object.assign(s, { quantity })
    forceUpdate()
  }

  const onDeleteItem = (key) => {
    const f = formFields.size
    const exists = f.findIndex((s) => key === s)
    f.splice(exists, 1)
    forceUpdate()
  }

  const newProps = {
    ...props,
    handleValueChange,
    handleArrow,
    onDeleteItem
  }

  return (
    <CopytCard className="inventory-details">
      <CopytCard.Header>
        <CopytCard.Text tag="div">Inventory Details</CopytCard.Text>
      </CopytCard.Header>
      <CopytCard.Body className={isSimplified && 'simplified'}>
        {isSimplified ? <SimplifiedInventoryDetails {...newProps} /> : <ExpandedInventoryDetails {...newProps} />}
      </CopytCard.Body>
    </CopytCard>
  )
}

export default InventoryDetails
