import {useCallback} from 'react'
import axios from 'axios'
import {useAuth0} from '@auth0/auth0-react'
import * as Sentry from '@sentry/react'

import {apiConfig} from 'config'
import {paths} from 'constant/api'
import {createRequestOptions} from 'util/network'

// market shopify

const getShopifyCallback = async (tokenGetter, query) => {
  try {
    const token = await tokenGetter()
    const response = await axios.get(
      paths.market + `/shopify/callback` + query,
      createRequestOptions(token),
    )
    return response.data.data
  } catch (e) {
    Sentry.captureException(e)
    console.log('Error posting to shopify callback: ', e)
    throw e.message
  }
}

const postShopifySetup = async (tokenGetter, input) => {
  try {
    const token = await tokenGetter()
    const response = await axios.post(
      paths.market + `/shopify/setup`,
      input,
      createRequestOptions(token),
    )
    return response.data.data
  } catch (e) {
    Sentry.captureException(e)
    console.log('Error posting to shopify callback: ', e)
    throw e.message
  }
}

const postShopifyBillingSetup = async (tokenGetter, input) => {
  try {
    const token = await tokenGetter()
    const response = await axios.post(
      paths.market + `/shopify/setup-billing`,
      input,
      createRequestOptions(token),
    )
    return response.data.data
  } catch (e) {
    Sentry.captureException(e)
    throw e.message
  }
}

const getSquareCallback = async (tokenGetter, query) => {
  try {
    const token = await tokenGetter()
    const response = await axios.get(
      paths.market + `/square/callback` + query,
      createRequestOptions(token),
    )
    return response.data.data
  } catch (e) {
    Sentry.captureException(e)
    console.log('Error posting to square callback: ', e)
    throw e.message
  }
}

const postSquareSetup = async (tokenGetter, input) => {
  try {
    const token = await tokenGetter()
    const response = await axios.post(paths.market + `/square/setup`, input, createRequestOptions(token))
    return response.data.data
  } catch (e) {
    Sentry.captureException(e)
    console.log('Error posting to square callback: ', e)
    throw e.message
  }
}

const getEbayCallback = async (tokenGetter, code) => {
  try {
    const token = await tokenGetter()
    const query = {
      code: code,
    }

    const response = await axios.post(
      apiConfig.api_url + `/market/ebay/callback`,
      query,
      createRequestOptions(token),
    )

    return response.data.data
  } catch (e) {
    Sentry.captureException(e)
    console.log('Error posting to ebay callback: ', e)
    throw e.message
  }
}

const postEbaySetup = async (tokenGetter, input) => {
  try {
    const token = await tokenGetter()
    const response = await axios.post(paths.market + `/ebay/setup`, input, createRequestOptions(token))
    return response.data.data
  } catch (e) {
    Sentry.captureException(e)
    console.log('Error posting to ebay callback: ', e)
    throw e.message
  }
}

const postCloverSetup = async (tokenGetter, input) => {
  try {
    const token = await tokenGetter()
    const response = await axios.post(
      paths.market + `/clover/callback`,
      input,
      createRequestOptions(token),
    )
    return response.data.data
  } catch (e) {
    console.log(e)
    Sentry.captureException(e)
    console.log('Error posting to ebay callback: ', e)
  }
}

const getCheckbookCallback = async (tokenGetter, code) => {
  try {
    const token = await tokenGetter()
    const query = {
      code: code,
    }
    const response = await axios.post(paths.payment + `/checkbook/callback`, query, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data.data
  } catch (e) {
    Sentry.captureException(e)
    console.log('Error posting to CheckBook callback: ', e)
    throw e.message
  }
}

const postCheckBookSetup = async (tokenGetter, input) => {
  try {
    const token = await tokenGetter()
    const response = await axios.post(
      paths.payment + `/checkbook/callback`,
      input,
      createRequestOptions(token),
    )
    return response.data.data
  } catch (e) {
    Sentry.captureException(e)
    console.log('Error posting to CheckBook callback: ', e)
  }
}

const postPaypalSetup = async (tokenGetter, input) => {
  try {
    const token = await tokenGetter()
    const response = await axios.post(
      paths.payment + `/paypal/callback`,
      input,
      createRequestOptions(token),
    )

    return response.data.data
  } catch (e) {
    Sentry.captureException(e)
    console.log('Error posting to Paypal callback: ', e)
  }
}

export const usePlatformMarket = () => {
  const {getAccessTokenSilently} = useAuth0()

  const shopifyCallback = useCallback((query) => getShopifyCallback(getAccessTokenSilently, query), [])
  const shopifySetup = useCallback((input) => postShopifySetup(getAccessTokenSilently, input), [])
  const shopifyBillingSetup = useCallback(
    (input) => postShopifyBillingSetup(getAccessTokenSilently, input),
    [],
  )

  const squareCallback = useCallback((query) => getSquareCallback(getAccessTokenSilently, query), [])
  const squareSetup = useCallback((input) => postSquareSetup(getAccessTokenSilently, input), [])

  const ebayCallback = useCallback((query) => getEbayCallback(getAccessTokenSilently, query), [])
  const ebaySetup = useCallback((input) => postEbaySetup(getAccessTokenSilently, input), [])

  const cloverSetup = useCallback((input) => postCloverSetup(getAccessTokenSilently, input), [])

  const checkBookCallback = useCallback(
    (input) => getCheckbookCallback(getAccessTokenSilently, input),
    [],
  )
  const checkBookSetup = useCallback((input) => postCheckBookSetup(getAccessTokenSilently, input), [])

  const paypalSetup = useCallback((input) => postPaypalSetup(getAccessTokenSilently, input), [])

  return {
    shopifyCallback,
    shopifySetup,
    shopifyBillingSetup,
    squareCallback,
    squareSetup,
    ebayCallback,
    ebaySetup,
    cloverSetup,
    checkBookCallback,
    checkBookSetup,
    paypalSetup,
  }
}
