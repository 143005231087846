import React from 'react';
import {Box, BottomNavigation, BottomNavigationAction, Link} from '@mui/material';
import {styled} from '@mui/material/styles';
import MobileScreen from './MobileScreen';

export const BottomLink = styled(BottomNavigationAction)(() => ({
  color: 'var(--primary) !important',
  textTransform: 'uppercase',
  padding: '0px 12px !important',
}));

const Footer = ({isMobileScreen}) => {
  if (isMobileScreen) {
    return <MobileScreen />;
  }
  return (
    <Box display="flex" justifyContent="space-between" position="absolute" bottom={0} height="5%" width="100%" backgroundColor="white">
      <BottomNavigation showLabels className="nav full-height" sx={{width: '350px', maxWidth: '400px'}}>
        <BottomLink component={Link} href="https://www.copyt.io" label="Copyt" />
        <BottomLink component={Link} href="https://www.copyt.io/about" label="About Us" />
        <BottomLink component={Link} href="https://www.copyt.io/support" label="Change Log" />
      </BottomNavigation>
      <BottomNavigation showLabels className="nav full-height" sx={{marginRight: 1}}>
        <BottomLink component={Link} href="https://www.copyt.io/" target="_blank" label={`© ${new Date().getFullYear()} Copyt Inc.`} />
      </BottomNavigation>
    </Box>
  );
};

export default Footer;
