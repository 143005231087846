import React from 'react'

const TableHeader = ({children}) => {
  return (
    <div className="table-head">
      {children}
    </div>
  )
}

export default TableHeader
