import React, {useEffect, useCallback, useContext, useState} from 'react'
import {debounce, isEmpty, minBy} from 'lodash'
import clx from 'classnames'
import {useHistory} from 'react-router-dom'
import {useQueryClient} from 'react-query'
import {DataGrid} from '@mui/x-data-grid'
import {Box, Button, Grid} from '@mui/material'
import {FileDownload, KeyboardArrowDown as KeyboardArrowDownIcon} from '@mui/icons-material'
import SearchBar from 'material-ui-search-bar'

import DetailView from 'components/DetailView'
import {ConsignStatus} from 'enums'
import {CopytButton, EmptyData, LoadingBar, TooltipSnippets} from 'views_v2/lib/snippets'
import {capitalizeFirstLetter} from 'util/string_utils'
import {SearchContext} from 'views_v2/lib/classes'
import {getCurrency} from 'util/model/setting'
import {_getStatus} from 'util/string_utils'
import {ReactComponent as ConsignedBannerSvg} from '../../../../assets/img/consigned-banner_v2.svg'
import ConsignmentFilter from './snippets/ConsignmentFilter'
import {RedirectAction} from 'enums'

const ConsignmentOnEnterprise = (props) => {
  const {
    filter,
    setFilter,
    consignments,
    isLoading,
    isFetching,
    isApprovedConsignmentSuccess,
    isRejectConsignmentSuccess,
    approveConsignment,
    rejectConsignment,
    routeChange,
    visible,
    currentPage,
    setCurrentPage,
    onExport,
    onWithdrawItems,
  } = props
  const history = useHistory()
  const queryClient = useQueryClient()
  const search = useContext(SearchContext)
  const currency = getCurrency()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const [statusFilter, setStatusFilter] = useState(null)
  const onSearch = useCallback(
    debounce((searchKey) => search.setSearchText(searchKey), 1000),
    [],
  )

  /** Filter **/
  const handleFilterBtnClick = (event) => {
    event.stopPropagation()
    event.preventDefault()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleFilterClick = (status) => {
    setStatusFilter(status)
    setFilter((f) => ({...f, status}))
    handleClose()
  }

  /** End Filter **/

  useEffect(() => {
    return () => {
      onSearch.cancel()
    }
  }, [onSearch])

  useEffect(() => {
    if (isApprovedConsignmentSuccess) {
      queryClient.invalidateQueries('consignment')
    }
  }, [isApprovedConsignmentSuccess])

  useEffect(() => {
    if (isRejectConsignmentSuccess) {
      queryClient.invalidateQueries('consignment')
    }
  }, [isRejectConsignmentSuccess])

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const params = {}

    for (const [key, value] of searchParams.entries()) {
      params[key] = value
    }

    if (params[RedirectAction.SEARCH]) {
      setStatusFilter('withdraw_approved')
      setFilter((f) => ({...f, status: 'withdraw_approved'}))
    }
    
    search.setSearchText(params[RedirectAction.SEARCH]?.split(' ').join('+'))
    searchParams.delete(RedirectAction.SEARCH)

    console.log('searchParams', searchParams.toString())

    const newUrl = window.location.pathname + '?' + searchParams.toString()
    window.history.replaceState({}, '', newUrl)
  }, [])

  const columns = [
    {
      field: 'assets',
      headerName: '',
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const {product, consignee, status} = params.row || {}
        const hasConsignBanner =
          !isEmpty(consignee) &&
          ![ConsignStatus.WITHDRAW_APPROVED, ConsignStatus.REJECTED, ConsignStatus.PENDING].includes(
            status.toLowerCase(),
          )

        const image = isEmpty(product?.assets)
          ? null
          : minBy(product?.assets, (asset) => Math.abs(asset.order - 1))

        return (
          <div
            className={clx(
              isEmpty(product?.assets) && hasConsignBanner && 'no-image',
              'img-overlay-wrap',
            )}
          >
            {hasConsignBanner && <ConsignedBannerSvg />}
            {!isEmpty(product?.assets) && <img src={image?.asset?.url} />}
          </div>
        )
      },
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 2,
      minWidth: 130,
      renderCell: (params) => {
        const {product} = params?.row || {}
        const title = capitalizeFirstLetter(product?.title) || ''
        return <TooltipSnippets {...{title}} />
      },
      valueGetter: (params) => {
        const {product} = params?.row || {}
        return product?.title || ''
      },
    },
    {
      field: 'sku',
      headerName: 'SKU/StyleID',
      flex: 1,
      minWidth: 120,
      valueGetter: (params) => {
        const {product} = params?.row || {}
        return product?.sku || ''
      },
    },
    {
      field: 'internalSku',
      headerName: 'Internal SKU',
      flex: 1,
      minWidth: 120,
      valueGetter: (params) => {
        const {product} = params?.row || {}
        return product?.internalSku || ''
      },
    },
    {
      field: 'size',
      headerName: 'Size',
      flex: 1,
      minWidth: 120,
      valueGetter: (params) => {
        const {product} = params?.row || {}
        return product?.size || ''
      },
    },
    {
      field: 'desiredReturn',
      headerName: 'List Price',
      flex: 1,
      minWidth: 120,
      valueGetter: (params) => {
        const {product} = params?.row || {}
        const desiredReturn = product?.desiredReturn
        return !isNaN(desiredReturn) ? `${currency.format(desiredReturn)}` : ''
      },
    },
    {
      field: 'condition',
      headerName: 'Condition',
      flex: 0.8,
      minWidth: 80,
      valueGetter: (params) => {
        const {product} = params?.row || {}
        return product?.condition || ''
      },
    },
    {
      field: 'storelocation',
      headerName: 'Store Location',
      flex: 1,
      minWidth: 120,
      valueGetter: (params) => {
        const {product} = params?.row || {}
        return product?.location
      },
    },
    {
      field: 'consigner',
      headerName: 'Consignor',
      flex: 1.2,
      minWidth: 100,
      valueGetter: (params) => {
        const {consignor} = params?.row || {}
        return consignor?.email || ''
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1.5,
      minWidth: 130,
      cellClassName: 'font-tabular-nums',
      renderCell: (params) => {
        const {formattedValue, row} = params
        const val = formattedValue?.toLowerCase() || ''
        if (val === 'submitted' || val === 'pending') {
          return (
            <>
              <Button
                variant="contained"
                color="success"
                size="small"
                onClick={() => approveConsignment(row?.id)}
              >
                Approve
              </Button>
              <Button
                variant="contained"
                color="error"
                size="small"
                style={{marginLeft: 16}}
                onClick={() => rejectConsignment(row?.id)}
              >
                Deny
              </Button>
            </>
          )
        } else if (val === 'withdraw_pending') {
          return (
            <>
              <Button
                variant="contained"
                color="success"
                size="small"
                // disabled
                onClick={() => onWithdrawItems({consignIds: [row?.id], action: 'approve'})}
              >
                Confirm Withdraw
              </Button>
            </>
          )
        }

        let icon = 'far fa-thumbs-up'
        let color = 'success'
        if (val === 'rejected') {
          icon = 'fas fa-exclamation-triangle'
          color = 'error'
        } else if (val === 'active') {
          icon = 'far fa-check-circle'
          color = 'primary'
        } else if (val === 'processed') {
          icon = 'fas fa-sync'
          color = 'secondary'
        } else if (val === 'sold') {
          icon = 'fas fa-shopping-cart'
          color = 'secondary'
        } else if (val === 'listed') {
          icon = 'far fa-file-alt'
          color = 'secondary'
        }
        const _status = _getStatus(formattedValue)
        return (
          <Button
            {...{color}}
            variant="outlined"
            size="small"
            className="MuiButton-noEvent MuiButton-borderSolid"
          >
            <i className={icon} />
            &nbsp;{_status}
          </Button>
        )
      },
    },
  ]

  // set row total row count for table
  const rowCount = consignments?.total || 0

  if (isLoading) return <LoadingBar />
  let hasData = Array.isArray(consignments?.data) && consignments?.data?.length > 0

  const Toolbar = () => {
    return (
      <Box
        sx={{
          p: 0.5,
          pb: 0,
        }}
        style={{
          backgroundColor: '#faebf5',
          borderBottom: '1px solid #a5709b',
          display: 'flex',
          justifyContent: 'space-between',
          paddingLeft: '10px',
        }}
      >
        {/* <GridToolbarExport
          style={{
            color: '#d5239e',
            fontWeight: 700,
          }}
          csvOptions={{
            fileName: 'Copyt Inventory',
          }}
        /> */}
        <Box>
          <CopytButton
            onClick={onExport}
            variant="outlined"
            style={{
              padding: '12px 0',
              height: '35px',
              color: '#93186d',
            }}
          >
            <FileDownload /> Export
          </CopytButton>
        </Box>
        <Box
          sx={{
            p: 0.5,
            pb: 0,
          }}
          style={{
            display: 'flex',
            gap: '10px',
          }}
        >
          <SearchBar
            style={{
              height: '35px',
              marginBottom: '6px',
            }}
            value={search?.searchText}
            autoFocus
            placeholder="Search..."
            cancelOnEscape
            onCancelSearch={onSearch}
            onRequestSearch={(s) => onSearch(s)}
          />
        </Box>
      </Box>
    )
  }

  return (
    <DetailView.Panel style={{flex: 1}} isHidden={!visible}>
      <Box
        sx={{
          height: '94%',
          width: 1,
          boxShadow: '10px 10px 10px lightgrey',
        }}
      >
        <Grid container className="header-summary">
          <Grid item xs={10} />
          <Grid item xs={2} sx={{display: 'flex', justifyContent: 'flex-end'}}>
            <Button
              id="customized-button"
              className="options-btn"
              aria-controls={open ? 'customized-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              variant="contained"
              disableElevation
              onClick={handleFilterBtnClick}
              endIcon={<KeyboardArrowDownIcon />}
              style={{outline: 'none'}}
            >
              {statusFilter ? _getStatus(statusFilter) : 'Filter'}
            </Button>
            <ConsignmentFilter
              anchorEl={anchorEl}
              onClose={handleClose}
              handleFilterClick={handleFilterClick}
              open={open}
            />
          </Grid>
        </Grid>
        {hasData || search?.searchText ? (
          <DataGrid
            {...{columns, rowCount}}
            rows={consignments?.data}
            loading={isLoading || isFetching}
            disableColumnSelector
            disableColumnMenu
            disableDensitySelector
            disableColumnFilter
            disableVirtualization
            components={{Toolbar: Toolbar}}
            componentsProps={{
              toolbar: {
                onSearch,
              },
            }}
            filterModel={{
              items: [],
              quickFilterValues: [search?.searchText],
            }}
            onFilterModelChange={(s) => {
              onSearch(s?.quickFilterValues[0])
            }}
            getRowClassName={(params) => `datagrid-row super-app-theme--${params.row.status}`}
            onPageChange={(newPage) => setCurrentPage(newPage)}
            page={currentPage}
            pageSize={filter.take}
            pagination
            paginationMode="server"
            rowsPerPageOptions={[filter.take]}
            sx={{
              '& .MuiDataGrid-main': {
                bgcolor: 'white',
              },
              '& .MuiDataGrid-columnHeaders, & .MuiDataGrid-footer': {
                bgcolor: '#faebf5',
              },
            }}
          />
        ) : (
          <EmptyData title="No Consigned Items">
            Create your first batch of inventory on the
            <span
              onClick={() => history.push('/admin/inventory')}
              className="pink-text ml-1 cursor-pointer"
            >
              Inventory page
            </span>
            .
          </EmptyData>
        )}
      </Box>
    </DetailView.Panel>
  )
}

export default ConsignmentOnEnterprise
