import {get} from 'lodash'

import PlatformNames from 'enums/PlatformNames'
import logo from 'assets/img/copyt-logo.svg'
import paypalLogo from 'assets/img/paypal-icon.png'

export const getCredential = (platform) => {
  return get(platform, 'credentials[0]')
}

export const isCredentialEnabled = (platform) => {
  const cred = getCredential(platform)
  return cred?.enabled || false
}

export const getLogo = (platform) => {
  if (platform?.name?.toLowerCase() === 'shopify') {
    return 'https://cdn.shopify.com/assets/images/logos/shopify-bag.png'
  } else if (platform?.name === 'Ebay') {
    return 'https://upload.wikimedia.org/wikipedia/commons/4/48/EBay_logo.png'
  } else if (platform?.name?.toLowerCase() === 'square') {
    return 'https://upload.wikimedia.org/wikipedia/commons/2/27/Square%2C_Inc_-_Square_Logo.jpg'
  } else if (platform?.name?.toLowerCase() === 'clover') {
    return 'https://files.readme.io/603a3a7-clover-symbol-green_2.png'
  } else if (platform?.name?.toLowerCase() === 'venmo') {
    return 'https://upload.wikimedia.org/wikipedia/commons/8/84/Venmo_logo.png'
  } else if (platform?.name?.toLowerCase() === 'paypal') {
    // return 'https://upload.wikimedia.org/wikipedia/commons/b/b7/PayPal_Logo_Icon_2014.svg'
    return paypalLogo
  } else if (platform?.name?.toLowerCase() === 'checkbook') {
    return 'https://checkbook.io/img/company.png'
  } else if (platform?.name?.toLowerCase() === PlatformNames.TRADE_BLOCK) {
    return 'https://pbs.twimg.com/profile_images/1491522426013892614/Pu1B3OEG_400x400.jpg'
  } else {
    return platform?.logo_url || logo
  }
}
