import React from 'react'
import clx from 'classnames'
import { Box, Button, Typography } from '@mui/material'
import IssueBody from './IssueBody'
import Select from 'react-select'
import { styled } from '@mui/material/styles'

export const TextStyle = styled(Typography)(() => ({
  fontSize: '12px',
  fontWeight: 600,
  color: '#5d0953 !important',
  marginBottom: '2px'
}))

const dropdown = [
  {value: 'default', label: 'Select Type'},
  {value: 'list', label: 'Shoe did not list'},
  {value: 'delist', label: 'Shoe did not delist'},
  {value: 'platform', label: 'Cannot connect to platform'},
  {value: 'other', label: 'General/Other'},
]

const MdlSupport = (p) => {
  const {defaultDropdown, setDefaultDropdown, submitTicket} = p || {}

  return (
    <Box>
      <Box className="modal-title">
        <Box className="modal__heading">
          <Typography variant="h1">Tell us what happened</Typography>
        </Box>
      </Box>
      <Box sx={{ padding: '12px 0' }}>
        <TextStyle>Issue:</TextStyle>
          <Select
          className="react-select info"
          classNamePrefix="react-select"
          placeholder="Select Type"
          closeMenuOnSelect={true}
          onChange={(e) => setDefaultDropdown({value: e.value, label: e.label})}
          options={dropdown}
          value={
            defaultDropdown && {
              value: defaultDropdown.value,
              label: defaultDropdown.label,
            }
          }
        />
        <IssueBody issue={defaultDropdown?.value || 'default'} {...p} />
        <Box sx={{display: 'flex', mt: 2, gap: '10px'}}>
          <Button
            id="#onFormSubmit"
            sx={{mr: 2}}
            disabled={false}
            className={clx('btn-header', 'secondary', 'submit-consignment')}
            onClick={p.onCloseModal}
          >
            Cancel
          </Button>
          <Button
            disabled={defaultDropdown.value === 'default'}
            sx={{ml: 1}}
            className={clx('btn-header', 'danger')}
            onClick={submitTicket}
          >
            Submit
          </Button>
        </Box>

      </Box>
    </Box>
  )
}

export default MdlSupport
