import {useMutation, useQuery} from 'react-query'
import axios from 'axios'
import {useAuth0} from '@auth0/auth0-react'
import {useToastMessage} from 'components/index'
import * as Sentry from '@sentry/react'
import {createRequestOptions} from 'util/network'
import {paths} from 'constant/api'

const getProductTemplate = async (getAccessTokenSilently) => {
  try {
    const token = await getAccessTokenSilently()
    const response = await axios.get(paths.productTemplate, createRequestOptions(token))

    return response.data.data
  } catch (error) {
    Sentry.captureException(error)
    console.error('Error retrieving invoices', error)
    throw error
  }
}

export const useProductTemplate = () => {
  const {getAccessTokenSilently, user} = useAuth0()
  const {showToast} = useToastMessage()

  const {
    status,
    isLoading,
    isError,
    isFetched,
    refetch,
    data: templates = [],
    error,
  } = useQuery('product-template', () => getProductTemplate(getAccessTokenSilently))

  const addTemplate = useMutation(
    async (value) => {
      const token = await getAccessTokenSilently()

      return await axios.post(paths.productTemplate, {...value}, createRequestOptions(token))
    },
    {
      onSuccess: async (data, variables, context) => {
        showToast({
          variant: 'success',
          title: 'Success',
          body: `Product Template has been added.`,
        })
        refetch()
      },
    },
    {
      onMutate: async (data, variables, context) => {
        showToast({variant: 'success', title: 'Loading', body: ''})
        refetch()
      },
    },
    {
      onError: async (data, variables, context) => {
        showToast({variant: 'danger', title: 'Error', body: ''})
        refetch()
      },
    },
  )

  const deleteTemplate = useMutation(
    async (id) => {
      const token = await getAccessTokenSilently()

      return await axios.delete(`${paths.productTemplate}/${id}`, createRequestOptions(token))
    },
    {
      onSuccess: async (data, variables, context) => {
        showToast({
          variant: 'success',
          title: 'Success',
          body: `Product Template has been successfully deleted.`,
        })
        refetch()
      },
    },
    {
      onMutate: async (data, variables, context) => {
        showToast({variant: 'success', title: 'Loading', body: ''})
        refetch()
      },
    },
    {
      onError: async (data, variables, context) => {
        showToast({variant: 'danger', title: 'Error', body: ''})
        refetch()
      },
    },
  )

  const updateTemplate = useMutation(
    async ({id, body}) => {
      const token = await getAccessTokenSilently()

      return await axios.patch(
        `${paths.productTemplate}/${id}`,
        {
          ...body,
        },
        createRequestOptions(token),
      )
    },
    {
      onSuccess: async (data, variables, context) => {
        showToast({
          variant: 'success',
          title: 'Success',
          body: `Product Template has been updated.`,
        })
        refetch()
      },
    },
    {
      onMutate: async (data, variables, context) => {
        showToast({variant: 'success', title: 'Loading', body: ''})
        refetch()
      },
    },
    {
      onError: async (data, variables, context) => {
        showToast({variant: 'danger', title: 'Error', body: ''})
        refetch()
      },
    },
  )

  return {
    status,
    isLoading,
    refetchTemplate: refetch,
    isError,
    isProductTemplateFetch: isFetched,
    error,
    template: templates || [],
    addProductTemplate: addTemplate.mutate,
    deleteProductTemplate: deleteTemplate.mutate,
    updateProductTemplate: updateTemplate.mutate,
  }
}

export default useProductTemplate
