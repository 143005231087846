import axios from 'axios'
import {useAuth0} from '@auth0/auth0-react'
import {paths} from 'constant/api'
import {createRequestOptions} from 'util/network'
import { useMutation} from 'react-query'

export const useNotificationMutation = () => {
    const {getAccessTokenSilently} = useAuth0()

    const readNotification = useMutation(async (id) => {
        const token = await getAccessTokenSilently()
        return await axios.post(
            `${paths.notification}/read/${id}`,
            {},
            createRequestOptions(token)
        )
    },{
      onSuccess: async (data, variables, context) => {},
    },
    {
      onError: async (data, variables, context) => {
        showToast({variant: 'danger', title: 'Error', body: ''})
      },
    })

    const readAllNotifications = useMutation(async () => {
        const token = await getAccessTokenSilently()
        return await axios.post(
            `${paths.notification}/read/all`,
            {},
            createRequestOptions(token)
        )
    },{
      onSuccess: async (data, variables, context) => {},
    },
    {
      onError: async (data, variables, context) => {
        showToast({variant: 'danger', title: 'Error', body: ''})
      },
    })

    return {
        readNotification: readNotification.mutateAsync,
        readNotificationIsSuccess: readNotification.isSuccess,

        // readAllNotifications: readAllNotifications.mutate,
        // readAllNotificationsIsSuccess: readAllNotifications.isSuccess,
    }
}

export default useNotificationMutation


