import React, {useEffect, useMemo, useRef, useState} from 'react'
import {Box, Checkbox, Typography, Stack, FormControl} from '@mui/material'
import Select from 'react-select'
import {chain, get, isEmpty} from 'lodash'

import {ModalType} from 'enums'
import {_replaceWildCards} from 'util/string_utils'
import {CopytLabel, CopytStyledButton, LoadingBar} from 'views_v2/lib/snippets'
import {useConsignmentShops, useConsignments} from 'service/hook'

const MdlSelectApprovedItems = (props) => {
  const {
    consignee,
    setConsignee,
    setModalType,
    payload,
    setPayload,
    setSelectedLocation,
    selectedLocation,
    isCreatingShipment,
  } = props
  const [boxItems, setBoxItems] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [initialRender, setInitialRender] = useState(true)
  const [options, setOptions] = useState([])
  const {approvedPlatforms, itemsApproved, refetchItemsApproved, isFetchingItemsApproved} =
    useConsignments({take: 200, hide: 'shipment'}, null, consignee?.value)

  const {storeLocations} = useConsignmentShops(
    {
      options: {
        enabled: true,
        staleTime: Infinity,
      },
      queries: {},
    },
    consignee?.value,
  )

  const onContinue = () => {
    setPayload({...payload, boxItems})
    setModalType(ModalType.CREATE_BOX)
  }

  const handleCheckboxChange = (item) => {
    setBoxItems((prevItems) => {
      const itemIndex = prevItems.findIndex((i) => i.id === item.id)
      if (itemIndex > -1) {
        return prevItems.filter((i) => i.id !== item.id)
      } else {
        return [...prevItems, item]
      }
    })
  }

  const approvedPlatformsArray = useMemo(() => {
    const platforms = chain(approvedPlatforms)
      .filter((item) => get(item, 'platformName'))
      .map((item) => ({
        label: get(item, 'platformName'),
        value: get(item, 'enterpriseId'),
      }))
      .uniqBy('label')
      .value()

    if (!isEmpty(platforms)) {
      setConsignee((prevConsignee) => ({
        ...prevConsignee,
        label: platforms[0]?.label,
        value: platforms[0]?.value,
        location: 'default',
      }))
    }

    return platforms
  }, [approvedPlatforms])

  useEffect(() => {
    if (consignee?.value) {
      refetchItemsApproved({take: 200}, null, consignee?.value)
    }
  }, [consignee])

  useEffect(() => {
    setBoxItems([])
  }, [selectedLocation])

  useEffect(() => {
    setIsLoading(true)
    if (!isEmpty(storeLocations)) {
      setOptions(
        storeLocations.map((storeLocation) => ({
          label: storeLocation.name,
          value: storeLocation.id,
        })),
      )
      if (!initialRender) {
        setSelectedLocation({
          label: storeLocations[0].name,
          value: storeLocations[0].id,
        })
      }
      setIsLoading(false)
    } else {
      setSelectedLocation(null)
      setIsLoading(false)
    }

    setInitialRender(false)
  }, [storeLocations])

  return (
    <Box className="bulk-edit">
      <Box className="modal-title">
        <Box className="modal__heading" mx="0px !important">
          <Typography variant="h1" textAlign="left">
            Select Approved Items
          </Typography>
        </Box>
      </Box>
      <Box className="modal-body" maxHeight="85vh" padding="0" mb={2}>
        <Stack direction="column" bgcolor="var(--lighter)">
          <Stack direction="column" bgcolor="var(--lighter)">
            <Box
              padding={1}
              borderBottom="1px solid"
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              gap={4}
            >
              <FormControl>
                <CopytLabel>Consignee</CopytLabel>
                <Select
                  placeholder="Select Consignee"
                  closeMenuOnSelect
                  options={approvedPlatformsArray}
                  value={consignee}
                  onChange={(e) => {
                    setConsignee(e)
                    setBoxItems([])
                  }}
                  styles={{container: (base) => ({...base, width: '250px', float: 'right'})}}
                />
              </FormControl>
              <FormControl>
                <CopytLabel>Store Location</CopytLabel>
                <Select
                  placeholder="Select Store Location"
                  isDisabled={isLoading}
                  isLoading={isLoading}
                  classNamePrefix="react-select"
                  onChange={(e) => {
                    setSelectedLocation(e)
                    setConsignee({...consignee, location: e?.label})
                  }}
                  options={options}
                  value={selectedLocation}
                  styles={{container: (base) => ({...base, width: '200px', float: 'right'})}}
                />
              </FormControl>
            </Box>
          </Stack>

          <Stack
            direction="row"
            spacing={1}
            justifyContent="space-around"
            borderBottom="1px solid"
            p={1}
            alignItems="center"
          >
            <Checkbox
              checked={!isEmpty(boxItems) && boxItems?.length === payload?.items?.length}
              indeterminate={!isEmpty(boxItems) && boxItems?.length !== payload?.items?.length}
              onChange={() => (isEmpty(boxItems) ? setBoxItems(payload?.items) : setBoxItems([]))}
              color="secondary"
              disabled={isFetchingItemsApproved || isCreatingShipment}
            />
            <Stack direction="row" width="100%" justifyContent="space-between">
              <CopytLabel width="30%">Item Name</CopytLabel>
              <CopytLabel width="25%">Sku/Style ID</CopytLabel>
              <CopytLabel width="15%">Size</CopytLabel>
              <CopytLabel width="15%">Price</CopytLabel>
            </Stack>
          </Stack>
          {isFetchingItemsApproved && <LoadingBar type="linear" />}
        </Stack>

        <Box minHeight="50vh" maxHeight="60vh" overflow="hidden auto">
          {!isEmpty(itemsApproved) &&
            itemsApproved
              .filter(
                (item) =>
                  item?.product?.location?.toLowerCase() === selectedLocation?.label?.toLowerCase(),
              )
              .map((item) => {
                const product = item?.product
                return (
                  <Stack
                    key={item.id}
                    direction="row"
                    spacing={1}
                    justifyContent="space-between"
                    borderBottom="1px solid var(--light)"
                    alignItems="center"
                    padding={1}
                    sx={{
                      '&:hover': {
                        backgroundColor: 'var(--lighter)',
                      },
                    }}
                  >
                    <Checkbox
                      onChange={() => handleCheckboxChange(item)}
                      color="secondary"
                      checked={boxItems.some((s) => s?.id === item?.id)}
                      disabled={isFetchingItemsApproved || isCreatingShipment}
                    />
                    <Stack
                      direction="row"
                      width="100%"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <CopytLabel width="30%">{product?.title}</CopytLabel>
                      <CopytLabel width="25%">{product?.sku}</CopytLabel>
                      <CopytLabel width="15%">{product?.size}</CopytLabel>
                      <CopytLabel width="15%">{product?.desiredReturn}</CopytLabel>
                    </Stack>
                  </Stack>
                )
              })}
        </Box>
        <Stack
          direction="row"
          justifyContent="flex-end"
          pr={2}
          pt={2}
          borderTop="1px solid #eee"
          spacing={2}
        >
          <CopytStyledButton
            variant="outline"
            color="primary"
            disabled={isCreatingShipment}
            onClick={() => setModalType(ModalType.CREATE_LABEL)}
          >
            Back
          </CopytStyledButton>
          <CopytStyledButton
            disabled={isEmpty(boxItems) || isEmpty(consignee?.value) || isCreatingShipment}
            variant="contained"
            color="primary"
            onClick={onContinue}
          >
            Continue
          </CopytStyledButton>
        </Stack>
      </Box>
    </Box>
  )
}

export default MdlSelectApprovedItems
