import React from 'react'
import {Tooltip} from '@mui/material'

const TooltipSnippets = React.forwardRef(({title}, ref) => {
  return (
    <Tooltip ref={ref} title={title}>
      <span
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {title}
      </span>
    </Tooltip>
  )
})

export default TooltipSnippets
