import * as React from 'react';
import Box from '@mui/material/Box';

import Loader from 'react-loader-spinner';
import {
  Search as SearchIcon,
  Save as SaveIcon,
  Close as CloseIcon,
  DeleteForever as DeleteForeverIcon,
  ResetTv as ResetTvIcon,
  SettingsBackupRestore,
} from '@mui/icons-material';
import {isMobile} from 'react-device-detect';
import {useScreenSize} from 'util/window_utils';
import {ModalType} from 'enums';
import {CopytButton, CopytTooltip, CopytToolbar} from 'views_v2/lib/snippets';
import {useGlobalStore} from 'provider/global_store/hook';

export const MenuHeader = p => {
  const {
    editMode,
    label,
    canSaveAndConsign = false,
    isLoading,
    modalType,
    routeChange,
    onFormSubmit,
    onResetAll,
    updateStateValue,
    isDisabled,
    isConsigned,
    withdrawItem,
  } = p;
  const screenWidth = useScreenSize();
  const isMobileScreen = isMobile || screenWidth < 580;
  const {isEnterprise} = useGlobalStore();

  return (
    <CopytToolbar style={{justifyContent: 'space-between'}}>
      <Box>
        {modalType !== ModalType.SKU_UPC && (
          <CopytTooltip title={isMobileScreen && 'Search Item'} arrow={false} backgroundColor="var(--lighter)" color="var(--primary)">
            <Box component="span">
              <CopytButton
                variant="contained"
                color="error"
                onClick={() => updateStateValue('modalType', ModalType.SKU_UPC)}
                sx={{
                  marginRight: '10px',
                  minWidth: '20px !important',
                }}
              >
                {isMobileScreen ? <SearchIcon /> : 'Search Item'}
              </CopytButton>
            </Box>
          </CopytTooltip>
        )}
      </Box>
      <Box display="flex">
        <CopytTooltip
          title={isMobileScreen && (editMode ? 'Delete' : 'Clear All')}
          arrow={false}
          backgroundColor="var(--lighter)"
          color="var(--primary)"
        >
          <Box component="span">
            {/* TODO: Uncomment once withdraw feature is deployed */}
            {isConsigned && editMode && isEnterprise && (
              <CopytButton
                variant="contained"
                disabled={isLoading || modalType === ModalType.SKU_UPC || isDisabled}
                color="error"
                onClick={withdrawItem}
                sx={{
                  marginRight: 1,
                  minWidth: '20px !important',
                  height: 37,
                }}
              >
                {isLoading ? (
                  <Loader type="TailSpin" color="white" height={20} width={20} className="import-button" />
                ) : isMobileScreen ? (
                  <SettingsBackupRestore />
                ) : (
                  'Withdraw'
                )}
              </CopytButton>
            )}

            {(!isConsigned || (isConsigned && editMode && !isEnterprise)) && (
              <CopytButton
                variant="contained"
                disabled={isLoading || modalType === ModalType.SKU_UPC}
                color="error"
                onClick={onResetAll}
                sx={{
                  marginRight: 1,
                  minWidth: '20px !important',
                  height: 37,
                }}
              >
                {isLoading ? (
                  <Loader type="TailSpin" color="white" height={20} width={20} className="import-button" />
                ) : editMode ? (
                  isMobileScreen ? (
                    <DeleteForeverIcon />
                  ) : (
                    'Delete'
                  )
                ) : isMobileScreen ? (
                  <ResetTvIcon />
                ) : (
                  'Clear All'
                )}
              </CopytButton>
            )}
          </Box>
        </CopytTooltip>
        <CopytTooltip title={isMobileScreen && 'Cancel'} arrow={false} backgroundColor="var(--lighter)" color="var(--primary)">
          <Box component="span">
            <CopytButton
              disabled={modalType === ModalType.SKU_UPC}
              variant="outline"
              color="primary"
              onClick={() => routeChange('/admin/inventory/viewInventory')}
              sx={{
                marginRight: '10px',
                minWidth: '20px !important',
              }}
            >
              {isMobileScreen ? <CloseIcon /> : 'Cancel'}
            </CopytButton>
          </Box>
        </CopytTooltip>
        <CopytTooltip title={isMobileScreen && label} arrow={false} backgroundColor="var(--lighter)" color="var(--primary)">
          <Box component="span">
            <CopytButton
              disabled={isLoading || modalType === ModalType.SKU_UPC || canSaveAndConsign}
              variant="contained"
              color="primary"
              data-testid="save-item-button"
              onClick={() => {
                setTimeout(() => {
                  onFormSubmit();
                }, 100);
              }}
              sx={{
                minWidth: '20px !important',
                width: `${isMobileScreen ? '50px' : '120px'} !important`,
                height: 37,
                lineHeight: 1,
              }}
            >
              {isLoading ? (
                <Loader type="TailSpin" color="white" height={20} width={20} className="import-button" />
              ) : isMobileScreen ? (
                <SaveIcon />
              ) : (
                label
              )}
            </CopytButton>
          </Box>
        </CopytTooltip>
      </Box>
    </CopytToolbar>
  );
};

export default MenuHeader;
