import {useCallback, useEffect, useState} from 'react'
import {useUser} from 'service/hook'
import {getMinimumFee} from 'util/model'
import {useAuth0} from '@auth0/auth0-react'
import {createRequestOptions} from 'util/network'
import {paths} from 'constant/api'
import {useToastMessage} from 'components'
import axios from 'axios'

export function useMinimumInputFee() {
  const {user} = useUser()
  const [value, setValue] = useState(getMinimumFee(user))

  const [loading, setLoading] = useState('')
  const {getAccessTokenSilently} = useAuth0()
  const {showToast} = useToastMessage()

  // todo: add data refresh on the  useUser hook
  // since  useUser already load on app mount
  useEffect(() => {
    ;(async () => {
      try {
        setLoading(true)
        const token = await getAccessTokenSilently()
        const response = await axios.get(paths.user, createRequestOptions(token))
        setValue(response.data.data?.private?.minimumConsignmentFee || '')
      } catch (e) {
        console.error(e)
        return e
      } finally {
        setLoading(false)
      }
    })()
  }, [setValue])

  const onSave = useCallback(async () => {
    try {
      setLoading(true)
      const token = await getAccessTokenSilently()
      const response = await axios.patch(
        `${paths.userPrivate}/${user?.private?.id}`,
        {minimumConsignmentFee: value},
        createRequestOptions(token),
      )
    } catch (e) {
      console.error(e)
      return e
    } finally {
      setLoading(false)
    }
  }, [getAccessTokenSilently, value])

  const onChange = useCallback(
    (e) => {
      setValue(e.target.value)
    },
    [setValue],
  )

  return {value, loading, onChange, onSave}
}
