import {useAuth0} from '@auth0/auth0-react'
import {captureException} from '@sentry/browser'
import {useQuery} from 'react-query'
import axios from 'axios'

import {paths} from 'constant/api'
import {createRequestOptions} from 'util/network'

const getInventory = async ({token, filter, queries, signal}) => {
  try {
    const {
      data: {data},
    } = await axios.post(
      `${paths.metrics}/inventory`,
      {filter},
      {...createRequestOptions(token), signal},
    )

    return data
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('Request canceled', error.message)
    } else {
      captureException(error)
      throw new Error(error)
    }
  }
}

const getSales = async ({token, filter, queries, signal}) => {
  try {
    const {
      data: {data},
    } = await axios.post(`${paths.metrics}/sales`, {filter}, {...createRequestOptions(token), signal})

    return data
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('Request canceled', error.message)
    } else {
      captureException(error)
      throw new Error(error)
    }
  }
}

const getTrends = async ({token, filter, queries, signal}) => {
  try {
    const {
      data: {data},
    } = await axios.post(`${paths.metrics}/trends`, {filter}, {...createRequestOptions(token), signal})

    return data
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('Request canceled', error.message)
    } else {
      captureException(error)
      throw new Error(error)
    }
  }
}

const getPendings = async ({token, filter, queries, signal}) => {
  try {
    const {
      data: {data},
    } = await axios.post(`${paths.metrics}/pendings`, {filter}, {...createRequestOptions(token), signal})

    return data
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('Request canceled', error.message)
    } else {
      captureException(error)
      throw new Error(error)
    }
  }
}

const useMetrics = ({filter = {}, queries = {}, options = {}}) => {
  const {getAccessTokenSilently} = useAuth0()

  const createQueryKey = (baseKey, {filter, queries}) => {
    return [baseKey, JSON.stringify(filter), JSON.stringify(queries)]
  }

  const initial = useQuery(
    ['metrics:initial'],
    async () => {
      await new Promise((resolve) => setTimeout(resolve, 50))
    },
    {
      refetchOnMount: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      keepPreviousData: true,
    },
  )

  const sales = useQuery(
    createQueryKey('metrics:sales', {filter, queries}),
    async ({signal}) => {
      const token = await getAccessTokenSilently()
      const controller = new AbortController()
      const {signal: abortSignal} = controller
      signal.addEventListener('abort', () => controller.abort())
      return await getSales({token, filter, queries, signal: abortSignal})
    },
    {
      ...options,
      refetchOnMount: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      keepPreviousData: true,
      enabled: initial.isSuccess,
    },
  )

  const inventory = useQuery(
    createQueryKey('metrics:inventory', {filter, queries}),
    async ({signal}) => {
      const token = await getAccessTokenSilently()
      const controller = new AbortController()
      const {signal: abortSignal} = controller
      signal.addEventListener('abort', () => controller.abort())
      return await getInventory({token, filter, queries, signal: abortSignal})
    },
    {
      ...options,
      refetchOnMount: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      keepPreviousData: true,
      enabled: sales.isSuccess,
    },
  )

  const trends = useQuery(
    createQueryKey('metrics:trends', {filter, queries}),
    async ({signal}) => {
      const token = await getAccessTokenSilently()
      const controller = new AbortController()
      const {signal: abortSignal} = controller
      signal.addEventListener('abort', () => controller.abort())
      return await getTrends({token, filter, queries, signal: abortSignal})
    },
    {
      ...options,
      refetchOnMount: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      keepPreviousData: true,
      enabled: inventory.isSuccess,
    },
  )

  const pendings = useQuery(
    createQueryKey('metrics:pendings', {filter, queries}),
    async ({signal}) => {
      const token = await getAccessTokenSilently()
      const controller = new AbortController()
      const {signal: abortSignal} = controller
      signal.addEventListener('abort', () => controller.abort())
      return await getPendings({token, filter, queries, signal: abortSignal})
    },
    {
      ...options,
      refetchOnMount: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      keepPreviousData: true,
      enabled: false,
    },
  )

  return {
    inventory,
    sales,
    trends,
    pendings,
  }
}

export default useMetrics
