import { ar } from "variables/empty"

export function codeToIndex(images = []) {
  for (var i = 0; i < images.length; i++) {
    switch (images[i].index) {
      case 'outside':
        images[i].index = '1'
        break
      case 'inside':
        images[i].index = '2'
        break
      case 'top':
        images[i].index = '3'
        break
      case 'back':
        images[i].index = '4'
        break
      case 'soles':
        images[i].index = '5'
        break
      case 'sizeTag':
        images[i].index = '6'
        break
      case 'box':
        images[i].index = '7'
        break
      default:
        break
    }
  }
  return [...images]
}

export function getUrl(images = []) {
  return images ? images.map(i => i.url) : ar
}