import {Typography} from '@mui/material'
import {styled} from '@mui/system'

const EllipsisTypography = styled(Typography)({
  display: '-webkit-box',
  overflow: 'hidden',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
  textOverflow: 'ellipsis',
  lineHeight: '18px',
})

export default EllipsisTypography
