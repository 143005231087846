import React from 'react'
import {Stack} from '@mui/material'

const ErrorMessage = ({msg = 'Sorry, something went wrong. Please try again later.', children}) => {
  return (
    <Stack justifyContent="center" alignItems="center" height="100%">
      {children}
      {msg}
    </Stack>
  )
}

export default ErrorMessage
