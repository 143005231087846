import axios from 'axios'
import {useAuth0} from '@auth0/auth0-react'
import {paths} from '../../constant/api'
import {createRequestOptions} from 'util/network'
import { useMutation} from 'react-query'

export const useImportRetryMutation = () => {
    const {getAccessTokenSilently} = useAuth0()

    const retryImportById = useMutation(async(params) => {
        const token = await getAccessTokenSilently()
        console.log(params)
        return await axios.post(
            `${paths.product}/import/retry/${params.serverId}`,
            params,
            createRequestOptions(token)
        )
    },{
      onSuccess: async (data, variables, context) => {},
    },
    {
      onError: async (data, variables, context) => {
        showToast({variant: 'danger', title: 'Error', body: ''})
      },
    })



    return {
        retryImport: retryImportById.mutateAsync,
        retryImportByIdIsSuccess: retryImportById.isSuccess,
        retryImportIsLoading: retryImportById.isLoading,
        retryImportIsSuccess: retryImportById.isSuccess,
    }
}

export default useImportRetryMutation


