import React, {useState} from 'react'
import {useHistory} from 'react-router-dom'
import {Box, Typography, Stack} from '@mui/material'
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@mui/icons-material'
import {isEmpty, sumBy} from 'lodash'

import {ModalType} from 'enums'
import {useConsignments} from 'service/hook'
import {CopytLabel, CopytButton, CopytStyledButton} from 'views_v2/lib/snippets'
import {_replaceWildCards, _getValue} from 'util/string_utils'
import {getCurrency} from 'util/model/setting'

const MdlCreateBox = (p) => {
  const {setModalType, consignee, payload} = p
  const history = useHistory()
  const [showItems, setShowItems] = useState(false)
  const currency = getCurrency()
  const {onCreateConsignBatchAsync, onCreateConsignBatchLoading} = useConsignments()

  const onConfirm = async () => {
    try {
      const data = {
        consigneeId: consignee?.value,
        location: consignee?.location || 'default',
        consignmentIds: !isEmpty(payload?.boxItems) ? payload.boxItems.map((s) => s?.id) : [],
      }
      await onCreateConsignBatchAsync(data)
      history.push('/admin/consignments/batches')
      setModalType(ModalType.UNDEFINED)
    } catch (error) {
      console.error('Error creating consign batch:', error)
    }
  }

  return (
    <Box className="bulk-edit">
      <Box className="modal-title">
        <Box className="modal__heading" mx="0px !important">
          <Typography variant="h1" textAlign="left">
            Create Batch
          </Typography>
        </Box>
      </Box>
      <Box className="modal-body" maxHeight="85vh" padding="0" mb={2}>
        <Stack direction="column" padding={1}>
          <CopytLabel textAlign="center" sx={{fontSize: '1.2rem'}} pt={2}>
            {consignee?.label || 'All Stores'}
          </CopytLabel>
          <CopytLabel
            fontStyle="italic"
            sx={{fontWeight: 500, color: 'var(--primary) !important', padding: '20px'}}
          >
            All items in the batch must be shipped together with the batch summary included in the
            package. Your batch summary will be generated after submission.
          </CopytLabel>
          <Stack direction="column" spacing={2} alignItems="center">
            <CopytButton
              variant="outline"
              sx={{width: '40%', height: '40px'}}
              onClick={() => setShowItems(!showItems)}
            >
              <Box component="span" fontSize="1.2rem">
                {payload?.boxItems?.length}&nbsp;
              </Box>
              item(s) included
              <Box component="span" fontSize="1.2rem">
                {showItems ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </Box>
            </CopytButton>
            {showItems ? (
              <Box width="100%">
                <Stack
                  direction="row"
                  width="100%"
                  justifyContent="space-between"
                  spacing={1}
                  bgcolor="var(--lighter)"
                  borderBottom="1px solid"
                  p={1}
                  alignItems="center"
                >
                  <CopytLabel width="30%">Item Name</CopytLabel>
                  <CopytLabel width="25%">Sku/Style ID</CopytLabel>
                  <CopytLabel width="15%">Size</CopytLabel>
                  <CopytLabel width="15%">Price</CopytLabel>
                </Stack>
                <Box maxHeight="45vh" overflow="hidden auto">
                  {!isEmpty(payload?.boxItems) &&
                    payload?.boxItems.map((item) => {
                      const product = item?.product
                      return (
                        <Stack
                          direction="row"
                          width="100%"
                          justifyContent="space-between"
                          alignItems="center"
                          key={item.id}
                          spacing={1}
                          borderBottom="1px solid var(--light)"
                          padding={1}
                          sx={{
                            '&:hover': {
                              backgroundColor: 'var(--lighter)',
                            },
                          }}
                        >
                          <CopytLabel width="30%">{product?.title}</CopytLabel>
                          <CopytLabel width="25%">{product?.sku}</CopytLabel>
                          <CopytLabel width="15%">{product?.size}</CopytLabel>
                          <CopytLabel width="15%">{product?.desiredReturn}</CopytLabel>
                        </Stack>
                      )
                    })}
                </Box>
              </Box>
            ) : null}
            <Box mt={3}>
              Total List Price:{' '}
              <Box component="span" fontWeight={600}>
                {currency.format(
                  _getValue(
                    sumBy(payload.boxItems, (item) => parseInt(item?.product.desiredReturn, 10)),
                  ),
                )}
              </Box>
            </Box>
          </Stack>
        </Stack>

        <Stack
          direction="row"
          justifyContent="flex-end"
          pr={2}
          pt={1.5}
          mt={4}
          borderTop="1px solid var(--lighter)"
          spacing={2}
        >
          <CopytStyledButton
            variant="outline"
            color="primary"
            onClick={() => setModalType(ModalType.SELECT_APPROVED_ITEMS)}
            disabled={onCreateConsignBatchLoading}
          >
            Back
          </CopytStyledButton>
          <CopytStyledButton
            variant="contained"
            color="primary"
            onClick={onConfirm}
            disabled={onCreateConsignBatchLoading}
          >
            {onCreateConsignBatchLoading ? 'Creating...' : 'Confirm'}
          </CopytStyledButton>
        </Stack>
      </Box>
    </Box>
  )
}

export default MdlCreateBox
