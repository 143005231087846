import React from 'react'
import { Box } from '@mui/material'
import {Description, SelectPlatform, SelectShoes} from '../snippets'

const IssueBody = (p) => {
  if (p?.issue === 'default') return <Box />

  return (
    <Box sx={{mt: 2, mb: 2}}>
      {(p?.issue === 'list' || p?.issue === 'delist') && <SelectShoes {...p}/>}
      {p?.issue !== 'other' && <SelectPlatform {...p} />}
      <Description {...p} />
    </Box>
  )

  return <Box />
}

export default IssueBody
