import React, {useState} from 'react'
import clx from 'classnames'
import { Button } from '@mui/material'
import ArrayUtils from 'util/array_utils'
import BatchItemsTable from './BatchItemsTable'

const getBatchArr = (arr1, arr2) => {
  if (arr2?.length === 0) return []
  return arr2?.filter((x) => arr1.some((y) => y === x?.id))
}

const MdlBatchItems = (p) => {
  const {connections, user, onSaveChanges, sIds} = p
  const [isReady, setIsReady] = useState(false)
  const [arr, setArr] = useState([])

  // if (Object.keys(sIds)?.length === 0) return <div> The requested data does not exist for Batch Items</div>
  
  if (connections?.length > 0 && Object.keys(sIds)?.length > 0 && !isReady) {
    const connectData = connections.reduce((acc, tag) => {
      acc.push(tag?.item)
      return acc
    }, [])

    const flatConnect = connectData?.flat() || []
    const batchIds = ArrayUtils.objectToArray(sIds)
    const batchArr = getBatchArr(batchIds, flatConnect)
    setArr(batchArr)
    setIsReady(true)
  }

  if (!isReady && Object.keys(sIds)?.length > 0) {
    return <div>Please wait while we processed your data</div>
  }

  return (
    <div>
      <div className="modal-title">
        <div className="modal__heading">
          <h1 className="">{Object.keys(sIds)?.length === 0 ? 'Data Not Available': 'Selected Batch'}</h1>
        </div>
      </div>
      <div className="modal-body" style={{ padding: '0 24px' }}>
        <BatchItemsTable rows={arr} />
        <Button
          onClick={() => onSaveChanges({ batchItems: true })}
          className={clx('btn-header', 'primary', 'full-width')}
        >
          Finish
        </Button>
      </div>
    </div>
  )
}

export default MdlBatchItems
