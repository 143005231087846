import {useAuth0} from '@auth0/auth0-react'
import axios from 'axios'
import {apiConfig} from 'config'
import {useEffect, useState} from 'react'

const useLocations = () => {
  const [locations, setLocations] = useState([])
  const [location, setLocation] = useState('')
  const {getAccessTokenSilently} = useAuth0()

  useEffect(() => {
    ;(async () => {
      try {
        const token = await getAccessTokenSilently()
        const locationResponse = await axios.get(`${apiConfig.api_url}/location`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })

        if (locationResponse.status === 200) {
          setLocations([
            {
              value: '',
              label: 'All',
            },
            ...locationResponse.data.data.map((location) => ({
              value: location.name,
              label: location.name,
            })),
          ])
        }
      } catch (e) {
        console.error(e)
      }
    })()
  }, [getAccessTokenSilently])

  return {locations, location, setLocation}
}

export default useLocations
