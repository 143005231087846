import React from 'react'
import clx from 'classnames'
import { Box, Button, Typography } from '@mui/material'
import {HelpOutline as HelpOutlineIcon } from '@mui/icons-material'
import useEnterKeyListener from 'hooks/useEnterKeyListener'

const MdlUnsavedChanges = (p) => {
  useEnterKeyListener({
    querySelectorToExecuteClick: '#routeChange',
  })

  return (
    <Box>
      <Box className="modal-title">
        <Box className="modal__heading">
          <Typography variant="h1">Unsaved Changes</Typography>
        </Box>
      </Box>
      <Box className="modal-body" style={{ padding: '24px 24px 0' }}>
        <Box display="flex" alignItems="center">
          <Box width="100px"><HelpOutlineIcon style={{fontSize:'3.5rem'}} /></Box>
          <Box>
            <Typography mb={2}> You have unsaved changes that will be lost if you decide to continue.</Typography>
            <Typography> Are you sure you want to leave before saving your item?</Typography>
          </Box>
          
         
          </Box>
          <Box style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: 20 }}>
            <Button style={{ marginTop: 20 }} className={clx('btn-header', 'secondary')} onClick={() => p.onCloseModal()}>
              Keep Working
            </Button>
            <Button style={{ marginTop: 20 }} className={clx('btn-header', 'primary')} onClick={() => p?.action.routeChange(true)} id="routeChange">
              Leave Without Saving
            </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default MdlUnsavedChanges
