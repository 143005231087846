import React from 'react'
import clx from 'classnames'
import { Box, Button, Typography } from '@mui/material'
import { Error as ErrorIcon } from '@mui/icons-material'

const MdlListingFailures = ({
  title = 'Listing Failure',
  content = "Item is currently consigned and cannot be marked as sold.",
  onCloseModal = () => null
}) => {
  return (
    <Box>
      <Box className="modal-title">
        <Box className="modal__heading">
          <Typography variant="h1">{title}</Typography>
        </Box>
      </Box>
      <Box className="modal-body" sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
      }}>
        <Box sx={{ display: 'flex' }}>
          <ErrorIcon sx={{ height: '50px' }} />
          <Typography sx={{ ml: 2, mb: 2 }}>{content}</Typography>
        </Box>
        <Button
          className={clx('btn-header', 'secondary')}
          onClick={() => onCloseModal()}
        >
          Ok
        </Button>
      </Box>
    </Box>
  )
}

export default MdlListingFailures
