import {get} from 'lodash'
import {getSold, isConsigned} from 'util/model/product'
import {_getValue} from 'util/string_utils'

export const getPlatformName = (order) => {
  return get(order, 'listingPlatform.platform.name') || 'Elsewhere'
}

export const getConsignEmail = (order) => {
  return get(order, 'listing.product.consign.consignor.email')
}

export const getConsignorId = (order) => {
  return get(order, 'listing.product.consign.consignor.id')
}

export const getLedger = (order) => {
  return get(order, 'ledger')
}

export const getTotalSoldPrice = (order) => {
  return get(order, 'listings[0].totalSoldPrice')
}

export const getActualReturn = (product, user = null) => {
  const sold = getSold(product)
  if (!sold) {
    return 0
  }

  if (user && product?.consign) {
    if (product?.consign?.consignee?.id === user.id) {
      return _getValue(sold?.actualReturn)
    } else if (product?.consign?.consignor?.id === user.id) {
      return _getValue(sold?.consignActualReturn)
    }
  }

  return _getValue(sold?.actualReturn)
}

export const getProfit = (product, user = null) => {
  const sold = getSold(product)
  if (!sold || !product || !parseFloat(sold)) {
    return 0
  }

  if (user && product?.consign) {
    if (product?.consign?.consignee?.id === user.id) {
      return Number(sold?.profit)
    } else if (product?.consign?.consignor?.id === user.id) {
      return Number(sold?.consignActualProfit)
    }
  }

  return Number(sold.profit)
}

export const getFee = (sold) => {
  return Number(get(sold, 'consign.application.fee.value', 0))
}

export const getPayout = (sold) => {
  return Number(get(sold, 'consign.payout', 0))
}
