import React from 'react'
import classnames from 'classnames'

const ToolbarView = ({ bsSize, children, className }) => {
  const classNames = classnames(
    'p-detail-tab-wrapper__panel--flextool',
    className
  )

  return (
    <div className={classNames}>
      {children}
    </div>
  )
}

export default ToolbarView
