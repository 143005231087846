import React from 'react'

const TableFooter = ({children}) => {
  return (
    <div className="table-footer">
      {children}
    </div>
  )
}

export default TableFooter
