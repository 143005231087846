import { createContext } from "react"

export const ToastMesageContext = createContext({})

export const ToastMessageVariant = {
  SUCCESS: 'success',
  DANGER: 'danger'
}

export const ToastMessageVariantText = {
  [ToastMessageVariant.DANGER]: {
    className: 'bg-danger', title: 'Failed', body: 'Operation failed, please try again.'
  },
  [ToastMessageVariant.SUCCESS]: {
    className: 'bg-success', title: 'Success', body: 'Success!'
  },
}

export const defualtValue = { title: '', body: '', variant: ToastMessageVariant.SUCCESS }