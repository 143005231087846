import React, {useState, useEffect, useRef} from 'react'
import {useHistory} from 'react-router-dom'
import useSound from 'use-sound'
import clx from 'classnames'
import {Button, Tabs, Tab} from '@mui/material'
import {
  SpeakerNotesOff as SpeakerNotesOffIcon,
  StickyNote2 as StickyNote2Icon,
} from '@mui/icons-material'
import {isEmpty} from 'lodash'

import {InventoryQueryType} from 'constant/query_type'
import {useGlobalStore} from 'provider/global_store/hook'
import {LoadingBar} from 'views_v2/lib/snippets'
import {ModalType, ToastType} from 'enums'
import {urlWithoutParam, getParams} from 'util/window_utils'
import success from 'assets/sounds/success.wav'
import error from 'assets/sounds/error.wav'
import {useExport} from 'service/hook'
import {useAuditItemInventory} from 'service/hook/useItem'
import InventoryModals from './components/InventoryModals'
import InventoryToast from './components/InventoryToast'
import {AuditTable} from './components/common'
import './scss/parent.scss'

const Audit = () => {
  const {isEnterprise} = useGlobalStore()
  const [onSuccess] = useSound(success)
  const [onError] = useSound(error)
  const history = useHistory()
  const timerRef = useRef(null)
  const [currentPage, setCurrentPage] = useState(filter?.page)
  const [mount, setMount] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [queries, setQueries] = useState({})
  const [tabPanel, setTabPanel] = useState(0)
  const [modalType, setModalType] = useState(ModalType.UNDEFINED)
  const [item, setItem] = useState({})
  const [proceed, setProceed] = useState('')
  const [search, setSearch] = useState('')
  const [searchArr, setSearchArr] = useState([])
  const [toastType, setToastType] = useState(ToastType.UNDEFINED)
  const [toastContent, setToastContent] = useState({title: null, content: null})
  const [filter, setFilter] = useState({
    skip: 0,
    take: 100, //default page size
    page: 0,
  })
  const {verified, unverified} = useAuditItemInventory(
    InventoryQueryType.AUDIT,
    filter,
    searchText,
    queries,
  )

  const exportTotal = tabPanel ? verified?.total || 10000 : unverified?.total || 10000

  const {isErrorExport, isSuccessExport, invalidateExportQueries, refetchExport} = useExport({
    type: tabPanel ? 'verified' : 'unverified',
    filter: {...filter, take: exportTotal, limit: exportTotal},
    queries,
    exportTye: 'inventory',
  })

  const [count, setCount] = useState({
    unverified: unverified?.total,
    verified: verified?.total,
  })

  const refetchItems = () => {
    setTimeout(() => {
      unverified?.refetch()
      verified?.refetch()
    }, 5000)
  }

  useEffect(() => {
    return () => {
      if (timerRef?.current) {
        clearTimeout(timerRef.current)
      }
    }
  }, [])

  useEffect(() => {
    if (!mount) {
      const params = getParams(history)

      if (params?.hasOwnProperty('page')) {
        const pageNumber = Number(params?.page)
        if (pageNumber !== filter?.page && pageNumber > 0) {
          setFilter((f) => ({...f, page: pageNumber, skip: pageNumber * f.take}))
        }
      }
      setMount(true)
    }
  }, [mount])

  useEffect(() => {
    setCurrentPage(currentPage)
    onPageChange(currentPage)
  }, [currentPage])

  useEffect(() => {
    if (currentPage !== filter?.page) {
      setCurrentPage(filter?.page)
      onPageChange(filter?.page)
    }
  }, [filter?.page])

  useEffect(() => {
    setCount({
      unverified: unverified?.total,
      verified: verified?.total,
    })
  }, [unverified?.items, verified?.items])

  useEffect(() => {
    let arr = []
    if (!tabPanel && search) {
      arr = unverified?.items?.all?.filter((s) => s?.internalSku?.toLowerCase() === search.toLowerCase())
      if (arr?.length > 0) {
        onSuccess()
        const updatedFormFields = (({
          acquiredDate,
          assets,
          boxCondition,
          boxDimensions,
          boxWeight,
          brand,
          category,
          color,
          comments,
          condition,
          description,
          desiredReturn,
          flaws,
          id,
          internalSku,
          location,
          price,
          quantity,
          releaseDate,
          retailPrice,
          shippingMethod,
          size,
          sku,
          subLocation,
          subcategory,
          tagStatus,
          title,
          isVerified,
        }) => ({
          acquiredDate,
          assets,
          boxCondition,
          boxDimensions,
          boxWeight,
          brand,
          category,
          color,
          comments,
          condition,
          description,
          desiredReturn,
          flaws,
          id,
          internalSku,
          location,
          price,
          quantity,
          releaseDate,
          retailPrice,
          shippingMethod,
          size,
          sku,
          subLocation,
          subcategory,
          tagStatus,
          title,
          isVerified,
        }))(arr[0])

        unverified?.onVerifiedItem({
          ...updatedFormFields,
          isVerified: 1,
        })
        setItem(arr[0])
        setProceed(arr[0]?.id)
        setToastType(ToastType.SUCCESS)
      } else {
        onError()
        setItem({})
        setProceed('')
        setSearch('')
        setToastType(ToastType.ERROR)
      }
    }
    setSearchArr(arr)
  }, [search])

  useEffect(() => {
    if (isSuccessExport || unverified.isExportSuccess) {
      setToastType(ToastType.EXPORTED)
    } else if (isErrorExport || unverified.isExportError) {
      setToastType(ToastType.ERROR)
      setToastContent({
        title: 'Failed',
        content: 'An error has occured while saving your changes. Please try again!',
      })
    }
    invalidateExportQueries()
  }, [isErrorExport, isSuccessExport, unverified.isExportError, unverified.isExportSuccess])

  useEffect(() => {
    if (modalType === ModalType.UNDEFINED && proceed?.length > 0) setProceed('')
  }, [modalType])

  const onPageChange = (newPage) => {
    if (timerRef?.current) {
      clearTimeout(timerRef?.current)
    }

    timerRef.current = setTimeout(() => {
      setFilter((f) => ({...f, page: newPage, skip: newPage * f.take}))
      const newUrl = urlWithoutParam(newPage)
      window.history.pushState({path: newUrl}, '', newUrl)
    }, 1000)
  }

  const handleChange = (e, newValue) => {
    setTabPanel(newValue)
  }

  const routeChange = (route, data) => {
    history.push({pathname: route, data: data})
  }

  const onSaveChanges = (p) => {
    if (p?.onVerified) {
      unverified?.onAuditCSVExport(verified?.items?.data)
    } else if (p?.sort) {
      setQueries(p?.sort)
    } else if (p?.onExport) {
      refetchExport()
    }

    setProceed('')
    setSearch('')
    setModalType(ModalType.UNDEFINED)
  }

  if (!isEnterprise) {
    if (!verified?.isLoading && !unverified?.isLoading) {
      routeChange('/admin/inventory/viewInventory')
    }
    return <LoadingBar text="Please wait while we verifying your account" />
  }

  const isDisabledFinishBtn =
    unverified.isLoading || unverified.isExportLoading || verified.isExportLoading

  const isTabDisabled =
    verified?.isLoading || verified?.isFetching || unverified?.isLoading || unverified?.isFetching

  return (
    <div className={clx('p-detail-flex', 'inventory', 'view-inventory', 'audit')}>
      <div className="p-detail-toolbar no-print v2">
        <div className="right">
          <Button
            disabled={
              isDisabledFinishBtn || (isEmpty(unverified?.items?.data) && isEmpty(verified?.items?.data))
            }
            className={clx('btn-header', 'primary')}
            onClick={() => setModalType(ModalType.AUDIT)}
          >
            {isDisabledFinishBtn ? 'Please Wait' : 'Finish'}
          </Button>
        </div>
      </div>
      <Tabs
        value={tabPanel}
        onChange={handleChange}
        aria-label="icon label tabs"
        scrollButtons
        allowScrollButtonsMobile
        centered
      >
        <Tab
          icon={<SpeakerNotesOffIcon />}
          iconPosition="start"
          label={`Unverified - (${count?.unverified})`}
          disabled={isTabDisabled}
        />
        <Tab
          icon={<StickyNote2Icon />}
          iconPosition="start"
          label={`Verified - (${count?.verified})`}
          disabled={isTabDisabled}
        />
      </Tabs>
      <AuditTable
        {...{
          currentPage,
          filter,
          handleChange,
          item,
          proceed,
          tabPanel,
          routeChange,
          search,
          setCurrentPage,
          setModalType,
          setItem,
          setProceed,
          setFilter,
          onSaveChanges,
        }}
        setSearch={(s) => {
          setSearch(s)
          refetchItems()
        }}
        isLoading={tabPanel ? verified?.isLoading : unverified?.isLoading}
        isFetching={tabPanel ? verified?.isFetching : unverified?.isFetching}
        total={tabPanel ? verified.total : unverified.total}
        items={tabPanel ? verified?.items.data : unverified?.items.data}
      />
      <InventoryModals
        {...{
          count,
          item,
          modalType,
          setModalType,
          onSaveChanges,
        }}
        onSoundPlay={search && searchArr?.length > 0 ? onSuccess : onError}
        verifiedList={verified?.items?.data}
      />
      <InventoryToast
        {...{
          item,
          toastType,
          setToastType,
        }}
        payload={toastContent}
      />
    </div>
  )
}

export default Audit
