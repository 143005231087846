import React, {useState} from 'react'
import clx from 'classnames'
import {styled, alpha} from '@mui/material/styles'
import {Box, Button, Divider, Menu} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

import {categorySizes} from 'assets/data'
import './scss/country-dropdown.scss'
import FieldGroup from './FieldGroup'

const countries = ['US', 'UK', 'JP', 'KR', 'EU', 'CUSTOM']

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({theme}) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 300,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}))

export default function SizeDropdown({category, className = '', value, onChange}) {
  const [anchorEl, setAnchorEl] = useState(null)
  const [country, setCountry] = useState('US')
  const [selectedSize, setSelectedSize] = useState(value)
  const open = Boolean(anchorEl)

  const handleClick = (event) => setAnchorEl(event.currentTarget)

  const handleClose = () => setAnchorEl(null)

  const handleSizeChange = (size) => {
    const rules = /[^0-9\\./A-Za-z]+/
    if (!rules.test(size)) {
      setSelectedSize(size)
      onChange(size)
      handleClose()
    }
  }

  const isOtherCategory = category === 'Accessories' || category === 'Apparel'
  let sizes = categorySizes[country?.toLowerCase()]

  if (country === 'US') {
    if (category?.toLowerCase().includes('kid')) {
      sizes = categorySizes.kid
    } else if (category?.toLowerCase()?.includes('toddler')) {
      sizes = categorySizes.toddler
    } else if (isOtherCategory) {
      sizes = categorySizes.apparel
    } else {
      sizes = categorySizes.us
    }
  }

  const isCustom = country === 'CUSTOM'

  return (
    <Box
      className={clx('customized-country', className)}
      sx={{
        width: '100%',
      }}
    >
      <Button
        id="customized-country-button"
        className={clx('country')}
        variant="contained"
        sx={{
          color: selectedSize ? 'var(--primary) !important' : 'black',
          textTransform: 'capitalize !important',
        }}
        disableElevation
        onClick={handleClick}
        endIcon={!open ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
      >
        {selectedSize || 'Size'}
      </Button>
      <StyledMenu
        id="customized-menu"
        MenuListProps={{
          'aria-labelledby': 'customized-country-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Divider width={0} />
        <Box className="select-size">Select Size</Box>
        <Box className="country">
          {countries?.map((_country, index) => {
            const isActive = country === _country
            const disabled =
              _country !== 'CUSTOM' &&
              _country !== 'US' &&
              (categorySizes[_country?.toLowerCase()]?.length === 0 || isOtherCategory)
            return (
              <Box
                key={`size-${index}`}
                onClick={() => (!disabled ? setCountry(_country) : null)}
                className={clx(
                  'country-section',
                  isActive ? 'active' : null,
                  disabled ? 'disabled' : null,
                )}
              >
                {_country}
              </Box>
            )
          })}
        </Box>
        {isCustom ? (
          <Box className="custom-size">
            <FieldGroup
              type="text"
              className="custom-size-input"
              onChange={(event) => handleSizeChange(event.target.value)}
              placeholder="Enter custom size"
              value={selectedSize || ''}
            />
          </Box>
        ) : (
          <Box className="country-body">
            {sizes?.map((size, index) => (
              <Box key={index} onClick={() => handleSizeChange(size)}>
                {size}
              </Box>
            ))}
          </Box>
        )}
      </StyledMenu>
    </Box>
  )
}
