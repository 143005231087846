import React, {useEffect, useState} from 'react'
import {useHistory, withRouter} from 'react-router-dom'
import {useAuth0} from '@auth0/auth0-react'
import axios from 'axios'
import * as Sentry from '@sentry/react'
import {AppBar, Box, Stack} from '@mui/material'
import UpgradeIcon from '@mui/icons-material/Upgrade'
// reactstrap components
// TODO: Convert to MaterialUI components
import {Toast, ToastHeader} from 'reactstrap'

import {apiConfig} from 'config'
import {ModalType} from 'enums'
import {createRequestOptions} from 'util/network'
import {useGlobalStore} from 'provider/global_store/hook'
import {useNotificationMutation} from 'service/hook/useNotificationMutation'
import {useNotifications} from 'service/hook/useNotifications'
import {CustomButton} from 'views_v2/modules/Inventory/snippets/headers'
import MainMenu from './MainMenu'
import AccountMenu from './AccountMenu'
import NotificationsMenu from './NotificationsMenu'
import NavBarModals from './NavBarModals'
import '../../assets/scss/adminNavbar.scss'

function AdminNavbar(props) {
  const {user, getAccessTokenSilently} = useAuth0()
  const history = useHistory()
  const {isEnterprise, userLoading} = useGlobalStore()
  const {readNotification} = useNotificationMutation()
  const [defaultDropdown, setDefaultDropdown] = useState({value: 'default', label: 'Select Type'})
  const [shoe, setShoe] = useState([])
  const [shoeOptions, setShoeOptions] = useState([])
  const [platform, setPlatform] = useState('')
  const [platformOptions, setPlatformOptions] = useState([])
  const [description, setDescription] = useState('')
  const [email, setEmail] = useState(user?.email)
  const [errorMessage, setErrorMessage] = useState('')
  const {notifications, notificationCountData, refetchNotifications} = useNotifications(0, 100, {
    option: {cacheTime: 1000, refetchInterval: 5000},
  })
  const [showNotificationToast, setShowNotificationToast] = useState(false)
  const [notificationCount, setNotificationCount] = useState(0)
  const [initNotification, setInitNotification] = useState(true)
  const [modalType, setModalType] = useState(ModalType.UNDEFINED)

  useEffect(() => {
    ;(async () => {
      try {
        //Get the title of shoes
        const token = await getAccessTokenSilently()
        let shoeTitles
        if (defaultDropdown.value === 'list') {
          const response = await axios.get(`//${apiConfig.api_prefix}/item/?mode=inventory`, v)

          shoeTitles = response.data.map((item) => {
            return {value: item.title, label: item.title, id: item.id}
          })
          setShoeOptions([...shoeTitles])
        } else if (defaultDropdown.value === 'delist') {
          const response = await axios.get(
            `//${apiConfig.api_prefix}/item/?mode=inventory`,
            createRequestOptions(token),
          )

          shoeTitles = response.data
            .filter((item) => {
              if (
                item.listing &&
                !item.listing.soldPlatform &&
                !item.listing.soldDate &&
                !item.listing.soldPrice
              )
                return true
              else return false
            })
            .map((item) => {
              return {value: item.title, label: item.title, id: item.id}
            })
          setShoeOptions([...shoeTitles])
        }

        if (
          (!platformOptions || platformOptions.length === 0) &&
          (defaultDropdown.value !== 'other' || defaultDropdown.value !== 'default')
        ) {
          const supportedPlatforms = props?.platforms?.data || []
          if (supportedPlatforms?.length > 0) {
            let platformTitles = supportedPlatforms?.map((item) => {
              return {id: item.id, value: item.name, label: item.name}
            })
            setPlatformOptions([...platformTitles])
          }
        }
      } catch (e) {
        Sentry.captureException(e)
        console.error(e)
      }
    })()
  }, [getAccessTokenSilently, defaultDropdown])

  const toggleModal = () => setModalType(ModalType.SUPPORT)

  const handleCheckbox = (e) => {
    var temp = {
      value: e?.toLowerCase(),
    }

    var updated = false
    var tempPlatforms = []
    if (platform.platforms) {
      tempPlatforms = platform.platforms
      for (var i = 0; i < platform.platforms.length; i++) {
        if (platform.platforms[i].value === temp.value) {
          tempPlatforms.splice(i, 1)
          updated = true
        }
      }
    }
    if (!updated) {
      tempPlatforms.push(temp)
    }

    setPlatform({
      ...platform,
      platforms: tempPlatforms,
    })
  }

  const onCloseModal = () => {
    setDefaultDropdown({value: 'default', label: 'Select Type'})
    setErrorMessage('')
    setDescription('')
    setEmail('')
    setShoe([])
    setShoeOptions([])
    setPlatform('')
    setModalType(ModalType.UNDEFINED)
  }

  const submitTicket = async () => {
    let platformsSelected =
      platform && platform.platforms && platform.platforms.length > 0
        ? platform.platforms.map((p) => p.value).join(', ')
        : ''

    var formError
    if (!shoe.title && (defaultDropdown.value === 'list' || defaultDropdown.value === 'delist')) {
      formError = 'No Shoe Selected'
      setErrorMessage(formError)
      return
    } else if (!platformsSelected && defaultDropdown.value != 'other') {
      formError = 'No Platform Selected'
      setErrorMessage(formError)
      return
    } else if (!description) {
      formError = 'No Description'
      setErrorMessage(formError)
      return
    }

    let form = {
      subject: '',
      description: '',
      email: '',
    }

    if (defaultDropdown.value === 'list' || defaultDropdown.value === 'delist') {
      form = {
        description:
          'Shoe: ' +
          shoe.title +
          '<br/>' +
          'Shoe ID: ' +
          shoe.id +
          '<br/>' +
          'Platforms: ' +
          platformsSelected +
          '<br/>' +
          'Description: ' +
          description,
        subject: defaultDropdown.label + ' ' + shoe.title,
        email: user.email,
      }
    } else if (defaultDropdown.value === 'platform') {
      form = {
        description: 'Platforms: ' + platformsSelected + '<br/>' + 'Description: ' + description,
        subject: defaultDropdown.label + ' ' + platformsSelected,
        email: user.email,
      }
    } else {
      form = {
        description: description,
        subject: defaultDropdown.label,
        email: user.email,
      }
    }

    const token = await getAccessTokenSilently()

    const response = await axios.post(
      `//${apiConfig.api_prefix}/freshdesk/createTicket`,
      {...form},
      createRequestOptions(token),
    )
    onCloseModal()
  }

  const routeChange = (route, data) => {
    history.push({pathname: route, data: data})
  }

  let timeoutTracker
  useEffect(() => {
    if (notificationCountData && initNotification) {
      setNotificationCount(notificationCountData)
      setInitNotification(false)
    } else if (notificationCountData && notificationCountData > notificationCount) {
      setNotificationCount(notificationCountData)
      setShowNotificationToast(true)

      timeoutTracker = setTimeout(() => {
        setShowNotificationToast(false)
      }, 3000)
    }
    return () => {
      timeoutTracker && clearTimeout(timeoutTracker)
    }
  }, [notificationCountData])

  const {handleMiniClick, isMobileScreen, sidebarOpened} = props

  return (
    <Box sx={{flexGrow: 1}}>
      <AppBar position="static" sx={{backgroundColor: 'white', padding: '5px 0'}}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <MainMenu {...{handleMiniClick, sidebarOpened}} />
          {!userLoading && (
            <Stack direction="row">
              {!isEnterprise && (
                <CustomButton
                  title={`Upgrade ${!isMobileScreen ? 'to Enterprise' : null}`}
                  onClick={() => history.push('/admin/profile?toggle=On')}
                  data-testid="upgrade-to-enterprise"
                  icon={<UpgradeIcon />}
                  color="secondary"
                />
              )}
              <NotificationsMenu
                {...{
                  notifications,
                  readNotification,
                  refetchNotifications,
                  routeChange,
                }}
                badgeContent={notificationCountData}
              />
              <AccountMenu history={props.history} {...{toggleModal}} />
              {showNotificationToast && (
                <Toast
                  style={{
                    backgroundColor: 'var(--success)',
                    position: 'absolute',
                    top: '48px',
                    right: '20px',
                  }}
                  fade={true}
                >
                  <ToastHeader>You have a new notification.</ToastHeader>
                </Toast>
              )}
            </Stack>
          )}
        </Stack>
      </AppBar>
      <NavBarModals
        {...{
          defaultDropdown,
          handleCheckbox,
          modalType,
          toggleModal,
          platformOptions,
          onCloseModal,
          setModalType,
          setDefaultDropdown,
          shoeOptions,
          shoe,
          setShoe,
          setDescription,
          submitTicket,
        }}
      />
    </Box>
  )
}

export default withRouter(AdminNavbar)
