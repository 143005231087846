import {isArray, isEmpty} from 'lodash'
import {Box, Grid, Typography, Tooltip} from '@mui/material'
import {GridFooterContainer, GridFooter} from '@mui/x-data-grid'

import {getCurrency} from 'util/model/setting'
import {_getPercentage, _getValue} from 'util/string_utils'

const CustomFooter = (props) => {
  const {total, label, tooltip, otherFooters = [], isCardList} = props || {}
  const currency = getCurrency()
  const isMobile = window.innerWidth <= 900
  let totalComponent = []

  if (!isMobile) {
    if (!isEmpty(label) && isArray(label)) {
      totalComponent.push(
        label?.map((_, index) => {
          const tooltipContent = tooltip[index] ? (
            <Tooltip key={index} title={tooltip[index]}>
              <Box component="span">
                Total {label[index]}: {currency.format(total[index])}
              </Box>
            </Tooltip>
          ) : (
            <Box component="span">
              Total {label[index]}: {currency.format(total[index])}
            </Box>
          )

          return (
            <Typography key={index} fontSize="0.9rem" fontWeight="bold" ml={1.2}>
              {tooltipContent}
            </Typography>
          )
        }),
      )
    } else {
      totalComponent.push(
        <Typography key="totalComponent" fontSize="0.9rem" fontWeight="bold" ml={1.2}>
          Total {label}: {currency.format(total)}
        </Typography>,
      )
    }
  }

  return (
    <GridFooterContainer>
      <Grid container gap={1} justifyContent="space-between !important" alignItems="center">
        {isMobile ? (
          <Grid item />
        ) : (
          <>
            <Grid item>{totalComponent.map((comp, i) => comp)}</Grid>
            {!isEmpty(otherFooters) &&
              otherFooters.map((footer, i) => {
                let footerValue = currency.format(footer?.value)
                if (footer.label === 'Total Profit') {
                  footerValue = `${currency.format(footer?.value)} (${footer?.percentage}%)`
                }

                return (
                  <Grid key={i}>
                    {!isMobile && (
                      <Typography fontSize="0.9rem" fontWeight="bold" ml={1.2}>
                        {footer?.label}: {footerValue}
                      </Typography>
                    )}
                  </Grid>
                )
              })}
          </>
        )}
        <Grid item>
          <GridFooter />
        </Grid>
      </Grid>
    </GridFooterContainer>
  )
}

export default CustomFooter
