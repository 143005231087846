import {Stack, Typography} from '@mui/material'
import {Doughnut} from 'react-chartjs-2'

import Container from '../container'
import {COLORS} from '../constants'

const Breakdown = ({category, inventory}) => {
  let count = {
    owned: 0,
    consigned: 0,
  }

  switch (category) {
    case 'all':
      count.owned =
        (inventory.data?.unlisted.count.owned || 0) + (inventory.data?.listed.count.owned || 0)
      count.consigned =
        (inventory.data?.unlisted.count.consigned || 0) + (inventory.data?.listed.count.consigned || 0)
      break
    case 'unlisted':
      count.owned = inventory.data?.unlisted.count.owned || 0
      count.consigned = inventory.data?.unlisted.count.consigned || 0
      break
    case 'listed':
      count.owned = inventory.data?.listed.count.owned || 0
      count.consigned = inventory.data?.listed.count.consigned || 0
      break
  }

  const data = {
    labels: ['Owned', 'Consigned'],
    datasets: [
      {
        data: [inventory.isLoading ? 50 : count.owned, inventory.isLoading ? 50 : count.consigned],
        backgroundColor: [COLORS.OWNED, COLORS.CONSIGNED],
      },
    ],
  }

  const options = {
    cutout: '70%',
    rotation: -90,
    circumference: 180,
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
      },
      tooltip: {
        enabled: true,
      },
    },
  }

  return (
    <Container>
      <Stack spacing={2}>
        <Typography fontWeight={800} fontSize={20} color="black">
          Inventory Breakdown
        </Typography>
        <Stack spacing={1}>
          <Typography fontWeight={600} color="black">
            Owned vs Consigned
          </Typography>

          <Stack spacing={0.5}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography fontSize={14} color="black">
                Owned Products:
              </Typography>
              <Typography fontSize={14} color="black">
                {count.owned || 0}
              </Typography>
            </Stack>

            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography fontSize={14} color="black">
                Consigned Products:
              </Typography>
              <Typography fontSize={14} color="black">
                {count.consigned || 0}
              </Typography>
            </Stack>
          </Stack>

          <Doughnut data={data} options={options} />
        </Stack>
      </Stack>
    </Container>
  )
}

export default Breakdown
