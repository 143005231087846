import React, {useState} from 'react'
import {Box, LinearProgress, Stack, Typography} from '@mui/material'

import {ModalType} from 'enums'
import {CopytLabel, CopytStyledButton} from 'views_v2/lib/snippets'
import {Input as InputField} from 'reactstrap'
import {COLORS} from 'views_v2/modules/Dashboard/constants'

const MdlBulkScanInDuplicate = (p) => {
  const {setModalType, onCloseModal, onSaveChanges, selectedProduct, duplicateProduct} = p
  const [count, setCount] = useState(undefined)

  const handleChange = (event) => {
    const newValue = event.target.value

    if (!/^\d*\.?\d*$/.test(newValue)) return
    if (newValue > 100) return

    setCount(Number(newValue))
  }

  return (
    <Box>
      <Box className="modal-title">
        <Box className="modal__heading" mx="0px !important">
          <Typography variant="h1" textAlign="left">
            Duplicate Product
          </Typography>
        </Box>
      </Box>

      <LinearProgress
        sx={{
          opacity: duplicateProduct.isLoading ? 1 : 0,
          backgroundColor: 'white',
          '& .MuiLinearProgress-bar': {
            backgroundColor: COLORS.PRIMARY,
          },
        }}
      />

      <Box padding={4}>
        <Stack key={selectedProduct.sku} direction="row" gap={4} alignItems="center">
          <Stack
            width={124}
            overflow="hidden"
            borderRadius={2}
            bgcolor="white"
            position="relative"
            flexShrink={0}
          >
            {selectedProduct.assets?.length ? <img src={selectedProduct.assets[0].asset.url} /> : null}
          </Stack>
        </Stack>
        <Typography className="field-sku" fontSize={16} fontWeight={600} color="black">
          {selectedProduct.sku}
        </Typography>
        <Typography className="field-sku" fontSize={14} color="black">
          {selectedProduct.title}
        </Typography>
      </Box>

      <Box paddingX={4}>
        <CopytLabel>Duplicate Count</CopytLabel>
        <InputField
          readOnly={duplicateProduct.isLoading}
          type="numeric"
          placeholder="Enter the number of duplicates"
          value={count}
          onChange={handleChange}
          style={{
            color: 'var(--primary)',
          }}
        />
      </Box>

      <Box className="modal-body" p={0}>
        <Stack
          direction="row"
          justifyContent="flex-end"
          pr={2}
          py={1.5}
          mt={4}
          borderTop="1px solid var(--lighter)"
          spacing={2}
        >
          <CopytStyledButton
            variant="outline"
            color="primary"
            onClick={onCloseModal}
            disabled={duplicateProduct.isLoading}
          >
            Cancel
          </CopytStyledButton>
          <CopytStyledButton
            variant="contained"
            color="primary"
            id="id-listall-submit"
            disabled={!count || duplicateProduct.isLoading}
            onClick={async () => {
              await onSaveChanges({onBulkScanInDuplicate: {product: selectedProduct, count: count}})
              setModalType(ModalType.UNDEFINED)
            }}
          >
            Submit
          </CopytStyledButton>
        </Stack>
      </Box>
    </Box>
  )
}

export default MdlBulkScanInDuplicate
