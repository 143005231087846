import {ListingPlatformStatus} from '../../constant/listing_platform_status'
import {get} from 'lodash'

//todo: fix im multi sold to implement
export const getSold = (listing) => {
  return get(listing, 'solds[0]')
}

export const getSoldPlatformName = (listing) => {
  return get(listing, 'solds[0].listingPlatform.platform.name')
}

export const getDefaultLP = (listing) => {
  return get(listing, 'listingPlatforms[0]')
}

export const getDefaultPlatformName = (listing) => {
  return get(listing, 'listingPlatforms[0].platform.name')
}

export const getHasListingFailed = (listing) => {
  const platforms = get(listing, 'listingPlatforms') || []

  return platforms.some((v) => v.status === ListingPlatformStatus.LISTING_FAILED)
}

export const getProfit = (listings, isConsigned, isEnterprise) => {
  return get(
    listings,
    isConsigned && !isEnterprise ? '[0].solds[0].consignActualProfit' : '[0].solds[0].profit',
    0,
  )
}
