import React from 'react'
import {Box, Stack, Typography} from '@mui/material'
import {GppGood as GppGoodIcon} from '@mui/icons-material'
import {CopytStyledButton} from 'views_v2/lib/snippets'

const MdlAuditFinish = (p) => {
  const {count, onSaveChanges} = p

  return (
    <Box padding="0 !important">
      <Box className="modal-title">
        <Box className="modal__heading" mx="0px !important">
          <Typography variant="h1" textAlign="left">
            Success
          </Typography>
        </Box>
      </Box>
      <Box className="modal-body" padding={0} my={2}>
        <Stack direction="row" px={2} spacing={2} alignItems="center">
          <GppGoodIcon sx={{fontSize: '4rem !important', color: 'green'}} />
          <Stack direction="column" spacing={1}>
            <Typography color="var(--gray-dark) !important">
              Total Verified Items:{' '}
              <Box component="span" fontWeight={600}>
                {count?.verified?.toLocaleString()}
              </Box>
            </Typography>
            <Typography color="var(--gray-dark) !important">
              Remaining Unverified Items:{' '}
              <Box component="span" fontWeight={600}>
                {count?.unverified?.toLocaleString()}
              </Box>
            </Typography>
          </Stack>
        </Stack>

        <Stack
          direction="row"
          justifyContent="flex-end"
          pr={2}
          pt={2}
          mt={2}
          borderTop="1px solid var(--secondary)"
        >
          <CopytStyledButton
            variant="contained"
            color="primary"
            onClick={() => onSaveChanges({onVerified: true})}
          >
            Submit
          </CopytStyledButton>
        </Stack>
      </Box>
    </Box>
  )
}

export default MdlAuditFinish
