import React from 'react'
import { Box, Grid } from '@mui/material'
import {
  InventoryDetails,
  InventoryImages,
  ConsignorInformation,
  InventoryTracking,
  PricingData,
} from 'views_v2/modules/Inventory/components/cards'
import { isConsigned } from 'util/model/product'
import { CopytButton } from 'views_v2/lib/snippets'
import { ModalType } from 'enums'

const SimplifiedLayout = (p) => {
  const {
    formFields,
    imageArr,
    deleteImage,
    editMode,
    forceUpdate,
    handleInputChange,
    isEnterprise,
    setFormFields,
  } = p

  return (
    <Grid container gap={1}>
      <Grid item xs={12} lg={8}>
        <Grid container gap={2}>
          <Grid item xs={12} md={5.5} lg={5.8}>
            <InventoryImages
              {...{
                forceUpdate,
                formFields,
                imageArr,
                deleteImage,
                setFormFields,
              }}
              isSimplified
            />
          </Grid>
          <Grid item xs={12} md={5.5} lg={5.8}>
            <InventoryDetails
              {...p}
              isConsigned={isConsigned(formFields)}
              onChange={(e) => {
                Object.entries(e).map(([key, value]) => {
                  setFormFields({ ...formFields, [key]: value })
                })
              }}
              isSimplified
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={3.9} display="flex" flexDirection="column">
        <ConsignorInformation
          {...{ editMode, formFields, setFormFields }}
          consignor={formFields?.consign || null}
          consignorEmail={formFields?.consign?.consignor?.consignorEmail}
          feeStructure={formFields?.feeStructure}
          isConsignorEmailReadOnly={isConsigned(formFields)}
          isSimplified
        />
        {isEnterprise && (
          <InventoryTracking
            {...{
              isEnterprise,
              editMode,
              handleInputChange,
              formFields,
              setFormFields,
            }}
            location={formFields?.location}
            hasError={p?.hasError}
            errorMessage={p?.errorMessage}
            internalSku={formFields?.internalSku}
          />
        )}
        <Box display="flex">
          <CopytButton
            sx={{
              width: '100%',
              marginBottom: '10px',
            }}
            variant="outline"
            color="primary"
            onClick={() => p?.setModalType(ModalType.ADDITIONAL_FIELDS)}
          >
            Additional Fields +
          </CopytButton>
        </Box>
      </Grid>
      {/* TODO: // Hide for the meantime */}
      {/* <Grid item xs={12}>
				<PricingData
					{...{
						isEnterprise
					}}
				/>
			</Grid> */}
    </Grid>
  )
}

export default SimplifiedLayout
