import React from 'react'
import PropTypes from 'prop-types'
import clx from 'classnames'
import {Modal, ModalBody} from 'reactstrap'
import CloseIcon from '@mui/icons-material/Close'

import './scss/copyt-modal.scss'

const CloseButton = ({hidden, onClick}) => {
  if (hidden) return null
  return (
    <CloseIcon
      color="white"
      sx={{mt: '-10px', mr: 1, float: 'right', cursor: 'pointer'}}
      onClick={onClick}
    />
  )
}

const CopytModal = ({id, children, className, closeButton, show, size, toggle}) => {
  const classNames = clx('copyt-modal__v2', 'copyt_modal_container', size || null, className || null)

  return (
    <Modal
      {...{toggle}}
      id={id || null}
      className={classNames}
      aria-labelledby="contained-modal-title"
      container={this}
      isOpen={show}
      centered={show}
      backdrop={show}
    >
      <ModalBody>
        <CloseButton hidden={closeButton?.hidden || false} onClick={closeButton?.onClick || toggle} />
        {children}
      </ModalBody>
    </Modal>
  )
}

CopytModal.propTypes = {
  children: PropTypes.node,
  closeButton: PropTypes.oneOfType([
    PropTypes.shape({
      hidden: PropTypes.bool,
      onClick: PropTypes.func,
    }),
    PropTypes.instanceOf(null),
  ]),
  disableBackgroundClick: PropTypes.bool,
  enforceFocus: PropTypes.bool,
  show: PropTypes.bool,
  isOpen: PropTypes.bool,
  size: PropTypes.oneOf(['xxs', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl', PropTypes.instanceOf(null)]),
  style: PropTypes.object,
  onEnter: PropTypes.func,
  toggle: PropTypes.func,
  onClose: PropTypes.func,
  title: PropTypes.string,
}

CopytModal.defaultProps = {
  show: false,
  isOpen: false,
  size: 'md',
  disableBackgroundClick: false,
  enforceFocus: false,
}

export default CopytModal
