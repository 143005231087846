import React, { useCallback, useEffect, useState } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import { useRedirCallback } from 'hooks/use_redir_callback'
import { usePlatformMarket } from 'service/hook/use_market'
import { Button } from 'reactstrap'
import { AppConfig } from 'config'
import qs from 'querystring'
import {getPaypalScopes} from 'views/platforms/lib/util'

const PaypalCallback = (props) => {
  const history = useHistory();
  // const [authCode, setAuthCode] = useState(null);
  const [state, setState] = useState({ message: '', state: 'loading' })
  const { paypalSetup } = usePlatformMarket()

  const process = useCallback(async () => {
    try {
      const params = new URLSearchParams(window.location.search);
      const code = params.get('code');
      setState((s) => ({ ...s, state: 'loading' }))

      if (!code) {
        setState((s) => ({ ...s, state: 'failed', message: 'No code acquired' }))
        return
      }
      // setAuthCode(code)
      // await setupPaypal(code)

      const clientId = `${AppConfig.paypal_app_client_id}`
      const clientSecret = `${AppConfig.paypal_app_client_secret}`

      // Create a basic Base64 encoded client id:secret string
      const credentials = Buffer.from(`${clientId}:${clientSecret}`).toString('base64');

      // Define the PayPal token URL and headers
      const tokenUrl = `${AppConfig.paypal_api_url}/v1/oauth2/token`;
      const headers = {
        Accept: '*/*',
        // 'Accept-Language': 'en_US',
        'content-type': 'application/x-www-form-urlencoded',
        Authorization: `Basic ${credentials}`,
      };

      // Fetch the token from PayPal
      const response = await fetch(tokenUrl, {
        method: 'POST',
        headers: headers,
        // body: `grant_type=authorization_code&code=${code}`
        body: qs.stringify({
          grant_type: 'authorization_code',
          code: code,
          // scope: 'openid profile address https://uri.paypal.com/payments/payouts'
          scope: getPaypalScopes(),
        }),
      })

      if (response.ok) {
        const data = await response.json();
        await paypalSetup({
          accessToken: data?.access_token,
          refreshToken: data?.refresh_token,
          expiresIn: `${data?.expires_in}`,
        })

        // setToken(data.access_token);
        setState((s) => ({ ...s, message: 'Success', state: 'success' }))
      } else {
        console.error('Failed to fetch PayPal token', response);
        setState((s) => ({ ...s, state: 'failed', message: 'failed to fetch PayPal token' }))
      }

    } catch (error) {
      console.error(error)
      setState((s) => ({ ...s, state: 'failed', message: error?.message }))
    }
  }, [history, paypalSetup, setState])

  useRedirCallback(process)

  switch (state.state) {
    case 'loading':
      return <div className="content">Processing Paypal Auth...</div>
    case 'failed':
      return (
        <div className="content">
          Failed.
          <hr />
          <Button onClick={history.goBack}>Back</Button>
        </div>
      )
    case 'success':
      return (
        <div className="content">
          Success.
          <Redirect to="/admin/platforms" />
        </div>
      )
  }

  return <div className="content">Loading...</div>
}

export default PaypalCallback
