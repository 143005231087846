export const getShopifyRedirectUri = () => {
  return window.location.origin + '/admin/market/shopify/callback'
}

export const getEbayRedirectUri = () => {
  return window.location.origin + '/admin/market/ebay/callback'
}

export const getSquareRedirectUri = () => {
  return window.location.origin + '/admin/market/square/callback'
}

export const getCloverUri = () => {
  return window.location.origin + '/admin/market/clover/callback'
}

export const getCheckbookRedirectUri = () => {
  return window.location.origin + '/payment/checkbook/callback'
}

export const getPaypalRedirectUri = () => {
  return window.location.origin + '/admin/market/paypal/callback'
}

export const getPaypalScopes = () => {
  const scopes = [
    'openid',
    'profile',
    'address',
    // 'https://uri.paypal.com/services/subscriptions',
    // 'https://api.paypal.com/v1/payments/.*',
    // 'https://api.paypal.com/v1/vault/credit-card',
    // 'https://api.paypal.com/v1/vault/credit-card/.*',
    // 'https://uri.paypal.com/services/invoicing',
    'https://uri.paypal.com/payments/payouts',
    'https://uri.paypal.com/services/applications/webhooks',
    'https://uri.paypal.com/services/payments/realtimepayment',
    'https://uri.paypal.com/services/paypalattributes',
  ]
  return scopes.join(' ')
}
// https://uri.paypal.com/services/applications/webhooks
// sample shopify response response
// http://copyt.docker.localhost:8001/admin/platforms/shopify/callback?
// code=481d741b9cc325f23f8b7d0073e14bc5&
// hmac=1f989677310258c18dc6f36b755e497dc0d132ad8059fb9db9094eae4d5efe2d&
// host=Y29weXQta3J1bS5teXNob3BpZnkuY29tL2FkbWlu&
// shop=copyt-krum.myshopify.com&
// timestamp=1657019917
