import React from 'react'

const TableMessage = ({ message }) => {
  return (
    <div className="p-table-view message">
      <label dangerouslySetInnerHTML={{ __html: message }} />
    </div>
  )
}

export default TableMessage
