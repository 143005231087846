import React from 'react'
import clx from 'classnames'
import {
  CardBody,
  CardHeader,
  CardText,
  CardLink
} from './snippets'

import './scss/card.scss'

const CopytCard = React.forwardRef(
  ({ children, className, style, ...props }, ref) => {
    return (
      <div
        {...props}
        ref={ref}
        className={clx('card', className)}
        style={style}
      >
        {children}
      </div>
    )
  }
)

CopytCard.Body = CardBody
CopytCard.Header = CardHeader
CopytCard.Text = CardText
CopytCard.Link = CardLink

export default CopytCard
