import React, {useState, useEffect} from 'react'
import {FormGroup, Label, Input} from 'reactstrap'
import {Box, Checkbox, FormControlLabel, Stack, Typography} from '@mui/material'

import {formatName} from 'util/string_utils'
import {CopytStyledButton, CopytLabel, FieldGroup} from 'views_v2/lib/snippets'
import {ApplicationShippingTypeStr, validationErrorStr} from 'resources/constants'
import {useConsignmentShops, useUser} from 'service/hook'
import {useToastMessage} from 'components'

const MdlApply = (p) => {
  const {user} = useUser()
  const {onCloseModal, formValues} = p || {}
  const [data, setData] = useState(formValues)
  const [errors, setErrors] = useState({})
  const {submitApplication, submitApplicationAsync} = useConsignmentShops({options: {}})
  const contractPdfLink = formValues.contractPdfLink
  const [isSubmitEnabled, setSubmitButton] = useState(contractPdfLink ? false : true)
  const {showToast} = useToastMessage()

  useEffect(() => {
    if (!data.hasOwnProperty('fullname') && formatName(user)?.length > 0) {
      setData({...data, fullname: formatName(user)})
    }
  }, [user])

  const handleChange = (e) => {
    const {name, value} = e.target
    setData({...data, [name]: value})
  }

  const handleSubmit = async (e) => {
    const error = validate(data)
    setErrors(error)
    if (Object.keys(error).length === 0) {
      Object.assign(data, {numShoes: parseFloat(data?.numShoes || 0)})
      delete data.contractPdfLink

      const response = await submitApplicationAsync(data)
      console.log('response', response)
      if (response?.data?.success == false) {
        showToast({
          variant: 'danger',
          title: 'Error',
          body: response?.data?.message,
        })
        return
      }

      setData(Object.assign({}, {shippingType: ApplicationShippingTypeStr.SHIPPED}))
      onCloseModal()
    }
  }

  const validate = (values) => {
    const error = {}
    if (!values?.fullname || values?.fullname === '') {
      error.numShoes = validationErrorStr.NAME_ERROR
    }
    return error
  }

  return (
    <Box>
      <Box className="modal-title">
        <Box className="modal__heading" mx="0px !important">
          <Typography variant="h1" textAlign="left">
            Submit Application
          </Typography>
        </Box>
      </Box>
      <Box className="modal-body" maxHeight="85vh" padding="0" mb={2}>
        <Stack direction="column" spacing={2} pt={3} px={5}>
          <FieldGroup
            sLabel="Full Name"
            name="fullname"
            hasError={errors?.fullname?.length > 0}
            errorMessage={errors?.fullname}
            placeholder="Full Name"
            onChange={handleChange}
            value={data?.fullname}
          />
          <Stack direction="column">
            <CopytLabel>Shipping Type</CopytLabel>
            <div className="shipping-type">
              <FieldGroup
                sLabel="Shipped"
                type="radio"
                name="shippingType"
                value={ApplicationShippingTypeStr.SHIPPED}
                onChange={handleChange}
                checked={data?.shippingType === ApplicationShippingTypeStr.SHIPPED}
              />
              <FieldGroup
                sLabel="Drop off"
                type="radio"
                name="shippingType"
                value={ApplicationShippingTypeStr.DROP_OFF}
                onChange={handleChange}
                checked={data?.shippingType === ApplicationShippingTypeStr.DROP_OFF}
              />
            </div>
          </Stack>
          {contractPdfLink && (
            <FormGroup check className="pull-left">
              <Label check>
                <Input type="checkbox" onChange={(e) => setSubmitButton(e.target.checked)} />
                <span className="form-check-sign" />I agree to the terms of this{' '}
                <a href={contractPdfLink || ''}>
                  <span style={{cursor: 'pointer', fontWeight: '500'}}>Contract</span>
                </a>
              </Label>
            </FormGroup>
          )}
        </Stack>

        <Stack
          direction="row"
          justifyContent="flex-end"
          pr={2}
          pt={1.5}
          mt={4}
          borderTop="1px solid var(--lighter)"
          spacing={2}
        >
          <CopytStyledButton variant="outline" color="primary" onClick={onCloseModal}>
            Cancel
          </CopytStyledButton>
          <CopytStyledButton
            variant="contained"
            color="primary"
            onClick={() => handleSubmit()}
            disabled={!isSubmitEnabled}
            style={{width: '180px', lineHeight: '14px'}}
          >
            Submit Application
          </CopytStyledButton>
        </Stack>
      </Box>
    </Box>
  )
}

export default MdlApply
