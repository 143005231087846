import React, {useState, useEffect} from 'react'
import {useQueryClient} from 'react-query'
import {DataGrid} from '@mui/x-data-grid'
import {Box, Button, Link, Typography, Stack} from '@mui/material'
import {
  InfoOutlined as InfoOutlinedIcon,
  ImageNotSupported as ImageNotSupportedIcon,
} from '@mui/icons-material'
import {useTheme} from '@material-ui/core/styles'
import {useMediaQuery} from '@material-ui/core'
import {chain, isEmpty} from 'lodash'

import DetailView from 'components/DetailView'
import CopytCard from 'components/CopytCard'
import {ToastType} from 'enums'
import {ApplicationStatusStr, ApplicationShippingTypeStr} from 'resources/constants'
import {useFees, useUser, useExport} from 'service/hook'
import {useConsignmentShops} from 'service/hook/useConsignmentShops'
import {_getAddress} from 'util/string_utils'
import {ErrorMessage} from 'views_v2/lib/components'
import {CopytTooltip, FieldGroup, QuickSearchToolbar, TooltipSnippets} from 'views_v2/lib/snippets'
import InventoryToast from 'views_v2/modules/Inventory/components/InventoryToast'
import PlatformsModal, {PlatformsModalType} from '../PlatformsModal'

const ConsignmentApplicationMangement = (p) => {
  const [filter, setFilter] = useState({
    skip: 0,
    take: 100,
    page: 0,
  })

  const queryClient = useQueryClient()
  const [formValues, setFormValues] = useState({})
  const [toastType, setToastType] = useState(ToastType.UNDEFINED)
  const [modalType, setModalType] = useState(PlatformsModalType.UNDEFINED)
  const [queries, setQueries] = useState({})
  const [currentPage, setCurrentPage] = useState(filter?.page)

  const {
    consignmentShops,
    pendingApplications,
    completedApplications,
    isCompletedApplicationsLoading,
    isPendingApplicationsLoading,
  } = useConsignmentShops({
    options: {refetchInterval: 5000},
    queries,
  })

  const {isUpdateFeeToApplicationSuccess} = useFees()
  const {user} = useUser()

  const theme = useTheme()

  const handleShow = (storeId) => {
    const shop = consignmentShops.find((shop) => shop.id === storeId)

    if (!shop) {
      console.error('Shop not found!')
      return
    }

    const shopHasEcgMembership = shop.owner.memberships.some((membership) => membership.type === 'ECG')
    const userHasEcgMembership = user.memberships.some((membership) => membership.type === 'ECG')

    if (shopHasEcgMembership && !userHasEcgMembership) {
      setModalType(PlatformsModalType.ECG_REFERRAL_CODE)
    } else {
      setModalType(PlatformsModalType.APPLY)
    }

    setFormValues({
      ...formValues,
      platformId: storeId,
      email: user.email,
      shippingType: ApplicationShippingTypeStr.SHIPPED,
      contractPdfLink: shop.contract_pdf_link,
    })
  }

  const ApplicationStatus = (props) => {
    const {dataId, appStatus, applications, owner} = props
    if (appStatus === 'none') {
      if (applications && !isEmpty(applications)) {
        return (
          <Button
            variant="outlined"
            style={{
              border: 0,
              pointerEvents: 'none',
              minWidth: '110px',
              borderRadius: '20px',
              backgroundColor: 'var(--primary-light)',
              opacity: '0.9',
              color: 'var(--primary)',
              fontSize: '0.8rem',
              textTransform: 'capitalize',
            }}
          >
            Applied
          </Button>
        )
      }

      if (owner && !owner.isAcceptingApplications) {
        return (
          <Button
            variant="outlined"
            style={{
              border: 0,
              minWidth: '100px',
              pointerEvents: 'none',
              cursor: 'default',
              borderRadius: '20px',
              backgroundColor: 'var(--lighter)',
              color: 'var(--gray)',
              fontSize: '0.8rem',
              textTransform: 'capitalize',
            }}
          >
            Not Accepting
          </Button>
        )
      }
      return (
        <Button
          variant="outlined"
          style={{
            border: 0,
            minWidth: '110px',
            borderRadius: '20px',
            backgroundColor: 'rgba(0, 123, 255, 0.3)',
            opacity: '0.9',
            color: 'var(--default)',
            fontSize: '0.8rem',
            textTransform: 'capitalize',
          }}
          onClick={() => handleShow(dataId)}
        >
          Apply
        </Button>
      )
    } else {
      let color = 'success'
      let label = 'Accepted'
      let backgroundColor = 'var(--success-light)'

      if (appStatus === ApplicationStatusStr.PENDING) {
        color = 'warning'
        label = 'Pending'
        backgroundColor = 'var(--warning-light)'
      } else if (appStatus === ApplicationStatusStr.DENIED) {
        color = 'error'
        label = 'Rejected'
        backgroundColor = 'var(--error-light)'
      } else if (appStatus === ApplicationStatusStr.ERROR) {
        color = 'error'
        label = 'Error'
        backgroundColor = 'var(--error-light)'
      }
      return (
        <Button
          {...{color}}
          variant="outlined"
          size="small"
          style={{
            border: 0,
            minWidth: '110px',
            pointerEvents: 'none',
            cursor: 'default',
            borderRadius: '20px',
            backgroundColor: backgroundColor,
            fontSize: '0.8rem',
            textTransform: 'capitalize',
          }}
        >
          {label}
        </Button>
      )
    }
  }

  const columns = [
    {
      field: 'name',
      headerName: 'Company',
      flex: 1.5,
      renderCell: (params) => {
        const {owner, logo_url, platform} = params?.row || {}
        let title = owner?.businessName || platform?.name
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            {logo_url ? (
              <Box component="img" width={24} height={24} src={logo_url} />
            ) : (
              <ImageNotSupportedIcon sx={{m: 0, color: 'var(--gray)'}} />
            )}
            <Stack>
              <TooltipSnippets {...{title}} />
              <Typography sx={{color: 'var(--gray) !important'}} fontSize="12px" lineHeight="12px">
                {_getAddress(owner?.company)}
              </Typography>
            </Stack>
          </Stack>
        )
      },
    },
    {
      field: 'website',
      headerName: 'Website',
      flex: 1,
      renderCell: (params) => {
        const {owner} = params.row || {}
        const url = owner?.company?.website
        if (url && url.includes('.')) {
          return (
            <Link href={`https://${url}`} target="_blank" rel="noopener noreferrer">
              {url}
            </Link>
          )
        } else {
          return null
        }
      },
    },
    {
      field: 'support_email',
      headerName: 'Contact',
      flex: 1,
      renderCell: (params) => {
        const {owner, support_email} = params?.row || {}
        const email = support_email || owner?.email

        if (!!email && email !== 'null') {
          return (
            <Link href={`mailto:${email}`} target="_blank" rel="noopener noreferrer">
              {email}
            </Link>
          )
        }

        return ''
      },
    },
    {
      field: 'owner',
      headerName: '',
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        const phoneNumber = params?.value?.phoneNumber || ''

        return (
          <FieldGroup
            disableCountryCode={isEmpty(phoneNumber)}
            placeholder=""
            className="readOnly"
            id="phoneNumber"
            value={phoneNumber}
            style={{margin: '10px 0 0 0'}}
          />
        )
      },
    },

    {
      field: 'status',
      headerName: 'Status',
      sortable: false,
      flex: 0.8,
      renderCell: (params) => {
        const {id, status, applications, owner} = params?.row || {}
        return (
          <ApplicationStatus dataId={id} appStatus={status} applications={applications} owner={owner} />
        )
      },
    },
  ]

  const rows = chain(consignmentShops)
    .map((shop) => ({
      ...shop,
      status: 'none',
      name: shop.owner.businessName || shop.name,
    }))
    .filter((shop) => !shop.isHidden)
    .concat(pendingApplications, completedApplications)
    .orderBy(['owner.updatedAt'], ['desc'])
    .value()

  const {isErrorExport, isSuccessExport, invalidateExportQueries, refetchExport} = useExport({
    filter: {
      skip: 0,
      take: rows?.length || 10000,
      limit: rows?.length || 10000,
    },
    exportType: 'consignment',
  })

  useEffect(() => {
    if (isUpdateFeeToApplicationSuccess) {
      queryClient.invalidateQueries('applications')
    }
  }, [isUpdateFeeToApplicationSuccess])

  useEffect(() => {
    if (isSuccessExport) {
      setToastType(ToastType.EXPORTED)
    } else if (isErrorExport) {
      setToastType(ToastType.ERROR)
    }
    invalidateExportQueries()
  }, [isErrorExport, isSuccessExport])

  return (
    <DetailView.Panel style={{height: '95%'}}>
      <CopytCard className="full-height individual">
        <CopytCard.Header>
          <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
            <CopytCard.Text tag="div">Applications</CopytCard.Text>
            <CopytTooltip
              title={
                <Box>
                  <Typography sx={{fontSize: '12px', color: '#fff'}}>
                    Use this table to submit applications to registered consignment shops in order to
                    begin consigning.
                  </Typography>
                </Box>
              }
              placement="bottom"
            >
              <InfoOutlinedIcon fontSize="small" sx={{color: 'white'}} />
            </CopytTooltip>
          </Stack>
        </CopytCard.Header>
        <CopytCard.Body style={{flex: '1 1'}}>
          <DataGrid
            rows={rows.filter((row) => row.email !== user?.email)}
            columns={columns}
            disableColumnSelector
            disableColumnMenu
            disableDensitySelector
            disableColumnFilter
            getRowClassName={() => `datagrid-row`}
            loading={isCompletedApplicationsLoading || isPendingApplicationsLoading}
            components={{
              Toolbar: QuickSearchToolbar,
              NoRowsOverlay: ErrorMessage,
            }}
            componentsProps={{
              toolbar: {
                onExport: () => refetchExport(),
              },
            }}
            pageSize={[100]}
            page={currentPage}
            onPageChange={(newPage) => setCurrentPage(newPage)}
            getRowId={(row) => row.id}
            onSortModelChange={(newSort) => {
              const {field, sort} = newSort[0] || {}
              setQueries({
                sortBy: field,
                sortDirection: sort,
              })
            }}
            sx={{width: 1}}
          />
        </CopytCard.Body>
      </CopytCard>
      <PlatformsModal
        {...{
          modalType,
          setModalType,
          formValues,
        }}
      />
      {toastType > ToastType.UNDEFINED && <InventoryToast {...{toastType, setToastType}} />}
    </DetailView.Panel>
  )
}

export default ConsignmentApplicationMangement
