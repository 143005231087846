import {paths} from 'constant/api'
import * as Sentry from '@sentry/react'
import axios from 'axios'
import {createRequestOptions} from 'util/network'

export const getStoreLocations = async (tokenGetter) => {
  try {
    const token = await tokenGetter()
    const response = await axios.get(paths.storeLocations, {
      params: {
        type: 'market',
      },
      ...createRequestOptions(token),
    })
    return response.data
  } catch (e) {
    Sentry.captureException(e)
    console.log('Error retrieving store locations', e)
    throw e.message
  }
}

export const getCloverStoreLocations = async (tokenGetter, params) => {
  try {
    const token = await tokenGetter()
    const response = await axios.get(`${paths.storeLocations}/clover`, {
      params,
      ...createRequestOptions(token),
    })
    return response.data
  } catch (e) {
    Sentry.captureException(e)
    console.log('Error retrieving store locations', e)
    throw e.message
  }
}
