import React from 'react'
import {Checkbox, Tooltip} from '@mui/material'

const CustomCheckbox = React.forwardRef(({disabled, ...props}, ref) => (
  <Tooltip
    title={disabled ? 'Items must be assigned SKU/Style IDs to be listed.' : ''}
    PopperProps={{
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, -20],
          },
        },
      ],
    }}
  >
    <span>
      <Checkbox ref={ref} {...props} disabled={disabled} />
    </span>
  </Tooltip>
))

export default CustomCheckbox
