export const consignmentStatus = [
  {id: 'all', title: 'All'},
  {id: 'consignment', title: 'Consigned'},
  {id: 'none', title: 'Non Consigned'},
]

export const filterArray = ['consignmentStatus', 'platform', 'soldPlatform', 'listingPlatformStatus']

export const statusArray = [
  {id: 'all', title: 'All'},
  {id: 'success', title: 'Success'},
  {id: 'queued', title: 'Pending'},
  {id: 'reject', title: 'Rejected'},
  {id: 'hold', title: 'On Hold (POS)'},
]

export const shipmentType = [
  {id: 'all', title: 'All'},
  {id: 'consignment', title: 'Consignment'},
  {id: 'order_fulfillment', title: 'Order Fulfillment'},
  {id: 'purchase', title: 'Purchase'},
  {id: 'other', title: 'Other'},
]

export const shipmentStatus = [
  {id: 'all', title: 'All'},
  {id: 'draft', title: 'Draft'},
  {id: 'pre_transit', title: 'Pre Transit'},
  {id: 'transit', title: 'Transit'},
  {id: 'delivered', title: 'Delivered'},
  {id: 'returned', title: 'Returned'},
  {id: 'failure', title: 'Failure'},
  {id: 'unknown', title: 'Unknown'},
]

export const shipmentRefund = [
  {id: 'for_refund', title: 'Pending'},
  {id: 'success', title: 'Success'},
  {id: 'failed', title: 'Failed'},
]
