import {useState, useEffect} from 'react'
import {useQuery} from 'react-query'
import axios from 'axios'
import {useAuth0} from '@auth0/auth0-react'
import {captureException} from '@sentry/react'

import {paths} from 'constant/api'
import {createRequestOptions} from 'util/network'

const getPlatforms = async (getAccessTokenSilently) => {
  try {
    const token = await getAccessTokenSilently()
    const response = await axios.get(`${paths.supportedPlatform}`, createRequestOptions(token))
    return response?.data
  } catch (error) {
    captureException(error)
    throw error
  }
}

export const usePlatforms = (filter, searchText, option = {}) => {
  const {getAccessTokenSilently} = useAuth0()
  const [fetchEnabled, setFetchEnabled] = useState(true)

  const {
    isLoading,
    isError,
    isFetched,
    refetch,
    data: platforms = [],
    error,
  } = useQuery(
    ['platforms', filter, searchText],
    () => getPlatforms(getAccessTokenSilently, filter, searchText),
    {
      ...option,
      enabled: fetchEnabled,
      keepPreviousData: true,
    },
  )

  useEffect(() => {
    if (fetchEnabled) {
      refetch()
      setFetchEnabled(false)
    }
  }, [fetchEnabled, refetch])

  return {
    isLoading,
    isError,
    isLedgersFetched: isFetched,
    refetchPlatforms: () => setFetchEnabled(true),
    error,
    platforms: platforms || [],
  }
}

export default usePlatforms
