import { useMutation, useQuery, useQueryClient } from 'react-query'
import axios from 'axios'
import { useAuth0 } from '@auth0/auth0-react'
import * as Sentry from '@sentry/react'
import { createRequestOptions } from 'util/network'
import { paths } from 'constant/api'
import { useToastMessage } from 'components/index'

const getConnections = async (getAccessTokenSilently, user, page) => {
  try {
    const token = await getAccessTokenSilently()
    const res = await axios.get(`${paths.connection}?email=${user?.email}`, createRequestOptions(token))
    return res.data
  } catch (error) {
    Sentry.captureException(error)
    throw error
  }
}

export const useConnections = ({ page }) => {
  const { getAccessTokenSilently, user } = useAuth0()
  const queryClient = useQueryClient()
  const { showToast } = useToastMessage()
  const {
    status,
    isLoading,
    isError,
    isFetched,
    refetch,
    data: connections = { data: [] },
    error,
  } = useQuery('connections', () => getConnections(getAccessTokenSilently, user, page))


  const searchConnect = useMutation(
    async (email) => {
      const token = await getAccessTokenSilently()
      return await axios.get(`${paths.connection}/search?email=${email}&excludeConnection=true`, createRequestOptions(token))
    }
  )

  const addConnect = useMutation(
    async (value) => {
      const token = await getAccessTokenSilently()

      return await axios.post(paths.connection, { ...value }, createRequestOptions(token))
    },
    {
      onSuccess: async (data, variables, context) => {
        refetch()
      },
    },
    {
      onMutate: async (data, variables, context) => {
        refetch()
      },
    },
    {
      onError: async (data, variables, context) => {
        refetch()
      },
    },
  )

  const deleteConnect = useMutation(
    async (id) => {
      const token = await getAccessTokenSilently()

      return await axios.delete(`${paths.connection}/${id}`, createRequestOptions(token))
    },
    {
      onSuccess: async (data, variables, context) => {
        refetch()
      },
    },
    {
      onMutate: async (data, variables, context) => {
        refetch()
      },
    },
    {
      onError: async (data, variables, context) => {
        refetch()
      },
    },
  )

  const updateConnect = useMutation(
    async ({ id, viewStatus }) => {
      const token = await getAccessTokenSilently()

      return await axios.patch(
        `${paths.connection}/${id}`,
        {
          ...{ viewStatus },
        },
        createRequestOptions(token),
      )
    },
    {
      onSuccess: async (data, variables, context) => {
        refetch()
      },
    },
    {
      onMutate: async (data, variables, context) => {
        refetch()
      },
    },
    {
      onError: async (data, variables, context) => {
        refetch()
      },
    },
  )

  const applyApplication = useMutation(
    async (email) => {
      const token = await getAccessTokenSilently()
      try {
        const connect = await axios.get(`${paths.connection}/apply/exist/${email?.label}`, createRequestOptions(token))
        if (connect?.data?.data?.isExisting) {
          return showToast({ variant: 'danger', title: 'Error', body: 'The email you entered is already connected to an account.' })
        } else {
          return await axios.post(`${paths.connection}/apply`, { ...{ connectionUserId: email?.value } }, createRequestOptions(token))
        }
      } catch (e) {
        showToast({ variant: 'danger', title: 'Error', body: `${e.response.data?.message}` })
        Sentry.captureException(e)
        throw e.message
      }
    },
    {
      onSuccess: async (data, variables, context) => {
        refetch()
        showToast({ variant: 'success', title: 'Success', body: 'Your request was successfully sent.' })
      },
    },
    {
      onMutate: async (data, variables, context) => {
        refetch()
      },
    },
    {
      onError: async (data, variables, context) => {
        refetch()
      },
    },
  )

  const denyApplication = useMutation(
    async (id) => {
      const token = await getAccessTokenSilently()
      return await axios.post(`${paths.connection}/deny/${id}`, null, createRequestOptions(token))
    },
    {
      onSuccess: async (data, variables, context) => {
        showToast({ variant: 'success', title: 'Success', body: 'Request denied' })
        refetch()
      },
    },
    {
      onMutate: async (data, variables, context) => {
        refetch()
      },
    },
    {
      onError: async (data, variables, context) => {
        refetch()
      },
    },
  )

  const approveApplication = useMutation(
    async (id) => {
      const token = await getAccessTokenSilently()
      return await axios.post(`${paths.connection}/approve/${id}`, null, createRequestOptions(token))
    },
    {
      onSuccess: async (data, variables, context) => {
        showToast({ variant: 'success', title: 'Success', body: 'Request approved' })
        refetch()
      },
    },
    {
      onMutate: async (data, variables, context) => {
        refetch()
      },
    },
    {
      onError: async (data, variables, context) => {
        refetch()
      },
    },
  )

  const reinvalidateConnections = () => {
    queryClient.invalidateQueries('connections')
  }

  return {
    status,
    isLoading,
    refetchConnection: refetch,
    isError,
    isConnectionsFetch: isFetched,
    error,
    connections: connections || { data: [] },
    addConnection: addConnect.mutate,
    deleteConnection: deleteConnect.mutate,
    updateConnection: updateConnect.mutate,
    searchConnection: searchConnect.mutate,
    applyApplication: applyApplication.mutate,
    denyApplication: denyApplication.mutate,
    approveApplication: approveApplication.mutate,
    reinvalidateConnections
  }
}

export default useConnections
