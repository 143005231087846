export function validateEnterpriseUser(user) {
  return user && user?.type === 'enterprise'
}

export function validateCustomPayout(user) {
  return user && user?.private?.defaultFeeType?.customPayout
}

export function validateHasMop(user) {
  return user && !!user?.private?.paymentMethodId
}

export function getMinimumFee(user) {
  return user?.private?.minimumConsignmentFee || 0
}
