import React from 'react'
import clx from 'classnames'

const DetailView = ({classNames, children}) => {
  const style = clx('p-panel-detail', classNames)
  return (
    <div className={style}>
      {children}
    </div>
  )
}

export default DetailView
