import React from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { Box, Button, Tooltip } from '@mui/material'

const BatchItemsTable = (p) => {
  const {
    rows
  } = p

  const columns = [
    {
      field: 'title',
      headerName: 'Item Name',
      headerAlign: 'center',
      flex: 1.5,
      renderCell: (params) => {
        const title = params?.value
        return (
          <Tooltip {...{ title }}>
            <span>{title}</span>
          </Tooltip>
        )
      },
    },
    {
      field: 'sku',
      headerName: 'SKU/Style ID',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
    },
    {
      field: 'size',
      headerName: 'Size',
      headerAlign: 'center',
      align: 'center',
      flex: 0.7,
    },
    {
      field: 'condition',
      headerName: 'Condition',
      headerAlign: 'center',
      align: 'center',
      flex: 0.7,
    },
    {
      field: 'desiredReturn',
      headerName: 'Unit Price',
      headerAlign: 'center',
      align: 'center',
      flex: 0.7,
    },
    {
      field: 'discordUser',
      headerName: 'Username',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (params) => {
        const { owner } = params?.row || {}
        if (owner?.discordUserName) return owner?.discordUserName
        return ''
      }
    }
  ]

  return (
    <Box
      sx={{
        height: '71vh',
        width: 1,
      }}>
      <DataGrid
        {...{rows, columns}}
        disableSelectionOnClick
        disableColumnMenu
        disableColumnSelector
        disableDensitySelector
        disableColumnFilter
        pagination
        paginationMode="server"
        rowsPerPageOptions={[5]}
      />
    </Box>
  )
}

export default BatchItemsTable
