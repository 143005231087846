import {head, isEmpty, isNaN, isString, pick, values, upperFirst} from 'lodash'

import {ListingStatus} from 'constant/listing_status'

export const getInitials = (string) => {
  return upperFirst(head(string))
}

export const capitalizeFirstLetter = (string) => {
  if (!string) {
    return
  }
  return getInitials(string) + string.slice(1)
}

export const capitalizeText = (text) => {
  if (!text) return 'Shipment type...'
  return text
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}

export const deleteKeys = (obj, arr) => {
  if (Array.isArray(arr) && arr?.length > 0) {
    return arr.forEach((a) => {
      delete obj[a]
    })
  }
  return obj
}

export const getPredefinedObject = (obj, arr) => {
  if (Array.isArray(arr) && arr?.length > 0) {
    const object = {}

    arr.forEach((a) => {
      Object.assign(object, {
        [a]: obj[a],
      })
    })
    return object
  }
  return obj
}

export const formatName = (user) => {
  const name = []

  if (user?.firstName) name.push(user?.firstName)
  if (user?.lastName) name.push(user?.lastName)

  return name.join(' ')
}

export const toMoney = (v) => {
  return Number(v).toLocaleString('en-US', {minimumFractionDigits: 2})
}

export const checkObjIfEmpty = (obj) => {
  return values(obj).some((x) => x === '' || x === undefined || x === null)
}

export const _getPercentage = (x, y, isRound = false) => {
  const percentage = (Number(x) / Number(y)) * 100
  if (percentage !== Infinity && !isNaN(percentage)) {
    return !isRound ? percentage.toFixed(2) : Math.round(percentage)
  }
  return 0
}

export const _getRandomColor = () => {
  const random = () => Math.floor(Math.random() * 255)
  return `rgb(${random()}, ${random()}, ${random()}, 0.7)`
}

export const isNan = (str) => {
  if (str) return str?.toLowerCase() === 'nan' || str === NaN || isNaN(str)
  return true
}

export const _getValue = (val) => {
  if (!isNaN(parseFloat(val)) && isFinite(val)) {
    return parseFloat(val)
  }
  return 0
}

export const _getStatus = (s) => {
  let status = s?.toLowerCase() || ''
  switch (status) {
    case ListingStatus.WITHDRAW_PENDING:
      return 'Withdraw Requested'
    case ListingStatus.WITHDRAW_APPROVED:
      return 'Withdraw Approved'
    case ListingStatus.WITHDRAW_DENIED:
      return 'Withdraw Denied'
    default:
      return capitalizeFirstLetter(status)
  }
}

export const _getStatusColor = (s) => {
  let status = s?.toLowerCase() || ''
  switch (status) {
    case ListingStatus.WITHDRAW_PENDING:
    case ListingStatus.WITHDRAW_APPROVED:
    case ListingStatus.WITHDRAW_DENIED:
      return 'var(--indigo)'
    case ListingStatus.CREATED:
      return 'var(--purple)'
    case ListingStatus.FOR_REMOVE:
      return 'var(--red)'
    case ListingStatus.SOLD:
      return 'var(--success)'
    default:
      return 'green'
  }
}

export const _validateFormFields = (formFields, requiredProperties) => {
  const missingProperties = requiredProperties.filter((prop) => !formFields[prop])

  if (missingProperties.length > 0 || (formFields?.size && isEmpty(formFields?.size))) {
    return 'is required'
  } else {
    return null
  }
}

export const _replaceWildCards = (product, template, wildcardLists) => {
  const sku = product?.sku || ''
  const sizes =
    Array.isArray(product?.size) && product?.size?.length > 0
      ? product?.size?.map((s) => s?.size).join(' ')
      : product?.size

  const description = wildcardLists.reduce((desc, wildcard) => {
    if (desc.includes(wildcard)) {
      switch (wildcard) {
        case '[SKU]':
          return desc.replace(wildcard, `${sku} `)
        case '[SIZE]':
          return desc.replace(wildcard, `${sizes || ''} `)
        case '[BOX CONDITION]':
          return desc.replace(wildcard, `${product?.boxCondition || ''} `)
        case '[ITEM CONDITION]':
          return desc.replace(wildcard, `${product?.condition || ''} `)
        case '[NAME]':
          return desc.replace(wildcard, `${product?.title || ''} `)
        default:
          return desc
      }
    }
    return desc
  }, product?.description)

  return description.trim()
}

export const _replaceWithWildcards = (description, product, wildcardLists) => {
  const sku = product?.sku || ''
  const sizes =
    Array.isArray(product?.size) && product?.size?.length > 0
      ? product?.size?.map((s) => s?.size).join(' ')
      : product?.size

  return wildcardLists.reduce((desc, wildcard) => {
    switch (wildcard) {
      case '[SKU]':
        return desc.replace(new RegExp(`\\b${sku}\\b`, 'g'), '[SKU]')
      case '[SIZE]':
        return desc.replace(new RegExp(`\\b${sizes}\\b`, 'g'), '[SIZE]')
      case '[BOX CONDITION]':
        return desc.replace(new RegExp(`\\b${product?.boxCondition || ''}\\b`, 'g'), '[BOX CONDITION]')
      case '[ITEM CONDITION]':
        return desc.replace(new RegExp(`\\b${product?.condition || ''}\\b`, 'g'), '[ITEM CONDITION]')
      case '[NAME]':
        return desc.replace(new RegExp(`\\b${product?.title || ''}\\b`, 'g'), '[NAME]')
      default:
        return desc
    }
  }, description)
}

export const isURL = (value) => {
  const urlRegex = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/
  return isString(value) && urlRegex.test(value)
}

export const _getPercentageInBetween = (x, y) => {
  return ((1 - _getValue(x) / _getValue(y)) * 100).toFixed(2)
}

export const _getAddress = (address, properties = []) => {
  if (isEmpty(address)) return '--'

  const keys = isEmpty(properties) ? ['city', 'state', 'zip'] : properties
  return values(pick(address, keys)).filter(Boolean).join(', ')
}
