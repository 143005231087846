import React, {useState, useEffect, useRef} from 'react'
import clx from 'classnames'
import {Route, Switch, Redirect, useHistory} from 'react-router-dom'
import routes from 'routes'
import {withAuth0, useAuth0} from '@auth0/auth0-react'
import axios from 'axios'
import {createRequestOptions} from 'util/network'
import {paths} from 'constant/api'
// react plugin for creating notifications over the dashboard
import NotificationAlert from 'react-notification-alert'

// core components
import SplitView from 'components/SplitView'
import AdminNavbar from 'components/Navbars/AdminNavbar'
import Footer from 'components/Footer'
import AdminSidebar from './common/AdminSidebar'
import {parsed_query_string_search} from './utils/classes/query_string_handler'
import {usePlatforms} from 'service/hook'
import {isMobile} from 'react-device-detect'
import {useScreenSize} from 'util/window_utils'
import './scss/parent.scss'

const Admin = (props) => {
  const {getAccessTokenSilently} = useAuth0()
  const isMounted = useRef(false)
  const {platforms} = usePlatforms('', '')
  const [sidebarOpened, setSidebarOpened] = useState(false)
  const [routesArr, setRoutesArr] = useState(routes)
  const screenWidth = useScreenSize()
  const isMobileScreen = isMobile || screenWidth < 768
  const isValid = parsed_query_string_search(props.location, 'collapsed', 'true')

  useEffect(() => {
    setSidebarOpened(isValid)
    isMounted.current = true
    return () => (isMounted.current = false)
  }, [])

  useEffect(() => {
    setSidebarOpened(isValid)
  }, [screenWidth])

  useEffect(() => {
    ;(async () => {
      if (isMounted.current) {
        if (!localStorage.getItem('currency')) localStorage.setItem('currency', 'USD')
        setRoutesArr(routes)

        // if (localStorage.getItem('hasRoutes') === null) {
        //   const token = await getAccessTokenSilently()
        //   const response = await axios.get(paths.credential + '?take=1000', createRequestOptions(token))
        //   const hasConnect = response.data.data?.some(
        //     (s) => s?.platform?.name === 'Copyt Network' && s?.enabled,
        //   )
        //   localStorage.setItem('hasRoutes', hasConnect)
        //   const arr = hasConnect
        //     ? routes
        //     : routes?.filter((r) => r?.name?.toLowerCase() !== 'Network'.toLowerCase())

        // }
      }
    })()
  }, [isMounted.current])

  const getRoutes = (routes) => {
    if (routes?.length > 0) {
      return routes?.map((prop, key) => {
        if (prop.collapse || (prop.views && prop.views.length > 0)) {
          return getRoutes(prop.views)
        }

        return (
          prop.layout === '/admin' && (
            <Route path={prop.layout + prop.path} component={prop.component} key={key} />
          )
        )
      })
    }
    return null
  }

  const getActiveRoute = (routes) => {
    let activeRoute = 'Default Brand Text'
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views)
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute
        }
      } else {
        if (window.location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
          return routes[i].name
        }
      }
    }
    return activeRoute
  }

  const handleMiniClick = () => {
    if (isValid) {
      props.history.push(`${window.location.pathname}`)
    } else {
      props.history.push(`${window.location.pathname}?collapsed=true`)
    }
  }

  const shouldHide = isMobileScreen && sidebarOpened && isValid

  return (
    <main className={clx('wrapper', window.location.pathname.split('/').pop())}>
      <AdminNavbar
        {...props}
        {...{handleMiniClick, sidebarOpened, platforms, isMobileScreen}}
        brandText={getActiveRoute(routesArr)}
      />
      <SplitView
        className={clx((sidebarOpened || isMobileScreen) && 'collapsed', shouldHide && 'isMobile')}
      >
        <SplitView.Master>
          <div className="flex-wrap">
            <div className="rna-container">
              <NotificationAlert ref={useRef('notificationAlert')} />
            </div>
            <AdminSidebar
              {...props}
              {...{isMobileScreen, isValid, shouldHide}}
              routes={routesArr}
              // logo={{
              //   outterLink: 'https://www.copyt.io/',
              //   text: user.name,
              //   imgSrc: logo,
              // }}
            />
          </div>
        </SplitView.Master>
        <SplitView.Detail className="no-scroll">
          <Switch>
            {getRoutes(routesArr)}
            <Redirect from="*" to="/admin/dashboard" />
            {/* <Redirect from="*" to="/admin/inventory/viewInventory" /> */}
          </Switch>

          {
            // we don't want the Footer to be rendered on full screen maps page
            props.location.pathname.indexOf('full-screen-map') !== -1 ? null : (
              <Footer {...{isMobileScreen}} />
            )
          }
        </SplitView.Detail>
      </SplitView>
    </main>
  )
}

export default withAuth0(Admin)
