import React from 'react'
import Barcode from 'react-barcode'
import {Box, Stack, Typography} from '@mui/material'

import {useGlobalStore} from 'provider/global_store/hook'
import copytLogo from 'assets/img/copyt-logo-sidebar.svg'

const BatchesPrint = React.forwardRef(({printData}, ref) => {
  const {user} = useGlobalStore()
  const {logo_url} = user?.platform || ''
  const {id, consignor, consignee, consigns} = printData || {}

  return (
    <Box ref={ref} margin={10}>
      <Stack direction="column" spacing={1}>
        <Stack direction="column" alignItems="center">
          <Typography textAlign="center" color="black">
            Batch Summary for Batch ID: {id}
          </Typography>
          <Box
            className="barcode-image"
            component="img"
            sx={{
              maxWidth: '85%',
              maxHeight: '85%',
              width: 'auto',
              height: 'auto',
            }}
            src={logo_url || copytLogo}
          />
          <Typography textAlign="center" color="black">
            Store: {consignee?.businessName || 'All Stores'}
          </Typography>
          <Typography textAlign="center" color="black">
            Consignor: {consignor?.email}
          </Typography>
        </Stack>
        <Barcode value={id} />
        <Stack direction="row" spacing={2}>
          <Typography color="black">Item(s):</Typography>
          <Typography fontWeight={600} color="black">
            {consigns?.length}
          </Typography>
        </Stack>
        {consigns?.map((item) => {
          return (
            <Stack direction="row" justifyContent="space-between" key={item?.id}>
              <Typography color="black">{item?.product?.title}</Typography>
              <Typography color="black">{item?.product?.internalSku}</Typography>
              <Typography color="black">{item?.product?.size}</Typography>
              <Typography color="black">{item?.product?.condition}</Typography>
            </Stack>
          )
        })}
      </Stack>
    </Box>
  )
})

export default BatchesPrint
