import {useState, useEffect} from 'react'
import {useQuery, useMutation} from 'react-query'
import {useAuth0} from '@auth0/auth0-react'
import axios from 'axios'
import {captureException} from '@sentry/react'

import {paths} from 'constant/api'
import {useToastMessage} from 'components/index'
import {createRequestOptions} from 'util/network'

const getUserSettings = async (getAccessTokenSilently) => {
  try {
    const token = await getAccessTokenSilently()
    const response = await axios.get(`${paths.user}`, createRequestOptions(token))
    return response.data?.data
  } catch (error) {
    captureException(error)
  }
}

const putUserSettings = async (getAccessTokenSilently, values) => {
  const token = await getAccessTokenSilently()
  return await axios.patch(`${paths.user}`, values, createRequestOptions(token))
}

export const useUser = () => {
  const {getAccessTokenSilently} = useAuth0()
  const {showToast} = useToastMessage()
  const [fetchEnabled, setFetchEnabled] = useState(true)

  const {
    isLoading,
    isError,
    isFetched,
    refetch,
    data: user = {},
    error,
  } = useQuery('userSettings', () => getUserSettings(getAccessTokenSilently), {
    keepPreviousData: true,
    refetchOnWindowFocus: true,
  })

  const updateDiscordName = useMutation((values) => putUserSettings(getAccessTokenSilently, values), {
    onSuccess: async (data, variables, context) => {
      showToast({variant: 'success', title: 'Success', body: 'Discord name has been updated.'})
      setFetchEnabled(true)
      refetch()
    },
    onError: async (error) => {
      showToast({variant: 'danger', title: 'Error', body: ''})
      setFetchEnabled(true)
      refetch()
    },
  })

  const updateUser = useMutation((values) => putUserSettings(getAccessTokenSilently, values), {
    onSuccess: async (data, variables, context) => {
      showToast({variant: 'success', title: 'Success', body: 'User info has been updated.'})
      setFetchEnabled(true)
      refetch()
    },
    onError: async (error) => {
      showToast({variant: 'danger', title: 'Error', body: ''})
      setFetchEnabled(true)
      refetch()
    },
  })

  useEffect(() => {
    if (fetchEnabled) {
      refetch()
      setFetchEnabled(false)
    }
  }, [fetchEnabled, refetch])

  return {
    userLoading: isLoading,
    isError,
    userSettingsFetched: isFetched,
    refetchUserSettings: () => setFetchEnabled(true),
    error,
    user: user || {},
    updateDiscordName: updateDiscordName.mutate,
    updateUser: updateUser.mutate,
  }
}

export default useUser
