import React from 'react'
import {Box, Typography, Stack} from '@mui/material'

import {_replaceWildCards, formatName} from 'util/string_utils'
import {CopytLabel, CopytStyledButton} from 'views_v2/lib/snippets'

const MdlLabelPurchased = (props) => {
  const {purchasedShipments, downloadShipmentLabels} = props

  return (
    <Box padding="0 !important">
      <Box className="modal-title">
        <Box className="modal__heading" mx="0px !important">
          <Typography variant="h1" textAlign="left">
            Label Purchased
          </Typography>
        </Box>
      </Box>

      <Box className="modal-body" padding={0} my={2}>
        <Stack direction="column" px={2} spacing={1} mb={4} justifyContent="center" alignItems="center">
          <CopytLabel style={{fontSize: '1.2rem'}}>Order Shipped</CopytLabel>
          <Typography
            sx={{
              fontSize: '1rem',
              lineHeight: '15px',
              textAlign: 'center',
            }}
          >
            Your order has been shipped. Track your shipment to see the delivery status.
          </Typography>
          {purchasedShipments.length <= 1 && (
            <>
              <CopytStyledButton
                variant="contained"
                color="primary"
                lineHeight="12px"
                style={{width: '200px', marginTop: '30px'}}
                onClick={() => window.open(shipment?.url, '_blank')}
              >
                Track Your Shipment
              </CopytStyledButton>
              <CopytLabel style={{fontSize: '0.9rem'}}>OR</CopytLabel>
            </>
          )}
          <CopytStyledButton
            variant="contained"
            color="primary"
            lineHeight="12px"
            style={{
              width: '150px',
              backgroundColor: 'var(--success) !important',
            }}
            onClick={async () => {
              const transactions = purchasedShipments.map((shipment) => shipment.shippoTransactionId)
              const {data} = await downloadShipmentLabels({transactions})
              window.open(data?.data?.url, '_blank')
            }}
          >
            {purchasedShipments.length <= 1 ? 'Download Label' : 'Download Labels'}
          </CopytStyledButton>
        </Stack>
      </Box>
    </Box>
  )
}

export default MdlLabelPurchased
