import {useState, useMemo} from 'react'
import {Box, Grid, Skeleton, Stack, Typography} from '@mui/material'
import {useDashboard} from 'stores/useDashboard'

import Container from '../container'
import SearchBar from 'material-ui-search-bar'
import {COLORS} from '../constants'
import debounce from 'lodash/debounce'

const Trends = ({currency, trends}) => {
  const [search, setSearch] = useState('')

  const handleSearch = (term) => {
    setSearch(term.toLowerCase())
  }

  const debouncedHandleSearch = useMemo(() => debounce(handleSearch, 300), [])
  console.log('Trends', trends)
  const filteredProducts = trends.data?.entities.products.filter(
    (product) =>
      product.sku.toLowerCase().includes(search) || product.title.toLowerCase().includes(search),
  )

  return (
    <Container width="100%">
      <Stack spacing={2}>
        <Typography fontWeight={800} fontSize={20} color="black">
          Top Products
        </Typography>

        <Stack spacing={1}>
          <SearchBar
            value={search}
            onChange={debouncedHandleSearch}
            onRequestSearch={() => {}}
            placeholder="Search by SKU or Title"
          />
          <Stack spacing={2} marginTop={2} height="500px" overflow="auto" paddingRight={2}>
            {trends.isLoading || trends.isIdle ? (
              <Stack>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => (
                  <Skeleton key={i} height="64px"></Skeleton>
                ))}
              </Stack>
            ) : (
              filteredProducts.map((product) => (
                <Stack key={product.sku} direction="row" gap={4} alignItems="center">
                  <Stack
                    width={124}
                    overflow="hidden"
                    borderRadius={2}
                    bgcolor="white"
                    position="relative"
                    flexShrink={0}
                  >
                    {product.assetUrls?.length ? <img src={product.assetUrls[0]} /> : null}

                    {product.consignId ? (
                      <Stack
                        bgcolor={COLORS.PRIMARY}
                        position="absolute"
                        top={15}
                        left={-42}
                        paddingX={5}
                        sx={{
                          transform: 'rotate(-45deg)',
                        }}
                      >
                        <Typography color="white" fontSize={8} fontWeight={600}>
                          Consigned
                        </Typography>
                      </Stack>
                    ) : null}
                  </Stack>
                  <Stack width="100%">
                    <Typography color="black" fontSize={14} fontWeight={600}>
                      {product.title}
                    </Typography>

                    <Stack direction="row" gap={0.5} marginBottom={1} flexWrap="wrap">
                      {product.sizes.map((size) => (
                        <Stack paddingX={1} paddingTop={0.2} bgcolor="#f2f2f2" borderRadius={1}>
                          <Typography whiteSpace="nowrap" color="black" fontSize={12}>
                            {size}
                          </Typography>
                        </Stack>
                      ))}
                    </Stack>

                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={5}>
                        <Typography whiteSpace="nowrap" color="black" fontSize={12}>
                          SKU: {product.sku}
                        </Typography>

                        <Typography whiteSpace="nowrap" color="black" fontSize={12}>
                          Type: {product.consignId ? 'Consigned' : 'Owned'}
                        </Typography>
                      </Grid>

                      <Grid item xs={4}>
                        <Typography whiteSpace="nowrap" color="black" fontSize={12}>
                          Avg. Sell Price: {currency.format(product?.average.sellPrice.toFixed(2))}
                        </Typography>
                        <Typography whiteSpace="nowrap" color="black" fontSize={12}>
                          Avg. Turnaround: {product?.average.turnaround.toFixed(0)}
                        </Typography>
                      </Grid>

                      <Grid item xs={3}>
                        <Typography whiteSpace="nowrap" color="black" fontSize={12}>
                          Units Sold: {product.count.sold.toFixed(0)}
                        </Typography>
                        <Typography whiteSpace="nowrap" color="black" fontSize={12}>
                          Current Stock: {product.count.stock.toFixed(0)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Stack>
                </Stack>
              ))
            )}
          </Stack>
        </Stack>
      </Stack>
    </Container>
  )
}

export default Trends
