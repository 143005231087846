import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import Switch from 'react-bootstrap-switch'
import {useAuth0} from '@auth0/auth0-react'
import * as Sentry from '@sentry/react'
import {
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Toast,
  ToastHeader,
  ToastBody,
  FormFeedback,
} from 'reactstrap'
import {Box, Grid, Stack, Typography} from '@mui/material'
import Loader from 'react-loader-spinner'
import axios from 'axios'
import CreatableSelect from 'react-select/creatable'

import {apiConfig} from 'config'
import CopytCard from 'components/CopytCard'
import {paths} from 'constant/api'
import PlatformNames from 'enums/PlatformNames'
import {useGlobalStore} from 'provider/global_store/hook'
import ArrayUtils from 'util/array_utils'
import {getLogo} from 'util/model/platform'
import {isEnabledByPlatform, getByPlatform} from 'util/model/credential'
import {createRequestOptions} from 'util/network'
import {useStoreSettings} from 'service/hook'
import {usePlatformId} from 'service/hook/use_platform'
import {useCredential} from 'service/hook/use_credential'
import {getCloverStoreLocations} from 'service/hook/use_store_locations'
import {CopytLabel, CopytButton, LoadingBar} from 'views_v2/lib/snippets'
import {
  getEbayRedirectUri,
  getShopifyRedirectUri,
  getSquareRedirectUri,
  getCloverUri,
  getCheckbookRedirectUri,
  getPaypalRedirectUri,
  getPaypalScopes,
} from './lib/util'
import './platforms.scss'

// todo: refactor

const EditPlatform = (props) => {
  const {user, getAccessTokenSilently} = useAuth0()
  const [saveLoading, setSaveLoading] = useState(false)

  const [authInProgress, setAuthInProgress] = useState(false)

  // const [checkbookAuthInProgress, setCheckbookAuthInProgress] = useState(false)
  const [shopifyStore, setShopifyStore] = useState('')
  const [errorMessage, setErrorMessage] = useState()
  const [showToast, setShowToast] = useState(null)
  const [showAuthenticationToast, setShowAuthenticationToast] = useState(null)
  const [platformEmail, setPlatformEmail] = useState('')
  const [platformPassword, setPlatformPassword] = useState('')
  const [platformRecoveryCode, setPlatformRecoveryCode] = useState('')
  const [settings, setSettings] = useState(null)
  const {locations: settingsStoreLocations} = useStoreSettings()
  const {credential, onPatchCredential, onCreateCredential} = useCredential()
  const [storeLocations, setStoreLocations] = useState([])
  const [selectedStoreLocation, setSelectedStoreLocation] = useState()
  const {isEnterprise} = useGlobalStore()

  const history = useHistory()

  let {pathname} = props.location
  const id = pathname.replace('/admin/platforms/', '')

  const {data: platform} = usePlatformId(id)

  const platformCredential = getByPlatform(platform, credential?.data)

  const [platformEnabled, setPlatformEnabled] = useState(
    isEnabledByPlatform(platform, credential.data) || false,
  )
  const [disableSwitch, setDisableSwitch] = useState(false)

  const [isExecutedToMapStoreLocation, setIsExecutedToMapStoreLocation] = useState(false)

  useEffect(() => {
    if (!platform || !credential || !settingsStoreLocations?.length) return
    if (!platformEnabled) {
      const isEnabled = isEnabledByPlatform(platform, credential.data) || false
      setPlatformEnabled(isEnabled)
      setDisableSwitch(isEnabled)
    }

    if (!storeLocations.length) {
      setStoreLocations(
        settingsStoreLocations.map((settingsStoreLocation) => ({
          label: settingsStoreLocation.name,
          value: settingsStoreLocation.id,
        })),
      )
    } else {
      setIsExecutedToMapStoreLocation(true)
      if (isExecutedToMapStoreLocation) return
      const populateCloverStoreLocations = async (accessToken, storeLocationId, refId) => {
        const merchant = await getCloverStoreLocations(getAccessTokenSilently, {
          accessToken,
          merchantId: refId,
        })
        setStoreLocations((prevStoreLocations) => {
          return prevStoreLocations.map((prevStoreLocation) => {
            if (storeLocationId === prevStoreLocation.value) {
              return {...prevStoreLocation, label: `${prevStoreLocation.label} | ${merchant.name}`}
            }
            return prevStoreLocation
          })
        })
      }

      if (platform.name === 'Clover') {
        const merchants = credential.data.filter(
          (credential) => credential?.platform && credential.platform.id === id,
        )
        for (const merchant of merchants) {
          populateCloverStoreLocations(
            merchant.value.accessToken,
            merchant.value.storeLocationId,
            merchant.refId,
          )
        }
      }
    }
  }, [platform, settingsStoreLocations, storeLocations, isExecutedToMapStoreLocation])

  const updateEmail = (e) => {
    setPlatformEmail(e.target.value)
  }
  const updatePassword = (e) => {
    setPlatformPassword(e.target.value)
  }
  const updateRecoveryCode = (e) => {
    setPlatformRecoveryCode(e.target.value)
  }

  // The purpose of this function is to create/update user's ebay token setting
  const createUpdateEbayAccessTokenUserSetting = async (ebayToken, token) => {
    if (!token) return

    try {
      // check if the setting exists for the plaform or not
      // here we can utlize the settings call that we are making above but since there can be a race condition between two useEffects, its better to create a different one

      let settingsGetResponse = await axios.get(
        `//${apiConfig.api_prefix}/userSettings/${'platform-' + id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )

      let currentSetting = {}

      if (settingsGetResponse && settingsGetResponse.data) {
        // if the setting exists

        currentSetting = settingsGetResponse.data.value
        if (!currentSetting.credentials) {
          currentSetting.credentials = {}
        }
        currentSetting.credentials.ebayToken = {
          ...JSON.parse(ebayToken),
          dateUpdatedUtc: new Date().toISOString(),
        }
      } else {
        // if the setting doesnt exists
        currentSetting = {
          enabled: false,
          credentials: {
            ebayToken: {
              ...JSON.parse(ebayToken),
              dateUpdatedUtc: new Date().toISOString(),
            },
          },
        }
      }

      // Now update the setting
      var settingsPostResponse = await axios.post(
        `//${apiConfig.api_prefix}/userSettings`,
        {
          key: 'platform-' + id,
          value: currentSetting,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )

      // Update the current setting object to prserve its state
      if (settings && settings.length) {
        let found = false
        settings.forEach((item, index) => {
          if (item.id == settingsPostResponse.data.id) {
            found = true
            settings[index] = settingsPostResponse.data
          }
        })

        // this is for incase settings is not found
        if (!found) {
          settings.push(settingsPostResponse.data)
        }
        setSettings(settings)
      }
    } catch (e) {
      Sentry.captureException('Failed to create user setting for ebay access token', e)
    }
  }

  const createUpdateShopifyAccessTokenUserSetting = async (shopifyToken, token, shopId) => {
    shopifyToken = shopifyToken.replace(/"/g, '')
    shopId = shopId.replace(/"/g, '')

    let userToken = token

    if (!token) {
      token = await getAccessTokenSilently()
    }

    try {
      // check if the setting exists for the plaform or not
      // here we can utlize the settings call that we are making above but since there can be a race condition between two useEffects, its better to create a different one

      let settingsGetResponse = await axios.get(
        `//${apiConfig.api_prefix}/userSettings/${'platform-' + id}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        },
      )

      let currentSetting = {}
      if (settingsGetResponse?.data) {
        // if the setting exists
        currentSetting = settingsGetResponse.data.value
        if (!currentSetting.credentials) {
          currentSetting.credentials = {}
        }
        currentSetting.credentials.shopifyToken = shopifyToken
        currentSetting.credentials.shopId = shopId
      } else {
        // if the setting doesnt exists
        currentSetting = {
          enabled: true,
          credentials: {
            shopifyToken: shopifyToken,
            shopId: shopId,
          },
        }
      }

      // Now update the setting
      var settingsPostResponse = await axios.post(
        `//${apiConfig.api_prefix}/userSettings`,
        {
          key: 'platform-' + id,
          value: currentSetting,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        },
      )

      // Update the current setting object to prserve its state
      if (settings?.length) {
        let found = false
        settings.forEach((item, index) => {
          if (item.id == settingsPostResponse.data.id) {
            found = true
            settings[index] = settingsPostResponse.data
          }
        })

        // this is for incase settings is not found
        if (!found) {
          settings.push(settingsPostResponse.data)
        }
        setSettings(settings)
      }
    } catch (e) {
      Sentry.captureException('Failed to create user setting for shopify access token', e)
    }
  }

  const createUpdateSquareAccessTokenUserSetting = async (squareToken, token) => {
    if (!token) return

    try {
      // check if the setting exists for the plaform or not
      // here we can utlize the settings call that we are making above but since there can be a race condition between two useEffects, its better to create a different one

      let settingsGetResponse = await axios.get(
        `//${apiConfig.api_prefix}/userSettings/${'platform-' + id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )

      let currentSetting = {}

      if (settingsGetResponse?.data) {
        // if the setting exists

        currentSetting = settingsGetResponse.data.value
        if (!currentSetting.credentials) {
          currentSetting.credentials = {}
        }
        currentSetting.credentials.squareToken = {
          ...JSON.parse(squareToken),
          dateUpdatedUtc: new Date().toISOString(),
        }
      } else {
        // if the setting doesnt exists
        currentSetting = {
          enabled: false,
          credentials: {
            squareToken: {
              ...JSON.parse(squareToken),
              dateUpdatedUtc: new Date().toISOString(),
            },
          },
        }
      }

      // Now update the setting
      var settingsPostResponse = await axios.post(
        `//${apiConfig.api_prefix}/userSettings`,
        {
          key: 'platform-' + id,
          value: currentSetting,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )

      // Update the current setting object to prserve its state
      if (settings?.length) {
        let found = false
        settings.forEach((item, index) => {
          if (item.id == settingsPostResponse.data.id) {
            found = true
            settings[index] = settingsPostResponse.data
          }
        })

        // this is for incase settings is not found
        if (!found) {
          settings.push(settingsPostResponse.data)
        }
        setSettings(settings)
      }
    } catch (e) {
      Sentry.captureException('Failed to create user setting for square access token', e)
    }
  }
  // The purpose of this useEffect is to save ebay token as a user setting if the url contains ebay access token query parameter

  useEffect(() => {
    const fetch = async () => {
      const token = await getAccessTokenSilently()

      // we will use this useEffect to capture the ebay access token given to us upon redirect
      const url = new URL(window.location.href)

      if (url.searchParams.get('ebay_access_token')) {
        // create the user setting before deleting the access token
        createUpdateEbayAccessTokenUserSetting(url.searchParams.get('ebay_access_token'), token)
        // once setting is removed, delete the token from the url
        url.searchParams.delete('ebay_access_token')
        window.history.replaceState({}, '', url.href)
      }
      if (url.searchParams.get('shopify_access_token')) {
        // create the user setting before deleting the access token
        createUpdateShopifyAccessTokenUserSetting(
          url.searchParams.get('shopify_access_token'),
          token,
          url.searchParams.get('shop'),
        )
        // show authentication toast if callback successful from shopify
        setShowAuthenticationToast(true)
        //window.history.replaceState({}, '', url.href)
      }
      if (url.searchParams.get('square_access_token')) {
        // create the user setting before deleting the access token
        createUpdateSquareAccessTokenUserSetting(
          url.searchParams.get('square_access_token'),
          token,
          url.searchParams.get('square_refresh_token'),
        )
        // once setting is removed, delete the token from the url
        url.searchParams.delete('square_access_token')
        url.searchParams.delete('square_refresh_token')

        window.history.replaceState({}, '', url.href)
      }
      if (url.searchParams.get('checkbook_access_token')) {
        // create the user setting before deleting the access token
        createUpdateCheckbookAccessTokenUserSetting(
          url.searchParams.get('checkbook_access_token'),
          token,
          url.searchParams.get('checkbook_refresh_token'),
        )
        // once setting is removed, delete the token from the url
        url.searchParams.delete('checkbook_access_token')
        url.searchParams.delete('checkbook_refresh_token')

        window.history.replaceState({}, '', url.href)
      }
    }

    fetch()
  }, [user])

  // This function is called when user wants to autheticate with ebay

  const apiCall = async (url, type) => {
    const token = await getAccessTokenSilently()
    if (type === 'post') {
      return axios.post(url, null, createRequestOptions(token))
    }
    return axios.get(url, createRequestOptions(token))
  }

  const urlBuilders = {
    [PlatformNames.EBAY]: () => `${apiConfig.api_url}/market/ebay/login`,
    [PlatformNames.SHOPIFY]: () =>
      `${paths.market}/shopify/login?shop=${shopifyStore}&redirect_uri=${getShopifyRedirectUri()}`,
    [PlatformNames.SQUARE]: () => `${paths.market}/square/login?&redirect_uri=${getSquareRedirectUri()}`,
    [PlatformNames.CLOVER]: () =>
      `${process.env.REACT_APP_CLOVER_POS_URL}/oauth/authorize?client_id=${
        process.env.REACT_APP_CLOVER_APP_ID
      }&redirect_uri=${getCloverUri()}`,
    [PlatformNames.VENMO]: () =>
      `${process.env.REACT_APP_PAYPAL_AUTH_URL}/signin/authorize?flowEntry=static&client_id=${
        process.env.REACT_APP_PAYPAL_CLIENT_ID
      }&response_type=code&scope=openid profile email address&redirect_uri=${getPaypalRedirectUri()}`,
    [PlatformNames.PAYPAL]: () =>
      `${process.env.REACT_APP_PAYPAL_AUTH_URL}/signin/authorize?flowEntry=static&client_id=${
        process.env.REACT_APP_PAYPAL_CLIENT_ID
      }&response_type=code&scope=openid profile email address&redirect_uri=${getPaypalRedirectUri()}`,
    [PlatformNames.CHECKBOOK]: () => `${paths.payment}/checkbook/login`,
  }

  const performAuth = async (platform) => {
    let type = null
    if (platform === PlatformNames.SHOPIFY) {
      // Shopify-specific logic
      if (props?.credential?.length > 0) {
        const shopifyStatus = ArrayUtils.getShopifyBillingStatus(props?.credential)
        if (!shopifyStatus) return window.open('/shopifybilling', '_self')
      }
      setAuthInProgress(true)
    }

    if (platform === PlatformNames.CLOVER) {
      if (!selectedStoreLocation) return
      const url = `${process.env.REACT_APP_CLOVER_POS_URL}/oauth/authorize?client_id=${
        process.env.REACT_APP_CLOVER_APP_ID
      }&redirect_uri=${getCloverUri()}?store_location_id:${selectedStoreLocation.value}`
      window.open(url, '_self')
      return
    } else if (platform === PlatformNames.SQUARE) {
      setAuthInProgress(true)
    } else if ([PlatformNames.VENMO, PlatformNames.PAYPAL].includes(platform)) {
      setAuthInProgress(true)
      performPaypalAuth()
      return
      // setPaypalAuthInProgress(true)
    } else if (platform === PlatformNames.COPYT_NETWORK) {
      history.push('/copytnetwork')
      return
    } else if (platform === PlatformNames.CHECKBOOK) {
      setAuthInProgress(true)
      type = 'post'
    } else if (platform === PlatformNames.EBAY) {
      setAuthInProgress(true)
    }

    const url = urlBuilders[platform]()
    if (url) {
      const response = await apiCall(url, type)
      const resData = response?.data
      const dataUrl =
        resData.data?.url || resData?.url || resData?.data || 'https://apps.shopify.com/copyt'
      window.open(dataUrl, '_self')
    }
  }

  const performPaypalAuth = async () => {
    const clientId = process.env.REACT_APP_PAYPAL_CLIENT_ID
    // const clientSecret = process.env.REACT_APP_PAYPAL_CLIENT_SECRET;
    const authUrl = `${
      process.env.REACT_APP_PAYPAL_AUTH_URL
    }/signin/authorize?flowEntry=static&client_id=${clientId}&response_type=code&scope=${getPaypalScopes()}&redirect_uri=${getPaypalRedirectUri()}`
    window.location.href = authUrl
  }
  // this function is just for displaying what bay button to display in the UI
  const checkForEbayCreds = (settings) => {
    if (
      settings &&
      settings.value &&
      settings.value.credentials &&
      settings.value.credentials.ebayToken
    ) {
      return true
    }
  }

  const onFormSubmit = async () => {
    try {
      setErrorMessage(null)
      setShowToast(null)
      if (!platformEmail || !platformPassword) {
        setErrorMessage('Email and password required')
        return
      }
      setSaveLoading(true)

      const token = await getAccessTokenSilently()
      if (id) {
        const response = await axios.put(
          `//${apiConfig.api_prefix}/supportedPlatforms/${id}`,
          {
            email: platformEmail,
            password: platformPassword,
            recovery_code: platformRecoveryCode,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        setShowToast(true)
        setTimeout(() => {
          setShowToast(null)
        }, 10000)
      }
      setSaveLoading(false)
    } catch (e) {
      Sentry.captureException(e)
      setShowToast(false)
      setErrorMessage(e.response && e.response.data ? e.response.data : e.message)
      setSaveLoading(false)
      setTimeout(() => {
        setShowToast(null)
        setErrorMessage(null)
      }, 10000)
    }
  }

  const toggleShowAuthenticationToast = () => {
    setShowAuthenticationToast(!showAuthenticationToast)
  }

  const handleSwitch = (s, v) => {
    setPlatformEnabled(v)
    setDisableSwitch(true)
    setTimeout(() => {
      setDisableSwitch(false)
    }, 2000)

    return (async () => {
      try {
        if (platformCredential) {
          onPatchCredential({
            id: platformCredential.id,
            input: {
              enabled: v,
            },
          })
        } else {
          onCreateCredential({
            platformId: platform.id,
            value: {
              // enabled: v,
            },
          })
        }
        return
      } catch (e) {
        Sentry.captureException(e)
        console.error(e)
      }
    })()
  }

  const platformActions = {
    [PlatformNames.EBAY]: {
      action: () => performAuth(PlatformNames.EBAY),
      note: (
        <Stack>
          <Typography>Requirements</Typography>
          <ul>
            <li>
              In order to post listings to eBay, you will need to have policies set up on eBay. You will
              need:
              <ul>
                <li>Fulfillment Policy</li>
                <li>Payment Policy</li>
                <li>Return Policy</li>
              </ul>
            </li>
            <li>
              <strong>
                A 1.5% transaction fee will be charged to the payment method on file at the time of a
                detected sale. The fee will be calculated based on the total sale price.
              </strong>
            </li>
          </ul>
        </Stack>
      ),
    },
    [PlatformNames.TRADE_BLOCK]: {
      action: () => null, //performAuth(PlatformNames.TRADE_BLOCK),
      note: (
        <Stack>
          <Typography>Requirements</Typography>
          <ul>
            <li>
              <strong>
                A 1.5% transaction fee will be charged to the payment method on file at the time of a
                detected sale. The fee will be calculated based on the total sale price.
              </strong>
            </li>
          </ul>
        </Stack>
      ),
    },
    [PlatformNames.SHOPIFY]: {
      action: () => performAuth(PlatformNames.SHOPIFY),
      note: '*If you have already installed the Copyt app on your Shopify store before Authenticating, please delete the app and then return to this page to complete the Authentication process.',
    },
    [PlatformNames.SQUARE]: {
      action: () => performAuth(PlatformNames.SQUARE),
    },
    [PlatformNames.CLOVER]: {
      action: () => performAuth(PlatformNames.CLOVER),
    },
    [PlatformNames.CHECKBOOK]: {
      action: () => performAuth(PlatformNames.CHECKBOOK),
    },
    [PlatformNames.COPYT_NETWORK]: {
      action: () => performAuth(PlatformNames.COPYT_NETWORK),
    },
    [PlatformNames.VENMO]: {
      action: () => performAuth(PlatformNames.VENMO),
      note: (
        <>
          *Before you can set up your Paypal/Venmo payouts in Copyt, you must first apply
          <a
            href="https://www.paypal.com/payoutsweb/landing?_ga=1.90298330.1399832895.1681830290"
            target="_blank"
            rel="noopener noreferrer"
          >
            {' '}
            here{' '}
          </a>
          to gain access to Paypal's Payout feature.
        </>
      ),
    },
    [PlatformNames.PAYPAL]: {
      action: () => performAuth(PlatformNames.PAYPAL),
      note: (
        <>
          *Before you can set up your Paypal/Venmo payouts in Copyt, you must first apply
          <a
            href="https://www.paypal.com/payoutsweb/landing?_ga=1.90298330.1399832895.1681830290"
            target="_blank"
            rel="noopener noreferrer"
          >
            {' '}
            here{' '}
          </a>
          to gain access to Paypal's Payout feature.
        </>
      ),
    },
  }

  const {updatePayoutInfo, payoutInfo: userPayoutInfo, isLoadingPayoutInfo} = useStoreSettings()
  const [validPayout, setValidPayout] = useState(true)
  const [payoutInfo, setPayoutInfo] = useState('')
  const [disablePayoutBtn, setDisablePayoutBtn] = useState(false)

  useEffect(() => {
    if (userPayoutInfo && Object.keys(userPayoutInfo).length > 0) {
      if (userPayoutInfo) {
        setPlatformEnabled(true)
      }
      setPayoutInfo(userPayoutInfo)
    }
  }, [userPayoutInfo])

  const validatePayout = () => {
    if (!payoutInfo) {
      return false
    }
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    const phonePattern = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
    return emailPattern.test(payoutInfo) || phonePattern.test(payoutInfo)
  }

  const handleInputChange = (e) => {
    if (e.target.value != '') {
      handleSwitch(null, true)
    } else {
      handleSwitch(null, false)
    }
    setValidPayout(true)
    setPayoutInfo(e.target.value)
  }
  const handleSubmit = async () => {
    try {
      setDisablePayoutBtn(true)
      const isValid = validatePayout()

      if (isValid) {
        await updatePayoutInfo(payoutInfo)
      } else {
        setValidPayout(false)
      }

      setTimeout(() => {
        setDisablePayoutBtn(false)
      }, 1000)
    } catch (error) {
      console.log('error', error.message)
    }
  }

  const renderAuthButton = (platformName, inProgress) => {
    const platformData = platformActions[platformName.toLowerCase()]

    if (!platformData) return null

    if (platform?.name.toLowerCase() === PlatformNames.PAYPAL.toLowerCase() && !isEnterprise) {
      return (
        <Grid alignItems="flex-end" spacing={3}>
          <Grid item xs={12}>
            <CopytCard.Text tag="div" style={{fontSize: '20px'}}>
              Payout Information
            </CopytCard.Text>

            <CopytCard.Body>
              <Typography style={{fontSize: '16px'}}>
                Some Enterprises pay their consignors out through Copyt via Paypal. As a consignor, you
                have the option to have your payout sent to either Paypal or Venmo account. For Paypal,
                enter your account's Email address below. for Venmo, enter the Phone Number linked to
                your account.
              </Typography>

              <FormGroup style={{marginTop: '20px'}}>
                <Input
                  placeholder="Enter email or phone number"
                  type="text"
                  value={payoutInfo}
                  onChange={handleInputChange}
                  invalid={!validPayout}
                  style={{borderColor: validPayout ? '' : 'red'}}
                />
                <FormFeedback style={{color: validPayout ? '' : 'red'}}>
                  Please enter a valid email or US phone number
                </FormFeedback>
              </FormGroup>
              <Button disabled={disablePayoutBtn} onClick={handleSubmit}>
                Update Payout
              </Button>
            </CopytCard.Body>
          </Grid>
        </Grid>
      )
    }

    return (
      <>
        {inProgress ? (
          <span>Please wait...</span>
        ) : (
          <Grid container alignItems="flex-end" spacing={3}>
            <Grid item>
              <CopytButton
                onClick={platformData.action}
                disabled={platformName?.toLowerCase() === PlatformNames.TRADE_BLOCK}
                variant="outlined"
                style={{
                  borderColor: 'var(--primary)',
                  color: 'var(--primary)',
                }}
              >
                Authenticate
              </CopytButton>
            </Grid>
            {platformName === 'Clover' ? (
              <Grid lg={3} item>
                <CopytLabel>Store Location</CopytLabel>
                <CreatableSelect
                  className="react-select info"
                  ckassNamePrefix="react-select"
                  id="location-autocomplete"
                  name="location"
                  placeholder="Store location"
                  closeMenuOnSelect
                  isDisabled={!isExecutedToMapStoreLocation}
                  onChange={(value) => {
                    setSelectedStoreLocation(value)
                  }}
                  options={isExecutedToMapStoreLocation ? storeLocations : []}
                  // tabIndex={editMode ? '6' : '30'}
                />
              </Grid>
            ) : null}
          </Grid>
        )}
        {platformData.note && (
          <div style={{marginTop: '32px', color: '#140010', fontSize: '14px'}}>{platformData.note}</div>
        )}
      </>
    )
  }

  if (!platform) return <LoadingBar />

  return (
    <div className="p-detail-flex edit-platforms">
      {(showToast == true && (
        <div className="bg-success my-2 rounded fixed-toast">
          <Toast>
            <ToastHeader toggle={() => setErrorMessage(null) && setShowToast(null)}>Success</ToastHeader>
            <ToastBody>Credentials updated!</ToastBody>
          </Toast>
          3
        </div>
      )) ||
        (showToast == false && errorMessage && (
          <div className="bg-danger my-2 rounded fixed-toast">
            <Toast>
              <ToastHeader toggle={() => setErrorMessage(null) && setShowToast(null)}>Error</ToastHeader>
              <ToastBody>{errorMessage}</ToastBody>
            </Toast>
          </div>
        ))}
      {showAuthenticationToast && (
        <div className="bg-success my-2 rounded fixed-toast">
          <Toast>
            <ToastHeader toggle={toggleShowAuthenticationToast}>Success!</ToastHeader>
            <ToastBody>Authentication Successful!</ToastBody>
          </Toast>
        </div>
      )}

      <div className="content">
        <Row>
          <CopytCard>
            <CopytCard.Header
              style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}
            >
              <CopytCard.Text tag="div">
                <img src={getLogo(platform)} width={40} height={40} style={{marginRight: '16px'}} />
                {platform.name === 'Paypal' ? platform.name + ' / Venmo' : platform.name}
              </CopytCard.Text>
              <Box display="flex" gap={2} alignItems="center">
                <Switch
                  bsSize="mini"
                  onChange={handleSwitch}
                  value={platformEnabled}
                  onText="Enabled"
                  offText="Disabled"
                  disabled={!platformCredential || disableSwitch}
                />
              </Box>
            </CopytCard.Header>
            <CopytCard.Body>
              {platform && platform.name ? (
                renderAuthButton(platform.name, authInProgress)
              ) : (
                <Form>
                  {errorMessage && showToast == null && (
                    <Col className="pr-md-1" md="6">
                      <h5 style={{color: 'red'}}>{errorMessage}</h5>
                    </Col>
                  )}
                  <Col className="pr-md-1" md="6">
                    <FormGroup>
                      <label>{platform.name} Email</label>
                      <Input
                        value={platformEmail}
                        onChange={updateEmail}
                        placeholder="Enter email"
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pr-md-1" md="6">
                    <FormGroup>
                      <label>{platform.name} password</label>
                      <Input
                        value={platformPassword}
                        onChange={updatePassword}
                        placeholder="********"
                        type="password"
                        autoComplete="off"
                      />
                    </FormGroup>
                  </Col>

                  {platform && platform.name && platform.name.toLowerCase() === 'stockx' ? (
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>{platform.name} recovery code</label>
                        <Input
                          value={platformRecoveryCode}
                          onChange={updateRecoveryCode}
                          type="text"
                          placeholder="Enter recovery code"
                          autoComplete="off"
                        />
                      </FormGroup>
                      <div className="input-note">
                        If 2-Factor Authentication is enabled, your recovery code is required to initiate
                        StockX. Once this code is submitted you will not need to do this again unless
                        notified
                      </div>
                    </Col>
                  ) : (
                    ''
                  )}

                  <Col
                    className="pr-md-1"
                    md="6"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                    }}
                  >
                    {saveLoading && (
                      <Loader
                        type="TailSpin"
                        color="#d5239e"
                        height={20}
                        width={20}
                        className="import-button"
                      />
                    )}
                    <Button
                      key="newSave"
                      disabled={saveLoading}
                      style={{padding: '0', color: '#d5239e'}}
                      color="link"
                      onClick={onFormSubmit}
                    >
                      UPDATE CREDENTIALS
                    </Button>
                  </Col>
                </Form>
              )}

              {platform &&
                platform.name &&
                platform.name.toLowerCase() === 'grailed' && [
                  <Col className="pr-md-1" md="12" style={{marginTop: '32px'}}>
                    <h4>Requirements</h4>
                    <ul>
                      <li>
                        In order to import listings from Grailed, you will need to supply a SKU in your
                        Grailed listings' descriptions.
                        <ul>
                          <li>
                            It should follow the format: <h6>SKU: [&lt;your sku&gt;]</h6>
                          </li>
                          <li>
                            For example: <h6>SKU: [635525-006]</h6>
                          </li>
                        </ul>
                      </li>
                      <li>Grailed listings require a tagged image upload in order to pass moderation</li>
                    </ul>
                  </Col>,
                ]}
            </CopytCard.Body>
          </CopytCard>
        </Row>
      </div>
    </div>
  )
}

export default EditPlatform
