import React from 'react'
import ReactDOM from 'react-dom'
import {createBrowserHistory} from 'history'
import {Auth0Provider} from '@auth0/auth0-react'
import 'assets/css/nucleo-icons.css'
import 'react-notification-alert/dist/animate.css'
import 'assets/scss/copyt-platform-ui.scss?v=1.1.0'
import 'assets/demo/demo.css'
import * as serviceWorker from './serviceWorker'
import ReactGA from 'react-ga'
import {loadStripe} from '@stripe/stripe-js'
import {Elements} from '@stripe/react-stripe-js'
import App from './app'
import {Router} from 'react-router-dom'
import {GlobalStoreProvider} from 'provider/global_store'
import {ToastMessageProvider} from 'components/ToastMessage/provider'
import {ReactQueryDevtools} from 'react-query/devtools'
import {QueryClient, QueryClientProvider} from 'react-query'
import { ThemeProvider } from '@mui/material'
import { theme } from 'config'

// Stripe
const {
  REACT_APP_STRIPE_PK,
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_AUTH0_AUDIENCE,
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_BUILD_VERSION,
} = process.env

console.log(process.env)
const stripe = loadStripe(REACT_APP_STRIPE_PK)

ReactGA.initialize('UA-195413895-1', {
  // debug: true,
  gaOptions: {
    siteSpeedSampleRate: 100,
  },
})

console.log('build version:', REACT_APP_BUILD_VERSION)
console.log('all', JSON.stringify(process.env))

export const hist = createBrowserHistory()

// Initialize google analytics page view tracking
hist.listen((location) => {
  ReactGA.set({page: location.pathname}) // Update the user's current page
  ReactGA.pageview(location.pathname) // Record a pageview for the given page
})

const onRedirectCallback = (appState) => {
  // If redirecting from shopify, force a full redirect to the correct
  // redirection path or component does not load properly.
  if (appState?.returnTo.includes('shopify_access_token=')) {
    window.location.href = appState.returnTo
  }
  // Otherwise, use the router's history module to replace the url
  hist.push(appState?.returnTo || window.location.pathname)
}

const queryClient = new QueryClient()

ReactDOM.render(
  <Auth0Provider
    domain={REACT_APP_AUTH0_DOMAIN}
    clientId={REACT_APP_AUTH0_CLIENT_ID}
    redirectUri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
    audience={REACT_APP_AUTH0_AUDIENCE}
    cacheLocation="localstorage"
  >
    <Elements stripe={stripe}>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
      />
      <QueryClientProvider client={queryClient}>
        <GlobalStoreProvider>
          <ToastMessageProvider>
            <ThemeProvider theme={theme}>
              <Router history={hist}>
                <App />
              </Router>
            </ThemeProvider>
          </ToastMessageProvider>
        </GlobalStoreProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Elements>
  </Auth0Provider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.register()
