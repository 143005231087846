import DateUtils from 'util/date_utils'

export const initialFormFields = {
  quantity: 1,
  assets: [],
  condition: 'Brand New',
  category: "Men's Shoes",
  boxCondition: 'Original Box (Good)',
  tagStatus: 'Intact',
  shippingMethod: 'UPS',
  boxDimensions: '15 x 10 x 5in',
  boxWeight: '4 lb',
  acquiredDate: DateUtils.toDateParam(DateUtils.now()),
  description: 'SKU: [SKU]\n',
}

export const initialProductTemplate = {
  value: false,
  label: 'No',
}

export default {
  initialFormFields,
  initialProductTemplate,
}
