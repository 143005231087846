import React, {useEffect, useState} from 'react'
import {Box, Checkbox, FormControlLabel, Stack, Typography} from '@mui/material'
import Select from 'react-select'
import {chain, has, isEmpty, keyBy} from 'lodash'

import {useCredential, useStoreSettings} from 'service/hook'
import {ModalType} from 'enums'
import {EmptyPlatform} from 'components/empty_platform'
import useEnterKeyListener from 'hooks/useEnterKeyListener'
import {CopytLabel, CopytStyledButton, CopytSwitch, CopytTooltip} from 'views_v2/lib/snippets'
import {getMerchant} from 'views_v2/modules/Listings/middleware/getMerchant'
import mw from '../../middleware'
import {useListingMethod} from 'service/hook'
import {ListingMethod} from 'enums'

const MdlListAllPlatform = (p) => {
  const {setModalType, onCloseModal, onSaveChanges} = p
  const {platforms, onChange, selected, setSelected, isEnterprise} = mw.usePlatformSelectionHook()

  const {credential} = useCredential()
  const {locations: settingsStoreLocations} = useStoreSettings()
  const [cloverStoreLocations, setCloverStoreLocations] = useState([])
  const [cloverSelectedStoreLocation, setCloverSelectedStoreLocation] = useState()
  const [isListingPriceBump, setIsListingPriceBump] = useState(false)
  const [listingMethod, updateListingMethod] = useListingMethod()

  const sortedPlatformOptions = chain(platforms)
    .filter((option) => option.name !== 'Paypal')
    .filter((option) => option.name !== 'Checkbook')
    .sortBy('name')
    .reverse()
    .value()

  const isClover = selected?.some((s) => s?.name?.toLowerCase() === 'clover')

  useEnterKeyListener({querySelectorToExecuteClick: '#id-listall-submit'})

  const onChangePlatform = (e) => {
    if (e.target?.labels?.textContent?.toLowerCase() === 'clover') {
      if (!isEmpty(cloverStoreLocations) && cloverStoreLocations.length === 1 && e.target.checked) {
        setCloverSelectedStoreLocation(cloverStoreLocations[0].value)
      } else {
        setCloverSelectedStoreLocation(undefined)
      }
    }
    onChange(e)
  }

  useEffect(() => {
    if (!credential || isEmpty(settingsStoreLocations)) return

    if (credential.data) {
      const merchants = getMerchant(credential)

      const storeLocationsMap = keyBy(settingsStoreLocations, 'id')

      const cloverMappedStoreLocations = chain(merchants)
        .filter((merchant) => has(storeLocationsMap, merchant.value?.storeLocationId))
        .map((merchant) => ({
          label: storeLocationsMap[merchant.value?.storeLocationId].name,
          value: `clover:${merchant.platform.id}:${merchant.refId}`,
        }))
        .value()

      setCloverStoreLocations(cloverMappedStoreLocations)
    }
  }, [settingsStoreLocations, credential?.data])

  return (
    <Box>
      <Box className="modal-title">
        <Box className="modal__heading" mx="0px !important">
          <Typography variant="h1" textAlign="left">
            Select Platform
          </Typography>
        </Box>
      </Box>
      <Box className="modal-body" p={0}>
        <Box m={2}>
          {isEmpty(sortedPlatformOptions) ? (
            <EmptyPlatform />
          ) : (
            <Typography
              color="var(--primary) !important"
              lineHeight="1.2rem"
              fontStyle="italic"
              fontSize="0.9rem"
            >
              Remember! Some platforms have specific requirements: If any of these items do not meet the
              requirements for each platform, they will result in a Listing Failure to that platform.
            </Typography>
          )}
        </Box>
        <Stack direction="column" px={2}>
          {sortedPlatformOptions?.map((i, key) => {
            let title = ''
            if (i?.name?.toLowerCase() === 'ebay') {
              title =
                'eBay requires you to have at least one photo attached to each listing. Listing failures can occur due to your eBay Monthly Selling Limits being reached.'
            } else if (isEnterprise && i.name?.toLowerCase() === 'square') {
              title = 'Required to have an internal SKU attached'
            }

            return (
              <CopytTooltip {...{title}} placement="left" key={key}>
                <Box key={key} height="35px" mx={1}>
                  <Stack direction="row" alignItems="flex-start">
                    <FormControlLabel
                      key={i.id}
                      value={i.id}
                      onChange={onChangePlatform}
                      control={<Checkbox key={i.id} checked={selected?.some((s) => s?.id === i?.id)} />}
                      label={i.name}
                      sx={{
                        width: '55%',
                      }}
                      componentsProps={{
                        typography: {
                          sx: {
                            marginLeft: '4px',
                            lineHeight: '14px',
                          },
                        },
                      }}
                    />
                    {i?.name?.toLowerCase() === 'shopify' && selected?.some((s) => s?.id === i?.id) && (
                      <CopytTooltip title="">
                        <Box flex={1}>
                          <CopytSwitch
                            labelEnd="POS Only"
                            _textEnd={{color: 'var(--success) !important', fontSize: '12px'}}
                            onChange={(e) =>
                              updateListingMethod(
                                'shopify',
                                e.target.checked ? ListingMethod.POS_ONLY : ListingMethod.VENDING_STYLE,
                              )
                            }
                            checked={listingMethod?.shopify === ListingMethod.POS_ONLY}
                          />
                        </Box>
                      </CopytTooltip>
                    )}
                    {i?.name?.toLowerCase() === 'ebay' && selected?.some((s) => s?.id === i?.id) && (
                      <CopytTooltip title="Automatically bump the list price that appears on eBay to account for eBay’s 8-12% seller fee.">
                        <Box flex={1}>
                          <CopytSwitch
                            labelEnd="Price Bump"
                            _textEnd={{color: 'var(--success) !important', fontSize: '12px'}}
                            onChange={(e) => setIsListingPriceBump(!isListingPriceBump)}
                            checked={isListingPriceBump}
                          />
                        </Box>
                      </CopytTooltip>
                    )}
                    {i?.name?.toLowerCase() === 'clover' && isClover && (
                      <Box width="100% !important">
                        <Select
                          className="react-select info"
                          classNamePrefix="react-select"
                          placeholder="Choose Clover Store Location"
                          closeMenuOnSelect
                          isClearable
                          value={cloverStoreLocations?.find(
                            (_) => _?.value === cloverSelectedStoreLocation,
                          )}
                          onChange={(e) => {
                            if (e) {
                              setCloverSelectedStoreLocation(e.value)
                              setSelected((v) => {
                                return [
                                  ...v.filter((i) => i.id != e.value.split(':')[1]),
                                  platforms.find((i) => i.id === e.value.split(':')[1]),
                                ]
                              })
                            } else {
                              setSelected((v) => {
                                return v.filter(
                                  (i) => i.id !== cloverSelectedStoreLocation?.split(':')[1],
                                )
                              })
                              setCloverSelectedStoreLocation(undefined)
                            }
                          }}
                          styles={{
                            control: (base) => ({
                              ...base,
                              borderColor: !cloverSelectedStoreLocation ? 'red !important' : 'none',
                            }),
                          }}
                          options={cloverStoreLocations}
                        />
                        {!cloverSelectedStoreLocation && (
                          <CopytLabel props={{error: true}}>Required to list to Clover</CopytLabel>
                        )}
                      </Box>
                    )}
                  </Stack>
                </Box>
              </CopytTooltip>
            )
          })}
        </Stack>

        <Stack
          direction="row"
          justifyContent="flex-end"
          pr={2}
          py={1.5}
          mt={4}
          borderTop="1px solid var(--lighter)"
          spacing={2}
        >
          <CopytStyledButton variant="outline" color="primary" onClick={onCloseModal}>
            Cancel
          </CopytStyledButton>
          <CopytStyledButton
            variant="contained"
            color="primary"
            id="id-listall-submit"
            onClick={() => {
              const cloverRefId = cloverSelectedStoreLocation?.split(':')[2]
              const listMultiple = {
                platformIds: isEmpty(selected) ? [] : selected?.map((s) => s?.id),
                refIds: !!cloverRefId ? {clover: cloverRefId} : {},
                listingPriceBump: isListingPriceBump,
                listingMethod,
              }

              onSaveChanges({onListMultiple: listMultiple})
              setModalType(ModalType.UNDEFINED)
            }}
            disabled={
              isEmpty(sortedPlatformOptions) ||
              (!cloverSelectedStoreLocation?.includes('clover') && isClover) ||
              !selected?.length
            }
          >
            Submit
          </CopytStyledButton>
        </Stack>
      </Box>
    </Box>
  )
}

export default MdlListAllPlatform
