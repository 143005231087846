import {create} from 'zustand'

export const useFilter = create((set) => ({
  audit: {
    filter: {},
  },
  setAuditFilter: (filter) =>
    set((state) => ({
      audit: {
        ...state.audit,
        filter: filter,
      },
    })),
}))
