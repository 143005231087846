import React, {useEffect, useState} from 'react'
import _ from 'lodash'
import Select from 'react-select'
import { Box } from '@mui/material'
import {useProductTemplate} from 'service/hook'
import {category_options, condition_options} from 'assets/data'
import {checkObjIfEmpty} from 'util/string_utils'
import CopytCard from 'components/CopytCard'
import FieldGroup from 'views_v2/lib/snippets/FieldGroup'
import { CopytButton, CopytLabel } from 'views_v2/lib/snippets'
import styled from 'styled-components'

export const CopytBtn = styled(CopytButton)({
  minWidth: '100px'
})

const ProductTemplate = () => {
  const { status, template, addProductTemplate, updateProductTemplate, deleteProductTemplate } =
    useProductTemplate()
  const [errorMessage, setErrorMessage] = useState()
  const [isNew, setIsNew] = useState(false)
  const [productTemplate, setProductTemplate] = useState({
    name: '',
    condition: 'Brand New',
    size: 'large',
    category: `Men's Shoes`,
    shippingWieght: '',
  })

  useEffect(() => {
    if (status === 'success') {
      if (Array.isArray(template) && template?.length > 0) {
        setProductTemplate({
          ...template[0],
        })
        setIsNew(true)
      } else {
        setProductTemplate({
          name: '',
          condition: 'Brand New',
          size: 'large',
          category: `Men's Shoes`,
          shippingWieght: '',
        })
        setIsNew(false)
      }
    }
  }, [status, template])

  const submit = () => {
    // Segment Track call - Product Template Save
    if (window.analytics) {
      window.analytics.track('PRODUCT_TEMPLATE_SAVE_CLICKED')
    }

    let formError = null
    if (checkObjIfEmpty(productTemplate)) {
      formError = 'is required'
    }
    setErrorMessage(formError)

    if (!formError) {
      if (isNew) {
        const id = template[0]?.id
        updateProductTemplate({ id, body: productTemplate })
      } else {
        addProductTemplate(productTemplate)
      }
    }
  }

  return (
    <CopytCard className="copyt-card">
      <CopytCard.Header>
        <CopytCard.Text tag="div">Product Template</CopytCard.Text>
      </CopytCard.Header>
      <CopytCard.Body>
        <FieldGroup
          sLabel="Template Name"
          type="text"
          hasError={productTemplate?.name === '' && errorMessage}
          errorMessage={productTemplate?.name === '' && `Template Name ${errorMessage}`}
          placeholder="Template Name"
          value={productTemplate?.name}
          onChange={(e) =>
            setProductTemplate({
              ...productTemplate,
              name: e.target.value,
            })
          }
        />
        <CopytLabel>Condition</CopytLabel>
        <Select
          className="react-select info"
          classNamePrefix="react-select"
          defaultValue={condition_options.find((c) => c?.value === productTemplate?.condition)}
          closeMenuOnSelect
          options={condition_options}
          onChange={(e) =>
            setProductTemplate({
              ...productTemplate,
              condition: e.value,
            })
          }
        />
        <CopytLabel marginTop={1}>Size Category</CopytLabel>
        <Select
          className="react-select info"
          classNamePrefix="react-select"
          defaultValue={category_options.find((c) => c?.value === productTemplate?.category)}
          closeMenuOnSelect
          options={category_options}
          onChange={(e) =>
            setProductTemplate({
              ...productTemplate,
              category: e.value,
            })
          }
        />
        <CopytLabel marginTop={1}>Shipping Weight (lb.)</CopytLabel>
        <FieldGroup
          type="number"
          placeholder="Shipping Weight (lb.)"
          hasError={productTemplate?.shippingWieght === '' && errorMessage}
          errorMessage={productTemplate?.shippingWieght === '' && `Shipping Weight ${errorMessage}`}
          value={productTemplate?.shippingWieght}
          onChange={(e) =>
            setProductTemplate({
              ...productTemplate,
              shippingWieght: e.target.value,
            })
          }
        />
        <Box display="flex" justifyContent="flex-end" gap={1}>
          {isNew && (
            <CopytButton
              variant="contained"
              color="error"
              disabled={!productTemplate?.shippingWieght || !productTemplate?.name}
              onClick={() => {
                const id = template[0]?.id
                deleteProductTemplate(id)
              }}
              sx={{ minWidth: '8rem' }}
            >
              Delete
            </CopytButton>
          )}
          <CopytButton
            variant="outline"
            color="primary"
            disabled={
              template?.length > 0
                ? _.isEqual(template[0], productTemplate)
                : _.isEmpty(productTemplate?.name) && _.isEmpty(productTemplate?.shippingWieght)
            }
            onClick={() => submit()}
            sx={{ minWidth: '8rem' }}
          >
            {isNew ? 'Update' : 'Save'}
          </CopytButton>
        </Box>
      </CopytCard.Body>
    </CopytCard>
  )
}

export default ProductTemplate
