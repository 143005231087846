import React from 'react'
import {chain} from 'lodash'
import Select from 'react-select'
import {Box, Checkbox, FormControlLabel, Stack} from '@mui/material'

import {ReactComponent as PlatformsEmptyStateSvg} from 'assets/img/platforms-empty-state.svg'
import CopytCard from 'components/CopytCard'
import {CopytLabel, CopytSwitch, CopytTooltip, EmptyData} from 'views_v2/lib/snippets'
import {_checkForClover} from 'util/array_utils'
import {useListingMethod} from 'service/hook'
import {ListingMethod} from 'enums'

const InventoryPlatforms = (props) => {
  const {
    isEnterprise,
    platformSelection = [],
    platformOptions = [],
    cloverStoreLocations = [],
    handleCheckbox = () => null,
    onChangeCloverDropdown = () => null,
    cloverSelectedStoreLocation = '',
    isDisabled = false,
    isListingPriceBump = false,
    setIsListingPriceBump,
    listingMethod,
    updateListingMethod,
  } = props

  const sortedPlatformOptions = chain(platformOptions)
    .filter((option) => option.label !== 'Paypal' && option.hasCred)
    .filter((option) => option.label !== 'Checkbook' && option.hasCred)
    .sortBy('label')
    .reverse()
    .value()
  const isClover = _checkForClover(platformOptions, platformSelection)
  const isEmpty = platformOptions.length === 0
  const isCopytNetwork =
    !isEnterprise &&
    !sortedPlatformOptions.find((platform) => platform.label === 'Copyt Network' && platform.hasCred)

  return (
    <CopytCard>
      <CopytCard.Header>
        <CopytCard.Text tag="div">Platforms</CopytCard.Text>
      </CopytCard.Header>
      <CopytCard.Body>
        {isEmpty || isCopytNetwork ? (
          <EmptyData
            title="You haven't enabled any platforms yet."
            icon={<PlatformsEmptyStateSvg className="empty-state-svg" />}
          >
            To list this item, head over to your <a href="/admin/platforms/management">platforms</a> page
            to link your accounts and enable the {isCopytNetwork && 'Marketplace'} platforms you would
            like to list to.
          </EmptyData>
        ) : (
          <>
            <CopytLabel>Select platforms for this listing</CopytLabel>
            {sortedPlatformOptions &&
              sortedPlatformOptions.map((platform, index) => (
                <Stack direction="row" key={index} alignItems="center">
                  <FormControlLabel
                    disabled={isDisabled}
                    id={platform.id}
                    sx={{
                      height: 30,
                      color: 'var(--success)',
                      width: '35%',
                      minWidth: '45%',
                    }}
                    componentsProps={{
                      typography: {
                        lineHeight: '16px',
                      },
                    }}
                    control={<Checkbox checked={platformSelection?.some((pf) => pf === platform.id)} />}
                    onChange={() => handleCheckbox(platform.id)}
                    label={platform.label}
                  />
                  {platform?.label?.toLowerCase() === 'shopify' &&
                    platformSelection?.some((pf) => pf === platform.id) && (
                      <CopytTooltip title="">
                        <Box
                          flex={1}
                          sx={{
                            opacity: isDisabled ? 0.1 : 1,
                            cursor: isDisabled ? 'default' : 'pointer',
                          }}
                        >
                          <CopytSwitch
                            labelEnd="POS Only"
                            _textEnd={{color: 'var(--success) !important', fontSize: '12px'}}
                            onChange={(e) =>
                              updateListingMethod(
                                'shopify',
                                e.target.checked ? ListingMethod.POS_ONLY : ListingMethod.VENDING_STYLE,
                              )
                            }
                            checked={listingMethod?.shopify === ListingMethod.POS_ONLY}
                          />
                        </Box>
                      </CopytTooltip>
                    )}
                  {platform?.label?.toLowerCase() === 'ebay' &&
                    platformSelection?.some((pf) => pf === platform.id) && (
                      <CopytTooltip title="Automatically bump the list price that appears on eBay to account for eBay's 8-12% seller fee.">
                        <Box
                          flex={1}
                          sx={{
                            opacity: isDisabled ? 0.1 : 1,
                            cursor: isDisabled ? 'default' : 'pointer',
                          }}
                        >
                          <CopytSwitch
                            labelEnd="Price Bump"
                            _textEnd={{color: 'var(--success) !important', fontSize: '12px'}}
                            onChange={(e) => {
                              isDisabled ? null : setIsListingPriceBump(!isListingPriceBump)
                            }}
                            checked={isListingPriceBump}
                          />
                        </Box>
                      </CopytTooltip>
                    )}
                  {platform?.label?.toLowerCase() === 'clover' && isClover && (
                    <Stack width={1} flex={1}>
                      <Select
                        isDisabled={isDisabled}
                        className="react-select info"
                        classNamePrefix="react-select"
                        placeholder="Clover Store Locations"
                        closeMenuOnSelect
                        isClearable
                        value={cloverStoreLocations?.find(
                          (_) => _?.value === cloverSelectedStoreLocation,
                        )}
                        onChange={onChangeCloverDropdown}
                        options={cloverStoreLocations}
                        styles={{
                          control: (base) => ({
                            ...base,
                            borderColor:
                              isClover && !cloverSelectedStoreLocation ? 'red !important' : 'none',
                          }),
                        }}
                      />
                      {!cloverSelectedStoreLocation && (
                        <CopytLabel props={{error: true}}>Required to list to Clover</CopytLabel>
                      )}
                    </Stack>
                  )}
                </Stack>
              ))}
          </>
        )}
      </CopytCard.Body>
    </CopytCard>
  )
}

export default InventoryPlatforms
