import React, { memo, useState } from 'react'
import clx from 'classnames'
import { Box, Tabs, Tab } from '@mui/material'
import { TabPanel } from 'views_v2/lib/snippets'
import PaywallWelcomeNote from './PaywallWelcomeNote'
import PaywallUpgradeAccount from './PaywallUpgradeAccount'

import {
  InfoOutlined as InfoOutlinedIcon,
  UpgradeOutlined as UpgradeOutlinedIcon
} from '@mui/icons-material'

export const PaywallIndividual = (p) => {
  const { visible, setContentType, onCloseModal } = p
  const [tab, setTab] = useState(0)

  const handleChange = (e, newValue) => {
    e.preventDefault()
    setTab(newValue)
  }

  return (
    <div className={clx('paywall-wrapper', !visible && 'hide')}>
      <div className="paywall-wrapper--body">
        <Box className="paywall-tab">
          <Tabs value={tab} onChange={handleChange} aria-label="icon paywall">
            <Tab icon={<InfoOutlinedIcon />} label="Welcome Message" iconPosition="start" />
            <Tab icon={<UpgradeOutlinedIcon />} label="Upgrade to Enterprise" iconPosition="start" />
          </Tabs>
        </Box>
        <TabPanel value={tab} index={0}>
          <PaywallWelcomeNote />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <PaywallUpgradeAccount {...{ setContentType, onCloseModal }} withoutBody visible />
        </TabPanel>
      </div>
    </div>
  )
}

export default memo(PaywallIndividual)
