import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Box } from '@mui/material'
import axios from 'axios'
import CopytCard from 'components/CopytCard'
import { FieldGroup } from 'views_v2/lib/snippets'
import { CopytBtn } from './ProductTemplate'
import { AccountSettingsModalType } from '../../AccountSettingsModals'

const LoginDetails = () => {
  const { user } = useAuth0()

  const resetPassword = () => {
    var options = {
      method: 'POST',
      url: 'https://auth.copyt.io/dbconnections/change_password',
      headers: { 'content-type': 'application/json' },
      data: {
        client_id: 'cMcIZj4W27Rr9SwjjNm4WyvS6GS9UUAy',
        email: user.email,
        connection: 'Username-Password-Authentication',
      },
    }

    axios
      .request(options)
      .then((r) => {
        setModalType(AccountSettingsModalType.RESET)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  return (
    <CopytCard className="copyt-card">
      <CopytCard.Header><CopytCard.Text tag="div">Login Details</CopytCard.Text></CopytCard.Header>
      <CopytCard.Body>
        <FieldGroup
          sLabel="Username"
          name="username"
          type="text"
          placeholder="Username"
          value={(user && user.email) || ''}
          readOnly
        />
        <FieldGroup sLabel="Password" placeholder="****************" readOnly />
        <Box display="flex" justifyContent="flex-end">
          <CopytBtn
            variant="contained"
            color="primary"
            onClick={() => resetPassword()}
          >
            Reset Password
          </CopytBtn>
        </Box>
      </CopytCard.Body>
    </CopytCard>
  )
}

export default LoginDetails