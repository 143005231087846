import React from 'react';
import clx from 'classnames';
import {ToastType} from 'enums';
import {ToastMessage} from 'views_v2/lib/snippets';

const AccountSettingsToast = p => {
  const {content, toastType, setToastType} = p;
  let header = 'Success';
  let body = content;

  switch (toastType) {
    case ToastType.ERROR: {
      header = 'Failed';
      break;
    }
  }

  if (toastType > ToastType.UNDEFINED) {
    return (
      <div className={clx('my-2', 'rounded', 'fixed-toast', toastType === ToastType.ERROR ? 'bg-danger' : 'bg-success')}>
        <ToastMessage {...{header, body}} toggle={() => setToastType(ToastType.UNDEFINED)} />
      </div>
    );
  }

  return null;
};

export default AccountSettingsToast;
