import React, {useCallback, useEffect, useState} from 'react'
import clx from 'classnames'
import {useGlobalStore} from 'provider/global_store/hook'
import onKeyPress from 'views_v2/lib/common/onKeyPress'
import FieldGroup from 'views_v2/lib/snippets/FieldGroup'
import {Delete as DeleteIcon} from '@mui/icons-material'
import {SizesDropdown} from 'views_v2/lib/snippets'
import {ob} from 'variables/empty'
import {
  Label,
  FormGroup
} from 'reactstrap'
import mw from '../middleware'

export const DuplicateItem = ({
  defaultValue = {},
  index,
  prices = ob,
  setDuplicateSizes,
  showDelete = false,
  setDeleteItem,
  formFields
}) => {
  const {user} = useGlobalStore()
  const [value, setValue] = useState({...{size: '', price: '', count: 1}, ...defaultValue})
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    if (
      Object.keys(defaultValue)?.length > 0 &&
      (value.size !== defaultValue.size ||
        value.count !== defaultValue.count ||
        value.price !== defaultValue.return)
    ) {
      Object.assign(defaultValue, {
        price: defaultValue?.return,
      })
      return setValue(defaultValue)
    }
  }, [defaultValue.size, defaultValue.count, defaultValue.return])

  useEffect(() => {
    // this will make sure that prices fields will auto filled
    // even modal already loaded before prices data
    if (prices && prices[value.size]) {
      setValue((s) => ({...s, price: mw.getDesiredPrice(prices[value.size], user)}))
    }
  }, [prices, value.size])

  useEffect(() => {
    setDuplicateSizes((s) => {
      s[index] = {size: value.size, count: value.count, return: value.price}
      return [...s]
    })
  }, [setDuplicateSizes, value, index, prices])

  const onChangeSize = useCallback(
    (v) => {
      setHasError(checkIfEmptyOrZero(v))
      setValue((s) => {
        if (prices[v]) {
          return {...s, size: v, price: mw.getDesiredPrice(prices[v], user)}
        } else {
          return {...s, size: v}
        }
      })
    },
    [setValue, prices],
  )

  const onChangeReturn = useCallback(
    (e) => {
      const target = e.target
      setHasError(checkIfEmptyOrZero(target.value))
      setValue((s) => ({...s, price: target.value}))
    },
    [setValue],
  )

  const onChangeCount = useCallback(
    (e) => {
      const target = e.target
      setHasError(checkIfEmptyOrZero(target.value))
      setValue((s) => ({...s, count: target.value}))
    },
    [setValue],
  )

  const checkIfEmptyOrZero = (str) => {
    return str === '' || String(str) === '0'
  }
  
  const onDuplicateItem = () => {
    if (onlySpaces(value?.size) || checkIfEmptyOrZero(value?.size) || checkIfEmptyOrZero(value?.return) || checkIfEmptyOrZero(value?.count)) {
      setHasError(true)
    } else {
      onSubmit()
    }
  }

  return (
    <>
      <div className="duplicate-item-row">
        <div className="duplicate-item-column">          
          <FormGroup>
            <Label className="label">Size *</Label>
            <SizesDropdown {...{ formFields, value }} editMode={true} handleSizeChange={onChangeSize} isDuplicate />
          </FormGroup>
        </div>
        <div className="duplicate-item-column">
          <FieldGroup
            sLabel="List Price *"
            value={value.price}
            onChange={onChangeReturn}
            name="desiredReturn"
            type="text"
            maxLength={13}
            onKeyPress={(e) => onKeyPress(e, 'e')}
            placeholder="($)"
          />
        </div>
        <div className="duplicate-item-column">
          <FieldGroup
            sLabel="Quantity *"
            name="quantity"
            type="text"
            maxLength={4}
            value={value.count}
            onChange={onChangeCount}
            onKeyPress={(e) => onKeyPress(e, 'e')}
          />
        </div>
        <div className={clx('duplicate-item-action', !showDelete && 'hide')}>
          <DeleteIcon
            onClick={() => {
              setDeleteItem(index)
            }}
          />
        </div>
      </div>
      {hasError && <span>Empty or Zero value is not allowed</span>}
    </>
  )
}

export default DuplicateItem
