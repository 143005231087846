import React, {useEffect, useMemo, useReducer, useState} from 'react'
import {useAuth0} from '@auth0/auth0-react'
import axios from 'axios'
import {Box, Typography, Stack} from '@mui/material'
import {Cancel as CancelIcon} from '@mui/icons-material'
import {styled} from '@mui/material/styles'
import Select from 'react-select'
import {captureException} from '@sentry/react'
import {isEmpty, map, uniq} from 'lodash'

import {condition_options} from 'assets/data'
import {apiConfig} from 'config'
import {ModalType} from 'enums'
import {createRequestOptions} from 'util/network'
import {getThumbImage, validateCustomPayout} from 'util/model'
import {
  getPredefinedObject,
  _validateFormFields,
  _replaceWildCards,
  _getPercentageInBetween,
} from 'util/string_utils'
import {_getEstimatedPayout} from 'util/model/product'
import {getCurrency} from 'util/model/setting'
import {CopytLabel, CopytButton, FieldGroup, SizesDropdown} from 'views_v2/lib/snippets'
import {useGlobalStore} from 'provider/global_store/hook'
import {ReactComponent as ConsignedBannerSvg} from '../../../../../assets/img/consigned-banner_v2.svg'

const CButton = styled(CopytButton)(() => ({
  width: '25%',
  height: 35,
  borderRadius: '6px !important',
}))

const MdlBulkEditLayout = (p) => {
  const {listSelections, bulkEdit, setModalType, refetch} = p
  const [, forceUpdate] = useReducer((x) => x + 1, 0)
  const {getAccessTokenSilently} = useAuth0()
  const [locationOptions, setLocationOptions] = useState([])
  const [subLocationOptions, setSubLocationOptions] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const currency = getCurrency()
  const {user, isEnterprise} = useGlobalStore()
  const isCustomPayout = validateCustomPayout(user)

  const estimatedPayouts = useMemo(() => {
    if (!isEmpty(p?.listSelections)) {
      return p?.listSelections.map((_) =>
        Number((1 - _.feeStructure / 100) * _.desiredReturn).toFixed(2),
      )
    }
    return []
  }, [])

  useEffect(() => {
    ;(async () => {
      try {
        const token = await getAccessTokenSilently()
        if (bulkEdit?.value === 'location') {
          const {data: locationData} = await axios.get(
            `${apiConfig.api_url}/location`,
            createRequestOptions(token),
          )
          if (locationData?.data) {
            setLocationOptions(locationData.data.map(({name}) => ({value: name, label: name})))
            p?.setListSelections((currentSelections) =>
              currentSelections.map((selection) => {
                if (locationData.data.length === 1) {
                  return {...selection, location: locationData.data[0].name}
                } else if (locationData.data.length > 1) {
                  const defaultLocation = locationData.data.find(
                    (loc) => loc.isDefault || loc.name.toLowerCase() === 'default',
                  )
                  return {
                    ...selection,
                    location: defaultLocation ? defaultLocation.name : selection.location,
                  }
                }
                return selection
              }),
            )
          }
        } else if (bulkEdit?.value === 'subLocation') {
          const {data: subLocationData} = await axios.get(
            `${apiConfig.api_url}/product/sub-location`,
            createRequestOptions(token),
          )

          if (!isEmpty(subLocationData?.data)) {
            setSubLocationOptions(
              map(uniq(subLocationData.data.map((s) => s?.subLocation)), (subLocation) => ({
                value: subLocation,
                label: subLocation,
              })),
            )
          }
        }
      } catch (e) {
        captureException(e)
      }
    })()
  }, [getAccessTokenSilently])

  const updateListSelections = (index, updatedSelection) => {
    p?.setListSelections((prevListSelections) => {
      const newListSelections = [...prevListSelections]
      newListSelections[index] = updatedSelection
      return newListSelections
    })
  }

  const onFormSubmit = async () => {
    setIsLoading(true)
    try {
      const token = await getAccessTokenSilently()

      const updatedForms = listSelections.map((selection) =>
        getPredefinedObject(selection, [
          'acquiredDate',
          'assets',
          'boxCondition',
          'boxDimensions',
          'boxWeight',
          'brand',
          'category',
          'color',
          'comments',
          'condition',
          'consignorEmail',
          'description',
          'desiredReturn',
          'flaws',
          'id',
          'internalSku',
          'location',
          'price',
          'quantity',
          'releaseDate',
          'retailPrice',
          'shippingMethod',
          'size',
          'sku',
          'subLocation',
          'subcategory',
          'tagStatus',
          'title',
          'feeStructure',
        ]),
      )

      axios
        .patch(`${apiConfig.api_url}/product`, updatedForms, createRequestOptions(token))
        .then((response) => {
          if (response?.status === 200) {
            refetch()
            setTimeout(() => {
              setIsLoading(false)
              setModalType(ModalType.UNDEFINED)
            }, 1000)
          }
        })
    } catch (e) {
      captureException(e)
    }
  }

  return (
    <Box className="bulk-edit">
      <Box className="modal-title">
        <Box className="modal__heading" mx="0px !important">
          <Typography variant="h1" textAlign="left">{`Enter ${bulkEdit?.label}`}</Typography>
        </Box>
      </Box>
      <Box className="modal-body" maxHeight="85vh" padding="0" mb={2}>
        <Stack
          direction="row"
          borderBottom="1px solid var(--success)"
          py={1}
          bgcolor="var(--lighter)"
          textAlign="center"
          lineHeight="20px"
        >
          <Box sx={{flex: 0.8}} />
          <CopytLabel sx={{flex: 1.2}}>Item Name</CopytLabel>
          <CopytLabel sx={{flex: 0.5}}>Size</CopytLabel>
          <CopytLabel sx={{flex: 0.9}}>Condition</CopytLabel>
          <CopytLabel sx={{flex: 0.8}}>Buy Price</CopytLabel>
          <CopytLabel sx={{flex: 0.8}}>List Price</CopytLabel>
          <CopytLabel sx={{flex: 0.8}}>Fee Structure</CopytLabel>
          <CopytLabel sx={{flex: 0.8}}>Estimated Payout</CopytLabel>
          <CopytLabel sx={{flex: 1}}>Location</CopytLabel>
          <CopytLabel sx={{flex: 1}}>Sub Location</CopytLabel>
          <Box sx={{flex: 0.2}} />
        </Stack>

        <Box minHeight="50vh" maxHeight="75vh" px={2} overflow="hidden auto">
          {listSelections &&
            listSelections?.map((ls, index) => {
              return (
                <Stack
                  key={index}
                  direction="row"
                  borderBottom="1px solid var(--secondary)"
                  p={1}
                  alignItems="center"
                  textAlign="center"
                  lineHeight="20px"
                  position="relative"
                >
                  <Box sx={{flex: 0.8}}>
                    {!isEmpty(ls?.consign) && (
                      <Box position="absolute" top={8} left={0} height="90px" display="flex">
                        <ConsignedBannerSvg />
                      </Box>
                    )}
                    <Box
                      className="platform-icon"
                      component="img"
                      display="block"
                      maxWidth="100%"
                      height="auto"
                      sx={{
                        width: '100px',
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center center',
                      }}
                      src={
                        getThumbImage(ls)?.url ||
                        'https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/300px-No_image_available.svg.png'
                      }
                    />
                  </Box>
                  <Box sx={{flex: 1.2}}>{ls?.title}</Box>
                  <Box sx={{flex: 0.5}}>
                    {bulkEdit?.value === 'size' ? (
                      <SizesDropdown
                        handleSizeChange={(size) => {
                          updateListSelections(index, {...ls, size})
                        }}
                        editMode
                        isBulkEdit
                        formFields={ls}
                      />
                    ) : (
                      ls?.size
                    )}
                  </Box>
                  <Box sx={{flex: 0.9}}>
                    {bulkEdit?.value === 'condition' ? (
                      <Select
                        className="react-select info"
                        classNamePrefix="react-select"
                        placeholder="Select"
                        closeMenuOnSelect
                        options={condition_options}
                        onChange={(e) => updateListSelections(index, {...ls, condition: e.value})}
                        value={{
                          value: ls?.condition,
                          label: ls?.condition,
                        }}
                      />
                    ) : (
                      ls?.condition
                    )}
                  </Box>
                  <Box sx={{flex: 0.8}}>
                    {bulkEdit?.value === 'buyPrice' ? (
                      <FieldGroup
                        style={{
                          opacity: !isEmpty(ls?.consign) ? 0.3 : 1,
                          cursor: !isEmpty(ls?.consign) ? 'no-drop' : 'text',
                        }}
                        disabled={!isEmpty(ls?.consign)}
                        fixedDecimalScale={false}
                        placeholder={ls?.price}
                        onValueChange={(e) => updateListSelections(index, {...ls, price: e.value})}
                        id="numeric"
                        isAllowed={(values) => {
                          const {formattedValue, floatValue} = values
                          return formattedValue === '' || (floatValue <= 99999 && floatValue >= 0)
                        }}
                      />
                    ) : (
                      currency.format(ls?.price)
                    )}
                  </Box>
                  <Box sx={{flex: 0.8}}>
                    {bulkEdit?.value === 'desiredReturn' ? (
                      <FieldGroup
                        placeholder={ls?.desiredReturn}
                        fixedDecimalScale={false}
                        onValueChange={(e) => {
                          const feeStructure =
                            isCustomPayout && isEnterprise
                              ? _getPercentageInBetween(
                                  estimatedPayouts.find((_, i) => index === i),
                                  e.value,
                                )
                              : ls?.feeStructure

                          updateListSelections(index, {
                            ...ls,
                            desiredReturn: e.value,
                            feeStructure,
                          })
                        }}
                        id="numeric"
                        isAllowed={(values) => {
                          const {formattedValue, floatValue} = values
                          return formattedValue === '' || (floatValue <= 99999 && floatValue >= 0)
                        }}
                      />
                    ) : (
                      currency.format(ls?.desiredReturn)
                    )}
                  </Box>
                  <Box sx={{flex: 0.8}}>
                    {bulkEdit?.value === 'feeStructure' ? (
                      <FieldGroup
                        style={{
                          opacity: isEmpty(ls?.consign) ? 0.3 : 1,
                          cursor: isEmpty(ls?.consign) ? 'no-drop' : 'text',
                        }}
                        disabled={isEmpty(ls?.consign)}
                        placeholder={ls?.feeStructure}
                        onValueChange={(e) =>
                          updateListSelections(index, {...ls, feeStructure: e.value})
                        }
                        fixedDecimalScale={false}
                        id="numeric"
                        isAllowed={(values) => {
                          const {formattedValue, floatValue} = values
                          return formattedValue === '' || (floatValue <= 100 && floatValue >= 0)
                        }}
                      />
                    ) : (
                      ls?.feeStructure
                    )}
                  </Box>
                  <Box sx={{flex: 0.8}}>
                    {(() => {
                      const estimatedPayout =
                        isCustomPayout && isEnterprise
                          ? estimatedPayouts.find((_, i) => index === i)
                          : _getEstimatedPayout(ls?.feeStructure, ls?.desiredReturn)

                      return bulkEdit?.value === 'estimatedPayout' ? (
                        <FieldGroup
                          style={{
                            opacity: isEmpty(ls?.consign) ? 0.3 : 1,
                            cursor: isEmpty(ls?.consign) ? 'no-drop' : 'text',
                          }}
                          disabled={isEmpty(ls?.consign)}
                          placeholder={estimatedPayout}
                          fixedDecimalScale={false}
                          onValueChange={(e) => {
                            if (!isEnterprise) {
                              updateListSelections(index, {
                                ...ls,
                                desiredReturn: estimatedPayout / (1 - Number(ls.feeStructure) / 100),
                              })
                            } else {
                              updateListSelections(index, {
                                ...ls,
                                feeStructure: _getPercentageInBetween(e.value, ls?.desiredReturn),
                              })
                            }
                          }}
                          id="numeric"
                          isAllowed={(values) => {
                            const {formattedValue, floatValue} = values
                            return formattedValue === '' || (floatValue <= 99999 && floatValue >= 0)
                          }}
                        />
                      ) : (
                        currency.format(estimatedPayout)
                      )
                    })()}
                  </Box>
                  <Box sx={{flex: 1}}>
                    {bulkEdit?.value === 'location' ? (
                      <Select
                        className="react-select info"
                        classNamePrefix="react-select"
                        placeholder="Select"
                        closeMenuOnSelect
                        options={locationOptions}
                        onChange={(e) => updateListSelections(index, {...ls, location: e.value})}
                        value={{
                          value: ls?.location,
                          label: ls?.location,
                        }}
                      />
                    ) : (
                      ls?.location
                    )}
                  </Box>
                  <Box sx={{flex: 1}}>
                    {bulkEdit?.value === 'subLocation' ? (
                      <Select
                        className="react-select info"
                        classNamePrefix="react-select"
                        placeholder="Select"
                        closeMenuOnSelect
                        isSearchable={false}
                        options={subLocationOptions}
                        onChange={(e) => updateListSelections(index, {...ls, subLocation: e.value})}
                        value={{
                          value: ls?.subLocation,
                          label: ls?.subLocation,
                        }}
                      />
                    ) : (
                      ls?.subLocation
                    )}
                  </Box>
                  <Box sx={{flex: 0.2}}>
                    <CancelIcon
                      onClick={() => {
                        listSelections.splice(index, 1)
                        p?.setListSelections(listSelections)
                        forceUpdate()
                      }}
                    />
                  </Box>
                </Stack>
              )
            })}
        </Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          px={2}
          pt={2}
          borderTop="1px solid var(--secondary)"
        >
          {bulkEdit?.value === 'feeStructure' ? (
            <Typography color="red !important" fontSize="0.9rem">
              Maximum Fee Structure is 100%
            </Typography>
          ) : (
            <Box />
          )}
          <Stack direction="row" spacing={2} width="50%" justifyContent="flex-end">
            <CButton
              variant="outline"
              color="primary"
              onClick={() => {
                setModalType(ModalType.BULK_EDIT)
              }}
            >
              Back
            </CButton>
            <CButton
              disabled={isLoading || isEmpty(listSelections)}
              variant="contained"
              color="primary"
              onClick={onFormSubmit}
            >
              Confirm
            </CButton>
          </Stack>
        </Stack>
      </Box>
    </Box>
  )
}

export default MdlBulkEditLayout
