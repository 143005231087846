import React, {useMemo} from 'react'
import {Box, Stack, Typography} from '@mui/material'
import {styled} from '@mui/material/styles'
import {add, groupBy, map, mapValues, reduce} from 'lodash'

import {ar} from 'variables/empty'
import {PaymentType} from 'enums'
import {useLedger} from 'service/hook/use_ledger'
import {useGlobalStore} from 'provider/global_store/hook'
import {getConsignorId, getConsignEmail} from 'util/model/sold'
import {getMinimumFee} from 'util/model'
import {CopytStyledButton} from 'views_v2/lib/snippets'
import mw from '../middleware'
import {getConsignmentPayout} from 'util/model/consignment'

export const TextStyle = styled(Typography)(() => ({
  fontWeight: 600,
  color: 'var(--default) !important',
  fontSize: '0.85rem',
}))

const batchLabelArr = ['Consigner', 'Total', 'Date', 'Method']

function ct(n, t = 0) {
  return Number(n) + Number(t)
}

const MdlBatch = (p) => {
  const {user} = useGlobalStore()
  const {data = ar, paymentType, onCancel, onSuccess, otherPaymentType} = p
  const {createLegerMany, createLedgerCheckbook} = useLedger({onSuccess})
  const minFee = getMinimumFee(user)
  const {isEnterprise} = useGlobalStore()

  const today = new Date()
  const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()

  const values = useMemo(() => {
    let g = {}
    let total = {}
    let names = []

    for (let i of data) {
      let consingorId = getConsignorId(i)
      let consignorName = getConsignEmail(i)
      if (consingorId) {
        if (g[consingorId]) {
          g[consingorId].push(i.id)
          // todo: should payout
          total[consingorId] = ct(getConsignmentPayout(i), total[consingorId])
        } else {
          g[consingorId] = [i.id]
          total[consingorId] = getConsignmentPayout(i)
          names.push(consignorName)
        }
      }
    }

    return {
      names: names.join(', '),
      totals: Object.keys(total)
        .map((i) => Number(total[i]).toLocaleString('en-US', {minimumFractionDigits: 2}))
        .join(', '),
    }
  }, [data, minFee])

  const paymentMethod = paymentType?.toLowerCase() === 'other' ? otherPaymentType : paymentType

  const updateApp = async () => {
    const groupedByConsignorId = groupBy(data, (item) => getConsignorId(item))

    const totals = mapValues(groupedByConsignorId, (items, consignorId) =>
      reduce(items, (sum, item) => add(sum, getConsignmentPayout(item)), 0),
    )

    const soldIds = mapValues(groupedByConsignorId, (items) => map(items, 'id'))

    const ledgerData = map(groupedByConsignorId, (items, consignorId) => ({
      method: paymentMethod,
      consignorId,
      total: totals[consignorId],
      soldIds: soldIds[consignorId],
    }))
    if (paymentType === PaymentType.CHECKBOOK) {
      await createLedgerCheckbook(ledgerData)
    } else {
      await createLegerMany(ledgerData)
    }

    onCancel()
  }

  return (
    <Box padding="0 !important">
      <Box className="modal-title">
        <Box className="modal__heading" mx="0px !important">
          <Typography variant="h1" textAlign="left">
            Batch Payout
          </Typography>
        </Box>
      </Box>
      <Box className="modal-body" padding={0} my={2}>
        <Stack maxHeight="50vh" sx={{overflowY: 'auto'}} px={3}>
          {[values?.names, values?.totals, date, paymentMethod].map((p, i) => {
            const lbl = batchLabelArr[i]
            return (
              <Stack direction="row" py={1} borderBottom="1px solid" alignItems="center" key={i}>
                <TextStyle className="half-width">{lbl}</TextStyle>
                <TextStyle className="half-width create-ledger">{p}</TextStyle>
              </Stack>
            )
          })}
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-end"
          pr={1}
          pt={1}
          mt={5}
          borderTop="1px solid var(--lighter)"
          spacing={2}
        >
          <CopytStyledButton variant="outline" color="primary" onClick={onCancel}>
            Cancel
          </CopytStyledButton>
          <CopytStyledButton variant="contained" color="primary" onClick={() => updateApp()}>
            Submit
          </CopytStyledButton>
        </Stack>
      </Box>
    </Box>
  )
}

export default MdlBatch
