import {CircularProgress, Typography, Stack} from '@mui/material'

const LoadingMessage = ({message = 'Please wait...'}) => (
  <Stack justifyContent="center" alignItems="center" my={7}>
    <CircularProgress size={24} />
    <Typography color="var(--gray) !important">{message}</Typography>
  </Stack>
)

export default LoadingMessage
