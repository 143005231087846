import React, {Fragment, useState, useReducer} from 'react'
import {Box, Checkbox, Collapse, IconButton, Table, TableBody, TableCell, TableRow} from '@mui/material'
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@mui/icons-material'

import {getCurrency} from 'util/model/setting'

const ConnectDetailRow = (p) => {
  const {row, sIds, setSIds} = p
  const [open, setOpen] = useState(false)
  const [, forceUpdate] = useReducer((x) => x + 1, 0)
  const currency = getCurrency()

  const handleParent = (e) => {
    e.stopPropagation()

    if (e.target.checked) {
      for (const i in row?.item) {
        const item = row?.item[i]
        const pID = getPID(item)
        if (sIds[pID]) {
          const arr = sIds[pID]
          const idExist = sIds[pID].some((x) => x == i.id)
          if (!idExist) {
            arr.push(item.id)
            setSIds(
              Object.assign(sIds, {
                [pID]: arr,
              }),
            )
          }
        } else {
          setSIds(
            Object.assign(sIds, {
              [pID]: [item.id],
            }),
          )
        }
      }
    } else {
      for (const i in row?.item) {
        const item = row?.item[i]
        const pID = getPID(item)
        if (sIds[pID]) {
          const arr = sIds[pID]
          const index = arr.findIndex((s) => s === item.id)
          if (index > -1) {
            arr.splice(index, 1)

            if (arr?.length === 0) {
              delete sIds[pID]
            } else {
              setSIds(
                Object.assign(sIds, {
                  [pID]: arr,
                }),
              )
            }
          }
        } else {
        }
      }
    }

    forceUpdate()
  }

  const getPID = (item) => {
    return `${item?.owner?.id}---${item?.sku}`
  }

  const handleCheckBox = () => {
    for (const i in row?.item) {
      const item = row?.item[i]
      const pID = `${item?.owner?.id}---${item?.sku}`
      if (sIds[pID]) {
        for (const index in sIds[pID]) {
          if (item.id === sIds[pID][index]) {
            return true
          }
        }
      }
    }

    return false
  }

  const handleChild = (e, hstryId, pID) => {
    const arr = sIds[pID] || []
    if (e.target.checked) {
      arr.push(hstryId)
      setSIds(
        Object.assign(sIds, {
          [pID]: arr,
        }),
      )
    } else {
      if (arr?.length > 0) {
        const index = arr.findIndex((s) => s === hstryId)
        if (index > -1) {
          arr.splice(index, 1)
          if (arr?.length === 0) {
            delete sIds[pID]
          } else {
            setSIds(
              Object.assign(sIds, {
                [pID]: arr,
              }),
            )
          }
        }
      }
    }
    forceUpdate()
  }

  let min = Math.min(...row?.item?.map((item) => item.desiredReturn))

  return (
    <Fragment key={row?.key}>
      <TableRow sx={{'& > *': {borderBottom: 'unset'}}}>
        <TableCell>
          <Checkbox
            size="small"
            checked={handleCheckBox()}
            onChange={(e) => handleParent(e)}
            sx={{
              color: 'white',
              '&.Mui-checked': {
                color: 'var(--darker)',
              },
            }}
          />
        </TableCell>
        <TableCell component="th" scope="row">
          <img src={row?.item[0]?.assets[0]?.asset?.url} style={{maxWidth: '100px'}} />
        </TableCell>
        <TableCell component="th" scope="row">
          {row?.item[0].title || '--'}
        </TableCell>
        <TableCell>{row?.item[0]?.sku || '--'}</TableCell>
        <TableCell>{row?.item[0]?.size || '--'}</TableCell>
        <TableCell>{row?.item[0]?.condition || '--'}</TableCell>
        <TableCell>{currency.format(min) || '--'}</TableCell>
        <TableCell>
          {row?.item.length || '--'}
          <IconButton
            aria-label="expand row"
            size="small"
            style={{left: 30}}
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={12} style={{paddingTop: 0, paddingBottom: 0}}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{margin: 1}}>
              <Table size="medium" aria-label="purchases">
                <TableBody>
                  {row.item.map((historyRow, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Checkbox
                          checked={sIds[getPID(historyRow)]?.some((s) => s === historyRow?.id) || false}
                          onChange={(e) => handleChild(e, historyRow?.id, getPID(historyRow))}
                          size="small"
                          sx={{
                            color: 'white',
                            '&.Mui-checked': {
                              color: 'var(--primary)',
                            },
                          }}
                        />
                      </TableCell>
                      <TableCell>{historyRow?.title}</TableCell>
                      <TableCell>{historyRow?.owner?.email}</TableCell>
                      <TableCell>{historyRow?.sku}</TableCell>
                      <TableCell>{historyRow?.size}</TableCell>
                      <TableCell>{historyRow?.condition}</TableCell>
                      <TableCell>{currency.format(historyRow?.desiredReturn)}</TableCell>
                      <TableCell>1</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  )
}

export default ConnectDetailRow
