import React, {useState} from 'react'
import clx from 'classnames'
import {IconButton, Stack, TablePagination, Typography} from '@mui/material'
import {
  PersonAddAlt1 as PersonAddAlt1Icon,
  Edit as EditIcon,
  PowerSettingsNew as PowerSettingsNewIcon,
} from '@mui/icons-material'
import {styled} from '@mui/material/styles'
import SearchBar from 'material-ui-search-bar'

import TableView from 'layouts/Admin/tools/TableView'
import {ModalType} from 'enums'
import {CopytButton, CopytTooltip} from 'views_v2/lib/snippets'

const TextConnection = styled(Typography)(({props}) => ({
  ...props,
  color: 'var(--gray-dark)',
  fontSize: '0.9rem',
}))

const getStatus = (isOwner, isChecked, status) => {
  let statusText = ''
  let statusColor = 'var(--gray)'

  if (status === 'approved') {
    statusText = 'Approved'
    statusColor = 'green'
  } else if (status === 'pending' && isOwner) {
    statusText = 'Pending'
    statusColor = 'var(--gray)'
  } else if (status === 'rejected') {
    statusText = 'Denied'
    statusColor = 'var(--red)'
  }

  return statusText ? (
    <Stack direction="row" alignItems="center">
      <Typography fontSize="0.7rem" color="var(--gray)">
        Status:&nbsp;
      </Typography>
      <Typography fontSize="0.7rem" color={statusColor} fontWeight={600}>
        {statusText}
      </Typography>
      {status === 'approved' && (
        <Typography fontSize="0.7rem" color="var(--gray)">
          /
        </Typography>
      )}
      {status === 'approved' && (
        <Typography fontSize="0.7rem" color={isChecked === 'enabled' ? 'var(--primary)' : 'var(--red)'}>
          {isChecked === 'enabled' ? 'Enabled' : 'Disabled'}
        </Typography>
      )}
    </Stack>
  ) : null
}

const getActionButton = (isChecked, stat, id, onSaveChanges, isOwner) => {
  if (stat === 'approved') {
    const isEnabled = isChecked === 'enabled'
    return (
      <IconButton
        sx={{
          mr: '10px !important',
          p: '2px !important',
          border: `2px solid ${isEnabled ? 'green' : 'red'} !important`,
          color: `${isEnabled ? 'green' : 'red'} !important`,
        }}
        onClick={() => {
          onSaveChanges({
            updateConnection: {
              id,
              viewStatus: isEnabled ? 'disabled' : 'enabled',
            },
          })
        }}
      >
        <CopytTooltip title={`Would you like to ${isEnabled ? 'DISABLE' : 'ENABLE'} the connection?`}>
          <PowerSettingsNewIcon />
        </CopytTooltip>
      </IconButton>
    )
  } else if ((stat === 'pending' && isOwner) || stat === 'rejected') {
    return null
  }

  return (
    <Stack direction="row" pr={1} spacing={1}>
      <CopytButton
        onClick={() => onSaveChanges({denyApplication: id})}
        variant="contained"
        color="error"
        style={{borderRadius: 6, height: 30}}
      >
        Deny
      </CopytButton>
      <CopytButton
        onClick={() => onSaveChanges({approveApplication: id})}
        variant="contained"
        style={{borderRadius: 6, backgroundColor: 'green', height: 30, width: 80}}
      >
        Approve
      </CopytButton>
    </Stack>
  )
}

const DetailView = (p) => {
  if (p?.count === 0 || p?.connections.length === 0) {
    return <TableView.Message message="No Contacts Found" />
  }

  return (
    p?.connections?.map((m, i) => {
      const isOwner = p?.ownerEmail === m?.owner?.email
      const isChecked = isOwner ? m?.connectionViewStatus : m?.ownerViewStatus
      // ToDo: Confirm what would be the condition
      // for showing the Deny and Approve Button
      const isApplied = m?.status === 'pending'
      const discordName = isOwner
        ? m?.connectionUser?.discordUserName || m?.connectionUser?.email
        : m?.owner?.discordUserName || m?.owner?.email

      return (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          borderBottom="1px solid var(--gray-light)"
          pl={2}
          minHeight={60}
          key={i}
        >
          <Stack>
            <CopytTooltip title={discordName}>
              <TextConnection
                maxWidth={isApplied ? '135px' : '250px'}
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
              >
                {discordName}
              </TextConnection>
            </CopytTooltip>
            {getStatus(isOwner, isChecked, m?.status)}
          </Stack>
          {getActionButton(isChecked, m?.status, m?.id, p?.onSaveChanges, isOwner)}
        </Stack>
      )
    }) || []
  )
}

const ConnectDetailTableView = (p) => {
  const {discordUserName, ownerEmail, onSaveChanges, setModalType, page, onPageChange} = p || {}
  const [searchKey, setSearchKey] = useState(null)
  let connections = p?.connections
  if (searchKey && connections.total > 0) {
    connections = Object.assign({
      data: p?.connections?.data?.filter(
        (s) => s?.connectionUser?.email?.includes(searchKey) || s?.owner?.email?.includes(searchKey),
      ),
    })
  }

  return (
    <TableView classNames={clx(connections?.data?.length === 0 && 'isEmpty')}>
      <TableView.Header>
        <Stack border="none" bgcolor="var(--gray-light) !important" padding="0 !important">
          <TextConnection>Username</TextConnection>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            bgcolor="white"
            pl={2}
            height={40}
          >
            <TextConnection fontWeight={600}>{discordUserName}</TextConnection>
            <IconButton
              onClick={() => setModalType(ModalType.EDIT_DISCORD_NAME)}
              sx={{
                '&:focus': {
                  outline: 'none',
                },
              }}
            >
              <EditIcon fontSize="small" color="primary" />
            </IconButton>
          </Stack>
        </Stack>
      </TableView.Header>
      <TableView.Footer>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          bgcolor="white"
          pl={2}
          height={40}
        >
          <TextConnection fontWeight={600}>Connections</TextConnection>
          <IconButton
            onClick={() => setModalType(ModalType.SEND_CONNECTION_REQUEST)}
            sx={{
              '&:focus': {
                outline: 'none',
              },
            }}
          >
            <PersonAddAlt1Icon color="primary" />
          </IconButton>
        </Stack>
        <SearchBar
          placeholder="Search Existing Connection"
          className="search-item"
          cancelOnEscape
          onCancelSearch={(s) => setSearchKey(null)}
          onRequestSearch={(s) => setSearchKey(s)}
        />
      </TableView.Footer>
      <TableView.Body style={{maxHeight: 'calc(100% - 50px)'}}>
        <DetailView
          {...{connections: connections?.data, ownerEmail, onSaveChanges, count: p?.connections?.total}}
        />
      </TableView.Body>
      {p?.connections?.total ? (
        <div
          style={{width: '100%', height: 50, position: 'sticky', bottom: 0, backgroundColor: 'white'}}
        >
          <TablePagination
            style={{width: 500}}
            rowsPerPageOptions={[100]}
            count={p?.connections?.total}
            rowsPerPage={100}
            page={page}
            onPageChange={(e, page) => {
              p?.onPageChange(page)
            }}
            onRowsPerPageChange={() => {}}
          />
        </div>
      ) : null}
    </TableView>
  )
}

export default ConnectDetailTableView
