import React, { useState } from 'react'
import { isEmpty } from 'lodash'
import { Box } from '@mui/material'
import CopytCard from 'components/CopytCard'
import FieldGroup from 'views_v2/lib/snippets/FieldGroup'
import onKeyPress from 'views_v2/lib/common/onKeyPress'
import { CopytButton } from 'views_v2/lib/snippets'
import { useUser } from 'service/hook'
import { useSmartPricing } from '../hooks/useSmartPricing'
import { CopytBtn } from './ProductTemplate'

export const SmartPricing = () => {
  const [errorMessage, setErrorMessage] = useState()
  const { value, loading, onChange, onSave } = useSmartPricing()
  const { user } = useUser()

  const updatePricing = () => {
    if (value) {
      onSave()
      setErrorMessage()
    } else {
      setErrorMessage('is required')
    }
  }

  return (
    <CopytCard className="copyt-card">
      <CopytCard.Header>
        <CopytCard.Text tag="div">Smart Pricing</CopytCard.Text>
      </CopytCard.Header>
      <CopytCard.Body>
        <FieldGroup
          {...{ onChange, value }}
          sLabel="Percentage over StockX Lowest Ask"
          hasError={errorMessage}
          errorMessage={`Percentage over StockX Lowest Ask ${errorMessage}`}
          placeholder="Percentage (%)"
          type="number"
          onKeyPress={(e) => onKeyPress(e, 'e')}
        />
        <Box gap={1} display="flex" justifyContent="flex-end">
          <CopytBtn
            disabled={loading || isEmpty(value) || user?.private?.lowestStockXAskPercentage === Number(value)}
            onClick={updatePricing}
            variant="contained"
            color="primary"
          >
            Save
          </CopytBtn>
        </Box>
      </CopytCard.Body>
    </CopytCard>
  )
}

export default SmartPricing
