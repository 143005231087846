import React from 'react'
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

const CopytDetail = styled(Box)(({ props }) => ({
  ...props,
  overflowY: 'auto',
  padding: 20,
  height: '100%'
}))

const PanelDetailView = (props) => {
  return (
    <CopytDetail {...props.style}>
      {props.children}
    </CopytDetail>
  )
}

export default PanelDetailView
