import React from 'react'
import clx from 'classnames'
import PropTypes from 'prop-types'
import CopytModal from 'components/CopytModal'
import {ModalType} from 'enums'
import { MdlSupport } from './modal-contents'

const ModalContents = ({children, common, focus, onCloseModal}) => {
  if (focus !== ModalType.UNDEFINED) {
    return React.Children.map(children, (c, i) => {
      if (c.props?.modalType === focus) {
        // Show modal based on the given modalType
        return React.cloneElement(c, {...common, onCloseModal})
      }
      return null
    })
  }
  return null
}

const NavBarModals = (p) => {
  const {modalType, modals, onCloseModal} = p || {}
  const m = modals[modalType]

  return (
    <CopytModal
      className={m?.className || null}
      closeButton={{hidden: true}}
      id={m?.id}
      show={modalType > ModalType.UNDEFINED}
      size={m?.size || 'sm'}
      toggle={onCloseModal}
    >
      <ModalContents focus={modalType} common={p} onCloseModal={onCloseModal}>
        <MdlSupport modalType={ModalType.SUPPORT} />
      </ModalContents>
    </CopytModal>
  )
}

NavBarModals.propTypes = {
  modals: PropTypes.object,
}

NavBarModals.defaultProps = {
  modals: {
    [ModalType.SUPPORT]: {
      className: clx('copyt_modal_container'),
      size: 'xxs',
    }
  },
}

export default NavBarModals
