import React from 'react'
import classnames from 'classnames'

import PanelView from './PanelView'

const PanelGroup = ({ children, className, commonData = {}, tabFocus }) => {
  if (children) {
    const classNames = classnames('p-detail-tab-wrapper__group', className || null)

    return (
      <div className={classNames}>
        {
          React.Children.map(children, (c, i) => {
            if (!c) return null
            return (
              <PanelView
                isHidden={c?.props?.tabFocus !== tabFocus}
                scrollable={c?.props?.scrollable || false}
              >
                {React.cloneElement(c, commonData)}
              </PanelView>
            )
          }) || <></>
        }
      </div>
    )
  }
  return <></>
}

export default PanelGroup
