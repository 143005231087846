import {isEmpty} from 'lodash'
import React, {useEffect, useState} from 'react'
import Select from 'react-select'

const CountrySelect = ({selected, onSelect, isDisabled}) => {
  const [countries, setCountries] = useState([])
  const [userCountryCode, setUserCountryCode] = useState()
  const [selectedCountry, setSelectedCountry] = useState({})

  useEffect(() => {
    if (isEmpty(countries)) {
      fetch('https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code')
        .then((response) => response.json())
        .then((data) => {
          setCountries(data.countries)
          setUserCountryCode(data.userCountryCode)
          if (!selected) {
            setSelectedCountry(data.userSelectValue || {})
          }
        })
    }
  }, [countries])

  useEffect(() => {
    if (selected) {
      const foundCountry = countries.find(
        (c) =>
          c.value.toLowerCase() === selected.toLowerCase() ||
          selected.toLowerCase().includes(c.label.toLowerCase()),
      )
      setSelectedCountry(foundCountry || {})
    }
  }, [selected, countries])

  return (
    <Select
      isDisabled={isDisabled}
      options={countries}
      value={selectedCountry}
      onChange={(selectedOption) => {
        setSelectedCountry(selectedOption)
        if (onSelect) onSelect(selectedOption?.value)
      }}
    />
  )
}

export default CountrySelect
