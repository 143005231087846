export default [
  {value: 'Red', label: 'Red'},
  {value: 'Pink', label: 'Pink'},
  {value: 'Orange', label: 'Orange'},
  {value: 'Yellow', label: 'Yellow'},
  {value: 'Green', label: 'Green'},
  {value: 'Blue', label: 'Blue'},
  {value: 'Purple', label: 'Purple'},
  {value: 'Gold', label: 'Gold'},
  {value: 'Silver', label: 'Silver'},
  {value: 'Black', label: 'Black'},
  {value: 'Grey', label: 'Grey'},
  {value: 'White', label: 'White'},
  {value: 'Cream', label: 'Cream'},
  {value: 'Brown', label: 'Brown'},
  {value: 'Tan', label: 'Tan'},
  {value: 'Multicolor', label: 'Multicolor'},
]
