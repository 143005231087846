import {useMutation, useQuery} from 'react-query'
import axios from 'axios'
import {useAuth0} from '@auth0/auth0-react'
import {flatten} from 'lodash'
import {captureException} from '@sentry/react'
import {stringify} from 'querystring'

import {useToastMessage} from 'components/index'
import {paths} from 'constant/api'
import {ar} from 'variables/empty'
import {createRequestOptions} from 'util/network'
import {PlatformType} from '../../constant/platform_type'
import {getRequest} from './util'

const getPlatformAccepted = async (tokenGetter) => {
  try {
    const token = await tokenGetter()
    const response = await axios.get(paths.platform, {
      params: {
        status: 'member',
        type: 'market',
      },
      ...createRequestOptions(token),
    })
    return response.data.data
  } catch (e) {
    captureException(e)
    console.log('Error retrieving platform accepted', e)
    throw e.message
  }
}

export const usePlatformAccepted = () => {
  const {getAccessTokenSilently} = useAuth0()

  // todo: fix pagination emplem
  const accepted = useQuery('platform/accepted', () => getPlatformAccepted(getAccessTokenSilently))

  return {
    ...accepted,
    data: accepted.data || ar,
  }
}

const getAllPlatforms = async (tokenGetter, filter = {}) => {
  try {
    const token = await tokenGetter()
    let query = ''
    if (filter?.type === PlatformType.MARKET) {
      query = `?${stringify(filter)}`
    } else if (filter?.type === PlatformType.BOTH) {
      query = ['?type=market', '?type=payment']
    }

    if (Array.isArray(query)) {
      const promise = await Promise.all(
        query.map((q) => {
          return axios.get(`${paths.platform}${q}`, createRequestOptions(token))?.then((s) => {
            if (s?.status === 200) return s?.data?.data
          })
        }),
      )

      return flatten(promise)
    }

    const response = await axios.get(`${paths.platform}${query}`, createRequestOptions(token))

    return response.data.data
  } catch (e) {
    captureException(e)
    console.log('Error retrieving platform accepted', e)
    throw e.message
  }
}

const getAvailablePlatforms = async (tokenGetter, filter = {}) => {
  try {
    const token = await tokenGetter()
    // todo support pagination
    const response = await axios.get(paths.platform, {
      params: {status: 'available', ...filter},
      headers: createRequestOptions(token).headers,
    })
    return response.data.data
  } catch (e) {
    captureException(e)
    console.log('Error retrieving available platforms', e)
    throw e.message
  }
}
const getMemberPlatforms = async (tokenGetter, filter = {}) => {
  try {
    const token = await tokenGetter()
    // todo support pagination
    const response = await axios.get(paths.platform, {
      params: {status: 'member', ...filter},
      headers: createRequestOptions(token).headers,
    })
    return response.data.data
  } catch (e) {
    captureException(e)
    console.log('Error retrieving available platforms', e)
    throw e.message
  }
}

const getMarketPlatforms = async (tokenGetter, filter = {}) => {
  try {
    const token = await tokenGetter()
    // todo support pagination
    const response = await axios.get(paths.platform, {
      // params: {status: 'market', ...filter},
      headers: createRequestOptions(token).headers,
    })
    return response.data.data
  } catch (e) {
    captureException(e)
    console.log('Error retrieving available platforms', e)
    throw e.message
  }
}

export const usePlatform = (filter) => {
  const {getAccessTokenSilently} = useAuth0()
  const {showToast} = useToastMessage()

  const {
    isLoading: isPelatformsLoading,
    isError: isPlatformsError,
    isFetched: isPlatformsFetched,
    refetch: platformsRefetch,
    data: platforms = [],
    error: platformsError,
  } = useQuery('platforms', () => getAllPlatforms(getAccessTokenSilently, filter))

  const {
    isLoading: isAvailablePlatformsLoading,
    isError: isAvailablePlatformsError,
    isFetched: isAvailablePlatformsFetched,
    refetch: availablePlatformsRefetch,
    data: availablePlatforms = [],
    error: availablePlatformsError,
  } = useQuery('availablePlatforms', () => getAvailablePlatforms(getAccessTokenSilently, filter))

  const {
    // isLoading: isAvailablePlatformsLoading,
    // isError: isAvailablePlatformsError,
    // isFetched: isAvailablePlatformsFetched,
    // refetch: availablePlatformsRefetch,
    data: memberPlatforms = ar,
    // error: availablePlatformsError,
  } = useQuery('memberPlatforms', () => getMemberPlatforms(getAccessTokenSilently, filter))

  const {
    // isLoading: isAvailablePlatformsLoading,
    // isError: isAvailablePlatformsError,
    // isFetched: isAvailablePlatformsFetched,
    // refetch: availablePlatformsRefetch,
    data: marketPlatforms = ar,
    // error: availablePlatformsError,
  } = useQuery('marketPlatforms', () => getMarketPlatforms(getAccessTokenSilently, filter))

  /**
   * updates platform card info of enterprise user
   */
  const updatePlatformCardInfo = useMutation(
    async (data) => {
      const token = await getAccessTokenSilently()
      const fd = new FormData()
      fd.append('faq_link', data.faq_link)
      fd.append('contract_pdf_link', data.contract_pdf_link)
      fd.append('support_email', data.support_email)
      fd.append('file', data.file)

      const headers = createRequestOptions(token)
      headers.headers['content-type'] = 'multipart/form-data'

      return await axios.post(`${paths.platform}/card-info`, fd, createRequestOptions(token))
    },
    {
      onSuccess: async (response) => {
        showToast({variant: 'success', title: 'Success', body: 'Platform card updated'})
        platformsRefetch()
      },
    },
    {
      onMutate: async () => {
        showToast({variant: 'success', title: 'Loading', body: ''})
        platformsRefetch()
      },
    },
    {
      onError: async (error) => {
        showToast({variant: 'danger', title: 'Error', body: ''})
        platformsRefetch()
      },
    },
  )

  return {
    platforms: platforms || ar,
    availablePlatforms: availablePlatforms || ar,
    availablePlatformsRefetch,
    updatePlatformCardInfo: updatePlatformCardInfo.mutate,
    updatePlatformCardLoading: updatePlatformCardInfo.isLoading,
    memberPlatforms,
    marketPlatforms,
  }
}

const getPlatformById = async (tokenGetter, id) => {
  try {
    const token = await tokenGetter()
    // todo support pagination
    const response = await axios.get(paths.platform + `/${id}`, createRequestOptions(token))
    return response.data.data
  } catch (e) {
    captureException(e)
    console.log('Error retrieving platform id: ' + id, e)
    throw e.message
  }
}

export const usePlatformId = (id) => {
  const {getAccessTokenSilently} = useAuth0()

  // todo: fix pagination emplem
  return useQuery('platform-' + id, () => getPlatformById(getAccessTokenSilently, id))
}

export const usePlatformMember = () => {
  const {getAccessTokenSilently} = useAuth0()

  return useQuery('platform/member', () =>
    getRequest({
      url: paths.platform + '?status=member&limit=10000',
      tg: getAccessTokenSilently,
    }),
  )
}

/**
 *
 * @param { referral_code, platformId } input
 * @returns
 */
export const validateReferralCode = async (input) => {
  try {
    const response = await axios.post(`${paths.referralCode}/validate`, input)
    return response.data
  } catch (e) {
    captureException(e)
    throw e.message
  }
}
