
// todo: will be shifted to project base
// todo: make this functions as service

import { useCallback } from "react"
import { useHistory } from "react-router-dom"
import { path as inventory } from '../../views/inventory/path'

const paths = {
  // module name
  inventory
}

// todo: improve data args
export const useNavigator = () => {
  const history = useHistory()

  const go = useCallback((path) => {
    history.push(path)
  }, [history])

  return {
    go,
    paths
  }
}