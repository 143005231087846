import React from 'react'
import clx from 'classnames'
import {ToastType} from 'enums'
import {ToastMessage} from 'views_v2/lib/snippets'

const InventoryToast = (p) => {
  const {content, title, toastType, setToastType, deletionCount} = p || {}
  let showError = false
  let header = title || 'Success'
  let body = content || 'You have successfully saved your changes'

  switch (toastType) {
    case ToastType.ERROR: {
      header = title || 'Failed'
      body = content || 'An error has occured while saving your changes. Please try again!'
      showError = true
      break
    }

    case ToastType.CONSIGN_SHOP:
      {
        body =
          content || 'Your batch was created and is pending verification from the consignment shop. Go ahead and deliver your items to the store now.'
      }
      break

    case ToastType.DELETE_ALL_SUCCESS: {
      body = `${deletionCount} items have been deleted.`
      break
    }

    case ToastType.DELETE_ALL_FAILURE: {
      header = 'Failed'
      body = content || 'There were no unlisted items deleted.'
      break
    }

    case ToastType.DELETE_ALL_ERROR: {
      header = 'Failed'
      body = content || 'There were no unlisted items deleted.'
      showError = true
      break
    }
    case ToastType.EXPORTED: {
      body = 'Product exported! Please check your email.'
      break
    }
  }

  if (toastType > ToastType.UNDEFINED) {
    return (
      <div className={clx('my-2', 'rounded', 'fixed-toast', showError ? 'bg-danger' : 'bg-success')}>
        <ToastMessage {...{header, body}} toggle={() => setToastType(ToastType.UNDEFINED)} />
      </div>
    )
  }
  return null
}

export default InventoryToast
