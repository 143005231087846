import React from 'react'
import { Grid } from '@mui/material'
import { isConsigned } from 'util/model/product'
import {
  InventoryDetails,
  InventoryImages,
  ConsignorInformation,
  Overview,
  InventoryPricing,
  InventoryTracking,
} from '../cards'

const ExpandedLayout = (p) => {
  const {
    formFields,
    editMode,
    handleInputChange,
    forceUpdate,
    isEnterprise,
    setFormFields,
  } = p
  return (
    <Grid container gap={1}>
      <Grid item xs={12} lg={8}>
        <Grid container gap={2}>
          <Grid item xs={12} md={5.5} lg={5.8}>
            <Overview
              {...p}
              {...{
                formFields,
                handleInputChange,
                isEnterprise,
                setFormFields
              }}
              hasError={p?.hasError}
              hasSkuIssue={p?.hasSkuIssue}
              errorMessage={p?.errorMessage}
              template={p?.template}
              setProductTemplate={p?.setProductTemplate}
              updateDescriptionTemplate={p?.updateDescriptionTemplate}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={5.8}>
            <InventoryDetails {...p} />
          </Grid>
        </Grid>
        <InventoryImages
          {...{
            formFields,
            setFormFields
          }}
          deleteImage={p?.deleteImage}
          forceUpdate={p?.forceUpdate}
        />
      </Grid>
      <Grid item xs={12} lg={3.9}>
        {isEnterprise && (
          <InventoryTracking
            {...{
              editMode,
              formFields,
              isEnterprise,
              handleInputChange,
              setFormFields
            }}
            hasError={p?.hasError}
            errorMessage={p?.errorMessage}
            internalSku={formFields?.internalSku}
            location={formFields?.location}
          />
        )}
        <ConsignorInformation
          {...{ editMode, formFields, setFormFields }}
          consignor={formFields?.consign || null}
          consignorEmail={formFields?.consign?.consignor?.consignorEmail}
          feeStructure={formFields?.feeStructure}
          isConsignorEmailReadOnly={isConsigned(formFields)}
        />
        <InventoryPricing
          {...{ formFields, isEnterprise }}
          hasError={p?.hasError}
          errorMessage={p?.errorMessage}
          isConsigned={isConsigned(formFields)}
          hasPricingData
          onChange={(e) => {
            Object.entries(e).map(([key, value]) => {
              setFormFields({ ...formFields, [key]: value })
            })
          }}
        />
      </Grid>
    </Grid>
  )
}

export default ExpandedLayout
