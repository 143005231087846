import React, {useState} from 'react'
import axios from 'axios'
import {useAuth0} from '@auth0/auth0-react'
import clx from 'classnames'
import {Badge, Box, Menu, ListItemIcon, Divider, Tooltip, Typography} from '@mui/material'
import {
  CircleNotificationsOutlined as CircleNotificationsOutlinedIcon,
  NotificationsOff as NotificationsOffIcon,
} from '@mui/icons-material'
import {
  CheckCircle as CheckCircleIcon,
  Pending as PendingIcon,
  Error as ErrorIcon,
  Report as ReportIcon,
} from '@mui/icons-material'

import {paths} from 'constant/api'
import {ToastType} from 'enums'
import {capitalizeFirstLetter} from 'util/string_utils'
import InventoryToast from 'views_v2/modules/Inventory/components/InventoryToast'
import {AccountItem, AvatarIcon} from './AccountMenu'

const NotificationsMenu = (p) => {
  const {badgeContent, notifications, readNotification, refetchNotifications, routeChange} = p || {}
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const {getAccessTokenSilently} = useAuth0()
  const [toastType, setToastType] = useState(ToastType.UNDEFINED)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <React.Fragment>
      <Box sx={{display: 'flex', alignItems: 'center', textAlign: 'center'}}>
        <Tooltip title="Notifications">
          <AvatarIcon
            onClick={handleClick}
            size="small"
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Badge {...{badgeContent}} max={100} color="secondary">
              <CircleNotificationsOutlinedIcon
                sx={{fontSize: '2rem !important', color: 'var(--success)'}}
              />
            </Badge>
          </AvatarIcon>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        MenuListProps={{
          style: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: '5px',
            height: 'auto',
            maxHeight: '75vh',
            overflow: 'hidden auto',
            maxWidth: '30rem',
          },
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
          },
        }}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
      >
        {notifications?.length > 0 ? (
          notifications.map((item, index) => {
            let icon = null
            if (
              item?.status === 'pending' ||
              item?.status === 'partially_done' ||
              item?.status === 'none' ||
              item?.status === 'in_progress' ||
              item?.status === 'retry_in_progress'
            ) {
              icon = <PendingIcon fontSize="large" style={{fill: '#d5239e'}} color="primary" />
            } else if (item?.status === 'failed') {
              icon = <ErrorIcon fontSize="large" style={{fill: '#d5239e'}} color="error" />
            } else if (item?.status === 'done') {
              icon = <CheckCircleIcon fontSize="large" style={{fill: '#d5239e'}} color="primary" />
            }

            const delistingListingPlatform = async (listingPlatformId) => {
              try {
                const token = await getAccessTokenSilently()
                await axios.delete(`${paths.listing}/one`, {
                  data: {
                    listingPlatformId: listingPlatformId,
                  },
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                })

                setToastType(ToastType.SUCCESS)
              } catch (err) {
                setToastType(ToastType.ERROR)
                SentryError.captureException(err)
              }
            }

            let image
            let hasNoImage
            if (item?.type === 'delisting_failed') {
              icon = <ReportIcon fontSize="large" style={{fill: '#aa2e25'}} color="secondary" />
              hasNoImage =
                item.meta.listingPlatform.listing.product.assets?.length === 0
                  ? null
                  : item.meta.listingPlatform.listing.product.assets.reduce((a, b) => {
                      return Math.abs(b.order - 1) < Math.abs(a.order - 1) ? b : a
                    })
              image = hasNoImage
            }

            return (
              <Box key={`item-${index}`} sx={{width: '100%'}}>
                <AccountItem
                  key={`menu-item-${item?.id}`}
                  sx={{backgroundColor: item?.isRead ? 'white' : '#B2BEB5 !important', width: '29.4rem'}}
                  onClick={() => {
                    readNotification(item?.id)
                    refetchNotifications()
                    if (item?.type === 'delisting_failed') {
                      delistingListingPlatform(item?.meta.listingPlatform.id)
                    } else {
                      if (
                        item?.status !== 'done' &&
                        item?.status !== 'none' &&
                        item?.status !== 'in_progress' &&
                        item?.status !== 'retry_in_progress'
                      ) {
                        routeChange(`/admin/inventory/import/${item.id}`, item)
                      }
                    }
                  }}
                >
                  <ListItemIcon>{icon}</ListItemIcon>
                  <Box sx={{display: 'flex', flexDirection: 'column', width: '25rem', ml: 1}}>
                    <Typography fontWeight={600} fontSize="0.9rem">
                      {capitalizeFirstLetter(item?.type.replace('_', ' '))}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: '0.8rem',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: '25rem',
                      }}
                    >
                      {item?.type === 'delisting_failed' ? (
                        <div>
                          <div
                            className={clx(hasNoImage && 'no-image', 'img-overlay-wrap')}
                            style={{
                              display: 'inline-block',
                              width: '100px',
                              marginRight: '10px',
                              marginBottom: '10px',
                            }}
                          >
                            <img src={image?.asset?.url} />
                          </div>
                          <div>
                            <span
                              style={{display: 'block'}}
                            >{`${item.meta.listingPlatform.listing.product.internalSku}`}</span>
                            <span style={{display: 'block'}}>
                              {`Failed to delist from ${item.meta.listingPlatform.platform.name} after ${item.meta.action}`}
                            </span>
                            <span style={{display: 'block', fontWeight: 'bold', marginTop: '10px'}}>
                              Retry now
                            </span>
                          </div>
                        </div>
                      ) : (
                        item?.description
                      )}
                    </Typography>
                  </Box>
                </AccountItem>
                <Divider width="100%" />
              </Box>
            )
          })
        ) : (
          <Box sx={{width: '100%'}}>
            <AccountItem>
              <ListItemIcon>
                <NotificationsOffIcon fontSize="large" />
              </ListItemIcon>
              No Notifications!
            </AccountItem>
          </Box>
        )}
      </Menu>
      {toastType > ToastType.UNDEFINED && <InventoryToast {...{toastType, setToastType}} />}
    </React.Fragment>
  )
}

export default NotificationsMenu
