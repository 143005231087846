// migrated from viewInventory.js
// changes should be minimal
// todo: filter should handle by api

export function filter(text, filters, items, isEnterprise) {
  if (!text && !(filters.length > 0)) {
    return items
  }
  // todo: important!
  // todo: idont know what this is doing
  return items
    .filter((item) => {
      // TODO:  Cleanup filtering logic below and handle in a single filter function
      if (!(filters.length > 0)) {
        return true
      }

      const brandFilters = filters.filter((b) => b.key === 'item_type.brand').map((b) => b.value)
      const conditionFilters = filters.filter((b) => b.key === 'condition').map((b) => b.value)
      const sizeFilters = filters.filter((b) => b.key === 'size').map((b) => b.value)

      return (
        (brandFilters.length == 0 ||
          (item.item_type &&
            item.item_type.brand &&
            brandFilters.indexOf(item.item_type.brand) !== -1)) &&
        (conditionFilters.length == 0 ||
          (item.condition && conditionFilters.indexOf(item.condition) !== -1)) &&
        (sizeFilters.length == 0 || (item.size && sizeFilters.indexOf(item.size) !== -1))
      )
    })
    // .filter((item) => {
    //   // TODO:  Cleanup filtering logic below and handle in a single filter function
    //   if (!text) {
    //     return true
    //   }

    //   var checkName = item.item_type.name.toLowerCase().indexOf(text.toLowerCase())
    //   if (checkName !== -1) {
    //     return true
    //   }

    //   var checkSku = item.sku.toLowerCase().indexOf(text.toLowerCase())
    //   if (checkSku !== -1) {
    //     return true
    //   }
    //   var checkCondition = item.condition.toLowerCase().indexOf(text.toLowerCase())
    //   if (checkCondition !== -1) {
    //     return true
    //   }

    //   //Enterprise accounts

    //   if (isEnterprise) {
    //     var checkInternalSku = item.internalSku
    //       ? item.internalSku.toLowerCase().indexOf(text.toLowerCase())
    //       : -1
    //     if (checkInternalSku !== -1) {
    //       return true
    //     }

    //     var checkLocation = item.location ? item.location.toLowerCase().indexOf(text.toLowerCase()) : -1
    //     if (checkLocation !== -1) {
    //       return true
    //     }

    //     var checkSubLocation = item.subLocation
    //       ? item.subLocation.toLowerCase().indexOf(text.toLowerCase())
    //       : -1
    //     if (checkSubLocation !== -1) {
    //       return true
    //     }

    //     var checkConsignorEmail = item.consignorEmail
    //       ? item.consignorEmail.toLowerCase().indexOf(text.toLowerCase())
    //       : -1
    //     if (checkConsignorEmail !== -1) {
    //       return true
    //     }

    //     var checkColor = item.color ? item.color.toLowerCase().indexOf(text.toLowerCase()) : -1
    //     if (checkColor !== -1) {
    //       return true
    //     }

    //     var checkBrand = item.item_type.brand
    //       ? item.item_type.brand.toLowerCase().indexOf(text.toLowerCase())
    //       : -1
    //     if (checkBrand !== -1) {
    //       return true
    //     }

    //     var checkCategory = item.item_type.category
    //       ? item.item_type.category.toLowerCase().indexOf(text.toLowerCase())
    //       : -1
    //     if (checkCategory !== -1) {
    //       return true
    //     }

    //     var checkSubCategory = item.item_type.subcategory
    //       ? item.item_type.subcategory.toLowerCase().indexOf(text.toLowerCase())
    //       : -1
    //     if (checkSubCategory !== -1) {
    //       return true
    //     }

    //     var checkDesireReturn = item.desiredReturn
    //       ? item.desiredReturn.toLowerCase().indexOf(text.toLowerCase())
    //       : -1
    //     if (checkDesireReturn !== -1) {
    //       return true
    //     }

    //     var checkPlatforms = item.listing?.platforms[0].name
    //       ? item.listing?.platforms[0].name.toLowerCase().indexOf(text.toLowerCase())
    //       : -1
    //     if (checkPlatforms !== -1) {
    //       return true
    //     }

    //     if (!isNaN(parseFloat(text))) {
    //       var checkPrice =
    //         !isNaN(parseFloat(item.desiredReturn)) && parseFloat(item.desiredReturn) == parseFloat(text)
    //       if (checkPrice) {
    //         return true
    //       }

    //       var checkSize = !isNaN(parseFloat(item.size)) && parseFloat(item.size) == parseFloat(text)
    //       if (checkSize) {
    //         return true
    //       }
    //     }
    //   }
    //   return false
    // })
}
