import {validateEnterpriseUser} from 'util/model/user'
import * as Sentry from '@sentry/react'

export default function getDesiredPrice(stockXPrice, user, desiredReturn) {
  try {
    if (stockXPrice && user && validateEnterpriseUser(user) && user.private?.lowestStockXAskPercentage) {
      const lp = Number(stockXPrice)
      const p = Number(user.private.lowestStockXAskPercentage)

      if (p > 0) {
        return lp + lp * p * 0.01
      }
    }
  } catch (e) {
    Sentry.captureException(e)
  }
  return desiredReturn || ''
}
