import React from 'react'
import classnames from 'classnames'
import { Row, Col } from 'reactstrap'

const TableSection = ({ children, className = null, count = 0, visible = true, name, onSelect }) => {
  return (
    <Row>
      {
        visible ? (
          <Col sm={12} xs={12}>
            <div className={classnames('p-table-section', className)} onClick={() => onSelect ? onSelect() : null}>
              <span className="counter">{count.toLocaleString()}</span>
              <label>{(name || '').toUpperCase()}</label>
            </div>
          </Col>
        ) : null
      }
      {children}
    </Row>
  )
}

export default TableSection
