import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import PanelGroup from './PanelGroup'
import PanelView from './PanelView'
import PanelDetailView from './PanelDetailView'
import ToolbarView from './ToolbarView'

import './scss/parent.scss'

class DetailView extends Component {
  static Group = PanelGroup
  static Panel = PanelView
  static PanelDetail = PanelDetailView
  static Toolbar = ToolbarView

  render() {
    const { expand, tabView } = this.props
    const parentClass = classnames(
      tabView ? 'p-detail-tab-wrapper' : 'p-detail-content'
    )

    return (
      <div className={parentClass}>
        {this.props.children}
      </div>
    )
  }
}

DetailView.propTypes = {
  children: PropTypes.node,
  expand: PropTypes.bool,
  tabView: PropTypes.bool
}

DetailView.defaultProps = {
  expand: false,
  tabView: false
}

DetailView.Group = PanelGroup
DetailView.Panel = PanelView
DetailView.PanelDetail = PanelDetailView
DetailView.Toolbar = ToolbarView

export default DetailView
