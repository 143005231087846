import React, { useCallback, useState } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import { useRedirCallback } from 'hooks/use_redir_callback'
import { usePlatformMarket } from 'service/hook/use_market'

const ShopifyCallback = (props) => {
  const history = useHistory()
  const [state, setState] = useState({ message: '', state: 'loading' })
  const { shopifyCallback } = usePlatformMarket()

  const urlParams = new URLSearchParams(history?.location?.search)

  const process = useCallback(async () => {
    try {
      setState((s) => ({ ...s, state: 'loading' }))
      const data = await shopifyCallback(history.location.search)
      if (!data) {
        throw new Error('Faild to process input.')
      }
      setState((s) => ({ ...s, message: 'Setting up shopify webhooks...' }))
    } catch (e) {
      setState((s) => ({ ...s, state: 'failed', message: e.message }))
    }
  }, [history, shopifyCallback, setState])

  useRedirCallback(process)

  if (state.state === 'failed') {
    return (
      <div className="content">
        Failed.
        <hr />
        <Button onClick={history.goBack}>Back</Button>
      </div>
    )
  }
  const url = Object.fromEntries(urlParams)
  return (
    <Redirect
      to={`/shopifybilling?code=${url?.code}&hmac=${url?.hmac}&host=${url?.host}&shop=${url?.shop}&state=${url?.state}&timestamp=${url?.timestamp}`}
    />
  )
}

export default ShopifyCallback
