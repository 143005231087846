import React from 'react'
import clx from 'classnames'

const CardText = ({ children, className, style }) => (
  <div className={clx('card-text', className)} style={style}>
    {children}
  </div>
)

export default CardText
