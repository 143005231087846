import React from 'react'
import Select from 'react-select'
import {Box, FormControlLabel} from '@mui/material'
import {Input} from 'reactstrap'
import _ from 'lodash'

import {box_options, condition_options, flaw_options} from 'assets/data'
import {CopytLabel, SizesDropdown, FieldGroup} from 'views_v2/lib/snippets'
import CheckSwitch from 'views_v2/lib/snippets/CopytSwitch/CheckSwitch'
import {useSiteSettings} from 'service/hook'
import {_replaceWildCards} from 'util/string_utils'
import {CopytBox} from './InventoryDetails'
import {SizesContainer} from '../../snippets'

export const formatDescription = (description) => {
  if (description && description.includes('[SKU]') && !description.includes('SKU: [SKU]')) {
    return description.replace('[SKU]', 'SKU: [SKU]')
  }
  return description
}

const ExpandedInventoryDetails = (props) => {
  const {currentDescriptionWildcardList} = useSiteSettings()
  const {
    handleValueChange,
    handleArrow,
    hasError,
    editMode,
    errorMessage,
    handleSizeChange,
    forceUpdate,
    productTemplate,
    previewState,
    onDeleteItem,
    setPreviewState,
    setFormFields,
    readOnly = false,
    isLoading = true,
  } = props

  const formFields = Object.assign(props.formFields, {
    description: formatDescription(props.formFields.description),
  })

  return (
    <React.Fragment>
      <Box>
        <CopytBox>
          <CopytLabel
            className="half-width"
            sx={{
              color:
                hasError && (!formFields.size || formFields.size?.length === 0) && '#d3103f !important',
            }}
          >
            Sizes *
          </CopytLabel>
          <CopytLabel className="half-width">Item Condition</CopytLabel>
        </CopytBox>
        <CopytBox>
          {readOnly ? (
            <>
              <FieldGroup value={formFields.size} readOnly />
              <FieldGroup value={formFields.condition} readOnly />
            </>
          ) : (
            <>
              <SizesDropdown
                className="half-width"
                hasError={hasError && (!formFields.size || formFields.size?.length === 0)}
                {...{handleSizeChange, formFields, editMode, readOnly}}
              />
              <Select
                className="react-select info half-width"
                classNamePrefix="react-select"
                placeholder="Choose Condition"
                closeMenuOnSelect
                isDisabled={productTemplate?.value || readOnly}
                onChange={(e) =>
                  setFormFields({
                    ...formFields,
                    condition: e.value,
                  })
                }
                options={condition_options}
                value={
                  (formFields.condition && {
                    value: formFields.condition,
                    label: formFields.condition,
                  }) || {value: 'Brand New', label: 'Brand New'}
                }
                tabIndex="18"
              />
            </>
          )}
        </CopytBox>
      </Box>
      <SizesContainer
        arr={formFields.size}
        {...{errorMessage, handleArrow, handleValueChange, onDeleteItem}}
      />
      <Box>
        <CopytBox sx={{mt: 2}}>
          <CopytLabel>Box Condition</CopytLabel>
          <CopytLabel className="half-width">Flaws</CopytLabel>
        </CopytBox>
        <CopytBox>
          {readOnly ? (
            <>
              <FieldGroup value={formFields.boxCondition} readOnly />
              <FieldGroup value={formFields.flaws} readOnly />
            </>
          ) : (
            <>
              <Select
                className="react-select info half-width"
                classNamePrefix="react-select"
                placeholder="Choose Box Condition"
                closeMenuOnSelect
                onChange={(e) =>
                  setFormFields({
                    ...formFields,
                    boxCondition: e?.value,
                  })
                }
                options={box_options}
                value={
                  (formFields.boxCondition && {
                    value: formFields.boxCondition,
                    label: formFields.boxCondition,
                  }) || {
                    value: 'Good Condition',
                    label: 'Good condition',
                  }
                }
                tabIndex="16"
              />
              <Select
                className="react-select info half-width"
                classNamePrefix="react-select"
                placeholder="Choose Flaws"
                closeMenuOnSelect
                isClearable
                onChange={(e) =>
                  setFormFields({
                    ...formFields,
                    flaws: e?.value || '',
                  })
                }
                options={flaw_options}
                value={
                  (formFields.flaws && {
                    value: formFields.flaws,
                    label: formFields.flaws,
                  }) ||
                  ''
                }
                tabIndex="19"
              />
            </>
          )}
        </CopytBox>
      </Box>

      <Box display="flex" alignItems="center" justifyContent="space-between">
        <CopytLabel>Description</CopytLabel>
        <FormControlLabel
          label="Preview"
          sx={{
            m: 1,
            color: 'var(--success)',
          }}
          componentsProps={{
            typography: {
              fontSize: '12px',
            },
          }}
          control={<CheckSwitch sx={{m: 0}} checked={previewState} disabled={readOnly} />}
          onChange={() => setPreviewState(!previewState)}
        />
      </Box>
      <Box>
        <Input
          style={{
            padding: '10px',
          }}
          type="textarea"
          name="text"
          id="description"
          onChange={(e) =>
            setFormFields({
              ...formFields,
              description: e.target.value,
            })
          }
          value={
            previewState
              ? _replaceWildCards(formFields, formFields.description, currentDescriptionWildcardList)
              : formFields.description
          }
          tabIndex="20"
          readOnly={previewState || readOnly}
        />
      </Box>
    </React.Fragment>
  )
}

export default ExpandedInventoryDetails
