import React from 'react'
import { Box, Typography } from '@mui/material'
import { CopytButton } from 'views_v2/lib/snippets'
import { ReactComponent as ListingFailureSvg } from '../../../../../assets/img/ListingFailure.svg'
import { styled } from '@mui/material/styles'

export const TextStyle = styled(Typography)(() => ({
  lineHeight: 1.2,
  fontSize: "0.8rem",
  fontStyle: "italic",
  textAlign: "center",
  marginBottom: 10,
  color: "#5d0953 !important"
}))

const MdlUploadImageFailed = (p) => {
  return (
    <Box>
      <Box className="modal-title">
        <Box className="modal__heading">
          <Typography variant="h1">Image Upload Failure</Typography>
        </Box>
      </Box>
      <Box className="modal-body" sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
      }}>
        <TextStyle>Your item was saved but not all images uploaded successfully.</TextStyle>
        <TextStyle>We will take you to your item, please try again.</TextStyle>
        <ListingFailureSvg />
        <Box mt={2}>
          <CopytButton
            variant="contained"
            color="primary"
            onClick={() => {
              p?.onCloseModal()
            }}
          >
            Ok
          </CopytButton>
        </Box>
      </Box>
    </Box>
  )
}

export default MdlUploadImageFailed
