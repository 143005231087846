/* eslint-disable max-len */
import React from 'react'
import PropTypes from 'prop-types'
import clx from 'classnames'
import { Button, ButtonGroup } from '@mui/material'

export const TabPanel = {
  UNDEFINED: -1,
  GENERAL: 0,
  CONSIGNMENT: 1,
  LISTING: 2
}

export const TabPanelType = [
  { eventKey: TabPanel.GENERAL, title: 'General' },
  { eventKey: TabPanel.CONSIGNMENT, title: 'Consignment' },
  { eventKey: TabPanel.LISTING, title: 'Listing' },
]

const TabPanels = ({ size, disabled, tabPanel, onSelect, isEnterprise }) => {
  return (
    <div className="segments">
      <ButtonGroup>
        {
          TabPanelType.map((tab, i) => {
            let hide = false
            if (tab.eventKey === TabPanel.CONSIGNMENT && !isEnterprise) hide = true

            const isActive = tab.eventKey === tabPanel
            const color = isActive ? 'primary' : 'secondary'
            const style = clx(tab.title, isActive && 'active', hide ? 'hide' : 'show')
            return (
              <Button
                className={style}
                key={tab.title}
                disabled={disabled || tab.disabled || false}
                onClick={() => onSelect(tab.eventKey)}
                variant={isActive ? 'contained' : 'outlined'}
                {...{ size, color }}
              >
                {tab.title}
              </Button>
            )
          })
        }
      </ButtonGroup>
    </div>
  )
}

TabPanels.propTypes = {
  size: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.any,
  tabPanel: PropTypes.oneOf(
    Object.values(TabPanel)
  ),
  onSelect: PropTypes.func.isRequired
}

TabPanels.defaultProps = {
  size: 'small',
  disabled: false,
  id: null,
  tabPanel: TabPanel.GENERAL
}

export default TabPanels
