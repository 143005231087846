import React, {useState, useEffect} from 'react'
import clx from 'classnames'
import {useHistory} from 'react-router-dom'
import {useLedger} from 'service/hook/use_ledger'
import {DataGrid} from '@mui/x-data-grid'
import {Box} from '@mui/material'
import {ModalType, ToastType} from 'enums'
import {formatTo} from 'util/date_utils'
import {capitalizeFirstLetter, toMoney} from 'util/string_utils'
import {getConsignorEmail} from 'util/model/ledger'
import DetailView from 'components/DetailView'
import {useExport} from 'service/hook'
import {EmptyData, LoadingBar, TooltipSnippets, SearchToolbar} from 'views_v2/lib/snippets'
import LedgersModal from './LedgersModal'
import InventoryToast from '../Inventory/components/InventoryToast'
import './scss/parent.scss'
import {RedirectAction} from 'enums'

const Ledger = () => {
  const [queries, setQueries] = useState({})
  const [data, setData] = useState(null)
  const [modalType, setModalType] = useState(ModalType.UNDEFINED)
  const [toastType, setToastType] = useState(ToastType.UNDEFINED)
  const [filter, setFilter] = useState({
    page: 0,
    take: 100,
    skip: 0,
  })
  const history = useHistory()
  const {ledgers, isLoading, isError, isFetching, refecthLedgers} = useLedger({}, filter, queries)
  const {isErrorExport, isSuccessExport, invalidateExportQueries, refetchExport} = useExport({
    filter: {...filter, take: ledgers?.total || 10000, limit: ledgers?.total || 10000},
    exportType: 'ledger',
  })

  useEffect(() => {
    if (queries?.hasOwnProperty('search')) {
      refecthLedgers()
    }
  }, [queries])

  useEffect(() => {
    if (isSuccessExport) {
      setToastType(ToastType.EXPORTED)
    } else if (isErrorExport) {
      setToastType(ToastType.ERROR)
    }
    invalidateExportQueries()
  }, [isErrorExport, isSuccessExport])

  const columns = [
    {
      field: 'consignee',
      headerName: 'Consigner',
      flex: 1.3,
      minWidth: 150,
      renderCell: (params) => {
        const title = getConsignorEmail(params?.row.consigns)
        return <TooltipSnippets {...{title}} />
      },
      valueGetter: (params) => {
        return getConsignorEmail(params?.row) || ''
      },
    },
    {
      field: 'amount',
      headerName: 'Amount',
      flex: 0.8,
      minWidth: 150,
      valueGetter: (params) => {
        return toMoney(params.row?.total)
      },
    },
    {
      field: 'date',
      headerName: 'Date',
      flex: 0.8,
      minWidth: 150,
      valueGetter: (params) => `${formatTo(params?.row?.createdAt, 'MMM DD, YYYY')}`,
    },
    {
      field: 'method',
      headerName: 'Method',
      flex: 0.8,
      minWidth: 150,
      valueGetter: (params) => {
        return capitalizeFirstLetter(params?.row?.method?.toLowerCase()) || ''
      },
    },
  ]

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const params = {}

    for (const [key, value] of searchParams.entries()) {
      params[key] = value
    }
    setQueries({search: params[RedirectAction.SEARCH]?.split(' ').join('+')})
    searchParams.delete(RedirectAction.SEARCH)

    const newUrl = window.location.pathname + '?' + searchParams.toString()
    window.history.replaceState({}, '', newUrl)
  }, [])

  if (isLoading) return <LoadingBar />
  else if (isError) {
    return (
      <EmptyData title="Internal Server Error">
        An internal server error occured while getting your data. Try again later.
      </EmptyData>
    )
  }
  const hasData = ledgers?.data?.length > 0
  const rowCount = ledgers?.total || 0

  const onSearch = (searchKey) => setQueries({search: searchKey})

  const Toolbar = () => {
    return (
      <SearchToolbar
        onExport={() => refetchExport()}
        onSearch={onSearch}
        search={{
          onSearch: onSearch,
          value: queries?.search,
          disabled: isFetching,
        }}
      />
    )
  }

  const CustomNoRowsOverlay = () => {
    return (
      <EmptyData title="No Consigned Items">
        Create your first payout batch on the
        <span onClick={() => history.push('/admin/orders')} className="pink-text ml-1 cursor-pointer">
          Orders page
        </span>
        .
      </EmptyData>
    )
  }

  return (
    <div className={clx('p-detail-flex', 'ledger')}>
      <DetailView.Panel style={{flex: 1}}>
        <Box sx={{height: 1}}>
          <DataGrid
            {...{columns, rowCount}}
            rows={ledgers?.data}
            loading={isFetching}
            disableColumnMenu
            disableColumnSelector
            disableDensitySelector
            disableColumnFilter
            components={{Toolbar: Toolbar, NoRowsOverlay: CustomNoRowsOverlay}}
            componentsProps={{
              toolbar: {
                onSearch,
              },
            }}
            onPageChange={(newPage) => setFilter((f) => ({...f, page: newPage, skip: newPage * f.take}))}
            onCellClick={(param) => {
              setData(param?.row)
              setModalType(ModalType.CREATE_BATCH)
            }}
            filterModel={{
              items: [],
              quickFilterValues: [queries?.search || ''],
            }}
            onFilterModelChange={(s) => {
              onSearch(s?.quickFilterValues[0])
            }}
            pageSize={filter.take}
            pagination
            paginationMode="server"
            rowsPerPageOptions={[filter.take]}
            onSortModelChange={(newSort) => {
              const {field, sort} = newSort[0] || {}
              if (queries?.hasOwnProperty('search')) delete queries?.search
              setQueries({
                sortBy: field,
                sortDirection: sort,
              })
            }}
          />
        </Box>
        <LedgersModal
          {...{
            data,
            modalType,
            setModalType,
          }}
        />
        {toastType > ToastType.UNDEFINED && <InventoryToast {...{toastType, setToastType}} />}
      </DetailView.Panel>
    </div>
  )
}

export default Ledger
