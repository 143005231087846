import React, {useState, useEffect, useRef} from 'react'
import {useHistory} from 'react-router-dom'
import clx from 'classnames'
import {Box, Button, Tabs, Tab, Stack} from '@mui/material'
import {SpeakerNotesOff as SpeakerNotesOffIcon, People as PeopleIcon} from '@mui/icons-material'
import {isEmpty} from 'lodash'

import {ModalType} from 'enums'
import {useConnections, useUser, useConnectionProduct, useCredential} from 'service/hook'
import SplitView from 'components/SplitView'
import {getPredefinedObject} from 'util/string_utils'
import {urlWithoutParam, getParams} from 'util/window_utils'
import {LoadingBar} from 'views_v2/lib/snippets'
import {ConnectDetailView, ConnectDetailTableView} from './components/common'
import ConnectModals from './components/ConnectModals'
import {CustomButton} from '../Inventory/snippets/headers'
import './scss/parent.scss'

const Network = () => {
  const {credential} = useCredential()
  const [tabPanel, setTabPanel] = useState(0)
  const [sIds, setSIds] = useState({})
  const [modalType, setModalType] = useState(ModalType.UNDEFINED)
  const history = useHistory()
  const [item, setItem] = useState({})
  const {user, updateDiscordName} = useUser()
  const [connectionPage, setConnectionPage] = useState(0)
  const [queries, setQueries] = useState({})
  const [loading, setLoading] = useState(false)
  const [mount, setMount] = useState(false)
  const [hideConnectionList, setHideConnectionList] = useState(true)
  const [filter, setFilter] = useState({
    skip: 0,
    take: 100,
    page: 0,
  })
  const [connectionProductPage, setConnectionProductPage] = useState(filter?.page)
  const {
    isLoading,
    connections,
    searchConnection,
    applyApplication,
    approveApplication,
    denyApplication,
    updateConnection,
    refetchConnection,
  } = useConnections({page: connectionPage})

  const {
    isProductConnectionsFetching,
    productConnections,
    reinvalidateProductConnections,
    refetchProductConnection,
  } = useConnectionProduct({filter, queries})

  const timerRef = useRef(null)

  useEffect(() => {
    return () => {
      if (timerRef?.current) {
        clearTimeout(timerRef.current)
      }
    }
  }, [])

  useEffect(() => {
    if (!mount) {
      const params = getParams(history)

      if (params?.hasOwnProperty('page')) {
        const pageNumber = Number(params?.page)
        if (pageNumber !== filter?.page && pageNumber > 0) {
          setFilter((f) => ({...f, page: pageNumber, skip: pageNumber * f.take}))
        }
      }
      setMount(true)
    }
  }, [mount])

  useEffect(() => {
    if (queries?.hasOwnProperty('search')) {
      refetchProductConnection()
      setLoading(isProductConnectionsFetching)
    }
    setLoading(false)
  }, [queries, productConnections])

  useEffect(() => {
    if (updateConnection.isSuccess) {
      setConnectionPage(0)
      refetchConnection()
      refetchProductConnection()
    }
  }, [updateConnection.isSuccess])

  useEffect(() => {
    if (denyApplication.isSuccess) {
      setConnectionPage(0)
      refetchConnection()
      refetchProductConnection()
    }
  }, [denyApplication.isSuccess])

  useEffect(() => {
    if (applyApplication.isSuccess) {
      setConnectionPage(0)
      refetchConnection()
      refetchProductConnection()
    }
  }, [applyApplication.isSuccess])

  useEffect(() => {
    setConnectionProductPage(connectionProductPage)
    onPageChange(connectionProductPage)
  }, [connectionProductPage])

  useEffect(() => {
    if (connectionProductPage !== filter?.page) {
      setConnectionProductPage(filter?.page)
      onPageChange(filter?.page)
    }
  }, [filter?.page])

  const handleChange = (e, newValue) => {
    setTabPanel(newValue)
  }

  const onPageChange = (newPage) => {
    if (timerRef?.current) {
      clearTimeout(timerRef?.current)
    }

    timerRef.current = setTimeout(() => {
      setFilter((f) => ({...f, page: newPage, skip: newPage * f.take}))
      const newUrl = urlWithoutParam(newPage)
      window.history.pushState({path: newUrl}, '', newUrl)
    }, 1000)
  }

  const onSaveChanges = (p) => {
    if (p?.sendConnectionRequest) {
      searchConnection(p?.sendConnectionRequest)
    } else if (p?.updateDiscordName) {
      const userObj = getPredefinedObject(p?.updateDiscordName, ['discordUserName'])
      updateDiscordName(userObj)
    } else if (p?.denyApplication) {
      denyApplication(p?.denyApplication)
    } else if (p?.approveApplication) {
      approveApplication(p?.approveApplication)
    } else if (p?.applyApplication) {
      applyApplication(p?.applyApplication)
    } else if (p?.batchItems) {
      setSIds({})
    } else if (p?.updateConnection) {
      updateConnection(p?.updateConnection)
    } else if (p?.filter) {
      const filter = p?.filter
      setQueries({
        ...queries,
        ...filter,
      })
    }
    setModalType(ModalType.UNDEFINED)
  }

  if (isLoading) return <LoadingBar />

  return (
    <div className={clx('p-detail-flex', 'connect')}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        borderBottom="1px solid var(--gray-light)"
        bgcolor="var(--lighter) !important"
        height="50px"
        px={1.5}
      >
        <CustomButton
          icon={<PeopleIcon />}
          title={`${!hideConnectionList ? 'Hide' : 'Show'} Connection List`}
          onClick={() => setHideConnectionList(!hideConnectionList)}
          variant="outline"
          color="secondary"
        />
        <Button
          className={clx('btn-header', 'primary')}
          disabled={isEmpty(item)}
          onClick={() => setModalType(ModalType.CONNECT_BATCH_ITEMS)}
        >
          Batch Items
        </Button>
      </Stack>
      <SplitView className={hideConnectionList && 'collapsed'}>
        <SplitView.Master>
          <ConnectDetailTableView
            {...{setModalType, onSaveChanges}}
            connections={connections || []}
            discordUserName={user?.discordUserName || '--'}
            ownerEmail={user?.email}
            page={connectionPage}
            onPageChange={(page) => {
              setConnectionPage(Math.ceil(page) * 100)
              refetchConnection()
            }}
          />
        </SplitView.Master>
        <SplitView.Detail>
          <Box borderBottom="1px solid var(--light)" margin="0 10px">
            <Tabs
              value={tabPanel}
              onChange={handleChange}
              aria-label="icon label tabs"
              scrollButtons
              allowScrollButtonsMobile
            >
              <Tab icon={<SpeakerNotesOffIcon />} iconPosition="start" label="Feed" />
            </Tabs>
          </Box>
          <ConnectDetailView
            data={productConnections || []}
            page={connectionProductPage}
            onPageChange={(newPage) => {
              setSIds({})
              setConnectionProductPage(newPage)
            }}
            loading={loading || isProductConnectionsFetching}
            {...{sIds, queries, setSIds, setQueries, onSaveChanges}}
          />
        </SplitView.Detail>
      </SplitView>
      <ConnectModals
        {...{
          user,
          item,
          sIds,
          modalType,
          setModalType,
          onSaveChanges,
        }}
        connections={productConnections?.data || []}
      />
    </div>
  )
}

export default Network
