import React, {useState, useEffect} from 'react'
import {DataGrid} from '@mui/x-data-grid'
import {Box, Button, Stack, Typography} from '@mui/material'
import {
  PendingActions as PendingActionsIcon,
  ThumbUpAlt as ThumbUpAltIcon,
  Error as ErrorIcon,
  InfoOutlined as InfoOutlinedIcon,
  RemoveShoppingCart as RemoveShoppingCartIcon,
} from '@mui/icons-material'
import {capitalize, isEmpty, replace} from 'lodash'

import DetailView from 'components/DetailView'
import CopytCard from 'components/CopytCard'
import {ToastType} from 'enums'
import {useConsignmentShops, useFees, useExport} from 'service/hook'
import {ApplicationStatusStr} from 'resources/constants'
import {CopytTooltip, TooltipSnippets, QuickSearchToolbar, FieldGroup} from 'views_v2/lib/snippets'
import {capitalizeFirstLetter, formatName, _getStatus} from 'util/string_utils'
import ToggleButton from './ToggleButton'
import {ConsignorProfile} from './ConsignorProfile'
import {ErrorMessage} from 'views_v2/lib/components'
import InventoryToast from 'views_v2/modules/Inventory/components/InventoryToast'

export const EnterprisePlatform = () => {
  const [toastType, setToastType] = useState(ToastType.UNDEFINED)
  const [queries, setQueries] = useState({})
  const {
    updateApplicationStatus,
    shopApplications,
    shopApplicationsRefetch,
    isShopApplicationsLoading,
    isShopApplicationsError,
  } = useConsignmentShops({
    options: {},
    queries,
  })

  const {isErrorExport, isSuccessExport, invalidateExportQueries, refetchExport} = useExport({
    filter: {
      skip: 0,
      // FixMe: Should be shopApplications.total
      take: shopApplications?.length || 10000,
      limit: shopApplications?.length || 10000,
    },
    exportType: 'consignment',
  })

  const {fees, assignFeeToApplication} = useFees()

  useEffect(() => {
    if (queries?.hasOwnProperty('search')) {
      shopApplicationsRefetch()
    }
  }, [queries])

  useEffect(() => {
    if (isSuccessExport) {
      setToastType(ToastType.EXPORTED)
    } else if (isErrorExport) {
      setToastType(ToastType.ERROR)
    }
    invalidateExportQueries()
  }, [isErrorExport, isSuccessExport])

  const updateApp = async (applicationId, status) => {
    updateApplicationStatus({applicationId, status})
  }

  const onFeeSelected = async (applicationId, feeId) => {
    assignFeeToApplication({applicationId, feeId})
  }

  const columns = [
    {
      field: 'fullname',
      headerName: 'Name',
      flex: 1,
      minWidth: 120,
      renderCell: (params) => {
        const title = params?.row?.fullname || formatName(params?.row?.owner)
        return <TooltipSnippets {...{title}} />
      },
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 2,
      renderCell: (params) => {
        const title = capitalizeFirstLetter(params.value)
        return <ConsignorProfile consignorEmail={title} consignorId={params.row.owner.id} />
      },
    },
    {
      field: 'owner',
      headerName: 'Phone Number',
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        const title = params.value?.phoneNumber || ''
        return (
          <FieldGroup
            disableCountryCode={isEmpty(title)}
            placeholder=""
            className="consignment"
            id="phoneNumber"
            value={title}
            style={{margin: '10px 0 0 0'}}
          />
        )
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.9,
      minWidth: 120,
      renderCell: (params) => {
        const {value} = params || {}
        let endIcon = <ThumbUpAltIcon />
        let color = 'success'
        let textValue = params.value ?? ''

        if (textValue === 'denied') {
          endIcon = <ErrorIcon />
          color = 'error'
        } else if (textValue === 'pending') {
          endIcon = <PendingActionsIcon />
          color = 'secondary'
        } else if (textValue?.includes('withdraw')) {
          endIcon = <RemoveShoppingCartIcon />
          color = textValue?.includes('withdraw_pending') ? 'secondary' : 'success'
          textValue = replace(textValue, /_/g, ' ')
        }

        return (
          <Button
            {...{color, endIcon}}
            size="small"
            variant="outlined"
            style={{
              pointerEvents: 'none',
              cursor: 'default',
              width: '100%',
            }}
          >
            <TooltipSnippets title={capitalize(textValue)} />
          </Button>
        )
      },
    },
    {
      field: 'shippingType',
      headerName: 'Shipping Type',
      flex: 0.8,
      minWidth: 110,
      isCellEditable: (params) => {
        const {status} = params?.row
        return status !== ApplicationStatusStr.PENDING
      },
      renderCell: (params) => {
        return capitalizeFirstLetter(params.value)
      },
    },
    {
      field: 'confirm',
      headerName: 'Confirm',
      flex: 1,
      minWidth: 180,
      isCellEditable: (params) => {
        const {status} = params?.row
        return status !== ApplicationStatusStr.PENDING
      },
      renderCell: (params) => {
        const {status, id} = params?.row
        return (
          status === ApplicationStatusStr.PENDING && (
            <span>
              <Button
                color="success"
                size="small"
                style={{margin: 5}}
                variant="contained"
                onClick={() => updateApp(id, ApplicationStatusStr.APPROVED)}
              >
                Approve
              </Button>
              <Button
                color="error"
                size="small"
                style={{margin: 5}}
                variant="contained"
                onClick={() => updateApp(id, ApplicationStatusStr.DENIED)}
              >
                Deny
              </Button>
            </span>
          )
        )
      },
    },
    {
      field: 'reason',
      headerName: 'Contract Status',
      flex: 0.7,
      minWidth: 100,
      editable: true,
      sortable: false,
      renderCell: (params) => {
        const isAcceptedContract = params.row?.isAcceptedContract
        const acceptedContractLink = params.row?.acceptedContractLink
        return isAcceptedContract ? (
          <a href={acceptedContractLink || '#'} target="_blank" rel="noopener noreferrer">
            Accepted
          </a>
        ) : (
          <>Pending</>
        )
      },
    },
    {
      field: 'fee',
      headerName: 'Fees',
      flex: 1,
      minWidth: 110,
      sortable: false,
      renderCell: (params) => {
        const {id, value} = params || {}
        return (
          <select
            name="fee"
            id="fees"
            style={{width: '100%'}}
            defaultValue={value?.id || ''}
            onChange={async (e) => await onFeeSelected(id, e.target.value)}
          >
            <option value="" disabled hidden>
              SET
            </option>
            {fees.map((fee, index) => {
              return (
                <option key={index} value={fee?.id}>
                  {`${fee.name} - ${fee.value}%`}
                </option>
              )
            })}
          </select>
        )
      },
    },
  ]

  const onSearch = (searchKey) => setQueries({search: searchKey})

  return (
    <DetailView.Panel style={{height: '95%'}}>
      <CopytCard className="full-height enterprise">
        <CopytCard.Header>
          <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
            <CopytCard.Text tag="div">Applications</CopytCard.Text>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <CopytTooltip
                title={
                  <Box>
                    <Typography sx={{fontSize: '12px', color: '#fff'}}>
                      Use this table to manage submitted applications from interested consignors.
                      Click&nbsp;
                      <Typography component="span" sx={{fontWeight: 'fontWeightBold'}}>
                        Approve
                      </Typography>
                      &nbsp;to allow the consignor to submit batches to your store or click&nbsp;
                      <Typography component="span" sx={{fontWeight: 'fontWeightBold'}}>
                        Deny
                      </Typography>
                      &nbsp;to deny their access to consign with you.
                    </Typography>
                  </Box>
                }
                placement="bottom"
              >
                <InfoOutlinedIcon fontSize="small" sx={{color: 'white'}} />
              </CopytTooltip>
              <ToggleButton />
            </Box>
          </Stack>
        </CopytCard.Header>
        <CopytCard.Body>
          <Box
            sx={{
              height: 1,
              width: 1,
            }}
          >
            {isShopApplicationsError ? (
              <ErrorMessage />
            ) : (
              <DataGrid
                initialState={{
                  sorting: {
                    sortModel: [{field: 'status', sort: 'desc'}],
                  },
                }}
                loading={isShopApplicationsLoading}
                rows={shopApplications}
                columns={columns}
                disableColumnMenu
                disableColumnSelector
                disableDensitySelector
                disableColumnFilter
                getRowClassName={() => 'datagrid-row'}
                components={{Toolbar: QuickSearchToolbar}}
                componentsProps={{
                  toolbar: {
                    quickFilterProps: {debounceMs: 500},
                    onExport: () => refetchExport(),
                  },
                }}
                onFilterModelChange={(s) => {
                  onSearch(s?.quickFilterValues[0])
                }}
                pageSize={10}
                onSortModelChange={(newSort) => {
                  const {field, sort} = newSort[0] || {}
                  if (queries?.hasOwnProperty('search')) delete queries?.search
                  setQueries({
                    sortBy: field,
                    sortDirection: sort,
                  })
                }}
                sx={{
                  '& .MuiDataGrid-main': {
                    bgcolor: 'white',
                  },
                  '& .MuiDataGrid-columnHeaders, & .MuiDataGrid-footer': {
                    bgcolor: 'var(--primary-light) !important',
                  },
                }}
              />
            )}
          </Box>
        </CopytCard.Body>
      </CopytCard>
      {toastType > ToastType.UNDEFINED && <InventoryToast {...{toastType, setToastType}} />}
    </DetailView.Panel>
  )
}

export default EnterprisePlatform
