import React, {useState} from 'react'
import {Box, Typography, Stack, CircularProgress, Chip} from '@mui/material'
import Select from 'react-select'

import {capitalizeText} from 'util/string_utils'
import {getCurrency} from 'util/model/setting'
import {metricSize, weightSize} from 'assets/data/options/dimension_options'
import {FieldGroup} from 'views_v2/lib/snippets'
import mw from '../middleware'
import {CopytButton} from 'views_v2/lib/snippets'
import MdlIncludedItems from './MdlncludedItems'
import CopytModal from 'components/CopytModal'

const MdlShipmentDetails = (p) => {
  const {shipment, isLoading, updateShipment, isUpdatingShipment, setModalType} = p
  const currency = getCurrency()
  const [isOpenIncludedItems, setIsOpenIncludedItems] = useState(false)

  return (
    <Box className="bulk-edit">
      <Box className="modal-title">
        <Box className="modal__heading" mx="0px !important">
          <Stack direction="row" justifyContent="space-between" alignItems="center" pr="20px">
            <Typography variant="h1" textAlign="left">
              <span>Shipping Details</span>{' '}
              <Chip label={capitalizeText(shipment?.type)} color="warning" size="small" />
            </Typography>
          </Stack>
        </Box>
      </Box>

      <Box className="modal-body" maxHeight="90vh" padding="0" mb={2} pt={2}>
        {isLoading ? (
          <Stack justifyContent="center" alignItems="center" my={7}>
            <CircularProgress size={24} />
            <Typography color="var(--gray) !important">Please wait...</Typography>
          </Stack>
        ) : (
          <>
            <Stack px={2} direction="row" justifyContent="space-between" spacing={5}>
              {/* Left */}
              <Stack width="50%" spacing={3}>
                <Stack
                  p={2}
                  sx={{
                    borderRadius: '6px',
                    maxHeight: '350px',
                    border: '2px solid gray',
                  }}
                >
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Stack direction="row" alignItems="center" gap={2}>
                      <Box component="img" width={32} src={shipment?.rate?.provider_image_200} />
                      <Typography fontWeight={600} fontSize={18}>
                        {shipment?.rate?.servicelevel?.name}
                      </Typography>
                    </Stack>
                    <Stack>
                      <Typography fontWeight={600} color="black">
                        {currency.format(Number(shipment.price))}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>

                <Stack>
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography fontSize={16}>Tracking Status:</Typography>
                    <Typography fontSize={16} fontWeight={600}>
                      {shipment?.status}
                    </Typography>
                  </Stack>
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography fontSize={16}>Tracking Number:</Typography>
                    <Typography fontSize={16}>
                      {' '}
                      <a href={shipment?.url} target="_blank">
                        {shipment?.number}
                      </a>
                    </Typography>
                  </Stack>
                </Stack>

                <Stack>
                  <Stack direction="row" gap={1} alignItems="end">
                    <FieldGroup
                      readOnly
                      id="numeric"
                      fixedDecimalScale={false}
                      decimalScale={4}
                      sLabel="Length"
                      defaultValue={parseFloat(shipment?.parcel?.length)}
                    />
                    <Typography style={{marginBottom: '15px', marginX: '2px'}}>×</Typography>
                    <FieldGroup
                      readOnly
                      id="numeric"
                      fixedDecimalScale={false}
                      decimalScale={4}
                      sLabel="Width"
                      defaultValue={parseFloat(shipment?.parcel?.width)}
                    />
                    <Typography style={{marginBottom: '15px'}}>×</Typography>
                    <FieldGroup
                      readOnly
                      id="numeric"
                      fixedDecimalScale={false}
                      decimalScale={4}
                      sLabel="Height"
                      defaultValue={parseFloat(shipment?.parcel?.height)}
                    />
                    <Box width="100%" marginBottom="10px">
                      <Select
                        className="react-select info"
                        classNamePrefix="react-select"
                        closeMenuOnSelect
                        isDisabled
                        value={{
                          value: shipment?.parcel?.distance_unit,
                          label: shipment?.parcel?.distance_unit,
                        }}
                        styles={{
                          control: (base) => ({
                            ...base,
                            borderColor: 'var(--lighter) !important',
                          }),
                        }}
                      />
                    </Box>
                  </Stack>
                </Stack>
                <Stack width="50%">
                  <Stack direction="row" gap={1} alignItems="end">
                    <FieldGroup
                      readOnly
                      id="numeric"
                      fixedDecimalScale={false}
                      decimalScale={4}
                      sLabel="Weight"
                      defaultValue={parseFloat(shipment?.parcel?.weight)}
                    />
                    <Box width="100%" marginBottom="10px">
                      <Select
                        className="react-select info"
                        classNamePrefix="react-select"
                        closeMenuOnSelect
                        isDisabled
                        value={{
                          value: shipment?.parcel?.mass_unit,
                          label: shipment?.parcel?.mass_unit,
                        }}
                        styles={{
                          control: (base) => ({
                            ...base,
                            borderColor: 'var(--lighter) !important',
                          }),
                        }}
                        options={weightSize}
                      />
                    </Box>
                  </Stack>
                </Stack>
              </Stack>

              {/* Right */}
              <Stack width="50%" spacing={3}>
                <Stack
                  direction="row"
                  p={1}
                  borderRadius="6px"
                  border="1px solid var(--lighter)"
                  alignItems="flex-start"
                  bgcolor="var(--lighter)"
                  spacing={2}
                  justifyContent="space-between"
                  minHeight="90px"
                >
                  <Stack direction="column">
                    <Stack direction="column">
                      <Typography fontSize="1rem" fontWeight={600} lineHeight="20px" marginBottom={1}>
                        Sender
                      </Typography>
                      <Typography fontSize="0.85rem" fontWeight={500} lineHeight="18px">
                        {shipment?.senderAddress.name}
                      </Typography>
                      <Typography fontSize="0.85rem" lineHeight="16px" fontWeight={500}>
                        {mw.formatAddress(shipment?.senderAddress)}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
                <Stack
                  direction="row"
                  p={1}
                  borderRadius="6px"
                  border="1px solid var(--lighter)"
                  alignItems="flex-start"
                  bgcolor="var(--lighter)"
                  spacing={2}
                  justifyContent="space-between"
                  minHeight="90px"
                >
                  <Stack direction="column">
                    <Typography fontSize="1rem" fontWeight={600} lineHeight="20px" marginBottom={1}>
                      Recipient
                    </Typography>
                    <Typography fontSize="0.85rem" fontWeight={500} lineHeight="18px">
                      {shipment?.recipientAddress.name}
                    </Typography>
                    <Typography fontSize="0.85rem" lineHeight="16px" fontWeight={500}>
                      {mw.formatAddress(shipment?.recipientAddress)}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </>
        )}

        <Stack px={2} pt={1.5} mt={4} borderTop="1px solid var(--lighter)">
          <Stack direction="row" justifyContent="end" spacing={2}>
            <CopytButton
              color="primary"
              sx={{
                marginRight: 'auto',
                borderRadius: '6px !important',
                height: '35px',
                textAlign: 'left',
              }}
              onClick={() => setIsOpenIncludedItems(true)}
            >
              {shipment?.products?.length || 0}{' '}
              {shipment?.products?.length > 1 ? 'Items Included' : 'Item Included'}
            </CopytButton>

            <CopytButton
              variant="contained"
              color="primary"
              sx={{
                width: '160px',
                borderRadius: '6px !important',
                height: '35px',
              }}
              onClick={() => {
                window.open(shipment?.label, '_blank')
              }}
            >
              Download Label
            </CopytButton>
          </Stack>
        </Stack>
      </Box>

      <CopytModal
        className="no-padding"
        id="create-box-preset"
        show={isOpenIncludedItems}
        size="xl"
        toggle={() => null}
        closeButton={{
          hidden: true,
        }}
      >
        <MdlIncludedItems
          shipment={shipment}
          onCloseModal={() => setIsOpenIncludedItems(false)}
          updateShipment={updateShipment}
          isUpdatingShipment={isUpdatingShipment}
        />
      </CopytModal>
    </Box>
  )
}

export default MdlShipmentDetails
