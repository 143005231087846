import React from 'react'
import clx from 'classnames'

const CardBody = ({ children, className, style }) => (
  <div className={clx('card-body', className)} style={style}>
    {children}
  </div>
)

export default CardBody
