import React from 'react'
import {Box, Typography, Stack} from '@mui/material'
import Select from 'react-select'

import {ModalType} from 'enums'
import {_replaceWildCards} from 'util/string_utils'
import {CopytLabel, CopytStyledButton} from 'views_v2/lib/snippets'

const MdlBulkEdit = ({bulkEdit, setBulkEdit, setModalType}) => {
  return (
    <Box padding="0 !important">
      <Box className="modal-title">
        <Box className="modal__heading" mx="0px !important">
          <Typography variant="h1" textAlign="left">
            Bulk Edit
          </Typography>
        </Box>
      </Box>

      <Box className="modal-body" padding={0} my={2}>
        <Box px={2}>
          <CopytLabel className="half-width">Choose the field to edit</CopytLabel>
          <Select
            className="react-select info"
            classNamePrefix="react-select"
            onChange={(e) => {
              setBulkEdit(e)
            }}
            options={[
              {value: 'buyPrice', label: 'Buy Price'},
              {value: 'desiredReturn', label: 'List Price'},
              {value: 'feeStructure', label: 'Fee Structure'},
              {value: 'estimatedPayout', label: 'Estimated Payout'},
              {value: 'size', label: 'Size'},
              {value: 'condition', label: 'Condition'},
              {value: 'location', label: 'Location'},
              {value: 'subLocation', label: 'Sub Location'},
            ]}
            value={bulkEdit}
            tabIndex="12"
          />
          {bulkEdit?.value === 'buyPrice' && (
            <CopytLabel sx={{marginTop: 2, color: 'red !important'}}>
              Note: You can't change the PRICE of a consign item.
            </CopytLabel>
          )}
          {(bulkEdit?.value === 'estimatedPayout' || bulkEdit?.value === 'feeStructure') && (
            <CopytLabel sx={{marginTop: 2, color: 'red !important'}}>
              {`Note: You can't change the ${
                bulkEdit?.value === 'estimatedPayout' ? 'ESTIMATED PAYOUT' : 'FEE STRUCTURE'
              } of a NON-consign item.`}
            </CopytLabel>
          )}
        </Box>

        <Stack
          direction="row"
          justifyContent="flex-end"
          pr={2}
          pt={1}
          mt={5}
          borderTop="1px solid var(--secondary)"
          spacing={2}
        >
          <CopytStyledButton
            variant="contained"
            color="primary"
            onClick={() => setModalType(ModalType.BULK_EDIT_LAYOUT)}
          >
            Next
          </CopytStyledButton>
        </Stack>
      </Box>
    </Box>
  )
}

export default MdlBulkEdit
