import { useQuery, useMutation } from 'react-query'
import { useAuth0 } from '@auth0/auth0-react'
import { apiConfig } from 'config'
import axios from 'axios'
import { useToastMessage } from 'components/index'
import { createRequestOptions } from 'util/network'
import { paths } from 'constant/api'
import { get } from 'lodash'

const getLocation = async (getAccessTokenSilently) => {
  try {
    const token = await getAccessTokenSilently()
    const response = await axios.get(`${paths.location}`, createRequestOptions(token))
    return response.data?.data
  } catch (error) {
    console.log(error)
  }
}

const addNewLocation = async (getAccessTokenSilently, values) => {
  const token = await getAccessTokenSilently()
  const response = await axios.post(`${paths.location}`, values, createRequestOptions(token))
  return response
}

const getPayoutInformation = async (getAccessTokenSilently) => {
  try {
    const token = await getAccessTokenSilently()
    const response = await axios.get(`${paths.payout}`, createRequestOptions(token))
    return get(response.data[0], 'information', '')
  } catch (error) {
    console.log(error)
  }
}

const updatePayoutInformation = async (getAccessTokenSilently, payoutInfo) => {
  const token = await getAccessTokenSilently()
  const response = await axios.post(
    `${paths.payout}`,
    { information: payoutInfo },
    createRequestOptions(token),
  )
  return response
}

export const useStoreSettings = () => {
  const { getAccessTokenSilently } = useAuth0()
  const { showToast } = useToastMessage()

  const {
    isLoading: isLoadingLocation,
    isError: isErrorLocation,
    isFetched: locationFetched,
    refetch: refetchLocation,
    data: locations = {},
    error: errorLocation,
  } = useQuery('location', () => getLocation(getAccessTokenSilently))

  const {
    isLoading: isLoadingPayoutInfo,
    isError: isErrorPayoutInfo,
    isFetched: payoutInfoFetched,
    refetch: refetchPayoutInfo,
    data: payoutInfo = {},
    error: errorPayoutInfo,
  } = useQuery('payoutInfo', () => getPayoutInformation(getAccessTokenSilently))

  const addLocation = useMutation((values) => addNewLocation(getAccessTokenSilently, values), {
    onSuccess: async (data, variables, context) => {
      showToast({ variant: 'success', title: 'Success', body: 'New location added' })
      refetchLocation()
    },
    onError: async (error) => {
      showToast({ variant: 'danger', title: 'Error', body: '' })
      refetchLocation()
    },
  })

  const updatePayoutInfo = useMutation(
    (values) => updatePayoutInformation(getAccessTokenSilently, values),
    {
      onSuccess: async (data, variables, context) => {
        showToast({ variant: 'success', title: 'Success', body: 'Payout info updated' })
        refetchPayoutInfo()
      },
      onError: async (error) => {
        showToast({ variant: 'danger', title: 'Error', body: '' })
        refetchPayoutInfo()
      },
      onMutate: async (data, variables, context) => {
        refetchPayoutInfo()
      },
    },
  )

  const deleteLocation = useMutation(
    async (id) => {
      const token = await getAccessTokenSilently()
      return await axios.delete(`${paths.location}/${id}`, createRequestOptions(token))
    },
    {
      onSuccess: async (data, variables, context) => {
        showToast({ variant: 'success', title: 'Success', body: 'Location removed' })
        refetchLocation()
      },
    },
    {
      onMutate: async (data, variables, context) => {
        refetchLocation()
      },
    },
    {
      onError: async (data, variables, context) => {
        showToast({ variant: 'danger', title: 'Error', body: '' })
        refetchLocation()
      },
    },
  )

  const updateLocation = useMutation(
    async (updatedLocation) => {
      const token = await getAccessTokenSilently()
      return await axios.patch(
        `${paths.location}/${updatedLocation?.id}`,
        updatedLocation,
        createRequestOptions(token),
      )
    },
    {
      onSuccess: async (data, variables, context) => {
        showToast({ variant: 'success', title: 'Success', body: 'Location updated' })
        refetchLocation()
      },
    },
    {
      onMutate: async (data, variables, context) => {
        refetchLocation()
      },
    },
    {
      onError: async (data, variables, context) => {
        showToast({ variant: 'danger', title: 'Error', body: '' })
        refetchLocation()
      },
    },
  )

  return {
    isLoadingLocation,
    isErrorLocation,
    locationFetched,
    refetchLocation,
    errorLocation,
    locations: locations || {},
    addLocation: addLocation.mutate,
    updateLocation: updateLocation.mutate,
    deleteLocation: deleteLocation.mutate,
    // payout info
    isLoadingPayoutInfo,
    isErrorPayoutInfo,
    payoutInfoFetched,
    refetchPayoutInfo,
    errorPayoutInfo,
    payoutInfo: payoutInfo,
    updatePayoutInfo: updatePayoutInfo.mutate,
  }
}

export default useStoreSettings
