import {Box, Stack, Typography} from '@mui/material'
import ReceiptIcon from '@mui/icons-material/Receipt'
import PriceCheckIcon from '@mui/icons-material/PriceCheck'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import SouthWestIcon from '@mui/icons-material/SouthWest'

import {CopytTooltip} from 'views_v2/lib/snippets'
import {COLORS} from '../constants'

const Summary = ({currency, inventory, sales}) => {
  const cost = {
    sales: sales?.data?.total.cost || 0,
    unlisted: inventory.data?.unlisted.unfiltered.total.cost || 0,
    listed: inventory.data?.listed.unfiltered.total.cost || 0,
  }

  const records = [
    {
      title: 'Total Sales',
      value: sales?.data?.count || 0,
      Icon: ReceiptIcon,
      tooltip: 'The number of sales you have made in this timeframe.',
    },
    {
      title: 'Total Revenue',
      value: currency.format(sales?.data?.total?.revenue || 0),
      Icon: PriceCheckIcon,
      tooltip: 'The sum of the Actual Returns of all items sold in this timeframe.',
    },
    {
      title: 'Total Cost',
      value:
        !sales.isLoading && !inventory.isLoading
          ? currency.format(Object.values(cost).reduce((a, b) => a + b, 0))
          : currency.format(0),
      Icon: SouthWestIcon,
      tooltip: 'The sum of the Buy Prices of all items that were created in that timeframe.',
    },
    {
      title: 'Gross Profit',
      value: currency.format(sales?.data?.total.grossProfit || 0),
      Icon: AttachMoneyIcon,
      tooltip: 'The sum of the Profits of all items that sold in this timeframe.',
    },
  ]

  return (
    <Box>
      <Stack direction="row" gap={2} justifyContent="space-between">
        {records?.map(({Icon, ...record}, index) => (
          <CopytTooltip key={index} title={record.tooltip} placement="bottom">
            <Box paddingX={2} paddingY={1} borderRadius={2} width="100%" bgcolor={COLORS.SECONDARY}>
              <Box>
                <Stack direction="row" gap={0.5} alignItems="center">
                  <Icon color="white" sx={{height: '100%'}} />
                  <Typography whiteSpace="nowrap" fontSize={14} color="white">
                    {record.title}
                  </Typography>
                </Stack>
                <Typography fontSize={18} color="white" fontWeight={600}>
                  {record.value}
                </Typography>
              </Box>
            </Box>
          </CopytTooltip>
        ))}
      </Stack>
    </Box>
  )
}

export default Summary
