import React from 'react'
import { FieldGroup } from 'views_v2/lib/snippets'
import CopytCard from 'components/CopytCard'
import onKeyPress from 'views_v2/lib/common/onKeyPress'
import { Grid } from '@mui/material'

const CompanyInformation = (props) => {
	const {
		businessName,
		company,
		formErrors,
		handleInputChange,
		handleCompanyChange
	} = props

	// 	const handleChange = () => {
	// 		const { name, value } = e.target;
	//     handleInputChange((prevState) => ({
	// 			...prevState,
	// 			company: {
	// 				...company,
	// 			[name]: value
	// 	}
	// }))
	// 	}

	return (
		<CopytCard className="copyt-card">
			<CopytCard.Header>
				<CopytCard.Text tag="div">
					Company Information
				</CopytCard.Text>
			</CopytCard.Header>
			<CopytCard.Body>
				<FieldGroup
					sLabel="Company Name"
					maxLength={20}
					placeholder="Enter company name"
					name="businessName"
					value={businessName || ''}
					onChange={handleInputChange}
				/>
				<FieldGroup
					sLabel="Industry"
					placeholder="Enter industry"
					maxLength={15}
					name="industry"
					value={company?.industry || ''}
					onChange={handleCompanyChange}
				/>
				<FieldGroup
					sLabel="Address"
					placeholder="Enter address"
					maxLength={20}
					name="address"
					value={company?.address || ''}
					onChange={handleCompanyChange}
				/>
				<Grid container spacing={1}>
					<Grid item xs={12} lg={5}>
						<FieldGroup
							sLabel="City"
							placeholder="Enter city"
							maxLength={20}
							name="city"
							value={company?.city || ''}
							onKeyPress={(e) => onKeyPress(e, 'text')}
							onChange={handleCompanyChange}
						/>
					</Grid>
					<Grid item xs={12} lg={4}>
						<FieldGroup
							sLabel="State"
							placeholder="State"
							maxLength={10}
							name="state"
							value={company?.state || ''}
							onKeyPress={(e) => onKeyPress(e, 'text')}
							onChange={handleCompanyChange}
						/>
					</Grid>
					<Grid item xs={12} lg={3}>
						<FieldGroup
							sLabel="ZIP Code"
							placeholder="Zip"
							maxLength={10}
							name="zipcode"
							value={company?.zipcode || ''}
							onChange={handleCompanyChange}
						/>
					</Grid>
				</Grid>

				<FieldGroup
					sLabel="Website"
					placeholder="Enter website"
					maxLength={20}
					name="website"
					hasError={!!formErrors?.website}
					errorMessage={formErrors?.website}
					value={company?.website || ''}
					onChange={handleCompanyChange}
				/>
			</CopytCard.Body>
		</CopytCard>
	)
}

export default CompanyInformation
