import React, {memo} from 'react'
import {Box, Grid} from '@mui/material'
import DetailView from 'components/DetailView'
import CopytCard from 'components/CopytCard'
import {VendingStyle, SmartPricing, ProductTemplate, DescriptionTemplate} from './cards'

const Listing = (p) => {
  return (
    <DetailView.Panel isHidden={!p.visible}>
      <Box display="flex" justifyContent="space-around">
        <CopytCard className="account-big-card card-layout">
          <Grid container gap={1} justifyContent="space-between">
            <Grid item xs={12} lg={5.9}>
              <SmartPricing />
              <ProductTemplate />
            </Grid>
            <Grid item xs={12} lg={6}>
              {/* TODO: fully remove this in the future */}
              {/* <VendingStyle /> */}
              <DescriptionTemplate />
            </Grid>
          </Grid>
        </CopytCard>
      </Box>
    </DetailView.Panel>
  )
}

export default memo(Listing)
