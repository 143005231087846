import React from 'react'
import clx from 'classnames'

const CardLink = ({ children, className, style, onSelect }) => (
  <div
    className={clx('card-link', className)}
    style={style}
    onClick={() => onSelect()}
  >
    {children}
  </div>
)

export default CardLink
