import {get} from 'lodash'

export default function getPayout(product, consignMinFee, isEnterprise = false) {
  const cFee = Number(consignMinFee || 0)
  const price = Number(product.desiredReturn || 0)
  if (isEnterprise) {
    const sfee = Number(product.feeStructure) * 0.01 * price
    const fee = cFee > sfee ? cFee : sfee
    return price - fee
  } else {
    const sfee = Number(product.feeStructure)
    const fee = cFee > sfee ? cFee : sfee
    return price - (fee / 100) * price
  }
}
