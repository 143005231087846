import React from 'react'
import {useGlobalStore} from 'provider/global_store/hook'
import {ReactComponent as EmptyState} from '../../../../assets/img/mobile-inventory-empty.svg'
import {ReactComponent as ListingEmptyState} from '../../../../assets/img/mobile-listing-empty.svg'
import { AddBtn } from './headers'

const EmptyInventory = (p) => {
  const {isEnterprise} = useGlobalStore()
  const {type, handleChange, tabSelection, routeChange, markAsProcessed, isEmpty, disableBtn} = p

  switch (type) {
    case 'listing':
      return (
        <div className="empty-state-card">
          <ListingEmptyState className="empty-state-svg" />
          <div className="empty-state-text">
            <p className="empty-state-header">
              Hmm...looks like you don't have any{' '}
              {tabSelection == 'sold' || 'processed' ? tabSelection + ' ' : ''}
              listings yet.
            </p>
            <p className="empty-state-body">
              You can create listings from items in your{' '}
              <a href="#" onClick={() => handleChange(null,0)}>
                inventory
              </a>
              .
            </p>
          </div>
        </div>
      )
    case 'unlisted': 
      return (
        <div className="empty-state-card">
          <EmptyState className="empty-state-svg" />
          <div className="empty-state-text">
            <p className="empty-state-header">We could not find any items matching your criteria</p>
            <p className="empty-state-body">
              Select the “Add Item” button below to start filling your inventory with amazing shoes.
            </p>
          </div>
          <div>
            {tabSelection == 'sold' && !isEmpty ? (
              <button className={'btn-secondary process-all-btn'} onClick={markAsProcessed}>
                PROCESS ALL
              </button>
            ) : <AddBtn {...{routeChange}} />}
          </div>
        </div>
      )
    default:
      return (
        <div className="empty-state-card">
          <EmptyState className="empty-state-svg" />
          <div className="empty-state-text">
            <p className="empty-state-header">No Results Found</p>
            <p className="empty-state-body">
              Try changing your text search
            </p>
          </div>
        </div>
      )
  }
}

export default EmptyInventory
