import axios from 'axios'
import {useAuth0} from '@auth0/auth0-react'
import {paths} from 'constant/api'
import {useCallback} from 'react'
import {createRequestOptions} from 'util/network'
import {fn} from 'variables/empty'
import * as Sentry from '@sentry/react'

export function useGetSneak() {
  const {getAccessTokenSilently} = useAuth0()
  const getSneak = useCallback(
    async (args, onSuccess = fn, onFailure = fn) => {
      try {
        const token = await getAccessTokenSilently()
        const response = await axios.get(`${paths.sneaks}/${args.sku}`, createRequestOptions(token))
        onSuccess(response.data.data)
        return response.data.data
      } catch (e) {
        Sentry.captureException(e)
        onFailure(e.message)
      }
    },
    [getAccessTokenSilently],
  )
  return getSneak
}
