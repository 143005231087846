const categorySizes = {
  us: [
    '3.5M/5W',
    '4M/5.5W',
    '4.5M/6W',
    '5M/6.5W',
    '5.5M/7W',
    '6M/7.5W',
    '6.5M/8W',
    '7M/8.5W',
    '7.5M/9W',
    '8M/9.5W',
    '8.5M/10W',
    '9M/10.5W',
    '9.5M/11W',
    '10M/11.5W',
    '10.5M/12W',
    '11M/12.5W',
    '11.5M/13W',
    '12M/13.5W',
    '12.5M/14W',
    '13M/14.5W',
    '13.5M/15W',
    '14M/15.5W',
    '14.5M/16W',
    '15M/16.5W',
    '16M',
    '17M',
    '18M',
  ],
  uk: [
    'UK3',
    'UK3.5',
    'UK4',
    'UK4.5',
    'UK5',
    'UK5.5',
    'UK6',
    'UK6.5',
    'UK7',
    'UK7.5',
    'UK8',
    'UK8.5',
    'UK9',
    'UK9.5',
    'UK10',
    'UK10.5',
    'UK11',
    'UK11.5',
    'UK12',
    'UK13',
    'UK14',
    'UK15',
    'UK16',
    'UK17',
  ],
  jp: [],
  kr: [],
  eu: [
    'EU35.5',
    'EU36',
    'EU36.5',
    'EU37',
    'EU37.5',
    'EU38',
    'EU38.5',
    'EU39',
    'EU40',
    'EU40.5',
    'EU41',
    'EU42',
    'EU42.5',
    'EU43',
    'EU44',
    'EU44.5',
    'EU45',
    'EU45.5',
    'EU46',
    'EU47',
    'EU47.5',
    'EU48.5',
    'EU49.5',
    'EU50.5',
    'EU51.5',
    'EU52.5',
  ],
  apparel: ['OS', 'XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL'],
  toddler: [
    '2C',
    '3C',
    '4C',
    '5C',
    '6C',
    '7C',
    '8C',
    '9C',
    '10C',
    '10.5C',
    '11C',
    '11.5C',
    '12C',
    '12.5C',
    '13C',
    '13.5C',
    '1Y',
    '1.5Y',
    '2Y',
    '2.5Y',
    '3Y',
  ],
  kid: ['3.5Y', '4Y', '4.5Y', '5Y', '5.5Y', '6Y', '6.5Y', '7Y']
}

export default categorySizes