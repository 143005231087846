export default {
  UNDEFINED: -1,
  SUCCESS: 0,
  ERROR: 1,
  LOADING: 2,
  CONSIGN_SHOP: 3,
  DELETE_ALL_SUCCESS: 4,
  DELETE_ALL_FAILURE: 5,
  DELETE_ALL_ERROR: 6,
  EXPORTED: 7,
}
