import {Accordion, Box, Grid, LinearProgress, Stack} from '@mui/material'
import {CalendarMonth} from '@mui/icons-material'
import Select from 'react-select'
import * as React from 'react'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import {FieldGroup, CopytButton} from 'views_v2/lib/snippets'
import Datetime from 'react-datetime'
import moment from 'moment'

import {useState} from 'react'
import {CopytLabel} from 'views_v2/lib/snippets'
import {COLORS} from '../constants'
import {useLocations} from 'service/hook'
import {CopytSwitch} from 'views_v2/lib/snippets'

const Menu = ({inventory, sales, filter}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const {product, sold, setProduct, setSold} = filter

  const {locations, location, setLocation} = useLocations()

  const handleToggle = (event) => {
    event.stopPropagation()
    setIsExpanded((prev) => !prev)
  }

  return (
    <div>
      <Accordion expanded={isExpanded}>
        <AccordionSummary onClick={(e) => e.stopPropagation()}>
          <Stack paddingX={2} direction="row" gap={2}>
            <CopytButton
              onClick={handleToggle}
              variant="contained"
              color="primary"
              style={{padding: '6px 16px', borderRadius: '4px'}}
            >
              All Filters
            </CopytButton>
            <Stack direction="row" gap={2}>
              <CopytSwitch
                labelEnd="Owned"
                _textEnd={{color: 'black'}}
                onChange={() => {
                  if (!product.isConsigned) {
                    setProduct({isOwned: !product.isOwned, isConsigned: !product.isConsigned})
                    return
                  }
                  setProduct({isOwned: !product.isOwned})
                }}
                checked={product.isOwned}
              />
              <CopytSwitch
                labelEnd="Consigned"
                _textEnd={{color: 'black'}}
                onChange={() => {
                  if (!product.isOwned) {
                    setProduct({isConsigned: !product.isConsigned, isOwned: !product.isOwned})
                    return
                  }
                  setProduct({isConsigned: !product.isConsigned})
                }}
                checked={product.isConsigned}
              />
            </Stack>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item xs={6}>
              <Stack marginRight={2}>
                <FieldGroup
                  sLabel="Product Title"
                  maxLength={20}
                  name="title"
                  value={product.title}
                  onChange={(event) => {
                    setProduct({title: event.target.value})
                  }}
                  placeholder="Enter Product Title"
                />
                <Stack direction="row" gap={2}>
                  <FieldGroup
                    sLabel="Product SKU"
                    maxLength={20}
                    name="sku"
                    value={product.sku}
                    onChange={(event) => {
                      setProduct({sku: event.target.value})
                    }}
                    placeholder="Enter Product SKU"
                  />
                  <Stack width="100%">
                    <CopytLabel>Store Location</CopytLabel>
                    <Select
                      className="react-select info"
                      classNamePrefix="react-select"
                      placeholder="Select Store Location"
                      closeMenuOnSelect
                      onChange={(selectedOption) => {
                        setLocation(selectedOption.value)
                        setProduct({location: selectedOption.value})
                      }}
                      options={locations.map(({value, label}) => ({
                        value,
                        label,
                      }))}
                      value={locations.find(({value}) => value === location) || ''}
                    />
                  </Stack>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack>
                <Stack direction="row" gap={2}>
                  <Box zIndex={10}>
                    <CopytLabel>Sold Start Date:</CopytLabel>
                    <CalendarMonth sx={{position: 'absolute', mt: 1, ml: 0.5}} />
                    <Datetime
                      renderInput={(props, openCalendar) => (
                        <input
                          {...props}
                          onClick={openCalendar}
                          className="form-control format-date-time"
                        />
                      )}
                      value={sold.startDate && moment(sold.startDate).format('MM/DD/YYYY')}
                      onChange={(event) => {
                        setSold({startDate: moment(event).format()})
                      }}
                      closeOnSelect
                    />
                  </Box>
                  <Box zIndex={10}>
                    <CopytLabel>Sold End Date:</CopytLabel>
                    <CalendarMonth sx={{position: 'absolute', mt: 1, ml: 0.5}} />
                    <Datetime
                      renderInput={(props, openCalendar) => (
                        <input
                          {...props}
                          onClick={openCalendar}
                          className="form-control format-date-time"
                        />
                      )}
                      value={sold.endDate && moment(sold.endDate).format('MM/DD/YYYY')}
                      onChange={(event) => {
                        setSold({endDate: moment(event).format()})
                      }}
                      closeOnSelect
                    />
                  </Box>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      {inventory.isFetching || sales.isFetching ? (
        <LinearProgress
          sx={{
            backgroundColor: 'white',
            '& .MuiLinearProgress-bar': {
              backgroundColor: COLORS.PRIMARY,
            },
          }}
        />
      ) : (
        <Stack height="4px"></Stack>
      )}
    </div>
  )
}

export default Menu
