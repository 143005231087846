import React, {useState} from 'react'
import SearchBar from 'material-ui-search-bar'
import {Paper, List, ListItemText, ListItemButton, Box} from '@mui/material'
import {styled} from '@mui/material/styles'
import {isEmpty} from 'lodash'

const StyledSearchBar = styled(SearchBar)({
  '& .MuiButtonBase-root.MuiIconButton-root.ForwardRef-iconButton-4': {
    background: 'transparent',
    outline: 'none',
    '&:hover, &:focus': {
      background: 'transparent',
      outline: 'none',
    },
  },
})

const SearchView = ({suggestions = [], onCancel, onSearch, style, minChar = 3, ...props}) => {
  const [searchValue, setSearchValue] = useState('')
  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(false)
  const [timer, setTimer] = useState(null)

  const handleSearchChange = (value) => {
    setSearchValue(value)
    if (value) {
      setOptions(suggestions.filter((item) => item.text.toLowerCase().includes(value.toLowerCase())))
    } else {
      setOptions([])
    }
    setLoading(false)
  }

  const handleSearch = (value) => {
    setLoading(true)
    if (timer) clearTimeout(timer)

    setTimer(
      setTimeout(() => {
        handleSearchChange(value)
      }, 500),
    )
  }

  const handleSuggestionClick = (suggestion) => {
    setSearchValue(suggestion?.text)
    setOptions([])
  }

  const highlightMatch = (text, highlight) => {
    if (!text || !highlight) return text
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'))
    return (
      <span>
        {parts.map((part, index) =>
          part.toLowerCase() === highlight.toLowerCase() ? (
            <Box component="span" fontWeight="fontWeightBold" key={index}>
              {part}
            </Box>
          ) : (
            part
          ),
        )}
      </span>
    )
  }

  const MessageItem = ({hidden = false, message}) =>
    hidden ? null : (
      <List sx={{pl: 2, color: 'var(--default)', lineHeight: '16px'}} className="pre-loader">
        {message || 'Searching...'}
      </List>
    )

  return (
    <React.Fragment>
      <StyledSearchBar
        {...props}
        value={searchValue}
        onChange={(newValue) => handleSearch(newValue)}
        onCancelSearch={handleSearchChange}
        onRequestSearch={() => console.log(`Searching for ${searchValue}`)}
        style={{
          ...style,
        }}
      />

      {!isEmpty(options) ? (
        <Paper style={{position: 'absolute', zIndex: 1, width: '45%', top: '22%'}}>
          <List>
            {options?.map((option, index) => (
              <ListItemButton
                key={index}
                onClick={() => {
                  handleSuggestionClick(option)
                  onSearch(option?.id || option?.object_id)
                }}
                sx={{width: '100%'}}
              >
                <ListItemText
                  sx={{
                    borderBottom: '1px solid var(--lighter)',
                    paddingX: 4,
                    color: 'var(--default)',
                  }}
                  componentsProps={{
                    typography: {
                      fontSize: '12px',
                    },
                  }}
                  primary={highlightMatch(option.text, searchValue)}
                />
              </ListItemButton>
            ))}
          </List>
        </Paper>
      ) : (
        <Paper style={{position: 'absolute', zIndex: 1, width: '45%', top: '22%'}}>
          <MessageItem
            hidden={
              !searchValue ||
              (!isEmpty(suggestions) && isEmpty(options) && !searchValue) ||
              suggestions?.some((suggest) => suggest?.text === searchValue)
            }
            message={`We couldn't find a match for ${searchValue}. Please try another search.`}
          />
        </Paper>
      )}
    </React.Fragment>
  )
}

export default SearchView
