import React from 'react'
import PropTypes from 'prop-types'
import clx from 'classnames'
import CopytModal from 'components/CopytModal'
import {ModalType} from 'enums'
import {MdlCreateBatch, MdlBatch, MdlDatePicker} from './modal-contents'
import {PaymentType} from 'enums'

const ModalContents = ({children, common, focus, onCloseModal}) => {
  if (focus !== ModalType.UNDEFINED) {
    return React.Children.map(children, (c, i) => {
      if (c.props?.modalType === focus) {
        // Show modal based on the given modalType
        return React.cloneElement(c, {...common, onCloseModal})
      }
      return null
    })
  }
  return null
}

const OrdersModals = (p) => {
  const {modalType, setModalType, setSelectionModel, setPaymentType, modals} = p
  const m = modals[modalType]

  const onClose = () => {
    setModalType(ModalType.UNDEFINED)
    setSelectionModel([])
    setPaymentType(PaymentType.CHECK)
  }

  return (
    <CopytModal
      className={m?.className || null}
      closeButton={m?.closeButton}
      id={m?.id}
      show={modalType > ModalType.UNDEFINED}
      size={m?.size || 'sm'}
      toggle={onClose}
    >
      <ModalContents focus={modalType} common={p} onCloseModal={onClose}>
        <MdlCreateBatch
          modalType={ModalType.CREATE_BATCH}
          onCancel={onClose}
          onSaveChanges={() => setModalType(ModalType.BATCH)}
        />
        <MdlBatch
          modalType={ModalType.BATCH}
          onCancel={onClose}
          onSaveChanges={() => setModalType(ModalType.UNDEFINED)}
        />
        <MdlDatePicker modalType={ModalType.DATE_PICKER} />
      </ModalContents>
    </CopytModal>
  )
}

OrdersModals.propTypes = {
  modals: PropTypes.object,
}

OrdersModals.defaultProps = {
  modals: {
    [ModalType.CREATE_BATCH]: {
      className: clx('no-padding', 'create-batch-modal'),
    },
    [ModalType.BATCH]: {
      className: clx('no-padding'),
      size: 'xs',
      closeButton: {
        hidden: true,
      },
    },
    [ModalType.DATE_PICKER]: {
      className: clx('copyt_modal_container'),
      size: 'xs',
      closeButton: {
        hidden: true,
      },
    },
  },
}

export default OrdersModals
