import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { useHistory, useParams } from 'react-router-dom'
import { DataGrid } from '@mui/x-data-grid'
import DetailView from 'components/DetailView'
import ImportContent from './ImportContent'
import { useItemMutation } from "service/hook/useItemMutation"
import { useImportRetryMutation } from "service/hook/useImportRetryMutation"
import { useImportRetry } from "service/hook/useImportRetry"

export const InventoryImport = () => {
  const { retryImport, retryImportIsLoading, retryImportIsSuccess } = useImportRetryMutation()
  const { uploadCSV, uploadCSVIsSuccess } = useItemMutation()
  const history = useHistory()
  const { id } = useParams()
  const [hasChanges, sethasChanges] = useState(false)
  const [page, setPage] = useState(20)

  const { items, isLoading, isFetching } = useImportRetry(id, page, 20);
  const rowCount = items?.total || 0

  // ToDo: Check the purpose of this
  // This is based on the old implementation
  // Check if this still needed; if not, remove it
  // Start here
  const columns = []
  const datum = []

  items.data.forEach(({ meta, id }) => {
    // Add columns for new meta keys, excluding "Owner" and "assets"
    Object.keys(meta).forEach(key => {
      if (key !== "Owner" && key !== "assets" && !columns.some(obj => obj.field === key)) {
        columns.push({
          field: key,
          flex: 1,
          headerName: key,
          editable: key !== "id" && key !== "error",
        });
      }
    });

    // Create data object with serverId and meta properties, excluding "Owner" and "assets"
    const data = { serverId: id, ...Object.fromEntries(Object.entries(meta).filter(([k]) => k !== "Owner" && k !== "assets")) };
    datum.push(data);
  });


  useEffect(() => {
    if (uploadCSVIsSuccess) {
      history.push('/admin/inventory/viewInventory/unlisted')
    }
  }, [retryImportIsSuccess])

  const onSubmit = async () => {
    const temp = [...data]
    for (const index in temp) {
      const item = data[index]
      await retryImport(item);
    }

    history.push('/admin/inventory/viewInventory/unlisted')
  }

  const handleProcessRowUpdateError = React.useCallback((error) => {
    console.log(error)
  }, []);

  const processRowUpdate = (newRow, oldRow) => {
    sethasChanges(true)
    let temp = [...data]
    for (const index in temp) {
      if (temp[index]?.serverId === newRow.serverId) {
        temp[index] = newRow
        break
      }
    }
    setData(temp)
  };
  // End Here

  return (
    <DetailView.Panel style={{height: "95%"}}>
      <ImportContent
        onUpload={(e) => uploadCSV(e)}
        onSubmit={onSubmit}
        hasChanges={hasChanges}
      />
      {
        // ToDo: Check the purpose and the expected output of this
        columns.length > 0 && datum.length > 0 || isLoading || isFetching ?
          <DetailView.Panel scrollable>
            <Box
              sx={{
                height: '95%',
                width: 1
              }}
            >
              <DataGrid
                rows={datum}
                columns={columns}
                loading={isLoading | retryImportIsLoading | isFetching}
                style={{ width: '200%' }}
                experimentalFeatures={{ newEditingApi: true }}
                disableSelectionOnClick
                disableColumnMenu
                disableColumnSelector
                disableDensitySelector
                disableColumnFilter
                paginationMode="server"
                componentsProps={{
                  toolbar: {
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
                pageSize={datum.length}
                rowsPerPageOptions={[20]}
                pagination
                rowCount={rowCount}
                onPageChange={(newPage) => {
                  setPage(newPage * 20)
                  sethasChanges(false)
                }}
                onProcessRowUpdateError={handleProcessRowUpdateError}
                processRowUpdate={processRowUpdate}
              />
            </Box>
          </DetailView.Panel> : null
      }
    </DetailView.Panel>
  )
}

export default InventoryImport
