import React, {useCallback, useState} from 'react'
import {Redirect, useHistory} from 'react-router-dom'
import {Button} from 'reactstrap'
import {useRedirCallback} from 'hooks/use_redir_callback'
import {usePlatformMarket} from 'service/hook/use_market'

const CloverCallback = (props) => {
  const history = useHistory()
  const [state, setState] = useState({message: '', state: 'loading'})
  const {cloverSetup} = usePlatformMarket()

  const process = useCallback(async () => {
    try {
      const queryParams = new URLSearchParams(`${window.location.search}`)
      const storeLocationId = Array.from(queryParams)
        .filter((param) => param[0].startsWith('store_location_id'))[0][0]
        .split(':')[1]
      const code = queryParams.get('code')
      const merchantId = queryParams.get('merchant_id')
      const employeeId = queryParams.get('employee_id')
      setState((s) => ({...s, state: 'loading'}))
      await cloverSetup({
        code: code,
        refId: merchantId,
        employeeId: employeeId,
        storeLocationId,
      })
      setState((s) => ({...s, message: 'Success', state: 'success'}))
    } catch (e) {
      console.log(e)
      setState((s) => ({...s, state: 'failed', message: e.message}))
    }
  }, [history, cloverSetup, setState])

  useRedirCallback(process)

  // useEffect(() => {
  //   process()
  // }, [process])

  if (state.state === 'loading') {
    return <div className="content">Processing Clover Auth...</div>
  }

  if (state.state === 'failed') {
    return (
      <div className="content">
        Failed.
        <hr />
        <Button onClick={history.goBack}>Back</Button>
      </div>
    )
  }

  if (state.state === 'success') {
    return (
      <div className="content">
        Success.
        <Redirect to="/admin/platforms/bfb51ea9-6d61-46ae-ac86-c5843af5e490" />
      </div>
    )
  }

  return <div className="content">Loading...</div>
}

export default CloverCallback
