import React, {useState} from 'react'
import {
  Box,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'

import {ModalType} from 'enums'
import {CopytLabel, CopytStyledButton} from 'views_v2/lib/snippets'
import {Input as InputField} from 'reactstrap'
import {COLORS} from 'views_v2/modules/Dashboard/constants'
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min'
import {currency_symbols} from 'assets/data'
import {getCurrency} from 'util/model/setting'

const MdlBulkScanInCreateProducts = (p) => {
  const {setModalType, onCloseModal, createdProducts} = p

  const currencySymbol = currency_symbols.find((c) => c.value === localStorage.getItem('currency'))
  const currency = getCurrency(currencySymbol?.value || 'USD')

  const history = useHistory()

  const products = Object.entries(
    Object.groupBy(createdProducts, (product) => {
      return `${product.sku}-${product.size}-${product.subLocation}-${product.condition}-${product.boxCondition}-${product.desiredReturn}-${product.price}-${product.consignorEmail}`
        .trim()
        .split(' ')
        .join('-')
    }),
  ).map(([key, value]) => {
    return {
      key,
      product: value[0],
      quantity: value.length,
    }
  })

  return (
    <Box>
      <Box className="modal-title">
        <Box className="modal__heading" mx="0px !important">
          <Typography variant="h1" textAlign="left">
            Products Created Successfully
          </Typography>
        </Box>
      </Box>

      <Box padding={4}>
        <Typography fontSize={16} color="black">
          Your scanned-in items will be added to your Unlisted tab shortly.
        </Typography>
      </Box>

      <Box paddingX={4} overflow="auto" maxHeight="400px">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map(({product, quantity}) => (
              <TableRow key={product.id}>
                <TableCell>
                  <Stack direction="row" alignItems="center" gap={2}>
                    <Stack
                      width={94}
                      overflow="hidden"
                      borderRadius={2}
                      bgcolor="white"
                      position="relative"
                      flexShrink={0}
                    >
                      {product.assets?.length ? <img src={product.assets[0].asset.url} /> : null}
                    </Stack>
                    <Stack gap={1}>
                      <Typography fontSize={16}>{product.title}</Typography>
                      <Box>
                        <Typography fontSize={14}>
                          <strong>Quantity: </strong>
                          {quantity}
                        </Typography>
                        <Typography fontSize={14}>
                          <strong>UPC: </strong>
                          {product.upc}
                        </Typography>
                        {product.consignorEmail ? (
                          <Typography fontSize={14}>
                            <strong>Owner: </strong>
                            {product.consignorEmail}
                          </Typography>
                        ) : null}
                        <Typography fontSize={14}>
                          <strong>SIZE: </strong>
                          {product.size}
                        </Typography>
                        <Typography fontSize={14}>
                          <strong>Sub Location: </strong>
                          {product.size}
                        </Typography>
                        <Typography fontSize={14}>
                          <strong>List Price: </strong>
                          {currency.format(product.price)}
                        </Typography>
                        <Typography fontSize={14}>
                          <strong>Buy Price: </strong>
                          {currency.format(product.desiredReturn)}
                        </Typography>
                      </Box>
                    </Stack>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>

      <Box className="modal-body" p={0}>
        <Stack
          direction="row"
          justifyContent="flex-end"
          pr={2}
          py={1.5}
          mt={4}
          borderTop="1px solid var(--lighter)"
          spacing={2}
        >
          <CopytStyledButton
            variant="contained"
            color="primary"
            id="id-listall-submit"
            sx={{
              whitespace: 'nowrap',
            }}
            onClick={async () => {
              history.push('/admin/inventory/viewInventory/unlisted?')

              setModalType(ModalType.UNDEFINED)
            }}
          >
            Unlisted{'>'}
          </CopytStyledButton>
        </Stack>
      </Box>
    </Box>
  )
}

export default MdlBulkScanInCreateProducts
