import React from 'react'

import {Card, CardHeader, CardBody, CardTitle, Label, FormGroup, Input} from 'reactstrap'

export function ListingOverView({product}) {
  return (
    <Card style={{height: 'calc(100% - 30px)'}}>
      <CardHeader>
        <CardTitle tag="div">Overview</CardTitle>
      </CardHeader>
      <CardBody>
        <FormGroup>
          <Label className="read-only-label">Brand</Label>
          <Input readOnly value={product?.brand || ''} />
        </FormGroup>

        <FormGroup>
          <Label className="read-only-label">Name</Label>
          <Input readOnly value={product?.title || ''} />
        </FormGroup>

        <FormGroup>
          <Label className="read-only-label">Sku/Style ID</Label>
          <Input readOnly value={product?.sku || ''} />
        </FormGroup>

        <FormGroup>
          <Label className="read-only-label">Category</Label>
          <Input readOnly value={product?.category || ''} />
        </FormGroup>

        <FormGroup>
          <Label className="read-only-label">Subcategory</Label>
          <Input readOnly value={product?.subcategory || ''} />
        </FormGroup>

        <FormGroup>
          <Label className="read-only-label">Color</Label>
          <Input readOnly value={product?.color || ''} />
        </FormGroup>
      </CardBody>
    </Card>
  )
}
