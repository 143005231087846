import {useCallback, useEffect, useState} from 'react'
import {useGlobalStore} from 'provider/global_store/hook'
import {useHistory} from 'react-router-dom'
import * as Sentry from '@sentry/react'

// private
export const usePaywall = function () {
  const history = useHistory()
  const {isEnterprise, hasMop} = useGlobalStore()
  const [isBetaModal, setBetaModal] = useState(false)

  const onCloseBetaModal = useCallback(() => {
    setBetaModal(false)
  }, [setBetaModal])

  // base on old implementation. i dont know if we still need this.
  // todo: tobe remove after verification
  const onSkipBetaModal = useCallback(() => {
    try {
      setBetaModal(false)
    } catch (e) {
      Sentry.captureException(e)
    }
  }, [setBetaModal])

  const onNext = useCallback(() => {
    history.push('/admin/settings')
  }, [history])

  useEffect(() => {
    setBetaModal(!isEnterprise && !hasMop)
  }, [hasMop, isEnterprise])

  return {
    isBetaModal,
    setBetaModal,
    onCloseBetaModal,
    onSkipBetaModal,
    onNext,
  }
}
