import React, {useReducer} from 'react'
import { checkObjIfEmpty } from 'util/string_utils'
import ArrayUtils from 'util/array_utils'
import { DuplicateItem } from '../../snippets'

const MdlDuplicateItem = (p) => {
  const {
    loading,
    onHide,
    onAddSize,
    onSubmit,
    prices,
    formFields,
    setDuplicateSizes,
    duplicateSizes,
  } = p
  const [, forceUpdate] = useReducer((x) => x + 1, 0)
  const entry = ArrayUtils.last(duplicateSizes)
  const disabled = !checkObjIfEmpty(entry)

  const setDeleteItem=(i) => {
    duplicateSizes.splice(i, 1)
    setDuplicateSizes(duplicateSizes)
    forceUpdate()
}

  return (
    <div>
      <div className="modal-title">
        <div className="modal__heading">
          <h1 className=""> Duplicate Item</h1>
        </div>
      </div>
      <div className="subtitle">Please complete all fields marked with * and click on the "Submit" button to duplicate your product.</div>
      <div className="modal-body form-item">
        {duplicateSizes.map((value, index) => (
          <DuplicateItem
            {...{ prices, formFields, setDuplicateSizes, index, setDeleteItem}}
            key={index}
            defaultValue={value}
            showDelete={duplicateSizes?.length > 1}
          />
          )
        )}
      </div>
      <div className="modal-footer">
        <button onClick={onHide} className="btn-secondary" >Cancel</button>
        <button disabled={!disabled} onClick={onAddSize} className="btn-primary btn-small" >Add Size</button>
        <button
          className="btn-primary"
          disabled={!disabled || loading}
          onClick={onSubmit} >{loading ? 'Please Wait' : 'Submit'}</button>
      </div>
    </div>
  )
}

export default MdlDuplicateItem
