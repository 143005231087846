import React, {useState} from 'react'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  CircularProgress,
  Stack,
} from '@mui/material'
import {styled} from '@mui/material/styles'
import SearchBar from 'material-ui-search-bar'

import DetailView from 'components/DetailView'
import {NetworkFilterMenu} from 'assets/data/options/inventory_filter_menu'
import FilterToolbar from 'views_v2/modules/Inventory/snippets/FilterToolbar'
import ConnectDetailRow from './ConnectDetailRow'

export const StyledSearchBar = styled(SearchBar)`
  max-width: 300px;
  height: 40px !important;
`

const ConnectDetailView = (p) => {
  const {data, filter, loading, sIds, setSIds, setQueries, queries, page} = p
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('')

  const onRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    const sortDirection = isAsc ? 'desc' : 'asc'
    const sortBy = property === 'listPriceRange' ? 'price' : property

    setOrder(sortDirection)
    setOrderBy(property)
    if (sortDirection !== order || orderBy !== property) {
      setQueries({
        ...filter,
        ...queries,
        sortBy,
        sortDirection,
      })
    }
  }

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <DetailView.Panel style={{flex: 1, height: '100%'}}>
      <Stack direction="column" height="90%">
        <Stack
          direction="row"
          justifyContent="flex-start"
          spacing={1}
          bgcolor="var(--primary-light)"
          borderBottom="1px solid var(--success)"
          padding={1}
          alignItems="center"
        >
          <StyledSearchBar
            placeholder="Search"
            className="search-item"
            cancelOnEscape
            onCancelSearch={() => setQueries({search: ''})}
            onRequestSearch={(s) => setQueries({search: s})}
          />
          <FilterToolbar
            {...{queries}}
            filterMenu={NetworkFilterMenu.filter((s) => s?.id !== 'quantity')}
            disabled={false}
            onSaveChanges={p?.onSaveChanges}
            onSetQueries={setQueries}
            _style={{
              sx: {
                width: '100px',
                height: '40px',
                color: 'var(--primary) !important',
                fontWeight: 600,
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              },
            }}
          />
        </Stack>

        <TableContainer component={Paper} style={{height: '90%'}}>
          <Table stickyHeader aria-label="collapsible table" className="table-product-connection">
            <TableHead style={{background: 'var(--primary-light)'}}>
              <TableRow className="table-product-connection-row">
                <TableCell width="5%" />
                <TableCell width="6%" />
                {NetworkFilterMenu.map((menu) => {
                  if (!menu.isHide) {
                    return (
                      <TableCell
                        key={menu.id}
                        sortDirection={orderBy === menu.id ? 'asc' : false}
                        disabled={loading}
                        width={menu?.width}
                        sx={{lineHeight: 1}}
                      >
                        <TableSortLabel
                          active={orderBy === menu.id}
                          direction={orderBy === menu.id ? order : 'asc'}
                          onClick={createSortHandler(menu.id)}
                          disabled={loading}
                        >
                          {menu?.alternateTitle || menu?.title}
                        </TableSortLabel>
                      </TableCell>
                    )
                  }
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={8} align="center" style={{height: '55vh'}}>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : data?.data?.length > 0 ? (
                data.data?.map((row, index) => (
                  <ConnectDetailRow key={index} {...{row, sIds, setSIds}} />
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={8} align="center" style={{height: '55vh'}}>
                    No Data Available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {data?.total ? (
          <div
            style={{
              width: '100%',
              height: 60,
              position: 'sticky',
              bottom: 0,
              backgroundColor: 'white',
              alignItems: 'stretch',
            }}
          >
            <TablePagination
              style={{position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, overflow: 'hidden'}}
              rowsPerPageOptions={[100]}
              count={data?.total}
              rowsPerPage={100}
              page={page}
              onPageChange={(e, page) => {
                p?.onPageChange(page)
              }}
              onRowsPerPageChange={() => {}}
            />
          </div>
        ) : null}
      </Stack>
    </DetailView.Panel>
  )
}

export default ConnectDetailView
