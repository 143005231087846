// handle 2x page reload on redir
import {useEffect} from 'react'

export const useRedirCallback = (fn) => {
  useEffect(() => {
    const t = setTimeout(fn, 2000)

    return () => clearTimeout(t)
  }, [fn])
}
