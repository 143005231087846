import React from 'react'
import CopytCard from 'components/CopytCard'
import { FieldGroup } from 'views_v2/lib/snippets'

export const Shipping = ({
  formFields,
  handleInputChange,
  readOnly = false,
  productTemplate
}) => {
  return (
    <CopytCard className="consignor-information">
      <CopytCard.Header>
        <CopytCard.Text tag="div">
          Shipping
        </CopytCard.Text>
      </CopytCard.Header>
      <CopytCard.Body>
        <FieldGroup
          {...{ readOnly }}
          sLabel="Shipping Method"
          onChange={handleInputChange}
          maxLength={5}
          name="shippingMethod"
          placeholder="Enter shipping method"
          value={formFields.shippingMethod || 'UPS'}
          tabIndex="27"
        />
        <FieldGroup
          {...{ readOnly }}
          sLabel="Box Dimensions"
          onChange={handleInputChange}
          maxLength={15}
          name="boxDimensions"
          placeholder="Enter box dimensions"
          value={formFields.boxDimensions || '15 x 10 x 5in'}
          tabIndex="28"
        />
        <FieldGroup
          {...{ readOnly }}
          sLabel="Box Weight (lb)"
          onChange={handleInputChange}
          maxLength={5}
          disabled={productTemplate?.value}
          name="boxWeight"
          placeholder="Enter box weight"
          value={formFields.boxWeight || '4 lb'}
          tabIndex="29"
        />
      </CopytCard.Body>
    </CopytCard>
  )
}

export default Shipping
