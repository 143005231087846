import React from 'react'

const TableBody = ({classNames, children}) => {
  return (
    <div className="table-body">
      {children}
    </div>
  )
}

export default TableBody
