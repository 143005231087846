import React from 'react'
import {ReactComponent as EmptyState} from 'assets/img/mobile-inventory-empty.svg'

const EmptyData = ({title, icon = null, children, titleProps, bodyProps}) => {
  return (
    <div className="empty-state-card" style={{marginBottom: 0, height: '100%'}}>
      {icon || <EmptyState className="empty-state-svg" />}
      <div className="empty-state-text">
        <p className="empty-state-header" style={{...titleProps}}>
          {title}
        </p>
        <p className="empty-state-body" style={{...bodyProps}}>
          {children}{' '}
        </p>
      </div>
    </div>
  )
}

export default EmptyData
