import React from 'react'
import clx from 'classnames'
import {Box, Skeleton, Stack, Typography} from '@mui/material'
import {
  Menu as MenuIcon,
  KeyboardDoubleArrowRight as KeyboardDoubleArrowRightIcon,
} from '@mui/icons-material'
import {isMobile} from 'react-device-detect'
import {makeStyles} from '@material-ui/core/styles'

import copytLogo from 'assets/img/copyt-logo.png'
import cLogo from 'assets/img/copyt-short-logo.png'
import {useGlobalStore} from 'provider/global_store/hook'
import {useScreenSize} from 'util/window_utils'
import {AvatarIcon} from './AccountMenu'

const useStyles = makeStyles({
  title: {
    color: 'var(--success)',
    fontWeight: 500,
    fontSize: '1.2rem',
    marginLeft: '8px',
  },
  titleMobile: {
    width: 150,
    lineHeight: 1.2,
    fontSize: '0.9rem',
    marginLeft: 10,
  },
})

const MainMenu = (p) => {
  const {isEnterprise, user, userLoading} = useGlobalStore()
  const {businessName} = user || ''
  const {handleMiniClick, sidebarOpened} = p
  const screenWidth = useScreenSize()
  const classes = useStyles()
  const isMobileScreen = isMobile || screenWidth < 515

  return (
    <Stack direction="row" alignItems="center">
      <AvatarIcon onClick={handleMiniClick} variant="small">
        {sidebarOpened ? (
          <KeyboardDoubleArrowRightIcon sx={{color: 'var(--success) !important'}} />
        ) : (
          <MenuIcon sx={{color: 'var(--success) !important'}} />
        )}
      </AvatarIcon>
      {userLoading ? (
        <Skeleton variant="circular" width={40} height={40} />
      ) : (
        <Box
          component="img"
          sx={{
            height: isEnterprise ? '40px' : '35px',
          }}
          src={userLoading ? null : isEnterprise ? cLogo : copytLogo}
        />
      )}
      {isEnterprise && (
        <Typography className={clx(classes.title, isMobileScreen && classes.titleMobile)}>
          {`Enterprise - ${businessName}`}
        </Typography>
      )}
    </Stack>
  )
}

export default MainMenu
