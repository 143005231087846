import React, {useState} from 'react'
import {Box, Typography, Stack, Checkbox} from '@mui/material'
import {isEmpty} from 'lodash'

import {_replaceWildCards} from 'util/string_utils'
import {CopytLabel, CopytStyledButton} from 'views_v2/lib/snippets'

const MdlIncludedItems = (props) => {
  const {shipment, onCloseModal, isUpdatingShipment, updateShipment} = props
  const [boxItems, setBoxItems] = useState([])

  const [items] = useState(shipment?.products)

  const handleCheckboxChange = (item) => {
    setBoxItems((prevItems) => {
      const itemIndex = prevItems.findIndex((i) => i.id === item.id)
      if (itemIndex > -1) {
        return prevItems.filter((i) => i.id !== item.id)
      } else {
        return [...prevItems, item]
      }
    })
  }

  return (
    <Box className="bulk-edit">
      <Box className="modal-title">
        <Box className="modal__heading" mx="0px !important">
          <Typography variant="h1" textAlign="left">
            Included Items
          </Typography>
        </Box>
      </Box>
      <Box className="modal-body" maxHeight="85vh" padding="0" mb={2}>
        <Stack direction="column" bgcolor="var(--lighter)">
          <Stack
            direction="row"
            spacing={1}
            justifyContent="space-around"
            borderBottom="1px solid"
            p={1}
            alignItems="center"
          >
            <Checkbox
              checked={!isEmpty(boxItems) && boxItems?.length === items?.length}
              indeterminate={!isEmpty(boxItems) && boxItems?.length !== items?.length}
              onChange={() => (isEmpty(boxItems) ? setBoxItems(items) : setBoxItems([]))}
              color="secondary"
              disabled={isUpdatingShipment}
            />
            <Stack direction="row" width="100%" justifyContent="space-between">
              <CopytLabel width="30%">Item Name</CopytLabel>
              <CopytLabel width="25%">Sku/Style ID</CopytLabel>
              <CopytLabel width="25%">Internal SKU</CopytLabel>
              <CopytLabel width="25%">Location</CopytLabel>
              <CopytLabel width="15%">Size</CopytLabel>
              <CopytLabel width="15%">Price</CopytLabel>
            </Stack>
          </Stack>
        </Stack>

        <Box minHeight="50vh" maxHeight="60vh" overflow="hidden auto">
          {!isEmpty(items) &&
            items?.map(({product}) => {
              return (
                <Stack
                  key={product.id}
                  direction="row"
                  spacing={1}
                  justifyContent="space-between"
                  borderBottom="1px solid var(--light)"
                  alignItems="center"
                  padding={1}
                  sx={{
                    '&:hover': {
                      backgroundColor: 'var(--lighter)',
                    },
                  }}
                >
                  <Checkbox
                    onChange={() => handleCheckboxChange(product)}
                    color="secondary"
                    checked={boxItems.some((s) => s?.id === product?.id)}
                    disabled={isUpdatingShipment}
                  />
                  <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
                    <CopytLabel width="30%">{product?.title}</CopytLabel>
                    <CopytLabel width="25%">{product?.sku}</CopytLabel>
                    <CopytLabel width="25%">{product?.internalSku}</CopytLabel>
                    <CopytLabel width="25%">{product?.location}</CopytLabel>
                    <CopytLabel width="15%">{product?.size}</CopytLabel>
                    <CopytLabel width="15%">{product?.desiredReturn}</CopytLabel>
                  </Stack>
                </Stack>
              )
            })}
        </Box>
        <Stack
          direction="row"
          justifyContent="flex-end"
          pr={2}
          pt={2}
          borderTop="1px solid #eee"
          spacing={2}
        >
          <CopytStyledButton
            variant="outline"
            color="primary"
            onClick={onCloseModal}
            disabled={isUpdatingShipment}
          >
            Close
          </CopytStyledButton>
          <CopytStyledButton
            disabled={isEmpty(boxItems) || isUpdatingShipment}
            variant="contained"
            color="primary"
            onClick={async () => {
              const itemsToRemove = items
                .filter(({product}) => !boxItems.includes(product))
                .map(({product}) => product.id)
              await updateShipment({
                id: shipment.id,
                productIds: itemsToRemove,
              })
              onCloseModal()
            }}
          >
            {isUpdatingShipment ? 'Removing...' : 'Remove'}
          </CopytStyledButton>
        </Stack>
      </Box>
    </Box>
  )
}

export default MdlIncludedItems
