import React, {useState, useEffect} from 'react'
import {Button, Box, CircularProgress} from '@mui/material'
import {isEmpty} from 'lodash'

import {useGlobalStore} from 'provider/global_store/hook'
import {useToastMessage} from 'components'
import CopytCard from 'components/CopytCard'
import CopytModal from 'components/CopytModal'
import {usePlatform} from 'service/hook/use_platform'
import FieldGroup from 'views_v2/lib/snippets/FieldGroup'
import {CopytButton} from 'views_v2/lib/snippets'
import {REGEX_EMAIL_FORMAT} from 'util/Regex'
import {isURL} from 'util/string_utils'
import {MdlPreview} from '../../component/modal-contents'
import {CopytBtn} from './ProductTemplate'

const CustomizePlatform = (p) => {
  const {user} = useGlobalStore()
  const [formData, setFormData] = useState({
    logo: null,
    support_email: null,
    faq_link: null,
    contract_pdf_link: null,
    company_name: '',
  })
  const [selectedImage, setSelectedImage] = useState(null)
  const [imageLoading, setImageLoading] = useState(false)
  const [showPreview, setShowPreview] = useState(false)
  const {updatePlatformCardInfo, updatePlatformCardLoading} = usePlatform()
  const {showToast} = useToastMessage()

  const updateCardInfo = async () => {
    if (!isEmpty(formData.contract_pdf_link) && !formData.contract_pdf_link.endsWith('.pdf')) {
      alert('Invalid contract PDF link. Please provide a link to a .pdf file.')
      return
    } else if (!isEmpty(formData.support_email) && !REGEX_EMAIL_FORMAT.test(formData.support_email)) {
      alert('Please enter a valid email address.')
      return
    } else if (!isEmpty(formData.faq_link) && !isURL(formData.faq_link)) {
      alert('Please enter a valid URL.')
      return
    }

    updatePlatformCardInfo({...formData, file: selectedImage})
  }

  const setImage = (image) => {
    setImageLoading(true)
    setSelectedImage(image)
    setFormData({...formData, logo: URL.createObjectURL(image)})
  }

  useEffect(() => {
    if (formData) {
      formData.faq_link = formData.faq_link ? formData.faq_link : user.platform?.faq_link
      formData.contract_pdf_link = formData.contract_pdf_link
        ? formData.contract_pdf_link
        : user.platform?.contract_pdf_link
      formData.support_email = formData.support_email
        ? formData.support_email
        : user.platform?.support_email
      formData.logo =
        selectedImage !== null ? URL.createObjectURL(selectedImage) : user.platform?.logo_url
      formData.company_name = formData?.company_name ? formData?.company_name : user?.company?.name
    }
  }, [selectedImage])

  const processImageFile = (imageInput) => {
    if (imageInput.target.files[0]) {
      const file = imageInput.target.files[0]
      const pattern = /image-*/

      if (!file.type.match(pattern)) {
        showToast({variant: 'danger', body: 'Invalid file format.'})
        return
      }
      setImage(imageInput.target.files[0])
    }
  }

  const ImagePreview = () => {
    const imgSrc = formData.logo && selectedImage ? formData.logo : user.platform?.logo_url
    const alt = formData.logo && selectedImage ? selectedImage.name : user.platform?.name
    if (isEmpty(imgSrc)) return <></>
    return (
      <Box mt={2} textAlign="center">
        <div style={{marginBottom: 5}}>Image Preview:</div>
        {imageLoading && (
          <CircularProgress
            sx={{
              position: 'absolute',
              left: '46%',
              bottom: '12%',
            }}
            color="secondary"
          />
        )}

        <img
          src={imgSrc}
          alt={alt}
          height="100px"
          style={{
            width: '25%',
            animation: 'fadeIn 1s',
            objectFit: 'contain',
            opacity: imageLoading ? 0.5 : 1,
          }}
          onLoad={(e) => setImageLoading(false)}
        />
      </Box>
    )
  }

  return (
    <CopytCard className="copyt-card">
      <CopytCard.Header
        style={{color: 'var(--success)', display: 'flex', justifyContent: 'space-between'}}
      >
        Customize Platform Card
        <CopytButton variant="outline" color="primary" onClick={() => setShowPreview(true)}>
          Preview
        </CopytButton>
      </CopytCard.Header>
      <CopytCard.Body>
        <FieldGroup
          sLabel="FAQ Link"
          value={formData?.faq_link === 'null' ? '' : formData?.faq_link || ''}
          placeholder="www.CopytEnterpriseFAQS.com"
          onChange={(e) => setFormData({...formData, faq_link: e.target.value})}
        />
        <FieldGroup
          sLabel="Consignment Contract PDF Link"
          value={formData?.contract_pdf_link === 'null' ? '' : formData?.contract_pdf_link || ''}
          placeholder="https://copyt.io/faq.pdf"
          onChange={(e) => setFormData({...formData, contract_pdf_link: e.target.value})}
        />
        <FieldGroup
          sLabel="Support Email"
          value={formData?.support_email === 'null' ? '' : formData?.support_email || ''}
          placeholder="info@copyt.io"
          onChange={(e) => setFormData({...formData, support_email: e.target.value})}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <input
            accept="image/*"
            // accept="image/png, image/gif, image/jpeg"
            type="file"
            id="select-image"
            style={{display: 'none'}}
            onChange={processImageFile}
          />
          <label htmlFor="select-image">
            <Button
              variant="contained"
              color="primary"
              component="span"
              sx={{
                color: 'var(--primary) !important',
                backgroundColor: 'white !important',
                fontWeight: 600,
              }}
            >
              Upload Logo
            </Button>
          </label>
        </Box>
        <ImagePreview />
        <Box gap={1} display="flex" justifyContent="flex-end">
          <CopytBtn
            disabled={updatePlatformCardLoading}
            onClick={() => updateCardInfo()}
            variant="contained"
            color="primary"
          >
            Save
          </CopytBtn>
        </Box>
        <CopytModal
          className="copyt_modal_container no-padding"
          show={showPreview}
          size="xs"
          toggle={() => setShowPreview(!showPreview)}
        >
          <MdlPreview {...formData} />
        </CopytModal>
      </CopytCard.Body>
    </CopytCard>
  )
}

export default CustomizePlatform
