import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import { captureException } from '@sentry/react'
import { styled } from '@mui/material/styles'
import Select from 'react-select'
import { useAuth0 } from '@auth0/auth0-react'
import axios from 'axios'
import { paths } from 'constant/api'
import { createRequestOptions } from 'util/network'
import { usePlatform } from 'service/hook/use_platform'
import useEnterKeyListener from 'hooks/useEnterKeyListener'
import { useGlobalStore } from 'provider/global_store/hook'
import { ModalType, ToastType } from 'enums'
import { FieldGroup, CopytLabel, CopytButton } from 'views_v2/lib/snippets'
import InventoryToast from 'views_v2/modules/Inventory/components/InventoryToast'
import { chain } from 'lodash'

export const TextStyle = styled(Typography)(() => ({
  lineHeight: 1.2,
  fontSize: "0.8rem",
  fontStyle: "italic",
  textAlign: "center",
  marginBottom: 10,
  color: "#5d0953 !important"
}))

const MdlMarkAsSold = (p) => {
  const { getAccessTokenSilently } = useAuth0()
  const { id } = useParams()
  const { formFields, setFormFields, setModalType, routeChange, markAsSold, shouldUsePropFunc = false } = p
  const [toastType, setToastType] = useState(ToastType.UNDEFINED)
  const [isLoading, setIsLoading] = useState(false)
  const [toastContent, setToastContent] = useState('')
  const [soldPlatformOptions, setSoldPlatformOptions] = useState([])
  const { platforms } = usePlatform({ type: 'market', take: 10 })
  const { isEnterprise } = useGlobalStore()

  useEnterKeyListener({ querySelectorToExecuteClick: '#markAsSoldConfirmBtn' })

  useEffect(() => {
    try {
      const allowedPlatforms = chain(platforms)
        .filter(i => i.name === 'Elsewhere' || i.credentials.length > 0)
        .filter(option => option.name !== 'Paypal')
        .filter(option => option.name !== 'Checkbook')
        .sortBy('name')
        .map(i => ({
          label: i.name,
          id: i.id,
          value: i.id,
          hasCred: i.credentials.length > 0,
        }))
        .value();

      setSoldPlatformOptions(allowedPlatforms)
    } catch (e) {
      captureException(e)
    }
  }, [platforms])

  const markAsSoldListed = async () => {
    setIsLoading(true)
    if (formFields?.consignStatus?.toLowerCase() === 'listed' && !isEnterprise) {
      setModalType(ModalType.LISTING_FAILURES)
    } else {
      try {
        const token = await getAccessTokenSilently()
        await axios.post(
          `${paths.sold}/`,
          {
            listingId: id,
            price: Number(formFields.soldPrice),
            platformId: formFields.soldPlatform.value || formFields.soldPlatform,
            shippingFee: Number(formFields.shippingCost) || 0,
            type: 'manual',
            quantity: 1,
            feeStructure: formFields?.feeStructure
          },
          createRequestOptions(token)
        )

        setToastType(ToastType.SUCCESS)

        setTimeout(() => {
          routeChange(`/admin/listings/viewSold/${formFields.listingId}`)
        }, 1000)
      } catch (e) {
        captureException(e)
        let m = 'Item is currently consigned and cannot be mark as sold manually'
        if (e?.response?.data?.message) {
          if (Array.isArray(e.response?.data?.message)) {
            m = e.response?.data?.message.join(', ')
          }
        }
        setToastType(ToastType.ERROR)
        setToastContent(m)
        setTimeout(() => {
          setIsLoading(false)
          p?.onCloseModal()
        }, 1500)
      }
    }
  }

  useEffect(() => {
    const elsewhere = soldPlatformOptions.find((plat) => plat.label === 'Elsewhere')
    setFormFields({
      ...formFields,
      soldPlatform: elsewhere,
    })
  }, [soldPlatformOptions])

  return (
    <Box>
      <Box className="modal-title">
        <Box className="modal__heading">
          <Typography variant="h1">Mark As Sold</Typography>
        </Box>
      </Box>
      <Box className="modal-body" sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
      }}>
        <TextStyle>Your total return and listing duration will be automatically calculated based on the
          information gathered below</TextStyle>
        <Box width="100%">
          <CopytLabel>Platform</CopytLabel>
          <Select
            className="react-select info"
            classNamePrefix="react-select"
            placeholder="Select Platform"
            onChange={(e) =>
              setFormFields({
                ...formFields,
                soldPlatform: e,
              })
            }
            options={soldPlatformOptions}
            value={formFields?.soldPlatform}
          />
        </Box>
        <FieldGroup
          sLabel="Sell Price"
          type="number"
          min="0"
          name="soldPrice"
          className="input-number"
          placeholder="e.g. 1000.00"
          onChange={(e) =>
            setFormFields({
              ...formFields,
              soldPrice: e.target.value,
            })
          }
          value={formFields?.soldPrice}
          autoFocus
        />
        <FieldGroup
          sLabel="Shipping Cost"
          type="number"
          min="0"
          name="shippingCost"
          className="input-number"
          placeholder="e.g. 10.00"
          onChange={(e) =>
            setFormFields({
              ...formFields,
              shippingCost: e.target.value,
            })
          }
          value={formFields?.shippingCost}
        />
        <Box mt={2}>
          <CopytButton
            variant="outline"
            color="primary"
            onClick={() => {
              p?.onCloseModal()
            }}
            sx={{ marginRight: '10px' }}
          >
            Cancel
          </CopytButton>
          <CopytButton
            disabled={isLoading || !formFields?.soldPrice}
            variant="contained"
            color="primary"
            id="markAsSoldConfirmBtn"
            onClick={() => {
              setIsLoading(true)
              shouldUsePropFunc ? markAsSold() : markAsSoldListed()
            }}
          >
            Confirm
          </CopytButton>
        </Box>
      </Box>
      {toastType > ToastType.UNDEFINED && (
        <InventoryToast {...{ toastType, setToastType }} content={toastContent} title={toastContent ? 'Listing failed to mark as sold' : ''} />
      )}
    </Box>
  )
}

export default MdlMarkAsSold
