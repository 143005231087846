import React, {memo} from 'react'
import {ReactComponent as PremiumBullet} from '../../assets/img/premium-bullet-point.svg'
import {usePaywall} from './hook'
import './Paywall.scss'

export const PaywallEnterprise = (p) => {
  const {onNext} = usePaywall()

  return (
    <>
      <div className="white-bubble">
        <div className="premium-modal-title">
          <p>THANKS FOR CREATING AN ACCOUNT. ADD YOUR PAYMENT INFORMATION TO GET STARTED</p>
        </div>
        <div className="premium-benefits">
          <p className="premium-benefits-description">
            You are able to add your payment information under Account Settings.
          </p>
          <div className="premium-benefits-item">
            <PremiumBullet className="premium-icon" />
            <p className="premium-benefits-text">
              You will be charged a $300 per location per month subscription fee billed at the end of the
              month.
            </p>
          </div>
          <div className="premium-benefits-item">
            <PremiumBullet className="premium-icon" />
            <p className="premium-benefits-text">
              Listing and delisting from online marketplace platforms will incur a 1.5% transaction fee
              upon the sale of the item. THIS DOES NOT INCLUDE YOUR ONLINE SHOP AND POS SYSTEM SALES.
            </p>
          </div>
          <div className="premium-benefits-item">
            <PremiumBullet className="premium-icon" />
            <p className="premium-benefits-text">
              Feel free to cancel your subscription anytime before your first payment is due.
            </p>
          </div>
        </div>
      </div>

      <div className="paywall-footer">
        <button className="premium-button" onClick={onNext}>
          GO TO ACCOUNT SETTINGS
        </button>
      </div>
    </>
  )
}

export default memo(PaywallEnterprise)
