import React from 'react'
import { Box } from '@mui/material'
import Select from 'react-select'
import { TextStyle } from '../modal-contents/MdlSupport'

const SelectShoes = (p) => {
  const {shoe, shoeOptions, setShoe} = p || {}

  return (
    <Box sx={{mb: 2}}>
      <TextStyle>Specify Shoe</TextStyle>
        <Select
          className="react-select info"
          classNamePrefix="react-select"
          placeholder="Specify Shoe"
          closeMenuOnSelect
          onChange={(e) =>
            setShoe({
              title: e.value,
              id: e.id,
            })
          }
          options={shoeOptions}
          value={shoe && {value: shoe.title, label: shoe.title, id: shoe.id}}
        />
    </Box>
    
  )
}

export default SelectShoes
