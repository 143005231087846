import React from 'react'

const MdlResetPassword = (p) => {
  const {user, onCloseModal} = p
  return (
    <div>
      <div className="modal-title">
        <div className="fee_settings_modal__heading">
          <h1 className="">Check your email</h1>
        </div>
      </div>
      <div className="modal-body">
        <div className="modal-body">
          We sent a reset password link to
          <br/><strong>{user?.email}</strong>.
          <button className="btn-primary ripple btn-secondary my-4 ml-auto" onClick={onCloseModal}>
            OK
          </button>
        </div>
      </div>
    </div>
  )
}

export default MdlResetPassword
