import moment from 'moment'
import {isDate, isNaN} from 'lodash'

export const isOneDayApart = (date1, date2) => {
  const momentD1 = moment(date1)
  const momentD2 = moment(date2)
  var diff = moment(momentD1).diff(moment(momentD2), 'days')
  return Math.abs(diff) === 1
}

export const isValidDate = (dateString) => {
  const date = new Date(dateString)
  return isDate(date) && !isNaN(date.getTime())
}

export const formatTo = (dateTime, textFormat, utcOffset = null) => {
  if (dateTime) {
    let timestamp = moment(dateTime)

    if (utcOffset !== null) {
      timestamp = timestamp.utcOffset(utcOffset)
    }
    return timestamp.format(textFormat)
  }
  return ''
}

export const now = () => {
  const utcTimestamp = moment.utc()
  return utcTimestamp.toISOString()
}

export const isNegativeOffset = () => {
  const timezoneOffset = moment().format('Z')
  return timezoneOffset.startsWith('-')
}

export const formatDateWithoutTimeZone = (date) => {
  if (moment.isMoment(date)) {
    const date1 = new Date(date)
    const month = (date1.getMonth() + 1).toString().padStart(2, '0')
    const day = date1.getDate().toString().padStart(2, '0')
    const year = date1.getFullYear().toString()

    return `${month}/${day}/${year}`
  } else {
    if (date) {
      const match = date.match(/^(\d{4}-\d{2}-\d{2})/)

      if (match) {
        const datePart = match[1]
        const [year, month, day] = datePart.split('-')

        return `${month}/${day}/${year}`
      }
    }
    return ''
  }
}

export const formatToUTC = (date) => {
  const isoString = moment.utc(date)
  return isoString.toISOString()
}

export const toDate = (text) => {
  const timestamp = moment(text)

  if (timestamp.isValid()) return timestamp.toDate()
  return null
}

export const toDateParam = (dateTime, formatText = 'YYYY-MM-DD') => {
  return moment(dateTime).format(formatText)
}

export const toLongFormat = (dateTime, utcOffset = null) => {
  return formatTo(dateTime, 'l LT', utcOffset)
}

export const toLongDateFormat = (dateTime, utcOffset = null) => {
  return formatTo(dateTime, 'LL', utcOffset)
}

export const toShortDateFormat = (dateTime, utcOffset = null) => {
  return formatTo(dateTime, 'l', utcOffset)
}

export const toShortDate = (dateTime, utcOffset = null) => {
  return formatTo(dateTime, 'L', utcOffset)
}

export const toTimeOnlyFormat = (dateTime, utcOffset = null) => {
  return formatTo(dateTime, 'LT', utcOffset)
}

export const convertDatesToUTC = (startDateStr, endDateStr) => {
  // Parse the start and end dates
  let start = new Date(startDateStr)
  let end = new Date(endDateStr)
  // Set start time to the beginning of the day
  start.setHours(0, 0, 0, 0)
  // Set end time to the end of the day
  end.setHours(23, 59, 59, 999)
  return {
    startDate: start.toISOString(),
    endDate: end.toISOString(),
  }
}

export const formatToLocalDate = (date) => {
  const utcDate = moment.utc(date)

  if (date) {
    const localDate = utcDate.local()
    return localDate.format('MM/DD/YYYY')
  }
  return ''
}

const calculateDateDifference = (listings, isToday, key) => {
  const today = isToday ? moment(isToday).startOf('day') : moment()

  const earliestDate = listings?.reduce((earliest, listing) => {
    const currentCreatedAt = key ? listing[key]?.[0]?.createdAt : listing.createdAt
    return !earliest || currentCreatedAt < earliest ? currentCreatedAt : earliest
  }, null)

  const formattedEarliestDate = formatToLocalDate(earliestDate) || today

  const firstDate = key ? moment(formattedEarliestDate) : today
  const secondDate = key ? today : moment(formattedEarliestDate)

  return {
    today,
    raw: formattedEarliestDate,
    hours: firstDate.diff(secondDate, 'hours'),
    days: firstDate.diff(secondDate, 'days'),
    isToOld: firstDate.diff(secondDate, 'hours') > 8,
  }
}

export default {
  calculateDateDifference,
  convertDatesToUTC,
  formatTo,
  formatToUTC,
  formatToLocalDate,
  formatDateWithoutTimeZone,
  isOneDayApart,
  isNegativeOffset,
  isValidDate,
  now,
  toDate,
  toDateParam,
  toLongFormat,
  toLongDateFormat,
  toShortDateFormat,
  toShortDate,
  toTimeOnlyFormat,
}
