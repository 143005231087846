export const formatAddress = (shipment = {}) => {
  const {street1 = '', street2 = '', city = '', state = '', country = ''} = shipment

  if (street2) {
    return `${street1} ${street2} ${city} ${state}, ${country}`
  }

  return `${street1} ${city} ${state}, ${country}`
}

export default formatAddress
