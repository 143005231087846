import React from 'react';
import PropTypes from 'prop-types';
import clx from 'classnames';
import CopytModal from 'components/CopytModal';
import {ModalType} from 'enums';
import {MdlSendConnectionRequest, MdlDiscordName, MdlBatchItems} from './modal-contents';

const ModalContents = ({children, common, focus, onCloseModal}) => {
  if (focus !== ModalType.UNDEFINED) {
    return React.Children.map(children, (c, i) => {
      if (c.props?.modalType === focus) {
        // Show modal based on the given modalType
        return React.cloneElement(c, {...common, onCloseModal});
      }
      return null;
    });
  }
  return null;
};

const ConnectModals = p => {
  const {modalType, setModalType, modals} = p || {};
  const m = modals[modalType];

  const onClose = () => {
    setModalType(ModalType.UNDEFINED);
  };

  return (
    <CopytModal
      className={m?.className || null}
      closeButton={m?.closeButton}
      id={m?.id}
      show={modalType > ModalType.UNDEFINED}
      size={m?.size || 'sm'}
      toggle={onClose}
    >
      <ModalContents focus={modalType} common={p} onCloseModal={onClose}>
        <MdlSendConnectionRequest modalType={ModalType.SEND_CONNECTION_REQUEST} />
        <MdlDiscordName modalType={ModalType.EDIT_DISCORD_NAME} />
        <MdlBatchItems modalType={ModalType.CONNECT_BATCH_ITEMS} />
      </ModalContents>
    </CopytModal>
  );
};

ConnectModals.propTypes = {
  modals: PropTypes.object,
};

ConnectModals.defaultProps = {
  modals: {
    [ModalType.SEND_CONNECTION_REQUEST]: {
      className: clx('no-padding', 'copyt_modal_container'),
      size: 'xs',
      closeButton: {
        hidden: true,
      },
    },
    [ModalType.EDIT_DISCORD_NAME]: {
      className: clx('no-padding', 'copyt_modal_container'),
      size: 'xs',
      closeButton: {
        hidden: true,
      },
    },
    [ModalType.CONNECT_BATCH_ITEMS]: {
      className: clx('copyt_modal_container'),
      size: 'xl',
      closeButton: {
        hidden: true,
      },
    },
  },
};

export default ConnectModals;
