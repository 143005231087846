import React, {useEffect, useState} from 'react'
import * as Sentry from '@sentry/react'
import {Route, Switch} from 'react-router-dom'
import axios from 'axios'
import {apiConfig} from 'config.js'
import {useAuth0} from '@auth0/auth0-react'
import {LoadingBar} from 'views_v2/lib/snippets'
import {createRequestOptions} from 'util/network'
import EditPlatform from './EditPlatform'
import PlatformManagement from './component/platform_management'
import './platforms.scss'

const Platforms = () => {
  const {getAccessTokenSilently} = useAuth0()
  const [platforms, setPlatforms] = useState(null)
  const [credential, setCredential] = useState([])

  useEffect(() => {
    ;(async () => {
      try {
        const token = await getAccessTokenSilently()

        let supportedPlatforms = await axios.get(
          `${apiConfig.api_url}/platform/market`,
          createRequestOptions(token),
        )

        let userSettings = await axios.get(
          `${apiConfig.api_url}/credential`,
          createRequestOptions(token),
        )

        const supportedPlatformsData = supportedPlatforms.data?.data || []
        const userSettingsData = userSettings.data?.data
        setCredential(userSettings.data?.data)

        if (Array.isArray(supportedPlatformsData) && supportedPlatformsData?.length > 0) {
          for (var i = 0; i < supportedPlatformsData?.length; i++) {
            var splatId = supportedPlatformsData[i].id
            let index = userSettingsData.findIndex((settings) => settings.key == `platform-${splatId}`)
            if (index !== -1) {
              supportedPlatformsData[i].settings = userSettingsData[index].value
            }
            if (!supportedPlatformsData[i].settings) {
              supportedPlatformsData[i].settings = {
                enabled: false,
              }
            }
          }
        }

        setPlatforms(supportedPlatformsData)
      } catch (e) {
        Sentry.captureException(e)
      }
    })()
  }, [getAccessTokenSilently])

  const handleSwitch = (elem, state) => {
    ;(async () => {
      try {
        var token = await getAccessTokenSilently()
        var updatedSetting = await axios.post(
          `${apiConfig.api_url}/credential`,
          {
            platformId: 'platform-' + elem.id,
            value: {
              enabled: state.state?.value,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )

        elem.settings.enabled = !elem.settings.enabled
        let platformIndex = platforms.findIndex((p) => p.id == elem.id)
        if (platformIndex !== -1) {
          setPlatforms((platforms) => {
            const temp = platforms.map((item, index) => {
              if (platformIndex == index) {
                return elem
              } else {
                return item
              }
            })
            return temp
          })
        }
      } catch (e) {
        Sentry.captureException(e)
      }
    })()
  }

  if (!platforms) return <LoadingBar />

  return (
    <Switch>
      <Route
        path="/admin/platforms/*"
        component={(props) => <EditPlatform {...props} {...{credential}} />}
      />
      <Route exact path="/admin/platforms">
        <div className="p-detail-flex">
          <div className="p-detail-content" style={{overflow: 'hidden'}}>
            <div className="p-detail-panel-wrapper full-height">
              <PlatformManagement {...{handleSwitch}} visible={true} />
            </div>
          </div>
        </div>
      </Route>
    </Switch>
  )
}

export default Platforms
