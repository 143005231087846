import React, { useState } from 'react'
import clx from 'classnames'
import {Button} from '@mui/material'
import {
  PublishedWithChanges as PublishedWithChangesIcon
 } from '@mui/icons-material'

const MdlProcessAll = (p) => {
  const {onCloseModal, markAsProcessed} = p

  return (
    <div>
      <div className="modal-title">
        <div className="modal__heading">
          <h1 className="">Confirm Action</h1>
        </div>
      </div>
      <div className="modal-body">
        <div className="delete-section text-center">
          <PublishedWithChangesIcon />
          <div>
            <div>Are you sure you want to process ALL of your sold items?</div>
            <div><strong>Warning! This action cannot be undone.</strong></div>
          </div>
        </div>
        <div className="delete-btn">
          <Button
            className={clx('btn-header', 'secondary', 'submit-consignment')}
            onClick={() => {
              onCloseModal()
            }}
          >
            Cancel
          </Button>
          <Button
            className={clx('btn-header', 'danger')}
            onClick={markAsProcessed}
          >
            Yes, Proceed
          </Button>
        </div>
      </div>
    </div>
  )
}

export default MdlProcessAll
