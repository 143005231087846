import {useQuery} from 'react-query'
import axios from 'axios'
import {useAuth0} from '@auth0/auth0-react'
import {map} from 'lodash'

import {paths} from 'v2/constant'
import {createRequestOptions} from 'util/network'

const getMetrics = async (getAccessTokenSilently, params) => {
  try {
    const token = await getAccessTokenSilently()
    const queryString = map(
      {...params},
      (value, key) => `${key}=${encodeURIComponent(value).replace(/%2F/g, '/')}`,
    ).join('&')

    const url = `${new URL(paths.product)}/metrics?${queryString}`

    const {data} = await axios.get(url.toString(), createRequestOptions(token))

    return data
  } catch (error) {
    console.error('Error retrieving metrics', error)
  }
}

const useMetrics = (params, options = {}) => {
  const {getAccessTokenSilently} = useAuth0()

  const query = useQuery(['useMetrics', params], () => getMetrics(getAccessTokenSilently, params), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    cacheTime: 3600000,
    ...options,
  })

  return {
    query,
  }
}

export {useMetrics}
