import React from 'react'
import {Box, Stack} from '@mui/material'
import {Add as AddIcon} from '@mui/icons-material'
import {useTheme} from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {CopytButton, CopytTooltip} from 'views_v2/lib/snippets'

const CustomButton = ({
  onClick = () => null,
  icon = <AddIcon />,
  title = null,
  color = 'primary',
  variant = 'contained',
  tooltipTitle = null,
  style,
  ...props
}) => {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <CopytTooltip
      title={tooltipTitle || (isSmallScreen && title)}
      arrow={false}
      backgroundColor="#eee"
      color="var(--primary)"
    >
      <Box component="span" display={style?.display}>
        <CopytButton
          {...props}
          {...{onClick, color, variant}}
          style={{
            ...style,
            borderRadius: '6px',
            padding: '12px 16px',
            border: '1px solid white',
            height: '40px',
            lineHeight: '15px',
          }}
        >
          <Stack direction="row" gap={1} alignItems="center">
            {icon}
            {!isSmallScreen && title}
          </Stack>
        </CopytButton>
      </Box>
    </CopytTooltip>
  )
}

export default CustomButton
