import {useState, useEffect} from 'react'
import {useQuery, useMutation} from 'react-query'
import {useAuth0} from '@auth0/auth0-react'
import axios from 'axios'
import {captureException} from '@sentry/react'

import {createRequestOptions} from 'util/network.js'
import {paths} from 'constant/api.js'

const getCredentials = async (tokenGetter) => {
  try {
    const token = await tokenGetter()
    const response = await axios.get(paths.credential + '?take=1000', createRequestOptions(token))
    return response.data.data
  } catch (e) {
    captureException(e)
    throw e.message
  }
}

const patchCredential = async (tokenGetter, id, input) => {
  try {
    const token = await tokenGetter()
    const response = await axios.patch(paths.credential + `/${id}`, input, createRequestOptions(token))
    return response.data.data
  } catch (e) {
    captureException(e)
    throw e.message
  }
}

const createCredential = async (tokenGetter, input) => {
  try {
    const token = await tokenGetter()
    const response = await axios.post(paths.credential, input, createRequestOptions(token))
    return response.data.data
  } catch (e) {
    captureException(e)
    throw e.message
  }
}

export const useCredential = (option = {}) => {
  const {getAccessTokenSilently} = useAuth0()
  const [fetchEnabled, setFetchEnabled] = useState(true)

  const credential = useQuery('credentials', () => getCredentials(getAccessTokenSilently), {
    ...option,
    keepPreviousData: true,
    enabled: fetchEnabled,
  })

  const onPatch = useMutation(({id, input}) => patchCredential(getAccessTokenSilently, id, input), {
    onSuccess: () => {
      setFetchEnabled(true)
      credential.refetch()
    },
  })

  const onCreate = useMutation((input) => createCredential(getAccessTokenSilently, input), {
    onSuccess: () => {
      setFetchEnabled(true)
      credential.refetch()
    },
  })

  useEffect(() => {
    if (fetchEnabled) {
      credential.refetch()
      setFetchEnabled(false)
    }
  }, [fetchEnabled, credential.refetch])

  return {
    credential,
    refetchCredential: () => setFetchEnabled(true),
    onPatchCredential: onPatch.mutate,
    onCreateCredential: onCreate.mutate,
  }
}

export default useCredential
