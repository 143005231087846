import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import {Box, Grid} from '@mui/material';
import {useAuth0} from '@auth0/auth0-react';
import {useStripe, useElements, CardNumberElement} from '@stripe/react-stripe-js';
import axios from 'axios';
import {get, isEmpty} from 'lodash';
import SweetAlert from 'react-bootstrap-sweetalert';

import {apiConfig} from 'config';
import {paths} from 'constant/api';
import {ToastType} from 'enums';
import {createRequestOptions} from 'util/network';
import {useGlobalStore} from 'provider/global_store/hook';
import {useStoreSettings, useUser} from 'service/hook';
import {LoadingBar} from 'views_v2/lib/snippets';
import TabPanels, {TabPanel} from './lib/TabPanels';
import {General, Consignment, Listing} from './component';
import AccountSettingsToast from './component/AccountSettingsToast';
import AccountSettingsModals, {AccountSettingsModalType} from './AccountSettingsModals';
import './AccountSettings.scss';

const AccountSettings = () => {
  const history = useHistory();
  let lastSegment = history?.location?.pathname.split('/').pop();
  if (lastSegment?.toLowerCase() === 'settings') lastSegment = 'general';
  lastSegment = TabPanel[lastSegment?.toUpperCase()];
  const stripe = useStripe();
  const elements = useElements();
  const {user, getAccessTokenSilently} = useAuth0();
  const {id} = useUser().user?.private || {};
  const {
    locations: _locations,
    addLocation,
    deleteLocation,
    updateLocation,
    isLoadingLocation,
    payoutInfo: _payoutInfo,
    updatePayoutInfo,
  } = useStoreSettings();
  const [loading, setLoading] = useState(false);
  const [loadingMain, setLoadingMain] = useState(false);
  const [cardError, setCardError] = useState(null);
  const [stripeError, setStripeError] = useState(null);
  const [hasMOP, setHasMop] = useState(false);
  const [paymentLoaded, setPaymentLoaded] = useState(false);
  const [tabPanel, setTabPanel] = useState(Number(lastSegment));
  const [modalType, setModalType] = useState(AccountSettingsModalType.UNDEFINED);
  const [alert, setAlert] = useState(null);
  const [fee, setFee] = useState(null);
  const [locForUpdate, setLocForUpdate] = useState(null);
  const [toastType, setToastType] = useState(ToastType.UNDEFINED);
  const [toastContent, setToastContent] = useState(null);

  const [billingDetails, setBillingDetails] = useState({
    email: '',
    name: '',
    address: '',
    city: '',
    state: '',
    zip: '',
  });
  var [ccInfo, setCCInfo] = useState({sameAsBilling: false});

  const [locations, setLocations] = useState([
    {
      name: 'Richmond',
      address: '123 Main St',
      city: 'Richmond',
      state: 'VA',
      zip: '123123',
    },
    {
      name: 'HQ',
      address: '123 South St',
      city: 'Columbia',
      state: 'SC',
      zip: '3123211',
    },
  ]);

  const {isEnterprise} = useGlobalStore();

  const routeChange = route => {
    history.push(route);
  };

  const submitMain = async () => {
    try {
      setLoadingMain(true);
      const token = await getAccessTokenSilently();

      const response = await axios.patch(
        `${apiConfig.api_url}/payment`,
        {
          billing_address: ccInfo.address,
          billing_city: ccInfo.city,
          billing_state: ccInfo.state,
          billing_zip: ccInfo.zip,
          billing_name: ccInfo.name,
          shipping_address: ccInfo.addressShipping,
          shipping_city: ccInfo.cityShipping,
          shipping_state: ccInfo.stateShipping,
          shipping_zip: ccInfo.zipShipping,
          sameAsBilling: ccInfo.sameAsBilling,
        },
        createRequestOptions(token),
      );

      if (response.status === 200 && response?.data?.hasOwnProperty('customer')) {
        setLoadingMain(false);
        setToastType(ToastType.SUCCESS);
        setToastContent('Account settings saved');
      } else {
        setToastType(ToastType.ERROR);
        setToastContent(response.data?.error || 'Failed to update account settings');
        setLoadingMain(false);
      }
    } catch (e) {
      Sentry.captureException(e);
      setLoadingMain(false);
      setToastType(ToastType.ERROR);
      setToastContent('Failed to update account settings');
    }
  };

  const onFormSubmit = async () => {
    setCardError(null);
    setStripeError(false);
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);

    let formError = null;
    if (
      !billingDetails.name ||
      !billingDetails.address ||
      !billingDetails.city ||
      !billingDetails.state ||
      !billingDetails.zip ||
      !cardElement
    ) {
      formError = 'is required';
    } else {
      formError = null;
    }

    setCardError(formError);
    if (!formError) {
      try {
        setLoading(true);

        const paymentMethod = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
          billing_details: {
            address: {
              line1: billingDetails.address,
              city: billingDetails.city,
              state: billingDetails.state,
              postal_code: billingDetails.zip,
            },
            name: billingDetails.name,
          },
        });

        if (paymentMethod.error) {
          setCardError(paymentMethod.error.message);
          setStripeError(true);
        } else {
          setStripeError(false);
        }

        const token = await getAccessTokenSilently();

        const response = await axios.post(
          `${apiConfig.api_url}/payment/subscription`,
          {
            paymentMethodId: paymentMethod.paymentMethod.id,
            name: billingDetails.name,
            email: user.email,
          },
          createRequestOptions(token),
        );

        if (response.status === 201) {
          var expDateFormatted =
            paymentMethod.paymentMethod.card.exp_month.toString().length > 1
              ? paymentMethod.paymentMethod.card.exp_month
              : '0' + paymentMethod.paymentMethod.card.exp_month;

          expDateFormatted += '/' + paymentMethod.paymentMethod.card.exp_year.toString().slice(-2);

          var flatResponse = {
            last4: paymentMethod.paymentMethod.card.last4,
            exp: expDateFormatted,
            name: paymentMethod.paymentMethod.billing_details.name,
            address:
              paymentMethod.paymentMethod.billing_details.address.line1 +
              (paymentMethod.paymentMethod.billing_details.address.line2
                ? ' ' + paymentMethod.paymentMethod.billing_details.address.line2
                : ''),
            city: paymentMethod.paymentMethod.billing_details.address.city,
            state: paymentMethod.paymentMethod.billing_details.address.state,
            zip: paymentMethod.paymentMethod.billing_details.address.postal_code,
          };
          setCCInfo({
            ...ccInfo,
            ...flatResponse,
          });
          setHasMop(true);
          setLoading(false);
          setModalType(AccountSettingsModalType.UNDEFINED);

          const skipPaywallResponse = await axios.patch(`${paths.userPrivate}/${id}`, {skipPaywall: true}, createRequestOptions(token));
        } else {
          //this isn't right
          setCardError(response.data);
          setStripeError(true);
          setLoading(false);
        }
      } catch (e) {
        Sentry.captureException(e);
        console.error('error', e, e.data);
        setLoading(false);
      }
    }
  };

  const handleCheckbox = e => {
    var temp = !ccInfo.sameAsBilling;
    ccInfo.sameAsBilling = temp;
    setCCInfo({
      ...ccInfo,
      sameAsBilling: temp,
    });

    if (ccInfo.sameAsBilling) {
      setCCInfo({
        ...ccInfo,
        addressShipping: ccInfo.address,
        cityShipping: ccInfo.city,
        stateShipping: ccInfo.state,
        zipShipping: ccInfo.zip,
      });
    }
  };

  const onSubmitLocation = newLocation => {
    setAlert(
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Confirm"
        confirmBtnBsStyle="danger"
        title="Add Location"
        onConfirm={() => {
          setLoading(true);
          addLocation(newLocation);
          setAlert(null);
          setTimeout(() => {
            setLoading(false);
          }, 3000);
        }}
        onCancel={() => {
          setAlert(null);
        }}
        focusCancelBtn
      >
        Click ‘Confirm’ to finish creating your new Store Location. This will automatically update your Copyt subscription. Read more about
        Copyt’s pricing
        <a href="https://copyt.io/features/#ptable" target="_blank" style={{cursor: 'pointer', color: '#d5239e', fontWeight: 600}}>
          {' '}
          here
        </a>
        .
      </SweetAlert>,
    );
  };

  const setLocationToUpdate = updatedLocation => {
    setLocForUpdate(updatedLocation);
  };

  const onUpdateLocation = updatedLocation => {
    updateLocation(updatedLocation);
  };

  const onDeleteLocation = locationId => {
    setAlert(
      <SweetAlert
        danger
        showCancel
        confirmBtnText="Confirm"
        confirmBtnBsStyle="danger"
        title="Delete Location"
        onConfirm={() => {
          setLoading(true);
          deleteLocation(locationId);
          setAlert(null);
          setTimeout(() => {
            setLoading(false);
          }, 3000);
        }}
        onCancel={() => {
          setAlert(null);
        }}
        focusCancelBtn
      >
        Click ‘Confirm’ to delete Store Location. This will automatically update your Copyt subscription. Read more about Copyt’s pricing
        <a href="https://copyt.io/features/#ptable" target="_blank" style={{cursor: 'pointer', color: '#d5239e', fontWeight: 600}}>
          {' '}
          here
        </a>
        .
      </SweetAlert>,
    );
  };

  const onUpdatePayoutInfo = payoutInfo => {
    updatePayoutInfo(payoutInfo);
  };

  useEffect(() => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await axios.get(`${apiConfig.api_url}/payment/method`, createRequestOptions(token));

        const {data} = response.data;
        if (response.status == 200 && response?.data) {
          const customer = get(data, 'customer', null);
          const method = get(data, 'method', null);
          const shipping = get(customer, 'shipping', null);
          const billing_details = get(method, 'billing_details', '');
          const card = get(method, 'card', '');

          if (isEmpty(card) && isEmpty(billing_details)) {
            setPaymentLoaded(true);
            return;
          }

          let expDateFormatted = card.exp_month.toString().length > 1 ? card.exp_month : '0' + card.exp_month;

          expDateFormatted += '/' + card.exp_year.toString().slice(-2);

          const flatResponse = {
            last4: card.last4,
            exp: expDateFormatted,
            name: billing_details.name,
            address: billing_details.address.line1 + (billing_details.address.line2 ? ' ' + billing_details.address.line2 : ''),
            city: billing_details.address.city,
            state: billing_details.address.state,
            zip: billing_details.address.postal_code,

            addressShipping: shipping ? shipping?.address?.line1 + (shipping?.address?.line2 ? ' ' + shipping?.address?.line2 : '') : '',
            cityShipping: shipping?.address?.city || '',
            stateShipping: shipping?.address?.state || '',
            zipShipping: shipping?.address?.postal_code || '',

            sameAsBilling: data.sameAsBilling,
          };

          setCCInfo(flatResponse);
          setHasMop(true);
        }
      } catch (e) {
        Sentry.captureException(e);
        console.error('exception', e);
      }
      setPaymentLoaded(true);
    })();
  }, [getAccessTokenSilently]);

  if (!paymentLoaded) {
    return <LoadingBar />;
  }

  const onChangeTab = tabPanel => {
    setTabPanel(tabPanel);
    const tab = Object.keys(TabPanel).find(key => TabPanel[key] === tabPanel);
    routeChange(`/admin/settings/${tab?.toLowerCase()}`);
  };

  const onSaveChanges = p => {
    if (p?.locations) {
      const arr = locations || [];
      arr?.push(p?.locations);
      setLocations(arr);
    } else if (p?.deleteLocation) {
      setLocations(locations.splice(p?.deleteLocation, 1));
    }
  };

  return (
    <div className="p-detail-flex account-settings">
      {alert}

      <div className="p-detail-toolbar no-print">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TabPanels {...{tabPanel, isEnterprise}} onSelect={onChangeTab} />
          </Grid>
        </Grid>
      </div>
      <Box className="p-detail-content">
        <div className="p-detail-panel-wrapper">
          <General
            {...{
              ccInfo,
              hasMOP,
              handleCheckbox,
              locations: _locations,
              loading: loading || isLoadingLocation,
              loadingMain,
              onSaveChanges,
              onDeleteLocation,
              setCCInfo,
              setLocationToUpdate,
              submitMain,
              setModalType,
            }}
            onUpdatePayoutInfo={onUpdatePayoutInfo}
            visible={tabPanel === TabPanel.GENERAL}
          />
          <Consignment
            onSetFee={e => {
              if (e?.hasOwnProperty('id')) {
                setFee(e);
              } else {
                setFee(null);
              }
              setModalType(AccountSettingsModalType.NEW_FEE);
            }}
            visible={tabPanel === TabPanel.CONSIGNMENT}
          />
          <Listing visible={tabPanel === TabPanel.LISTING} />
        </div>
      </Box>
      <AccountSettingsModals
        {...{
          fee,
          setFee,
          modalType,
          setModalType,
          user,
          locForUpdate,
          locations: _locations,
        }}
        {...{
          ccInfo,
          hasMOP,
          stripeError,
          cardError,
          billingDetails,
          setBillingDetails,
          onFormSubmit,
        }}
        onSubmitLocation={onSubmitLocation}
        onUpdateLocation={onUpdateLocation}
      />
      {toastType > ToastType.UNDEFINED && <AccountSettingsToast {...{toastType, setToastType}} content={toastContent} />}
    </div>
  );
};

export default AccountSettings;
