import React from 'react'
import { Box, TextField } from '@mui/material'
import { TextStyle } from '../modal-contents/MdlSupport'

const Description = (p) => {
  return (
    <Box>
      <TextStyle>Description</TextStyle>
      <TextField
        className="support-textarea"
        fullWidth
        multiline
        minRows={5}
        maxRows={15}
        onChange={(e) => p?.setDescription(e.target.value)}
      />
    </Box>
    
  )
}

export default Description
