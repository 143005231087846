import React, { memo } from 'react'
import { usePaywall } from './hook'

export const PaywallWelcomeNote = (p) => {
  const { onNext } = usePaywall()

  return (
    <div>
      <div className="welcome-title">Welcome to your new Individual Copyt Account!</div>
      <div className="welcome-subtitle">With this account, you can manage your inventory for <strong>FREE.</strong></div>
      <div className="welcome-body"> If you would like to use <strong>Copyt&apos;s Listing / Delisting feature, </strong>
        you will be charged a <strong>1.5% Transaction Fee </strong>for each Listing Sale made with Copyt.
      </div>
      <div className="actions-container">
        <div className="begin">To begin Listing, just enter your <strong>Payment Information!</strong></div>
        <button
          disabled={false}
          className="btn-secondary ripple"
          onClick={onNext}
        >
          Go to Account Settings
        </button>
      </div>
    </div>
  )
}

export default memo(PaywallWelcomeNote)
