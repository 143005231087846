
import { Box } from '@mui/material'
import styled from 'styled-components'

const ModalBodyStyle = styled(Box)(({ theme }) => ({
  ...theme,
  position: 'relative',
  flex: '1 1 auto',
  marginTop: 16,
  marginBottom: 16
}))

const ModalBody = ({ children, ...props }) => (
  <ModalBodyStyle {...props}>
    {children}
  </ModalBodyStyle>
)
export default ModalBody
