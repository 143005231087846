import React from 'react';
import { Container, Typography, Paper } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles'
import EngineeringIcon from '@mui/icons-material/Engineering';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  paper: {
    padding: theme.spacing(3),
    textAlign: 'center',
  },
}));

const MaintenancePage = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container maxWidth="sm">
        <Paper elevation={3} className={classes.paper}>
          <Typography variant="h4">Under Maintenance</Typography>
          <EngineeringIcon sx={{fontSize:'5rem !important'}} />
          <Typography variant="body1">
            We're sorry, but this page is currently undergoing maintenance.
            Please check back later.
          </Typography>
        </Paper>
      </Container>
    </div>
  );
};

export default MaintenancePage
