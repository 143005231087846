import React from 'react'
import PropTypes from 'prop-types'
import {TabPanel} from 'enums'
import SimplifiedLayout from './SimplifiedLayout'
import ExpandedLayout from './ExpandedLayout'

const TabContents = ({children, focus, common}) => {
  return React.Children.map(children, (c) => {
    if (c.props?.tab === focus) {
      return React.cloneElement(c, {...common})
    }
    return null
  })
}

const CustomTabPanel = (p) => {
  return (
    <TabContents focus={p.tab} common={p}>
      <SimplifiedLayout tab={TabPanel.ADD_ITEM_SIMPLIFIED} />
      <ExpandedLayout tab={TabPanel.ADD_ITEM_EXPANDED} />
    </TabContents>
  )
}

CustomTabPanel.propTypes = {
  tab: PropTypes.number.isRequired,
}

export default CustomTabPanel
