import {useState, useEffect} from 'react'
import {useQuery} from 'react-query'
import axios from 'axios'
import {useAuth0} from '@auth0/auth0-react'
import {captureException} from '@sentry/react'
import Qs from 'querystring'

import {paths} from 'constant/api'
import {createRequestOptions} from 'util/network'

const getNotifications = async (getAccessTokenSilently, skip, take) => {
  try {
    const token = await getAccessTokenSilently()
    const response = await axios.get(
      `${paths.notification}?${Qs.stringify({skip, take})}`,
      createRequestOptions(token),
    )
    return response?.data
  } catch (error) {
    captureException(error)
    console.error('Error retrieving notifications', error)
    throw error
  }
}

const getNotificationCount = async (getAccessTokenSilently) => {
  try {
    const token = await getAccessTokenSilently()
    const response = await axios.get(`${paths.notification}/count`, createRequestOptions(token))
    return response?.data
  } catch (error) {
    captureException(error)
    console.error('Error retrieving notification count', error)
    throw error
  }
}

export const useNotifications = (skip, take, options = {}) => {
  const {getAccessTokenSilently} = useAuth0()
  const [fetchNotificationsEnabled, setFetchNotificationsEnabled] = useState(true)
  const [fetchCountEnabled, setFetchCountEnabled] = useState(true)

  const {isLoading, isError, isFetched, refetch, data, error} = useQuery(
    ['notifications'],
    () => getNotifications(getAccessTokenSilently, skip, take),
    {
      ...options,
      enabled: fetchNotificationsEnabled,
      keepPreviousData: true,
    },
  )

  const {
    isLoading: notificationCountIsLoading,
    isError: notificationCountIsError,
    isFetched: notificationCountIsFetched,
    refetch: notificationCountRefetch,
    data: notificationCountData,
    error: notificationCountError,
  } = useQuery(['notifications-count'], () => getNotificationCount(getAccessTokenSilently), {
    ...options,
    enabled: fetchCountEnabled,
  })

  useEffect(() => {
    if (fetchNotificationsEnabled) {
      refetch()
      setFetchNotificationsEnabled(false)
    }
  }, [fetchNotificationsEnabled, refetch])

  useEffect(() => {
    if (fetchCountEnabled) {
      notificationCountRefetch()
      setFetchCountEnabled(false)
    }
  }, [fetchCountEnabled, notificationCountRefetch])

  return {
    isLoading,
    isError,
    isNotificationFetched: isFetched,
    refetchNotifications: () => setFetchNotificationsEnabled(true),
    error,
    notifications: data || [],
    notificationCountIsLoading,
    notificationCountIsError,
    notificationCountIsFetched,
    refetchNotificationCount: () => setFetchCountEnabled(true),
    notificationCountData: notificationCountData?.count,
    notificationCountError,
  }
}

export default useNotifications
