import {get} from 'lodash'

export const getByPlatform = (platform, credentials = []) => {
  if (!platform || !credentials) {
    return null
  }
  const platformId = get(platform, 'id')

  return credentials.find((i) => {
    return get(i, 'platform.id') === platformId
  })
}

export const isEnabledByPlatform = (platform, credentials = []) => {
  if (!platform || !credentials) {
    return false
  }
  const cred = getByPlatform(platform, credentials)

  return cred?.enabled
}
