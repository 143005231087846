import {useCallback, useMemo, useState} from 'react'
import {InventoryQueryType} from 'constant/query_type'
import {useUnlistedItemInventory} from 'service/hook/useItem'
import {ar} from 'variables/empty'
import {useGlobalStore} from 'provider/global_store/hook'
import {debounce} from 'lodash'
import {filter} from './helper'

// private
//todo: this is old imp support. this need to refactor, change how it works
export const useViewInventory = function () {
  const [searchText, setSearchText] = useState('')

  const [tabeSelected, TabHandler] = useViewInventoryTab()
  const {items, filterValues, isFetched, refetch, fetchNextPage, hasNextPage, isFetching, total} =
    useUnlistedItemInventory(tabeSelected, searchText)

  const {filteredItems, filters, addFilter} = useViewInventoryFilterSearch(items, searchText)

  const onSearch = useMemo(
    () =>
      debounce((v) => {
        setSearchText(v)
        refetch()
      }, 1000),
    [setSearchText, refetch],
  )

  // todo: rename return on refactor
  return {
    fetchNextPage,
    isInventoryFetched: isFetched,
    isFetching,
    hasNextPage,
    refetchInventory: refetch,
    brands: filterValues.brands,
    conditions: filterValues.conditions,
    sizes: filterValues.sizes,
    total,
    addFilter,
    TabHandler,
    tabSelection: tabeSelected,
    displayItems: filteredItems,
    searchQuery: searchText,
    filterSelections: filters,
    setQuery: onSearch, //setSearchText,
    setDisplayItems: () => {
      //todo: remove
    },
  }
}

const useViewInventoryFilterSearch = function (items, searchText) {
  const [filters, setFilters] = useState(ar)
  const {isEnterprise} = useGlobalStore()

  // todo: this should be toogle filter
  const addFilter = useCallback(
    (key, value) => {
      setFilters((f) => {
        const exists = f.findIndex((s) => s.key === key && s.value === value)
        if (exists !== -1) {
          f.splice(exists, 1)
          return [...f]
        } else {
          f.push({key: key, value: value})
          return [...f]
        }
      })
    },
    [setFilters],
  )

  const filteredItems = useMemo(
    () => filter(searchText, filters, items, isEnterprise),
    [searchText, filters, items, isEnterprise],
  )

  return {filters, searchText, filteredItems, addFilter}
}

const useViewInventoryTab = function () {
  const [tab, setTab] = useState(InventoryQueryType.UNLISTED)

  const onChangeTab = useCallback(
    (type) => {
      setTab(type)
      // other events
    },
    [setTab],
  )

  const onUnlisted = useCallback(() => onChangeTab(InventoryQueryType.UNLISTED), [onChangeTab])
  const onListed = useCallback(() => onChangeTab(InventoryQueryType.LISTED), [onChangeTab])
  const onSold = useCallback(() => onChangeTab(InventoryQueryType.SOLD), [onChangeTab])
  const onProcess = useCallback(() => onChangeTab(InventoryQueryType.PROCESSED), [onChangeTab])

  // will be shited to useMemo if user need memoise
  return [tab, {onUnlisted, onListed, onSold, onProcess}]
}
