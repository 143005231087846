import React, {useEffect, useMemo, useRef, useState} from 'react'
import {Box, Checkbox, Typography, Stack, FormControl} from '@mui/material'
import Select from 'react-select'
import {chain, get, isEmpty} from 'lodash'

import {ModalType} from 'enums'
import {_replaceWildCards} from 'util/string_utils'
import {CopytLabel, CopytStyledButton, LoadingBar} from 'views_v2/lib/snippets'
import {useConsignmentShops} from 'service/hook'

const MdlSelectApprovedItems = (props) => {
  const {
    approvedPlatforms,
    setModalType,
    payload,
    setPayload,
    consignee,
    setConsignee,
    setSelectedLocation,
    selectedLocation,
  } = props
  const [boxItems, setBoxItems] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [initialRender, setInitialRender] = useState(true)
  const [options, setOptions] = useState([])

  const {storeLocations} = useConsignmentShops(
    {
      options: {
        enabled: true,
        staleTime: Infinity,
      },
      queries: {},
    },
    consignee?.value,
  )

  useEffect(() => {
    setBoxItems([])
  }, [selectedLocation, consignee])

  useEffect(() => {
    setIsLoading(true)
    if (!isEmpty(storeLocations)) {
      setOptions(
        storeLocations.map((storeLocation) => ({
          label: storeLocation.name,
          value: storeLocation.id,
        })),
      )
      if (!initialRender) {
        setSelectedLocation({
          label: storeLocations[0].name,
          value: storeLocations[0].id,
        })
      }
      setConsignee({
        ...consignee,
        location: storeLocations[0].name,
      })
      setIsLoading(false)
    } else {
      setSelectedLocation(null)
      setConsignee({
        ...consignee,
        location: 'default',
      })
      setIsLoading(false)
    }

    setInitialRender(false)
  }, [storeLocations])

  const handleCheckboxChange = (item) => {
    setBoxItems((prevItems) => {
      // Check if the item is already in the array
      const itemIndex = prevItems.findIndex((i) => i.id === item.id)
      if (itemIndex > -1) {
        // Remove the item if it exists
        return prevItems.filter((i) => i.id !== item.id)
      } else {
        // Add the item if it doesn't exist
        return [...prevItems, item]
      }
    })
  }

  const onContinue = () => {
    setPayload({...payload, boxItems})
    setModalType(ModalType.CREATE_BOX)
  }

  const approvedPlatformsArray = useMemo(
    () =>
      chain(approvedPlatforms)
        .filter((item) => get(item, 'platformName'))
        .map((item) => ({
          label: get(item, 'platformName'),
          value: get(item, 'enterpriseId'),
        }))
        .uniqBy('label')
        .value(),
    [approvedPlatforms],
  )

  const filteredItems = payload?.items
    ?.filter((item) => {
      return item?.product?.listings?.every((listing) =>
        listing?.listingPlatforms?.every((platform) => platform?.platform?.type === 'user'),
      )
    })
    ?.filter((item) => item?.product?.location.toLowerCase() === selectedLocation?.label.toLowerCase())

  return (
    <Box className="bulk-edit">
      <Box className="modal-title">
        <Box className="modal__heading" mx="0px !important">
          <Typography variant="h1" textAlign="left">
            Select Approved items
          </Typography>
        </Box>
      </Box>
      <Box className="modal-body" maxHeight="85vh" padding="0" mb={2}>
        <Stack direction="column" bgcolor="var(--lighter)">
          <Box
            padding={1}
            borderBottom="1px solid"
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            gap={4}
          >
            <FormControl>
              <CopytLabel>Consignee</CopytLabel>
              <Select
                placeholder="Select Consignee"
                closeMenuOnSelect
                options={approvedPlatformsArray}
                value={consignee}
                onChange={(e) => {
                  setConsignee(e)
                  setBoxItems([])
                }}
                styles={{container: (base) => ({...base, width: '200px', float: 'right'})}}
              />
            </FormControl>
            <FormControl>
              <CopytLabel>Store Location</CopytLabel>
              <Select
                placeholder="Select Store Location"
                isDisabled={isLoading}
                isLoading={isLoading}
                classNamePrefix="react-select"
                onChange={(e) => {
                  setSelectedLocation(e)
                  setConsignee({...consignee, location: e?.label})
                }}
                options={options}
                value={selectedLocation}
                styles={{container: (base) => ({...base, width: '200px', float: 'right'})}}
              />
            </FormControl>
          </Box>

          <Stack
            direction="row"
            spacing={1}
            justifyContent="space-around"
            borderBottom="1px solid"
            p={1}
            alignItems="center"
          >
            <Checkbox
              checked={!isEmpty(boxItems) && boxItems?.length === payload?.items?.length}
              indeterminate={!isEmpty(boxItems) && boxItems?.length !== payload?.items?.length}
              onChange={() => (isEmpty(boxItems) ? setBoxItems(filteredItems) : setBoxItems([]))}
              color="secondary"
              disabled={props.isFetchingItemsApproved}
            />
            <Stack direction="row" width="100%" justifyContent="space-between">
              <CopytLabel width="30%">Item Name</CopytLabel>
              <CopytLabel width="25%">Sku/Style ID</CopytLabel>
              <CopytLabel width="15%">Size</CopytLabel>
              <CopytLabel width="15%">Price</CopytLabel>
            </Stack>
          </Stack>
          {props.isFetchingItemsApproved && <LoadingBar type="linear" />}
        </Stack>

        <Box minHeight="50vh" maxHeight="60vh" overflow="hidden auto">
          {!isEmpty(payload?.items) &&
            filteredItems.map((item) => {
              const product = item?.product
              return (
                <Stack
                  key={item.id}
                  direction="row"
                  spacing={1}
                  justifyContent="space-between"
                  borderBottom="1px solid var(--light)"
                  alignItems="center"
                  padding={1}
                  sx={{
                    '&:hover': {
                      backgroundColor: 'var(--lighter)',
                    },
                  }}
                >
                  <Checkbox
                    onChange={() => handleCheckboxChange(item)}
                    color="secondary"
                    checked={boxItems.some((s) => s?.id === item?.id)}
                    disabled={props.isFetchingItemsApproved}
                  />
                  <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
                    <CopytLabel width="30%">{product?.title}</CopytLabel>
                    <CopytLabel width="25%">{product?.sku}</CopytLabel>
                    <CopytLabel width="15%">{product?.size}</CopytLabel>
                    <CopytLabel width="15%">{product?.desiredReturn}</CopytLabel>
                  </Stack>
                </Stack>
              )
            })}
        </Box>
        <Stack
          direction="row"
          justifyContent="flex-end"
          pr={2}
          pt={2}
          borderTop="1px solid #eee"
          spacing={2}
        >
          <CopytStyledButton
            disabled={isEmpty(boxItems)}
            variant="contained"
            color="primary"
            onClick={onContinue}
          >
            Continue
          </CopytStyledButton>
        </Stack>
      </Box>
    </Box>
  )
}

export default MdlSelectApprovedItems
