import React from 'react'
import Loader from 'react-loader-spinner'
import CopytCard from 'components/CopytCard'
import FieldGroup from 'views_v2/lib/snippets/FieldGroup'
import onKeyPress from 'views_v2/lib/common/onKeyPress'
import { Box, Grid, FormControlLabel } from '@mui/material'
import CheckSwitch from 'views_v2/lib/snippets/CopytSwitch/CheckSwitch' 
import { CopytBtn } from './ProductTemplate'

const ShippingDetails = ({handleCheckbox, ccInfo, setCCInfo, loadingMain, submitMain}) => {
  return (
    <CopytCard className="copyt-card">
      <CopytCard.Header><CopytCard.Text tag="div">Shipping Details</CopytCard.Text></CopytCard.Header>
      <CopytCard.Body>
        <FormControlLabel
          sx={{
            color: '#5d0953',
          }}
          control={<CheckSwitch sx={{ m: 0 }} checked={ccInfo && ccInfo.sameAsBilling} />}
          onChange={(e) => handleCheckbox(e)}
          label="Shipping address same as billing address."
        />
        <Grid container gap={0.5} justifyContent="space-between">
          <Grid item xs={12} lg={12}>
            <FieldGroup
              sLabel="Shipping Address"
              placeholder="Enter address"
              value={(ccInfo && ccInfo.addressShipping) || ''}
              disabled={ccInfo && ccInfo.sameAsBilling}
              onKeyPress={(e) => onKeyPress(e, 'alphanumeric')}
              onChange={(e) =>
                setCCInfo({
                  ...ccInfo,
                  addressShipping: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} lg={5}>
            <FieldGroup
              sLabel="City"
              placeholder="Enter city"
              disabled={ccInfo && ccInfo.sameAsBilling}
              value={(ccInfo && ccInfo.cityShipping) || ''}
              onKeyPress={(e) => onKeyPress(e, 'text')}
              onChange={(e) =>
                setCCInfo({
                  ...ccInfo,
                  cityShipping: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} lg={4.5}>
            <FieldGroup
              sLabel="State"
              placeholder="Enter state"
              disabled={ccInfo && ccInfo.sameAsBilling}
              value={(ccInfo && ccInfo.stateShipping) || ''}
              onKeyPress={(e) => onKeyPress(e, 'text')}
              onChange={(e) =>
                setCCInfo({
                  ...ccInfo,
                  stateShipping: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} lg={2}>
            <FieldGroup
              sLabel="ZIP Code"
              placeholder="Enter zip"
              disabled={ccInfo && ccInfo.sameAsBilling}
              value={(ccInfo && ccInfo.zipShipping) || ''}
              onKeyPress={(e) => onKeyPress(e, 'alphanumeric')}
              onChange={(e) =>
                setCCInfo({
                  ...ccInfo,
                  zipShipping: e.target.value,
                })
              }
            />
          </Grid>
        </Grid>
        
        <Box display="flex" justifyContent="flex-end">
          <CopytBtn
            disabled={loadingMain}
            variant="outline"
            color="primary"
            onClick={() => submitMain()}
          >
            {loadingMain ? <Loader type="TailSpin" color="#d5239e" height={20} width={20} /> : 'Update'}
          </CopytBtn>
        </Box>
      </CopytCard.Body>
    </CopytCard>
  )
}

export default ShippingDetails