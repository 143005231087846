import React, {memo, useState, useCallback} from 'react'
import {Grid, Stack} from '@mui/material'

import DetailView from 'components/DetailView'
import CopytCard from 'components/CopytCard'
import {useLink} from 'service/hook/useLink'
import {CopytStyledButton, FieldGroup} from 'views_v2/lib/snippets'

const ClaimableItems = () => {
  const {onClaim} = useLink()
  const [code, setCode] = useState('')

  const handleClaim = useCallback(() => onClaim({code}), [onClaim, code])
  const onChange = useCallback((e) => setCode(e.target.value), [setCode])

  return (
    <DetailView.Panel style={{height: '95%'}}>
      <CopytCard
        className="account-big-card card-layout"
        style={{padding: 20, height: '100%', marginTop: 0}}
      >
        <Grid container gap={1} justifyContent="space-between">
          <Grid item xs={12} lg={5.9}>
            <CopytCard>
              <CopytCard.Header>
                <CopytCard.Text tag="div">Claimable Items</CopytCard.Text>
              </CopytCard.Header>
              <CopytCard.Body>
                <Stack spacing={1} alignItems="flex-end">
                  <FieldGroup sLabel="Code" value={code} onChange={onChange} placeholder="Enter code" />
                  <CopytStyledButton variant="contained" color="primary" onClick={handleClaim}>
                    Submit
                  </CopytStyledButton>
                </Stack>
              </CopytCard.Body>
            </CopytCard>
          </Grid>
        </Grid>
      </CopytCard>
    </DetailView.Panel>
  )
}

export default memo(ClaimableItems)
