export default {
  SHOPIFY: 'shopify',
  SQUARE: 'square',
  EBAY: 'ebay',
  CLOVER: 'clover',
  VENMO: 'venmo',
  PAYPAL: 'paypal',
  COPYT_NETWORK: 'copyt network',
  CHECKBOOK: 'checkbook',
  TRADE_BLOCK: 'tradeblock',
}
