import React, {useEffect, useMemo, useState} from 'react'
import {useAuth0} from '@auth0/auth0-react'
import axios from 'axios'
import {isEmpty} from 'lodash'
import Select from 'react-select'
import {NumericFormat} from 'react-number-format'
import {Stack} from '@mui/material'
import {InfoOutlined as InfoOutlinedIcon} from '@mui/icons-material'

import {paths} from 'constant/api'
import CopytCard from 'components/CopytCard'
import {useGlobalStore} from 'provider/global_store/hook'
import {useConsignmentShops} from 'service/hook'
import {CopytLabel, CopytTooltip} from 'views_v2/lib/snippets'
import {validateCustomPayout} from 'util/model'
import {_getPercentageInBetween} from 'util/string_utils'
import {getFee} from 'util/model/product'
import {getConsignmentPayout} from 'util/model/consignment'
import StockData from './StockData'

const ConsignorInformation = (props) => {
  const {
    consignor,
    consignorEmail,
    feeStructure,
    formFields = {},
    isConsignorEmailReadOnly = false,
    isFeeStructureReadOnly = false,
    setFormFields,
  } = props
  const {getAccessTokenSilently} = useAuth0()
  const {user, isEnterprise} = useGlobalStore()
  const isCustomPayout = validateCustomPayout(user)

  const [isFeeStructureDisabled, setIsFeeStructureDisabled] = useState(false)
  const [isEstimatedPayoutDisabled, setIsEstimatedPayoutDisabled] = useState(false)
  const [consignorArray, setConsignorArray] = useState([])
  const [storeLocationOptions, setStoreLocationOptions] = useState([])
  const [stockData, setStockData] = useState()
  const [selectedCompany, setSelectedCompany] = useState()
  const [selectedLocation, setSelectedLocation] = useState()

  const fee = Number(feeStructure) || getFee(formFields)
  const {shopApplications, completedApplications} = useConsignmentShops({
    options: {
      enabled: true,
      staleTime: Infinity,
    },
    queries: {},
  })

  const cEmail =
    formFields?.consignorEmail || consignor?.email || consignor?.consignor?.email || consignorEmail

  const feesMemoized = useMemo(() => {
    const estimatedPayout = Number((1 - feeStructure / 100) * formFields.desiredReturn).toFixed(2)
    return {fee, estimatedPayout}
  }, [cEmail])

  const handleEstimatedPayoutChange = (e) => {
    const estimatedPayout = parseFloat(e.value)

    if (!isEnterprise) {
      const desiredReturn = estimatedPayout / (1 - Number(feesMemoized.fee) / 100)
      setFormFields({
        ...formFields,
        estimatedPayout: estimatedPayout.toFixed(2),
        desiredReturn,
      })
    } else {
      const newFeeStructure = _getPercentageInBetween(e.value, formFields?.desiredReturn)

      setFormFields({
        ...formFields,
        estimatedPayout: estimatedPayout.toFixed(2),
        feeStructure: newFeeStructure,
      })
      setIsFeeStructureDisabled(e.value !== '')
    }
  }

  const handleFeeStructureChange = (e) => {
    const newEstimatedPayout = parseFloat((1 - e.value / 100) * formFields?.desiredReturn).toFixed(2)
    setFormFields({
      ...formFields,
      feeStructure: e.value,
      estimatedPayout: newEstimatedPayout,
    })

    setIsEstimatedPayoutDisabled(e.value !== '')
  }

  useEffect(() => {
    const fetchStocks = async (location = true) => {
      if (!formFields?.size || (Array.isArray(formFields?.size) && !formFields?.size.length)) return
      try {
        const token = await getAccessTokenSilently()

        const body = {
          ownerId: selectedCompany,
          sku: formFields?.sku,
          condition: formFields?.condition,
          location: location ? formFields?.location : null,
          sizes: formFields?.size.map((size) => size.size),
        }
        // Fetching product stock
        const {data: stockResponse} = await axios.post(paths.productStock, body, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })

        return stockResponse
      } catch (error) {
        Sentry.captureException(error)
      }
    }

    const fetchCity = async () => {
      try {
        const response = await axios.get(paths.locationByUser(selectedCompany), {
          headers: {Authorization: `Bearer ${await getAccessTokenSilently()}`},
        })

        const stocks = await fetchStocks(false)

        const newStoreLocationOptions = response.data.data.map((location) => {
          let availableSizes = 0

          if (formFields?.size && Array.isArray(formFields?.size) && formFields?.size.length) {
            Object.keys(stocks).forEach((size, i) => {
              const stock = stocks[size]
              const cheapestProduct = stock.cheapestProduct
              if (cheapestProduct?.location == location.name) {
                availableSizes++
              }
            })
          }

          let color
          if (availableSizes === formFields?.size?.length) {
            color = 'green'
          } else if (!isEmpty(availableSizes)) {
            color = 'orange'
          } else {
            color = 'red'
          }

          return {
            label: location.name,
            value: location.name,
            color: color,
          }
        })

        setStoreLocationOptions(newStoreLocationOptions)
        if (
          newStoreLocationOptions?.length === 1 &&
          newStoreLocationOptions[0]?.value !== formFields?.location
        ) {
          setSelectedLocation(newStoreLocationOptions[0]?.value)
          setFormFields({
            ...formFields,
            location: newStoreLocationOptions[0]?.value,
          })
        }
      } catch (error) {
        console.error('Error fetching location data: ', error)
      }
    }

    if (selectedCompany) {
      fetchCity()
      if (formFields?.location) {
        fetchStocks().then((stockResponse) => {
          setStockData(stockResponse)
        })
      }
    }
  }, [selectedCompany, getAccessTokenSilently, formFields, formFields?.size?.length])

  useEffect(() => {
    if (isEmpty(consignorArray)) {
      let arr = []

      if (isEnterprise) {
        arr = shopApplications
          ?.filter((c) => !['denied', 'pending'].includes(c?.status?.toLowerCase()))
          ?.map((s) => ({
            label: s?.email,
            value: s?.email,
            feeStructure: s?.fee?.value,
          }))
      } else {
        arr = completedApplications?.map((s) => ({
          label: s?.platform?.owner?.businessName,
          value: s?.platform?.owner.email,
          customPayout: s?.fee?.customPayout,
          feeStructure: s?.fee?.value,
          id: s?.platform?.owner?.id,
          isAcceptedContract: s.isAcceptedContract,
          contractPdfLink: s?.platform.contract_pdf_link,
        }))
      }

      if (!isEmpty(arr)) {
        setConsignorArray(arr)
      }
    }
  }, [completedApplications, shopApplications, consignorArray, isEnterprise])

  useEffect(() => {
    if (formFields?.consignorEmail || formFields?.desiredReturn) {
      const newFee = isCustomPayout
        ? _getPercentageInBetween(feesMemoized.estimatedPayout, formFields?.desiredReturn)
        : formFields?.feeStructure

      setFormFields({
        ...formFields,
        feeStructure: newFee,
        estimatedPayout: isCustomPayout
          ? feesMemoized
          : Number((1 - feeStructure / 100) * formFields.desiredReturn).toFixed(2),
      })
    }
  }, [formFields?.consignorEmail, formFields?.desiredReturn])

  const dot = (color = 'gray') => ({
    alignItems: 'center',
    display: 'flex',

    ':before': {
      backgroundColor: color,
      borderRadius: 10,
      content: '" "',
      display: 'block',
      marginRight: 8,
      height: 10,
      width: 10,
    },
  })

  const colourStyles = useMemo(() => {
    return {
      control: (styles) => ({...styles, backgroundColor: 'white'}),
      option: (styles, {data, isDisabled, isFocused, isSelected}) => {
        return {
          backgroundColor: 'white',
          color: data.color,
          cursor: isDisabled || isSelected ? 'not-allowed' : 'pointer',
          padding: '10px',
        }
      },
      input: (styles) => ({...styles, ...dot()}),
      placeholder: (styles) => ({...styles, ...dot('gray')}),
      singleValue: (styles, {data}) => {
        return {...styles, ...dot(data?.color)}
      },
    }
  }, [formFields?.size])

  const consignorLabel = !isEnterprise ? 'Company Name' : 'Select Consignor'

  return (
    <CopytCard className="consignor-information">
      <CopytCard.Header>
        <CopytCard.Text tag="div">Consignor Information</CopytCard.Text>
      </CopytCard.Header>
      <CopytCard.Body>
        <CopytLabel fontSize="1rem" marginTop={1}>
          {consignorLabel}
        </CopytLabel>
        <Select
          className="react-select info"
          classNamePrefix="react-select"
          closeMenuOnSelect
          placeholder={consignorLabel}
          isDisabled={isConsignorEmailReadOnly}
          isClearable
          onChange={(e) => {
            setSelectedCompany(e?.id || null)
            setFormFields({
              ...formFields,
              consignorEmail: e?.value || '',
              feeStructure: e?.customPayout ? 0 : e?.feeStructure || '',
              location: formFields?.location ?? '',
              isAcceptedContract: e?.isAcceptedContract || null,
              contractPdfLink: e.contractPdfLink,
            })
            setStockData(null)
          }}
          options={consignorArray}
          value={{
            value: cEmail,
            label: isEnterprise
              ? cEmail
              : consignor?.consignee?.businessName ||
                consignorArray?.find((s) => cEmail === s?.value)?.label,
          }}
        />

        {!isEnterprise ? (
          <CopytTooltip
            title={isEmpty(storeLocationOptions) && 'User needs to select a store location to consign.'}
          >
            <CopytLabel fontSize="1rem" marginTop={2}>
              Store Location
            </CopytLabel>
            <Select
              classNamePrefix="react-select"
              closeMenuOnSelect
              placeholder="Select Store Location"
              isDisabled={isConsignorEmailReadOnly || isEmpty(storeLocationOptions)}
              value={
                storeLocationOptions?.find((s) => s?.value === formFields?.location) ||
                formFields?.location
                  ? {
                      label: formFields?.location,
                      value: formFields?.location,
                    }
                  : null
              }
              onChange={(e) => {
                setSelectedLocation(e.value)
                setFormFields({
                  ...formFields,
                  location: e.value,
                })
              }}
              options={storeLocationOptions}
              styles={colourStyles}
            />
          </CopytTooltip>
        ) : null}

        <CopytLabel fontSize="1rem" marginTop={2}>
          Fee Structure (%)
        </CopytLabel>
        <NumericFormat
          value={!isEnterprise ? feesMemoized.fee : formFields?.feeStructure ?? fee ?? ''}
          disabled={isFeeStructureDisabled || !isEnterprise}
          onValueChange={handleFeeStructureChange}
          placeholder={isFeeStructureReadOnly ? '' : 'Enter fee structure'}
          className="numeric-input-consign"
          isAllowed={(values) => {
            const {formattedValue, floatValue} = values
            return formattedValue === '' || (floatValue <= 9999 && floatValue >= 1)
          }}
        />
        <Stack direction="row" alignItems="center" marginTop={2} mb={0.5} spacing={1}>
          <CopytLabel style={{marginBottom: 0}}>Estimated Payout</CopytLabel>
          {!isEnterprise && (
            <CopytTooltip
              title={`This value is calculated by deducting the Fee Structure (%) from the List Price. \n [Estimated Payout = List Price - (List Price x Fee Structure)]`}
              placement="bottom"
            >
              <InfoOutlinedIcon fontSize="small" sx={{color: 'var(--success)'}} />
            </CopytTooltip>
          )}
        </Stack>

        <NumericFormat
          value={
            !!formFields?.sold
              ? getConsignmentPayout(formFields?.sold)
              : typeof formFields?.estimatedPayout === 'object' && formFields?.estimatedPayout !== null
              ? formFields?.estimatedPayout?.estimatedPayout
              : formFields?.estimatedPayout
          }
          disabled={isEstimatedPayoutDisabled}
          allowNegative={false}
          onValueChange={handleEstimatedPayoutChange}
          placeholder="Enter estimated payout"
          className="numeric-input-consign"
          isAllowed={(values) => {
            const {formattedValue, floatValue} = values
            return formattedValue === '' || (floatValue <= 999999999.99 && floatValue >= 1)
          }}
        />
        <>
          {stockData ? (
            <StockData
              stockData={stockData}
              selectedSizes={formFields?.size}
              selectedLocation={selectedLocation}
            />
          ) : null}
        </>
      </CopytCard.Body>
    </CopytCard>
  )
}

export default ConsignorInformation
