import {useState} from 'react'
import {Container, Box, IconButton, Stack, Tooltip} from '@mui/material'
import {Visibility as VisibilityIcon} from '@mui/icons-material'

import {ModalType} from 'enums'
import PlatformsModal, {PlatformsModalType} from '../PlatformsModal'

export const ConsignorProfile = ({consignorEmail, consignorId}) => {
  const [isConsignorHovered, setIsConsignorHovered] = useState(false)
  const [modalType, setModalType] = useState(PlatformsModalType.UNDEFINED)

  return (
    <>
      <Tooltip title={consignorEmail}>
        <Box component="span">
          <IconButton
            style={{
              gap: 2,
              fontSize: '0.875rem',
              color: 'black',
              fontWeight: 400,
              padding: 0,
            }}
            sx={{
              '&:hover': {
                bgcolor: 'transparent !important',
              },
              '&:focus': {
                outline: 'none',
              },
            }}
            onMouseEnter={() => setIsConsignorHovered(true)}
            onMouseLeave={() => setIsConsignorHovered(false)}
            onClick={(event) => {
              event.stopPropagation()
              setModalType(ModalType.CONSIGNOR_PROFILE)
            }}
          >
            {(isConsignorHovered || modalType === ModalType.CONSIGNOR_PROFILE) && (
              <VisibilityIcon
                fontSize="small"
                style={{
                  opacity: 1,
                }}
              />
            )}
            {consignorEmail}
          </IconButton>
        </Box>
      </Tooltip>

      <PlatformsModal
        {...{
          modalType,
          setModalType,
          formFields: {selectedConsignorId: consignorId},
        }}
      />
    </>
  )
}
