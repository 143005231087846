export const ListingStatus = {
  CREATED: 'created',
  LISTED: 'listed',
  PENDING: 'pending',
  PROCESSED: 'processed',
  SOLD: 'sold',
  WITHDRAW_APPROVED: 'withdraw_approved',
  WITHDRAW_PENDING: 'withdraw_pending',
  WITHDRAW_DENIED: 'withdraw_denied',
  PROCESSING: 'processing',
  FOR_REMOVE: 'for_remove',
  REVIEWED: 'reviewed',
}
