import {useQuery, useMutation} from 'react-query'
import {useAuth0} from '@auth0/auth0-react'
import {apiConfig} from 'config'
import axios from 'axios'
import {useToastMessage} from 'components/index'
import {createRequestOptions} from 'util/network'

const getCompanySettings = async (getAccessTokenSilently) => {
  try {
    const token = await getAccessTokenSilently()
    const response = await axios.get(`${apiConfig.api_url}/company`, createRequestOptions(token))

    return response.data?.data
  } catch (error) {
    console.log(error)
    return error
  }
}

const putCompanySettings = async (getAccessTokenSilently, values) => {
  const token = await getAccessTokenSilently()
  const response = await axios.patch(
    `${apiConfig.api_url}/company/${values?.id}`,
    values?.value,
    createRequestOptions(token),
  )
  return response
}

export const useCompany = () => {
  const {getAccessTokenSilently} = useAuth0()
  const {showToast} = useToastMessage()
  const {
    isLoading,
    isError,
    isFetched,
    refetch,
    data: company = {},
    error,
  } = useQuery('companySetting', () => getCompanySettings(getAccessTokenSilently))

  const updateCompany = useMutation((values) => putCompanySettings(getAccessTokenSilently, values), {
    onSuccess: async (data, variables, context) => {
      showToast({variant: 'success', title: 'Success', body: 'Company info has been updated.'})
      refetch()
    },
    onError: async (error) => {
      showToast({variant: 'danger', title: 'Error', body: ''})
      refetch()
    },
  })

  return {
    isLoading,
    isError,
    companySettingsFetched: isFetched,
    refetchCompanySettings: refetch,
    error,
    company: company || {},
    updateCompany: updateCompany.mutate,
  }
}

export default useCompany
