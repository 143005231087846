import React, { memo } from 'react'
import {
  Label,
  FormGroup,
  Input,
  Row,
  Col
} from 'reactstrap'

import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js'
import onKeyPress from 'views_v2/lib/common/onKeyPress'


export const PaywallPaymentInfo = (p) => {
  const {cardError, stripeError, billingDetails, setBillingDetails} = p
  return (
    <div className="container">
      <FormGroup className={stripeError && 'has-danger'}>
        <Label>Card Number</Label>
        <div className={stripeError ? 'cc-input-error' : 'cc-input'}>
          <CardNumberElement options={{ showIcon: true }} />
        </div>
      </FormGroup>
      <Row>
        <Col lg="8" style={{ paddingLeft: '0px' }}>
          <FormGroup className={stripeError && 'has-danger'}>
            <Label>Expiration</Label>
            <div className={stripeError ? 'cc-input-error' : 'cc-input'}>
              <CardExpiryElement />
            </div>
          </FormGroup>
        </Col>
        <Col lg="4" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
          <FormGroup className={stripeError && 'has-danger'}>
            <Label>CVC</Label>
            <div className={stripeError ? 'cc-input-error' : 'cc-input'}>
              <CardCvcElement />
            </div>
          </FormGroup>
        </Col>
      </Row>
      <div className={stripeError ? 'error-message' : 'hidden'}>{cardError}</div>
      <FormGroup className={cardError && !billingDetails.name && 'has-danger'}>
        <Label>Name on Card</Label>
        <Input
          placeholder="Enter name"
          value={billingDetails.name || ''}
          required
          onKeyPress={(e) => onKeyPress(e, 'text')}
          onChange={(e) =>
            setBillingDetails({
              ...billingDetails,
              name: e.target.value,
            })
          }
        />
        <div className={cardError && !billingDetails.name ? 'error-message' : 'hidden'}>
          Name {cardError}
        </div>
      </FormGroup>

      <FormGroup className={cardError && !billingDetails.address && 'has-danger'}>
        <Label>Billing Address</Label>
        <Input
          placeholder="Enter address"
          value={billingDetails.address || ''}
          onKeyPress={(e) => onKeyPress(e, 'alphanumeric')}
          onChange={(e) =>
            setBillingDetails({
              ...billingDetails,
              address: e.target.value,
            })
          }
        />
        <div className={cardError && !billingDetails.address ? 'error-message' : 'hidden'}>
          Address {cardError}
        </div>
      </FormGroup>

      <FormGroup className={cardError && !billingDetails.city && 'has-danger'}>
        <Label>City</Label>
        <Input
          placeholder="Enter city"
          value={billingDetails.city || ''}
          onKeyPress={(e) => onKeyPress(e, 'text')}
          onChange={(e) =>
            setBillingDetails({
              ...billingDetails,
              city: e.target.value,
            })
          }
        />
        <div className={cardError && !billingDetails.city ? 'error-message' : 'hidden'}>
          City {cardError}
        </div>
      </FormGroup>
      <Row>
        <Col lg="8" style={{ paddingLeft: '0px' }}>
          <FormGroup className={cardError && !billingDetails.state && 'has-danger'}>
            <Label>State</Label>
            <Input
              placeholder="Enter state"
              value={billingDetails.state || ''}
              onKeyPress={(e) => onKeyPress(e, 'text')}
              onChange={(e) =>
                setBillingDetails({
                  ...billingDetails,
                  state: e.target.value,
                })
              }
            />
            <div className={cardError && !billingDetails.state ? 'error-message' : 'hidden'}>
              State {cardError}
            </div>
          </FormGroup>
        </Col>
        <Col lg="4" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
          <FormGroup className={cardError && !billingDetails.zip && 'has-danger'}>
            <Label>ZIP</Label>
            <Input
              placeholder="Enter zip"
              value={billingDetails.zip || ''}
              onKeyPress={(e) => onKeyPress(e, 'alphanumeric')}
              onChange={(e) =>
                setBillingDetails({
                  ...billingDetails,
                  zip: e.target.value,
                })
              }
            />
            <div className={cardError && !billingDetails.zip ? 'error-message' : 'hidden'}>
              Zip {cardError}
            </div>
          </FormGroup>
        </Col>
      </Row>
      {/* <Row>
        <button
          className="btn-secondary ripple"
          onClick={onCloseModal}
        >
          Cancel
        </button>
        <button
          className="btn-primary ripple"
          onClick={onSaveChanges}
        >
          {loading ? (
            <Loader
              type="TailSpin"
              color="white"
              height={20}
              width={20}
              className="import-button"
            />
          ) : (
            <>Save</>
          )}
        </button>
      </Row> */}
    </div>
  )
}

export default memo(PaywallPaymentInfo)
