import React from 'react'
import { Box, Checkbox, FormControlLabel, Grid } from '@mui/material'
import { TextStyle } from '../modal-contents/MdlSupport'

const SelectPlatform = (p) => {
  const { platformOptions = [], handleCheckbox } = p || {}
  if (platformOptions?.length > 0) {
    return (
      <Grid container xs={12}>
        <Grid xs={12}><TextStyle>Select Platform</TextStyle></Grid>
        {
          platformOptions.map((s, i) => {
            return (
              <Grid item xs={6} key={i}>
                <FormControlLabel
                  sx={{ marginLeft: 0, marginRight: 0}}
                  control={
                    <Checkbox color="secondary" onChange={() => handleCheckbox(s?.label)} />
                  }
                  label={s?.label}
                />
              </Grid>
            )
          }
          )}
      </Grid>
    )
  }
  return <Box />
}

export default SelectPlatform
