export const metricSize = [
  {value: 'cm', label: 'cm'},
  {value: 'in', label: 'in'},
  {value: 'mm', label: 'mm'},
]

export const weightSize = [
  {value: 'g', label: 'g'},
  {value: 'kg', label: 'kg'},
  {value: 'oz', label: 'oz'},
  {value: 'lb', label: 'lb'},
]
