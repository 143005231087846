import Button from '@mui/material/Button'
import {styled} from '@mui/material/styles'

const CopytButton = styled(Button)(({theme}) => ({
  ...theme,
  fontWeight: 600,
  '&.MuiButton-contained': {
    color: 'white',
    '&Primary': {
      backgroundColor: 'var(--primary)',
    },
    '&Secondary': {
      backgroundColor: 'var(--success)',
    },
    '&Error': {
      backgroundColor: 'var(--error)',
    },
  },
  '&.MuiButton-outline': {
    backgroundColor: 'white',
    boxShadow: '0px 1px 8px rgba(0, 0, 0, 0.24)',
    '&Primary': {
      color: 'var(--primary)',
    },
    '&Secondary': {
      color: 'var(--success)',
    },
    '&Error': {
      color: 'var(--error)',
    },
  },
}))

export default CopytButton
