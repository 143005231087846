import React from 'react'
import PropTypes from 'prop-types'
import clx from 'classnames'
import CopytModal from 'components/CopytModal'
import {ModalType} from 'enums'
import {MdlProceed, MdlAuditFinish} from './modal-contents'

const ModalContents = ({children, common, focus, onCloseModal}) => {
  if (focus !== ModalType.UNDEFINED) {
    return React.Children.map(children, (c, i) => {
      if (c.props?.modalType === focus) {
        // Show modal based on the given modalType
        return React.cloneElement(c, {...common, onCloseModal})
      }
      return null
    })
  }
  return null
}

const InventoryModals = (p) => {
  const {modalType, setModalType, modals, onSaveChanges} = p || {}
  const m = modals[modalType]

  const onClose = () => {
    setModalType(ModalType.UNDEFINED)
  }

  return (
    <CopytModal
      className={m?.className || null}
      closeButton={m?.closeButton}
      id={m?.id}
      show={modalType > ModalType.UNDEFINED}
      size={m?.size || 'sm'}
      toggle={onClose}
    >
      <ModalContents focus={modalType} common={p} onCloseModal={onClose}>
        <MdlProceed modalType={ModalType.NOTICE} />
        <MdlAuditFinish {...{onSaveChanges}} modalType={ModalType.AUDIT} />
      </ModalContents>
    </CopytModal>
  )
}

InventoryModals.propTypes = {
  modals: PropTypes.object,
}

InventoryModals.defaultProps = {
  modals: {
    [ModalType.NOTICE]: {
      className: clx('copyt_modal_container', 'notice-modal'),
      size: 'xs',
      closeButton: {
        hidden: true,
      },
    },
    [ModalType.AUDIT]: {
      className: clx('no-padding', 'audit-finish-modal'),
      size: 'xs',
    },
  },
}

export default InventoryModals
