import React from 'react'
import {
  Label,
  FormGroup,
  Input,
  Row,
  Col,
} from 'reactstrap'
import CopytCard from 'components/CopytCard'
import { Box, Grid } from '@mui/material'
import {ReactComponent as CreditCardSvg} from 'assets/img/credit-card.svg'
import { AccountSettingsModalType } from '../../AccountSettingsModals'
import { FieldGroup } from 'views_v2/lib/snippets'
import { CopytButton } from 'views_v2/lib/snippets'

const CreditCardSettings = ({hasMOP, ccInfo, setModalType}) => {
  return (
    <CopytCard className="copyt-card">
      <CopytCard.Header><CopytCard.Text tag="div">Payment Details</CopytCard.Text></CopytCard.Header>
      <CopytCard.Body>
        {!hasMOP ? (
          <div className="empty-state-card">
            <CreditCardSvg className="empty-state-svg"></CreditCardSvg>
            <div className="empty-state-text">
              <p className="empty-state-header">No Payment method on file</p>
              <p className="empty-state-body empty-state-body-payment-details">
                In order to list your inventory, add a payment method by selecting the button
                below
              </p>
            </div>
          </div>
        ) : (
          <Grid container gap={0.5} justifyContent="space-between">
            <Grid item xs={12} lg={5}>
              <FieldGroup
                sLabel="Card Number"
                readOnly
                placeholder="**** **** **** ****"
                value={ccInfo && ccInfo.last4 ? '**** **** **** ' + ccInfo.last4 : ''}
              />
            </Grid>
            <Grid item xs={12} lg={4.5}>
              <FieldGroup
                sLabel="Expiration"
                readOnly
                placeholder="MM/YY"
                value={(ccInfo && ccInfo.exp) || ''}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <FieldGroup
                sLabel="CVC"
                readOnly
                placeholder="Enter CVC"
                value="***"
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <FieldGroup
                sLabel="Name on Card"
                readOnly
                placeholder="Enter name"
                value={(ccInfo && ccInfo.name) || ''}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <FieldGroup
                sLabel="Billing Address"
                readOnly
                placeholder="Enter address"
                value={(ccInfo && ccInfo.address) || ''}
              />
            </Grid>
            <Grid item xs={12} lg={5}>
              <FieldGroup
                sLabel="City"
                readOnly
                placeholder="Enter city"
                value={(ccInfo && ccInfo.city) || ''}
              />
            </Grid>
            <Grid item xs={12} lg={4.5}>
              <FieldGroup
                sLabel="State"
                readOnly
                placeholder="Enter state"
                value={(ccInfo && ccInfo.state) || ''}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <FieldGroup
                sLabel="ZIP Code"
                readOnly
                placeholder="Enter zip"
                value={(ccInfo && ccInfo.zip) || ''}
              />
            </Grid>
          </Grid>
        )}
        <Box display="flex" justifyContent="flex-end">
          <CopytButton
            variant="outline"
            color="primary"
            onClick={() => {
              setModalType(AccountSettingsModalType.CREDIT_CARD)
            }}
          >
            {!hasMOP ? 'Add Payment Method' : 'Update Payment Method'}
          </CopytButton>
        </Box>
      </CopytCard.Body>
    </CopytCard>
  )
}

export default CreditCardSettings