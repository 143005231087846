import React, {useEffect, useState} from 'react';
import {Box} from '@mui/material';
import CreatableSelect from 'react-select/creatable';
import axios from 'axios';
import {apiConfig} from 'config';
import {useAuth0} from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import {CopytLabel, FieldGroup} from 'views_v2/lib/snippets';
import CopytCard from 'components/CopytCard';
import {isEmpty} from 'lodash';

export const InventoryTracking = props => {
  const {setFormFields, formFields, location, handleInputChange, errorMessage, editMode, isReadOnly, isEnterprise, internalSku, readOnly} =
    props;
  const {getAccessTokenSilently} = useAuth0();
  const [locationOptions, setLocationOptions] = useState([]);
  const [subLocationOptions, setSubLocationOptions] = useState([]);

  const hasError = props?.hasError && !formFields?.location;

  const customStyles = {
    control: provided => ({
      ...provided,
      borderColor: hasError ? 'red !important' : provided.borderColor,
    }),
  };

  useEffect(() => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();

        const locationResponse = await axios.get(`${apiConfig.api_url}/location`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (locationResponse.status === 200) {
          const locations = locationResponse.data.data;
          const locationName = locations.map(location => location.name);
          const defaultLocation = internalSku ? location : null;

          setLocationOptions(locationName);

          if (locationName?.length === 1) {
            setFormFields(formFields => ({
              ...formFields,
              location: defaultLocation || locationName[0],
            }));
          } else if (locationName?.length > 1) {
            setFormFields(formFields => ({
              ...formFields,
              location: defaultLocation || locations?.find(location => location?.isDefault)?.name,
            }));
          }
        }

        const subLocationResponse = await axios.get(`${apiConfig.api_url}/product/sub-location`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (subLocationResponse.status === 200) {
          setSubLocationOptions(subLocationResponse.data.data.map(subLocation => subLocation.subLocation));
        }
      } catch (e) {
        Sentry.captureException(e);
      }
    })();
  }, [getAccessTokenSilently]);

  const handleLocationCreate = inputValue => {
    setFormFields(formFields => ({...formFields, location: inputValue}));
  };

  const handleSubLocationCreate = inputValue => {
    setFormFields(formFields => ({...formFields, subLocation: inputValue}));
  };

  return (
    <CopytCard className="inventory-tracking" style={{marginBottom: '12px'}}>
      <CopytCard.Header>
        <CopytCard.Text tag="div">Inventory Tracking</CopytCard.Text>
      </CopytCard.Header>
      <CopytCard.Body>
        <FieldGroup
          {...{readOnly}}
          sLabel="Internal SKU"
          onChange={handleInputChange}
          disabled={isEnterprise}
          maxLength={30}
          required
          name="internalSku"
          tabIndex="9"
          placeholder="Enter Internal SKU"
          value={internalSku || ''}
        />
        <CopytLabel props={{error: hasError}}>Store Location *</CopytLabel>
        <CreatableSelect
          styles={customStyles}
          className="react-select info"
          id="location-autocomplete"
          classNamePrefix="react-select"
          placeholder="Choose Location"
          closeMenuOnSelect
          isClearable
          isDisabled={isReadOnly}
          onCreateOption={handleLocationCreate}
          name="location"
          onChange={newValue => {
            setFormFields(formFields => ({
              ...formFields,
              location: newValue?.value || '',
            }));
          }}
          options={locationOptions.map(s => {
            return {
              value: s,
              label: s,
            };
          })}
          value={
            (formFields?.location && {
              value: formFields?.location,
              label: formFields?.location,
            }) ||
            ''
          }
          tabIndex={editMode ? '6' : '30'}
        />
        {hasError ? (
          <Box color="red" fontSize="10px">
            Location {errorMessage}
          </Box>
        ) : null}

        <CopytLabel mt={1.5}>Sub Location</CopytLabel>
        <CreatableSelect
          className="react-select info"
          id="subLocation-autocomplete"
          classNamePrefix="react-select"
          placeholder="Choose Sub Location"
          closeMenuOnSelect
          isClearable
          isDisabled={isReadOnly}
          onCreateOption={handleSubLocationCreate}
          onChange={newValue => {
            setFormFields(formFields => ({
              ...formFields,
              subLocation: newValue?.value || '',
            }));
          }}
          options={subLocationOptions.map(s => {
            return {
              value: s,
              label: s,
            };
          })}
          value={
            (formFields?.subLocation && {
              value: formFields?.subLocation,
              label: formFields?.subLocation,
            }) ||
            ''
          }
          tabIndex={editMode ? '7' : '31'}
        />
      </CopytCard.Body>
    </CopytCard>
  );
};

export default InventoryTracking;
