import {useMutation, useQuery} from 'react-query'
import axios from 'axios'
import {useAuth0} from '@auth0/auth0-react'
import {useToastMessage} from 'components'
import * as Sentry from '@sentry/react'
import {paths} from 'constant/api'
import {createRequestOptions} from 'util/network'
import {ar} from 'variables/empty'
import {fn} from 'variables/empty'
import Qs from 'querystring'

const getLedgers = async (getAccessTokenSilently, filter, queries) => {
  try {
    const token = await getAccessTokenSilently()
    const response = await axios.get(
      `${paths.ledger}?${Qs.stringify({...filter, ...queries})}`,
      createRequestOptions(token),
    )
    return response?.data
  } catch (error) {
    Sentry.captureException(error)
    console.error('Error retrieving invoices', error)
    throw error
  }
}

export const useLedger = ({onSuccess = fn} = {}, filter = {}, queries = {}) => {
  const {getAccessTokenSilently, user} = useAuth0()
  const {showToast} = useToastMessage()

  const {
    isLoading,
    isError,
    isFetched,
    refetch,
    data: ledgers = ar,
    error,
  } = useQuery(
    ['ledgers', filter],
    () => getLedgers(getAccessTokenSilently, filter, queries),
    {
      keepPreviousData: true,
    },
  )

  const createLegerMany = useMutation(
    async (data) => {
      const token = await getAccessTokenSilently()
      return await axios.post(`${paths.ledger}/many`, data, createRequestOptions(token))
    },
    {
      onSuccess: async (data, variables, context) => {
        showToast({variant: 'success', title: 'Success', body: 'Batches have been created'})
        onSuccess()
      },
      onError: (e) => {
        showToast({ variant: 'danger', title: 'Failed', body: e?.response?.data?.msg || 'Failure to process request!' })
      },
    },
  )

  const createLedgerCheckbook = useMutation(
    async (data) => {
      const token = await getAccessTokenSilently()
      return await axios.post(`${paths.ledger}/checbook`, data, createRequestOptions(token))
    },
    {
      onSuccess: async (data, variables, context) => {
        showToast({ variant: 'success', title: 'Success', body: 'Payout batches for checkbook have been created' })
        onSuccess()
      },
      onError: (e) => {
        showToast({ variant: 'danger', title: 'Failed', body: e?.response?.data?.message || 'Failure to process request!' })
      },
    },
  )

  return {
    isLoading,
    isError,
    isLedgersFetched: isFetched,
    refecthLedgers: refetch,
    error,
    ledgers,
    createLegerMany: createLegerMany.mutate,
    createLedgerCheckbook: createLedgerCheckbook.mutate,
    isUpdateBatchPaymentsSuccess: createLegerMany.isSuccess,
  }
}

export default useLedger
