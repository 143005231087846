import {Menu, MenuItem} from '@mui/material';
import {styled, alpha} from '@mui/material/styles';
import React from 'react';
import {_getStatus} from 'util/string_utils';

export const StyledMenu = styled(props => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({theme}) => ({
  '& .MuiPaper-root': {
    outline: 'none',
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      padding: '0 5px',
      margin: 3,
      display: 'flex',
      justifyContent: 'flex-start',
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

const filterItems = ['pending', 'approved', 'rejected', 'listed', 'sold', 'processed', 'withdraw_approved'];

const ConsignmentFilter = ({onClose, handleFilterClick, open, anchorEl}) => {
  return (
    <StyledMenu
      id="customized-menu"
      MenuListProps={{
        'aria-labelledby': 'customized-button',
        className: 'options-list',
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
    >
      {filterItems.map((item, idx) => (
        <MenuItem
          key={idx}
          onClick={e => {
            e.stopPropagation();
            handleFilterClick(item);
          }}
        >
          {_getStatus(item)}
        </MenuItem>
      ))}
      <MenuItem
        key={6}
        onClick={e => {
          e.stopPropagation();
          handleFilterClick(null);
        }}
        style={{
          borderTop: '1px solid #d5239e',
          color: 'white !important',
        }}
      >
        Clear Filter
      </MenuItem>
    </StyledMenu>
  );
};

export default ConsignmentFilter;
