import {ar, ob} from 'variables/empty'

export function getPlatforms(item, d = ar) {
  return item?.listing?.platforms || d
}

export function getListing(item, d = ob) {
  return item?.listing || d
}

export function getThumbImage(product) {
  if (product && product.assets && product.assets[0]) {
    return product.assets[0].asset
  }
  return null
}
