import axios from 'axios'
import {useAuth0} from '@auth0/auth0-react'
import {paths} from 'constant/api'
import {createRequestOptions} from 'util/network'
import {useToastMessage} from 'components/index'
import { useMutation} from 'react-query'

export const useItemMutation = (filter = {}, searchText) => {

    const {getAccessTokenSilently} = useAuth0()
    const {showToast} = useToastMessage()

    const uploadCSV = useMutation(async (file) => {

        const token = await getAccessTokenSilently()
        const fd = new FormData()
        fd.append('file', file)
        
        const headers = createRequestOptions(token)
        headers.headers['content-type'] = 'multipart/form-data'

        const data = await axios.post(`${paths.product}/import/csv`, fd, createRequestOptions(token))
        return data
    },{
      onSuccess: async (data, variables, context) => {
        console.log("## Upload CSV success: ", data);
        showToast({
          variant: 'success',
          title: 'Success',
          body: `File Upload Success`,
        })
      },
      onError: (data, variables, context) => {
        console.log("## Upload CSV error: ", data)
        showToast({variant: 'danger', title: 'Invalid File', body: 'Please upload a csv file'})
      },
    },
    {
      onError: async (data, variables, context) => {
        console.log("## Upload CSV error: ", data);
        showToast({variant: 'danger', title: 'Error', body: ''})
      },
    }
    )

    return {
        uploadCSV: uploadCSV.mutate,
        uploadCSVIsSuccess: uploadCSV.isSuccess,
    }
}

export default useItemMutation


