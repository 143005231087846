import React from 'react'
import {Box, Stack, Typography} from '@mui/material'
import {Storefront as StorefrontIcon} from '@mui/icons-material'
import styled from 'styled-components'

const Text = styled((props) => <Typography {...props} />)(({theme}) => ({
  ...theme,
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  maxWidth: 320,
  fontSize: '1em',
  fontWeight: 600,
}))

const MdlPreview = (p) => {
  const {logo, faq_link, contract_pdf_link, support_email, company_name} = p || {}
  return (
    <Box padding="0 !important">
      <Box className="modal-title">
        <Box className="modal__heading" mx="0px !important">
          <Typography variant="h1" textAlign="left">
            Platform Preview
          </Typography>
        </Box>
      </Box>
      <Box className="modal-body" padding={0} my={2} mx={3}>
        <Stack direction="row" alignItems="center" spacing={1}>
          {logo ? (
            <Box
              component="img"
              sx={{
                height: 70,
                width: 70,
              }}
              src={logo}
            />
          ) : (
            <StorefrontIcon sx={{fontSize: '5rem'}} />
          )}
          <Stack direction="column" spacing={0.5}>
            <Typography fontWeight={600} color="var(--success) !important">
              {company_name}
            </Typography>
            <Text>
              FAQ:{' '}
              <Typography
                sx={{
                  fontSize: '0.9rem',
                  display: 'inline',
                  color: !faq_link && 'grey',
                  fontStyle: faq_link ? 'normal' : 'italic',
                }}
                component="span"
              >
                {faq_link || ' ex: www.CopytEnterpriseFAQS.com'}
              </Typography>
            </Text>
            <Text>
              Contract:{' '}
              <Typography
                sx={{
                  fontSize: '0.9rem',
                  display: 'inline',
                  color: !contract_pdf_link && 'grey',
                  fontStyle: contract_pdf_link ? 'normal' : 'italic',
                }}
                component="span"
              >
                {contract_pdf_link || ''}
              </Typography>
            </Text>

            <Text>
              Support Email:{' '}
              <Typography
                sx={{
                  fontSize: '0.9rem',
                  display: 'inline',
                  color: !support_email && 'grey',
                  fontStyle: support_email ? 'normal' : 'italic',
                }}
                component="span"
              >
                {support_email || ' ex: info@copyt.io'}
              </Typography>
            </Text>
          </Stack>
        </Stack>
      </Box>
    </Box>
  )
}

export default MdlPreview
