import React, {useCallback, useEffect, useState} from 'react'
import {Divider, Stack, Typography} from '@mui/material'
import {Redirect, useHistory} from 'react-router-dom'

import {useRedirCallback} from 'hooks/use_redir_callback'
import {usePlatformMarket} from 'service/hook/use_market'
import {CopytButton, LoadingBar} from 'views_v2/lib/snippets'

const EbayCallback = (props) => {
  const history = useHistory()
  const [state, setState] = useState({message: '', state: 'loading'})
  const {ebayCallback, ebaySetup} = usePlatformMarket()

  const process = useCallback(async () => {
    try {
      const queryParams = new URLSearchParams(history.location.search)

      setState((s) => ({...s, state: 'loading'}))
      const data = await ebayCallback(queryParams.get('code'))
      if (!data.success && data?.status === 400) {
        setState((s) => ({...s, message: data?.error, state: 'failed', status: data?.status}))
      } else {
        setState((s) => ({...s, message: 'Success', state: 'success'}))
      }
    } catch (err) {
      setState((s) => ({
        ...s,
        state: 'failed',
        message: err?.message || err?.error || err?.includes('500') ? null : err,
      }))
    }
  }, [history, ebayCallback, ebaySetup, setState])

  useRedirCallback(process)
  // useEffect(() => {
  //   process()
  // }, [process])
  // return 'testing' + state.state + state.message

  if (state.state === 'loading') {
    return (
      <Stack spacing={2} height={1} alignItems="center" justifyContent="center">
        <LoadingBar type="circular" />
        <Typography textAlign="center" color="var(--success) !important" fontSize="0.9rem">
          Processing Ebay Auth...
        </Typography>
      </Stack>
    )
  }

  if (state.state === 'failed') {
    return (
      <Stack h={1} paddingY={3} paddingX={2} spacing={2}>
        <Typography color="var(--default)" fontWeight={600}>
          Authentication Failed
        </Typography>
        <Divider />
        <Typography color="var(--default)">
          {state?.message || 'An error was encountered during authentication. Please try again!'}
        </Typography>
        <CopytButton variant="outline" color="primary" onClick={history.goBack} sx={{width: '100px'}}>
          Back
        </CopytButton>
      </Stack>
    )
  }

  if (state.state === 'success') {
    return (
      <div className="content">
        Success.
        <Redirect to="/admin/platforms/a341f0c4-7053-4c6e-85c9-98d9b0585d32" />
      </div>
    )
  }

  return (
    <Stack spacing={2} height={1} alignItems="center" justifyContent="center">
      <LoadingBar type="circular" />
      <Typography textAlign="center" color="var(--success) !important" fontSize="0.9rem">
        Please wait
      </Typography>
    </Stack>
  )
}

export default EbayCallback
