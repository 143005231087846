import React, {useMemo} from 'react'
import {validateEnterpriseUser, validateHasMop} from 'util/model/user'
import {useUser, useSiteSettings} from 'service/hook'
import {GlobalStoreContext, UserTypeContext} from './constant'
import {useGlobalStoreSupportedPlatform, useGlobalConsignmentFee} from './hook'

export function GlobalStoreProvider({children}) {
  const {user, userLoading, refetchUserSettings: userRefech} = useUser()
  const {supportedPlatforms, supportedPlatformsLoading} = useGlobalStoreSupportedPlatform()
  const {pending} = useGlobalConsignmentFee()
  const {currentDescriptionWildcardList} = useSiteSettings()

  // might add another global state for eg: userRole, userTypes, etc.

  const value = useMemo(
    () => ({
      hasMop: validateHasMop(user),
      user,
      userLoading,
      userRefech,
      isEnterprise: validateEnterpriseUser(user),
      userType: validateEnterpriseUser(user) ? 'enterprise' : '',
      pending,
      supportedPlatforms,
      supportedPlatformsLoading,
      currentDescriptionWildcardList,
    }),
    [user, userRefech, userLoading, supportedPlatforms, supportedPlatformsLoading, pending],
  )

  return (
    <GlobalStoreContext.Provider value={value}>
      {/** TODO: this should be remove after fixing all UserTypeContext to use useGlobalStore hook */}
      <UserTypeContext.Provider value={value.userType}>{children}</UserTypeContext.Provider>
    </GlobalStoreContext.Provider>
  )
}
