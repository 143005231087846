import React, {useEffect, useState} from 'react'
import clx from 'classnames'
import {isEmpty} from 'lodash'
import {Box, Grid, TextField, Typography, CircularProgress} from '@mui/material'
import {styled} from '@mui/material/styles'

import {ReactComponent as AddPhoto} from 'assets/img/AddPhoto.svg'
import {ReactComponent as EmptyMyImages} from 'assets/img/EmptyMyImages.svg'
import CopytCard from 'components/CopytCard'
import {useUploader} from 'components/uploder/hook'
import {CopytLabel} from 'views_v2/lib/snippets'
import DraggableImage from 'views_v2/lib/components/DragabbleImage'
import {useModifier} from 'stores/useModifier'

export const CopytBox = styled(TextField)(({theme}) => ({
  ...theme,
  display: 'none',
}))

const Input = styled('input')({
  opacity: 0,
  cursor: 'pointer',
  height: '100%',
  width: '100%',
  position: 'absolute',
})

const InventoryImages = (props) => {
  const {
    formFields,
    forceUpdate,
    imageArr = [],
    deleteImage,
    setFormFields,
    isSimplified = false,
    isListing = false,
  } = props
  const setAddItemPage = useModifier((state) => state.setAddItemPage)

  const {loading: uploaderLoading, onUpload} = useUploader()

  const [uploadingAssets, setUploadingAssets] = useState([])

  const onDragOver = (event) => {
    event.stopPropagation()
    event.preventDefault()
  }

  const onDragEnter = (event) => {
    event.stopPropagation()
  }

  const onFileDrop = (event) => {
    event.stopPropagation()
  }

  const onUploadImage = async (e) => {
    setAddItemPage.isSaveButtonDisabled(true)
    const files = Array.from(e.target.files)
    const newUploadingAssets = files.map((file) => ({
      id: URL.createObjectURL(file),
      file,
    }))
    setUploadingAssets((prev) => [...prev, ...newUploadingAssets])

    for (const {file, id} of newUploadingAssets) {
      onUpload(file, (uploaded) => {
        setFormFields((s) => ({
          ...s,
          assets: [
            ...(s.assets || []),
            {
              asset: uploaded,
              new: true,
            },
          ],
        }))
        setUploadingAssets((prev) => prev.filter((asset) => asset.id !== id))
      })
    }
    forceUpdate()
  }
  useEffect(() => {
    if (!uploadingAssets?.length) {
      setAddItemPage.isSaveButtonDisabled(false)
    }
  }, [uploadingAssets])

  const hasAsset = Array.isArray(formFields?.assets) && !isEmpty(formFields?.assets)

  const EmptyPhotos = () => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        height="330px"
        key={Math.floor(Math.random() * 100) + 1}
      >
        <EmptyMyImages className="empty-img-svg" />
        <Box className="no-photos">No Photos Yet</Box>
        <Box className="when-upload">When you upload your photos, they'll appear here.</Box>
      </Box>
    )
  }

  const ImageUploadContainer = () => {
    return (
      <Box
        onDragEnter={onDragEnter}
        onDragOver={onDragOver}
        onDrop={onFileDrop}
        sx={{
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          borderRadius: 1,
          boxShadow: '0 1px 8px 0 rgba(57, 5, 51, 0.4)',
          border: 'dashed 1px rgba(57, 5, 51, 0.4)',
          position: 'relative',
        }}
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Box display="flex">
          <AddPhoto className="img-upload-empty" />
          <Box display="flex" flexDirection="column" mr={2}>
            <Box className={imageArr.length <= 9 ? 'drop-here' : 'drop-here-disabled'}>
              Drop your image here, or{' '}
              <Typography display="inline" color="#d5239e !important">
                browse
              </Typography>{' '}
              your files
            </Box>
            <Box className={imageArr.length <= 9 ? 'image-supports' : 'image-supports-disabled'}>
              Supports: JPEG, JPEG2000, and PNG
            </Box>
          </Box>
        </Box>
        <Input
          multiple
          disabled={imageArr.length > 9 || uploaderLoading}
          type="file"
          accept="image/png, image/jpeg"
          name="tempImage"
          onChange={onUploadImage}
          tabIndex="26"
        />
      </Box>
    )
  }

  const moveImage = (fromIndex, toIndex) => {
    setFormFields((s) => {
      const updatedAssets = [...s.assets]
      const [movedItem] = updatedAssets.splice(fromIndex, 1)
      updatedAssets.splice(toIndex, 0, movedItem)
      return {...s, assets: updatedAssets}
    })
  }

  const UploadedImageContainer = () => {
    return (
      <CopytCard
        className={clx('myimages', (isSimplified || isListing) && 'simplified', !hasAsset && 'nophotos')}
      >
        {formFields?.assets?.length > 0 && formFields?.assets.every((s) => s.deletedAt) && (
          <EmptyPhotos />
        )}
        {hasAsset ? (
          formFields.assets.map((PA, index) => {
            if (PA.deletedAt) return null
            return (
              <DraggableImage
                showDelete={isListing}
                key={index}
                index={index}
                src={PA?.asset?.url}
                moveImage={moveImage}
                deleteImage={() => deleteImage(PA)}
              />
            )
          })
        ) : (
          <EmptyPhotos />
        )}
        {uploadingAssets.map(({id}) => (
          <Box
            key={id}
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="150px"
            width="150px"
            border="1px dashed #ccc"
            m={1}
          >
            <CircularProgress />
          </Box>
        ))}
      </CopytCard>
    )
  }

  return (
    <CopytCard className={clx('inventory-images')}>
      <CopytCard.Header>
        <CopytCard.Text tag="div">Images</CopytCard.Text>
      </CopytCard.Header>
      <CopytCard.Body className={(isSimplified || isListing) && 'simplified'}>
        <Grid container justifyContent="space-between">
          {isSimplified || isListing ? (
            <React.Fragment>
              <Grid item xs={12} lg={12}>
                <UploadedImageContainer />
              </Grid>
              <Grid item width="100%" display={isListing && 'none'}>
                {ImageUploadContainer()}
              </Grid>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Grid item xs={12} lg={5}>
                <CopytLabel fontSize="0.9rem !important" marginTop="10px">
                  Image Upload
                </CopytLabel>
                {ImageUploadContainer()}
              </Grid>
              <Grid item xs={12} lg={6.5}>
                <CopytLabel fontSize="0.9rem !important" marginTop="10px">
                  My Images
                </CopytLabel>
                <UploadedImageContainer />
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </CopytCard.Body>
    </CopytCard>
  )
}

export default InventoryImages
