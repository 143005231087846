import React, {useMemo, useState} from 'react'
import {Box, Typography, Stack, CircularProgress, Chip} from '@mui/material'
import Select from 'react-select'
import {includes, isEmpty, sortBy, values} from 'lodash'

import {metricSize, weightSize} from 'assets/data/options/dimension_options'
import CopytModal from 'components/CopytModal'
import {ModalType} from 'enums'
import {_replaceWildCards, capitalizeText} from 'util/string_utils'
import {CopytLabel, CopytStyledButton, EmptyData, FieldGroup} from 'views_v2/lib/snippets'
import {useShipmentParcelPreset} from 'service/hook/useShipments'

import MdlCreateBoxPreset from './MdlCreateBoxPreset'

const MdlPackageDetails = (props) => {
  const {setModalType, parcel, setParcel, shipment} = props
  const {parcelPreset, isLoading} = useShipmentParcelPreset()
  const [isOpenCreateBox, setIsOpenCreateBox] = useState(false)

  const memomizedPreset = useMemo(() => {
    if (!isEmpty(parcelPreset)) {
      const mappedPresets = parcelPreset.map((s) => ({
        value: s,
        label: `${s?.name}: ${s?.weight} ${s?.massUnit} - ${s?.length} x ${s?.width} x ${s?.height} ${s?.distanceUnit}`,
      }))

      return sortBy(mappedPresets, [(preset) => preset.value.name])
    }
    return []
  }, [parcelPreset])

  return (
    <Box padding="0 !important">
      <Box className="modal-title">
        <Box className="modal__heading" mx="0px !important">
          <Typography variant="h1" textAlign="left" pad>
            <span>Enter Package Details</span>{' '}
            <Chip label={capitalizeText(shipment?.type)} color="warning" size="small" />
          </Typography>
        </Box>
      </Box>

      <Box className="modal-body" padding={0} my={2}>
        <Stack direction="column" px={2} mb={4}>
          <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
            <Box flex={1}>
              <Select
                className="react-select info"
                classNamePrefix="react-select"
                closeMenuOnSelect
                isLoading={isLoading}
                disabled={isLoading}
                placeholder="Select Box Preset"
                styles={{
                  control: (base) => ({
                    ...base,
                    borderColor: 'var(--lighter) !important',
                    marginBottom: '5px',
                  }),
                }}
                onChange={(e) => {
                  if (e) {
                    const {distanceUnit, height, length, massUnit, name, weight, width} = e?.value
                    setParcel({
                      ...parcel,
                      distance_unit: distanceUnit,
                      height,
                      length,
                      mass_unit: massUnit,
                      name,
                      weight,
                      width,
                    })
                  } else {
                  }
                }}
                options={memomizedPreset}
              />
            </Box>

            <CopytStyledButton
              variant="contained"
              color="primary"
              onClick={() => setIsOpenCreateBox(true)}
            >
              Add New
            </CopytStyledButton>
          </Stack>

          <CopytLabel style={{fontSize: '0.9rem', marginTop: '20px'}}>Dimensions</CopytLabel>
          <Stack direction="row" alignItems="flex-end" gap={2}>
            <Stack width="80%" direction="row" gap={1} alignItems="end">
              <FieldGroup
                id="numeric"
                fixedDecimalScale={false}
                decimalScale={4}
                sLabel="Length"
                value={parcel?.length}
                onChange={(event) => {
                  const value = event.target.value
                  setParcel((prevState) => ({...prevState, length: value}))
                }}
              />
              <Typography style={{marginBottom: '15px', marginX: '2px'}}>×</Typography>
              <FieldGroup
                id="numeric"
                fixedDecimalScale={false}
                decimalScale={4}
                sLabel="Width"
                value={parcel?.width}
                onChange={(event) => {
                  const value = event.target.value
                  setParcel((prevState) => ({...prevState, width: value}))
                }}
              />
              <Typography style={{marginBottom: '15px', marginX: '2px'}}>×</Typography>
              <FieldGroup
                id="numeric"
                fixedDecimalScale={false}
                decimalScale={4}
                sLabel="Height"
                value={parcel?.height}
                onChange={(event) => {
                  const value = event.target.value
                  setParcel((prevState) => ({...prevState, height: value}))
                }}
              />
            </Stack>
            <Box width="20%" marginBottom="5px">
              <Select
                className="react-select info"
                classNamePrefix="react-select"
                closeMenuOnSelect
                onChange={({value}) => {
                  setParcel((prevState) => ({...prevState, distance_unit: value}))
                }}
                styles={{
                  control: (base) => ({
                    ...base,
                    borderColor: 'var(--lighter) !important',
                    marginBottom: '5px',
                  }),
                }}
                value={{
                  label: parcel.distance_unit,
                  value: parcel.distance_unit,
                }}
                options={metricSize}
              />
            </Box>
          </Stack>
          <CopytLabel style={{fontSize: '0.9rem', marginTop: '15px'}}>Package Weight</CopytLabel>
          <Stack direction="row" spacing={2} alignItems="flex-end" width="50%">
            <FieldGroup
              id="numeric"
              fixedDecimalScale={false}
              decimalScale={4}
              sLabel="Weight"
              value={parcel?.weight}
              onChange={(event) => {
                const value = event.target.value
                setParcel((prevState) => ({...prevState, weight: value}))
              }}
            />
            <Box width="100%">
              <Select
                className="react-select info"
                classNamePrefix="react-select"
                closeMenuOnSelect
                value={{
                  label: parcel.mass_unit,
                  value: parcel.mass_unit,
                }}
                onChange={({value}) => {
                  setParcel((prevState) => ({...prevState, mass_unit: value}))
                }}
                options={weightSize}
                styles={{
                  control: (base) => ({
                    ...base,
                    borderColor: 'var(--lighter) !important',
                    marginBottom: '10px',
                  }),
                }}
              />
            </Box>
          </Stack>
        </Stack>

        <Stack
          direction="row"
          justifyContent="flex-end"
          pr={2}
          pt={2}
          borderTop="1px solid #eee"
          spacing={2}
        >
          <CopytStyledButton
            variant="contained"
            color="primary"
            disabled={Object.values(parcel).some((p) => {
              if (p === undefined || p === null || p === '') {
                return true
              }
            })}
            onClick={() => setModalType(ModalType.ADDRESS)}
          >
            Continue
          </CopytStyledButton>
        </Stack>
      </Box>
      {isOpenCreateBox && (
        <CopytModal
          className="no-padding"
          id="create-box-preset"
          show={isOpenCreateBox}
          size="xl"
          toggle={() => null}
          closeButton={{
            hidden: true,
          }}
        >
          <MdlCreateBoxPreset onCloseModal={() => setIsOpenCreateBox(false)} />
        </CopytModal>
      )}
    </Box>
  )
}

export default MdlPackageDetails
