import React from 'react'
import {GridToolbarQuickFilter} from '@mui/x-data-grid'
import {Box} from '@mui/material'
import CopytButton from './CopytButton'
import {FileDownload} from '@mui/icons-material'

const QuickSearchToolbar = (p) => {
  const {onSearch, onExport, onChange, value} = p || {}

  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
      }}
      style={{
        backgroundColor: '#faebf5',
        borderBottom: '1px solid #a5709b',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Box>
        <CopytButton
          onClick={onExport}
          style={{
            padding: '12px 0',
            height: '35px',
            color: 'var(--darker)',
          }}
        >
          <FileDownload /> Export
        </CopytButton>
      </Box>
      {!onChange ? (
        <Box>
          <GridToolbarQuickFilter
            style={{color: 'var(--success)'}}
            autoFocus
            debounceMs={0}
            quickFilterParser={(searchInput) =>
              searchInput
                .split(',')
                .map((value) => value.trim())
                .filter((value) => value !== '')
            }
          />
        </Box>
      ) : (
        <GridToolbarQuickFilter
          style={{
            color: 'var(--success)',
          }}
          onChange={onChange}
          value={value}
        />
      )}
    </Box>
  )
}

export default QuickSearchToolbar
