import {get} from 'lodash'

export const getPlatformName = (order) => {
  return get(order, 'listingPlatform.platform.name')
}

export const getConsignEmail = (order) => {
  return get(order, 'listing.product.consign.consignor.email')
}

export const getFee = (order) => {
  return get(order, 'listing.product.consign.application.fee.value')
}
