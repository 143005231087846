import React, {useCallback} from 'react'
import {useHistory} from 'react-router-dom'
import {ReactComponent as PlatformsEmptyStateSvg} from '../assets/img/platforms-empty-state.svg'

// this will display empty platform message

export const EmptyPlatform = () => {
  const history = useHistory()

  const onPlatforms = useCallback(() => {
    history.push('/admin/platforms/management')
  }, [history])

  return (
    <div className="empty-state-card">
      <PlatformsEmptyStateSvg className="empty-state-svg"></PlatformsEmptyStateSvg>
      <div className="empty-state-text">
        <p className="empty-state-header">You haven't enabled any platforms yet.</p>
        <p className="empty-state-body empty-state-body-payment-details">
          To list this item, head over to your{' '}
          <a onClick={onPlatforms} href="#">
            platforms
          </a>{' '}
          page to link your accounts and enable the platforms you would like to list to.
        </p>
      </div>
    </div>
  )
}
