
import { Box } from '@mui/material'
import styled from 'styled-components'

const ModalHeading = styled(Box)(({ theme }) => ({
  ...theme,
  marginLeft: "0 !important",
  marginRight: "0 !important",
  textAlign: "left"
}))
export default ModalHeading
