import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useGlobalStore } from 'provider/global_store/hook'
import Loader from 'react-loader-spinner'
import { Box,Grid } from '@mui/material'
import DetailView from 'components/DetailView'
import CopytCard from 'components/CopytCard'
import { LoadingBar } from 'views_v2/lib/snippets'
import { getPredefinedObject } from 'util/string_utils'
import { REGEX_WEBSITE_FORMAT } from 'util/Regex'
import { validationErrorStr } from 'resources/constants'
import { ModalType } from 'enums'
import { useCompany, useUser } from 'service/hook'
import { ContactInformation, CompanyInformation, ProfileInformation } from './cards'
import ProfileModals from './ProfileModals'
import './profile.scss'

const userArr = ['businessName', 'createdAt', 'email', 'firstName', 'id', 'isAcceptingApplications', 'isActive', 'lastName', 'phoneNumber', 'type', 'updatedAt']

const Profile = () => {
  const history = useHistory()
  const { isEnterprise } = useGlobalStore()
  const mounted = useRef(false)
  const [profile, setProfile] = useState(null)
  const [loading, setLoading] = useState(false)
  const [formFields, setFormFields] = useState(null)
  const [formErrors, setFormErrors] = useState({})
  const { company, updateCompany } = useCompany()
  const { user, updateUser } = useUser()
  const [modalType, setModalType] = useState(ModalType.UNDEFINED)

  useEffect(() => {
    mounted.current = true

    if (mounted.current && modalType === ModalType.UNDEFINED) {
      const urlParams = new URLSearchParams(history?.location?.search)
      const params = Object.fromEntries(urlParams)
      if (params?.hasOwnProperty('toggle')) {
        setModalType(ModalType.NOTICE)
      }
    }
    return () => {
      mounted.current = false
    }
  }, [])

  const onFormSubmit = async () => {
    try {
      setLoading(true)
      const userObj = getPredefinedObject(formFields, userArr)

      const companyObj = Object.assign({}, {
        name: formFields.businessName,
        address: formFields?.company?.address || '',
        city: formFields?.company?.city || '',
        country: '',
        industry: formFields?.company?.industry || '',
        state: formFields?.company?.state || '',
        website: formFields?.company?.website || '',
        zipcode: formFields?.company?.zipcode || ''
      })

      if ((isEnterprise || formFields.businessName) && company?.length > 0) {
        if (companyObj.website?.length > 0 && !REGEX_WEBSITE_FORMAT.test(companyObj.website)) {
          setLoading(false)
          return setFormErrors(Object.assign({}, { website: validationErrorStr.WEBSITE_FORMAT }))
        } else {
          setFormErrors({})
        }
        updateCompany({ id: company[0].id, value: companyObj })
      }
      updateUser(userObj)

      setLoading(false)
      setProfile(formFields)
    } catch (err) {
      setLoading(false)
      console.error(err)
      return err
    }
  }

  useEffect(() => {
    if (user?.hasOwnProperty('id')) {
      Object.keys(user).forEach((k) => {
        if (user[k] === null) Object.assign(user, {
          [k]: ''
        })
      })
      setProfile(user)
      setFormFields(user)
    }
  }, [user])

  if (!profile || !formFields) return <LoadingBar />

  const handleInputChange = (e) => {
    let key = "phoneNumber"
    let val = e
    if (e.hasOwnProperty('target')) {
      const { name, value } = e.target || {}
      key = name
      val = value
    }

    setFormFields((prevState) => ({ ...prevState, [key]: val }))
  }

  const handleCompanyChange = (e) => {
    const { name, value } = e.target;
    setFormFields((prevState) => ({
      ...prevState,
      company: {
        ...prevState.company,
        [name]: value
      }
    }))
  };

  return (
    <div className="p-detail-flex profile">
      <div className="p-detail-toolbar no-print">
        <div className="btn-save-position">
          <button
            disabled={loading || (
              _.isEqual(getPredefinedObject(formFields, userArr), getPredefinedObject(profile, userArr))
              && _.isEqual(formFields?.company, profile?.company)
            )}
            className="btn-primary ripple"
            onClick={onFormSubmit}
          >
            {loading ? (
              <Loader
                type="TailSpin"
                color="white"
                height={20}
                width={20}
                className="import-button"
              />
            ) : (
              <>Save</>
            )}
          </button>
        </div>
      </div>
      <DetailView.Panel scrollable>
        <Box display="flex" justifyContent="space-around">
        <CopytCard className="profile-big-card card-layout">
          <Grid container spacing={1}>
            <Grid item xs={12} lg={3}>
              <ProfileInformation
                firstName={profile.firstName || null}
                lastName={profile.lastName || null}
                city={profile?.company?.city || null}
                logo={profile?.platform?.logo_url}
              />
            </Grid>
            <Grid item xs={12} lg={isEnterprise ? 4 : 9}>
              <ContactInformation
                {...{ handleInputChange }}
                firstName={formFields?.firstName}
                lastName={formFields?.lastName}
                email={formFields?.email}
                phoneNumber={formFields?.phoneNumber}
              />
            </Grid>
            {isEnterprise && (
              <Grid item xs={12} lg={5}>
                <CompanyInformation
                  {...{ handleInputChange, handleCompanyChange, formErrors }}
                  businessName={formFields?.businessName}
                  company={formFields?.company}
                />
              </Grid>
            )}
          </Grid>
        </CopytCard>
        </Box>
        
        <ProfileModals
          {...{
            isEnterprise,
            history,
            modalType,
            setModalType
          }}
        />
      </DetailView.Panel>
    </div>
  )
}

export default Profile
