import React from 'react'
import { NumericFormat } from 'react-number-format'
import { Box, Grid } from '@mui/material'
import {
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  Close as CloseIcon
} from '@mui/icons-material/'
import { CopytLabel} from 'views_v2/lib/snippets'

const SizesContainer = (props) => {
  const {
    arr = [],
    errorMessage,
    handleArrow,
    handleValueChange,
    onDeleteItem
  } = props

  if (Array.isArray(arr) && arr.length > 0) {
    return (
      <Box
        // maxHeight="150px"
        overflow="hidden auto"
        mt={1}
        border="1px solid #eee"
        borderRadius={2}
        p="0 10px"
      >
        {arr.map((s, i) => {
          return (
            <Grid container key={i} alignItems="center" justifyContent="space-between" py={1} borderBottom="1px solid darkgray">
              <Grid item xs={12} md={3}><CopytLabel fontSize="1rem">{s?.size || '--'}</CopytLabel></Grid>
              <Grid item xs={11} md={7}>
                <Box
                  display="flex"
                  border="1px solid"
                  borderColor={errorMessage && !s?.quantity ? 'red' : "#eee"}
                  borderRadius={2}
                  justifyContent="space-between"
                  height="3rem"
                >
                  <NumericFormat
                    value={s?.quantity}
                    allowNegative={false}
                    onValueChange={(value) => handleValueChange(s, value)}
                    placeholder="Quantity *"
                    className="numeric-input"
                    isAllowed={(values) => {
                      const { formattedValue, floatValue } = values
                      return formattedValue === '' || (floatValue <= 9999 && floatValue >= 1)
                    }}
                  />
                  <Box display="flex" flexDirection="column">
                    <KeyboardArrowUpIcon onClick={() => handleArrow(s, 'inc')} />
                    <KeyboardArrowDownIcon onClick={() => handleArrow(s)} />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={1}><CloseIcon onClick={() => onDeleteItem(s)} /></Grid>
            </Grid>
          )
        })}
      </Box>
    )
  }

  return null
}


export default SizesContainer
