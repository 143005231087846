import React from 'react'
import PropTypes from 'prop-types'
import CopytModal from 'components/CopytModal'
import {ModalType} from 'enums'
import MdlPaywall, { ContentType } from 'views_v2/modules/Inventory/components/modal-contents/MdlPaywall'

const ModalContents = ({children, common, focus, onCloseModal}) => {
  if (focus !== ModalType.UNDEFINED) {
    return React.Children.map(children, (c, i) => {
      if (c.props?.modalType === focus) {
        // Show modal based on the given modalType
        return React.cloneElement(c, {...common, onCloseModal})
      }
      return null
    })
  }
  return null
}

const ProfileModals = (p) => {
  const {modalType, setModalType, modals} = p
  const m = modals[modalType]

  const onClose = () => {
    setModalType(ModalType.UNDEFINED)
  }

  return (
    <CopytModal
      className={m?.className || null}
      closeButton={m?.closeButton}
      id={m?.id}
      show={modalType > ModalType.UNDEFINED}
      size={m?.size || 'sm'}
      toggle={onClose}
    >
      <ModalContents focus={modalType} common={p} onCloseModal={onClose}>
        <MdlPaywall
          modalType={ModalType.PAYWALL}
          onSaveChanges={() => setModalType(ModalType.UNDEFINED)}
        />
        <MdlPaywall
          modalType={ModalType.NOTICE}
          contentType={ContentType.UPGRADE_ACCOUNT}
        />
        {/* <MdlNotice modalType={ModalType.NOTICE} /> */}
      </ModalContents>
    </CopytModal>
  )
}

ProfileModals.propTypes = {
  modals: PropTypes.object,
}

ProfileModals.defaultProps = {
  modals: {
    [ModalType.PAYWALL]: {
      className: 'paywall-modal',
      size: 'md'
    },
    [ModalType.NOTICE]: {
      className: 'paywall-modal',
      size: 'md',
    },
  },
}

export default ProfileModals
