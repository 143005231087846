import React from 'react'
import { Box, Typography } from '@mui/material'
import { Add as AddIcon } from '@mui/icons-material'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { CopytButton, CopytTooltip } from 'views_v2/lib/snippets'

const AddBtn = ({
  routeChange
}) => {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <CopytTooltip title={isSmallScreen && 'Add Item'} arrow={false} backgroundColor="#eee" color="#d5239e">
      <Box component="span">
        <CopytButton
          variant="contained"
          color="primary"
          onClick={() => routeChange('/admin/inventory/addItem')}
          style={{
            borderRadius: '6px',
            padding: '12px 16px',
            border: '1px solid #ffffff',
            height: '40px'
          }}
        >
          <AddIcon color="white" />
          {!isSmallScreen && <Typography fontWeight={700} fontSize='1em' color='white'>Add Item</Typography>}
        </CopytButton>
      </Box>
    </CopytTooltip>
  )
}

export default AddBtn
