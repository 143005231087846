import React, {useState} from 'react';
import {Box, Typography, Stack} from '@mui/material';
import {CopytStyledButton, FieldGroup} from 'views_v2/lib/snippets';

const MdlDiscordName = p => {
  const {user, onSaveChanges, onCloseModal} = p;
  const [discordName, setDiscordName] = useState(user?.discordUserName || 'Username');

  return (
    <Box padding="0 !important">
      <Box className="modal-title">
        <Box className="modal__heading" mx="0px !important">
          <Typography variant="h1">Update Username</Typography>
        </Box>
      </Box>
      <Box className="modal-body" padding={0} my={2}>
        <Box mx={4}>
          <FieldGroup
            sLabel="Username"
            name="discordName"
            type="text"
            placeholder={discordName}
            onChange={e => setDiscordName(e.target.value)}
          />
        </Box>

        <Stack direction="row" justifyContent="flex-end" pr={1} pt={1} mt={5} borderTop="1px solid #eee" spacing={2}>
          <CopytStyledButton variant="outline" color="primary" onClick={() => onCloseModal()}>
            Cancel
          </CopytStyledButton>
          <CopytStyledButton
            variant="contained"
            onClick={() => onSaveChanges({updateDiscordName: Object.assign(user, {discordUserName: discordName})})}
          >
            Update
          </CopytStyledButton>
        </Stack>
      </Box>
    </Box>
  );
};

export default MdlDiscordName;
