import React, {useEffect} from 'react'
import {getShopifyRedirectUri} from 'views/platforms/lib/util'
import {ShopifyScopes} from 'constant/shopify'

const Register = (props) => {
  // The purpose of this useEffect is to start the activation process for a
  // shopify shop without requiring login first
  useEffect(() => {
    const fetch = async () => {
      // we will use this useEffect to capture the shopify access token given to us upon redirect
      const url = new URL(window.location.href)
      if (url.searchParams.get('shop')) {
        performShopifyAuth(url.searchParams.get('shop').replace('.myshopify.com', ''))
      }
    }
    fetch()

    //check if they are logged in or not
    //redirect to shopify Oauth page
  }, [])

  const performShopifyAuth = async (shopifyStore) => {
    //const response = await axios.get(`//${apiConfig.api_prefix}/shopify/login/${shopifyStore}`)
    // we dont want a user to open a new tab because we wanna make sure auth credetails set with auth0
    // const uri = 'https://development.dashboard.copyt.io'
    const callbackUrl = getShopifyRedirectUri()
    const scope = ShopifyScopes.join(',')

    // note: todo: change client_id ith env values
    const url = `https://${shopifyStore}.myshopify.com/admin/oauth/authorize?client_id=816ebde63bac19ce02d8c56d57b8d9a3&scope=${scope}&redirect_uri=${callbackUrl}&state=123123123`
    // console.log("redirecting",url)
    window.open(url, '_self')
  }

  return <div className="content">Loading...</div>
}

export default Register
