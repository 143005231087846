
import { Stack } from '@mui/material'

const ModalFooter = ({ children, ...props }) => (
  <Stack direction="row" justifyContent="flex-end" pr={1} pt={1} mt={5} borderTop="1px solid #eee" spacing={2} {...props}>
    {children}
  </Stack>
)

export default ModalFooter
