import React, {useState, useEffect} from 'react'
import {Row, FormGroup, Label, Input} from 'reactstrap'
import {useUser} from 'service/hook'
import {formatName} from 'util/string_utils'
import FieldGroup from 'views_v2/lib/snippets/FieldGroup'
import onKeyPress from 'views_v2/lib/common/onKeyPress'
import {ApplicationShippingTypeStr, validationErrorStr} from 'resources/constants'
import {useConsignmentShops, useConsignorProfile} from 'service/hook'
import {LoadingBar} from 'views_v2/lib/snippets'
import {useHistory} from 'react-router-dom'
import {ActionRedirect} from 'enums'
import {RedirectAction} from 'enums'
import {TabPanel} from '../TabPanels'

const MdlConsignorProfile = (p) => {
  const {user} = useUser()
  const {formFields} = p || {}

  const {data, isLoading} = useConsignorProfile(formFields?.selectedConsignorId)

  if (isLoading || !data) {
    return (
      <div
        style={{
          height: '500px',
        }}
      >
        <div className="modal-title">
          <div className="modal__heading">
            <h1 style={{textAlign: 'left'}}>Consignor Profile</h1>
          </div>
        </div>

        <LoadingBar text="" />
      </div>
    )
  }

  const ownerCompany = data?.application?.owner.company
  const addressArray = [
    ownerCompany?.address,
    ownerCompany?.city,
    ownerCompany?.state,
    ownerCompany?.zipcode,
  ].filter(Boolean)

  const address = addressArray.join(', ')

  const {application, metrics} = data

  return (
    <div className>
      <div className="modal-title">
        <div className="modal__heading">
          <h1 style={{textAlign: 'left'}}>Consignor Profile</h1>
        </div>
      </div>

      <div
        className="modal-body"
        style={{
          textAlign: 'center',
        }}
      >
        <div
          style={{
            marginBottom: '30px',
          }}
        >
          <h1
            style={{
              marginBottom: '5px',
              fontWeight: 'bold',
            }}
          >
            {application.fullname}
          </h1>
          <p>{address || 'No Address'}</p>
        </div>
        <div>
          <h2
            style={{
              marginBottom: '10px',
              fontSize: '1rem',
              fontWeight: '400',
              textDecoration: 'underline',
            }}
          >
            Contact Information
          </h2>
          <p>{application.owner.phoneNumber}</p>
          <p>{application.owner.email}</p>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          gap: '24px',
          marginTop: '24px',
          marginBottom: '28px',
        }}
      >
        <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
          }}
        >
          <GoTo
            name={`Unlisted Inventory (${metrics.unlistedProductsDetails.count})`}
            href={encodeURI(
              `/admin/inventory/viewInventory/unlisted?${RedirectAction.SEARCH}=${application.owner.email}`,
            )}
          />
          <GoTo
            name={`Listed Inventory (${metrics.listedProductsDetails.count})`}
            href={encodeURI(
              `/admin/inventory/viewInventory/listed?${RedirectAction.SEARCH}=${application.owner.email}`,
            )}
          />
          <GoTo
            name={`Orders (${metrics.ordersDetails.count})`}
            href={`/admin/orders?${RedirectAction.SEARCH}=${application.owner.email}`}
          />
          <GoTo
            name={`Payout History`}
            href={`/admin/consignments/ledger?${RedirectAction.SEARCH}=${application.owner.email}`}
          />
          <GoTo
            name={`Withdraws (${metrics.withdrawsDetails.count})`}
            href={`/admin/consignments/submitted-items?${RedirectAction.SEARCH}=${application.owner.email}`}
          />
        </div>
        <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <h2
            style={{
              marginBottom: '0px',
              fontSize: '1rem',
              fontWeight: 'bold',
              backgroundColor: '#63124A',
              padding: '10px 15px',
              color: 'white',
            }}
          >
            Quick Statistics
          </h2>
          <Statistic name="Total Sales" value={metrics.processedProductsDetails.sales} />
          <Statistic name="Total Payouts" value={metrics.ordersDetails.processed.value} />
          <Statistic name="Pending Payouts" value={metrics.ordersDetails.pending.value} />
        </div>
      </div>
    </div>
  )
}

export default MdlConsignorProfile

const GoTo = ({name, href}) => {
  const history = useHistory()

  return (
    <div
      href={href}
      style={{
        color: '#0C3BE4',
        fontSize: '1.05rem',
        fontWeight: 'semibold',
        textDecoration: 'underline',
        cursor: 'pointer',
      }}
      onClick={() => history.push(href)}
    >
      {name}
    </div>
  )
}

const Statistic = ({name, value}) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px 15px',
        backgroundColor: '#D9D9D9',
        alignItems: 'center',
        border: '1px solid black',
      }}
    >
      <h5
        style={{
          fontSize: '1.05rem',
          fontWeight: 'bold',
          margin: 0,
        }}
      >
        {name}
      </h5>
      <p
        style={{
          fontSize: '1.2rem',
          fontWeight: 'bold',
          color: '#F615ED',
          margin: 0,
        }}
      >
        {value.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        })}
      </p>
    </div>
  )
}
