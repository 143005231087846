export const createTokenOptions = (scope) => {
  return {}
}

export const createRequestOptions = (token) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
}

// request: Request eg. onError: (request) =>{}
export const getMessage = (request, d = '') => {
  return request?.response?.data?.message || ''
}
