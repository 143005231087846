import React from 'react'
import {Typography, Switch, Stack} from '@mui/material'
import {styled} from '@mui/material/styles'

const AntSwitch = styled(Switch)(({theme}) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiButtonBase-root': {
    position: 'absolute',
    padding: '2px !important',
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#5d0953',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: '#00000040',
    boxSizing: 'border-box',
  },
}))

const CopytSwitch = ({labelStart, labelEnd, checked, onChange, _textStart, _textEnd, ...props}) => {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {labelStart && <Typography {..._textStart}>{labelStart}</Typography>}
      <AntSwitch
        checked={checked}
        onChange={onChange}
        {...props}
        inputProps={{'aria-label': 'ant design'}}
      />
      {labelEnd && <Typography {..._textEnd}>{labelEnd}</Typography>}
    </Stack>
  )
}

export default CopytSwitch
