import {useContext, useEffect, useState, useRef} from 'react'
import {useAuth0} from '@auth0/auth0-react'
import {paths} from 'constant/api'
import axios from 'axios'
import {createRequestOptions} from 'util/network'
import {GlobalStoreContext} from './constant'
import * as Sentry from '@sentry/react'

// public
export function useGlobalStore() {
  const {
    isEnterprise,
    hasMop,
    userType,
    user,
    userLoading,
    supportedPlatforms,
    supportedPlatformsLoading,
    pending,
    userRefech,
  } = useContext(GlobalStoreContext)

  // should list all export for better backread ang intellecense
  return {
    isEnterprise,
    hasMop,
    userType,
    user,
    userRefech,
    userLoading,
    supportedPlatforms,
    supportedPlatformsLoading,
    pending,
  }
}

// private
export function useGlobalStoreSupportedPlatform() {
  const [supportedPlatforms, setSupportedPlatforms] = useState([])
  const [supportedPlatformsLoading, setLoading] = useState(false)
  const {getAccessTokenSilently} = useAuth0()

  useEffect(() => {
    ;(async () => {
      try {
        setLoading(true)
        const token = await getAccessTokenSilently()
        const response = await axios.get(paths.supportedPlatform, createRequestOptions(token))
        setSupportedPlatforms(response.data.data)
      } catch (e) {
        Sentry.captureException(e)
        console.log(e)
      } finally {
        setLoading(false)
      }
    })()
  }, [getAccessTokenSilently])

  return {
    supportedPlatforms,
    supportedPlatformsLoading,
  }
}

export function useGlobalConsignmentFee() {
  const [pending, setPending] = useState([])
  const {getAccessTokenSilently} = useAuth0()

  useEffect(() => {
    ;(async () => {
      try {
        // setLoading(true)
        const token = await getAccessTokenSilently()
        const response = await axios.get(paths.consign, createRequestOptions(token))
        const pending = response.data?.data?.filter((s) => s?.status === 'pending')?.length || 0
        setPending(pending)
      } catch (e) {
        Sentry.captureException(e)
        console.log(e)
      } finally {
        // setLoading(false)
      }
    })()
  }, [getAccessTokenSilently])

  return {
    pending,
  }
}
