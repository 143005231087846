import React, {useEffect, useRef, useState} from 'react'
import {urlWithoutParam} from 'util/window_utils'
import {UnlistedTable, ListedTable, SoldTable, ProcessedTable, InventoryCardList} from './tables'
import {chain, isEmpty, orderBy, chunk, last} from 'lodash'
import {categorySizes} from 'assets/data'

const WAIT_INTERVAL = 1000

const _identifyRegion = (size) => {
  if (size.startsWith('UK')) return 'uk'
  else if (size.startsWith('EU')) return 'eu'
  return 'us'
}

const _updatePage = (currentPage) => currentPage % 5

const InventoryBody = (p) => {
  const {chunkIndex, tabPanel, setFilter, showCard, selectedCriteria, filter, setChunkIndex} = p
  const timerRef = useRef(null)
  const [currentPage, setCurrentPage] = useState(filter?.page)
  const [chunks, setChunks] = useState(() => chunk(p.items.data, 100))

  const onPageChange = (newChunkIndex) => {
    if (timerRef?.current) {
      clearTimeout(timerRef?.current)
    }

    timerRef.current = setTimeout(() => {
      if (newChunkIndex >= chunks.length) {
        if (_updatePage(newChunkIndex) === 0) {
          setFilter((f) => ({...f, page: newChunkIndex, skip: Math.floor(newChunkIndex / 5) * f.take}))
        }
      } else {
        setChunkIndex(newChunkIndex)
      }

      const newUrl = urlWithoutParam(newChunkIndex)
      window.history.pushState({path: newUrl}, '', newUrl)
      setCurrentPage(newChunkIndex)
    }, WAIT_INTERVAL)
  }

  useEffect(() => {
    return () => {
      if (timerRef?.current) {
        clearTimeout(timerRef.current)
      }
    }
  }, [])

  useEffect(() => {
    if (p.items.data) {
      setChunks(chunk(p.items.data, 100))
      setChunkIndex(filter?.page >= 5 ? _updatePage(filter?.page) : filter?.page)
    }
  }, [p.items.data])

  useEffect(() => {
    setCurrentPage(currentPage)
    onPageChange(currentPage)
    setChunkIndex(_updatePage(currentPage))
  }, [currentPage])

  useEffect(() => {
    if (currentPage !== filter?.page) {
      setCurrentPage(filter?.page)
      onPageChange(filter?.page)
      setChunkIndex(_updatePage(filter?.page))
    }
  }, [filter?.page])

  const props = {
    ...p,
    currentPage,
    setCurrentPage,
    isCardList: false,
    allData: p?.items?.data,
    items: {...p.items, data: chunks[chunkIndex] || []},
  }

  if (showCard) {
    const processItemsWithChain = (items, selectedCriteria) => {
      if (isEmpty(items)) return []

      return chain(items)
        .groupBy((item) => selectedCriteria.map((criteria) => item[criteria]).join('-'))
        .map((group) => {
          const {minBuyPrice, minListPrice} = group.reduce(
            (acc, item) => {
              const price = parseFloat(item.price)
              const retailPrice = parseFloat(item.retailPrice)
              if (!isNaN(price)) acc.minBuyPrice = Math.min(acc.minBuyPrice, price)
              if (!isNaN(retailPrice)) acc.minListPrice = Math.min(acc.minListPrice, retailPrice)
              return acc
            },
            {minBuyPrice: Infinity, minListPrice: Infinity},
          )

          const sortedItems = orderBy(
            group,
            [
              (item) => {
                const {category, size} = item
                let arr =
                  category === 'Accessories' || category === 'Apparel'
                    ? categorySizes.apparel
                    : category.includes('toddler')
                    ? categorySizes.toddler
                    : category.includes('kid')
                    ? categorySizes.kid
                    : categorySizes[_identifyRegion(size)] || []
                return arr.indexOf(size) !== -1 ? arr.indexOf(size) : Infinity
              },
            ],
            ['asc'],
          )

          return {
            ...selectedCriteria.reduce(
              (acc, criteria) => ({...acc, [criteria]: group[0][criteria]}),
              {},
            ),
            BuyPrice: minBuyPrice === Infinity ? null : minBuyPrice,
            ListPrice: minListPrice === Infinity ? null : minListPrice,
            items: sortedItems,
          }
        })
        .value()
    }

    return (
      <InventoryCardList
        {...{selectedCriteria}}
        data={processItemsWithChain(p.items.data, selectedCriteria)}
        page={currentPage}
        onPageChange={(page) => {
          setCurrentPage(page)
          setSIds({})
        }}
        {...props}
      />
    )
  }

  if (tabPanel === 1) {
    return <ListedTable {...props} />
  } else if (tabPanel === 2) {
    return <SoldTable {...props} />
  } else if (tabPanel === 3) {
    return <ProcessedTable {...props} />
  }

  return <UnlistedTable {...props} />
}

export default InventoryBody
