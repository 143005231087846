import React, { memo, useEffect } from 'react'
import clx from 'classnames'
import {PublishedWithChanges as PublishedWithChangesIcon} from '@mui/icons-material'

export const PaywallEnterprise = (p) => {
  useEffect(() => {
    handleOpen()
}, [])

  const handleOpen = () => {
    const timer =  setTimeout(() => {
        p.onCloseModal()
     }, 5000)
      return () => clearTimeout(timer);
 }

  return (
    <div className={clx('paywall-wrapper')}>
      <div className="paywall-wrapper--body">
        <div>
          <PublishedWithChangesIcon />
          <div className="welcome-title">Checking your account before accessing your Enterprise Account</div>
          <div className="welcome-subtitle">Please wait as we complete your request</div>
          <div className="welcome-body">This process is automatic. Please allow up to 10 seconds...</div>
        </div>
      </div>
    </div>
  )
}

export default memo(PaywallEnterprise)
