import React, {useState, useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import clx from 'classnames'
import {pink} from '@mui/material/colors'
import {
  Box,
  Button,
  FormControlLabel,
  RadioGroup,
  Radio,
  Stack,
  Typography,
  Grid,
  FormControl,
  MenuItem,
  InputLabel,
} from '@mui/material'
import {useConsignmentShops} from 'service/hook'
import {EmptyData} from 'views_v2/lib/snippets'
import useEnterKeyListener from 'hooks/useEnterKeyListener'
import {styled} from '@mui/material/styles'
import Select from 'react-select'
import {CopytButton} from 'views_v2/lib/snippets'
import {CopytLabel} from 'views_v2/lib/snippets'
import InventoryModals from '../InventoryModals'
import {ModalType} from 'enums'
import {TabPanel} from 'enums'
import {ToastType} from 'enums'

const initialState = {
  editMode: false,
  modalType: ModalType.UNDEFINED,
  tab: TabPanel.CONSIGN_ITEM_UNLISTED,
  isDisabled: false,
  isLoading: false,
  toastType: ToastType.UNDEFINED,
}

const CButton = styled(CopytButton)(() => ({
  width: '20%',
  height: 35,
  borderRadius: '6px !important',
}))

export const SearchLabel = styled(InputLabel)(() => ({
  color: '#2f052a',
  fontSize: '0.9rem',
  marginBottom: '0',
}))

const MdlCreateBatch = (p) => {
  const history = useHistory()
  const [state, setState] = useState(initialState)
  const updateStateValue = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }))
  }
  const [formFields, setFormFields] = useState({
    isAcceptedContract: false,
    contractPdfLink: '',
  })
  const setModalType = (modalType) => updateStateValue('modalType', modalType)
  const [selectedOwnerId, setSelectedOwnerId] = useState(null)
  const [isAcceptedContract, setIsAcceptedContract] = useState(false)
  const {completedApplications} = useConsignmentShops({
    options: {
      enabled: true,
      staleTime: Infinity,
    },
    queries: {},
  })
  const [selecedPlatformOwnerId, setSelecedPlatformOwnerId] = useState(null)
  const {storeLocations, storeLocationsRefetch} = useConsignmentShops(
    {
      options: {
        enabled: true,
        staleTime: Infinity,
      },
      queries: {},
    },
    selecedPlatformOwnerId,
  )
  const [selectedLocation, setSelectedLocation] = useState(null)
  const [options, setOptions] = useState([])

  useEffect(() => {
    if (storeLocations.length > 0) {
      setOptions(
        storeLocations.map((storeLocation) => ({
          label: storeLocation.name,
          value: storeLocation.id,
        })),
      )
      setSelectedLocation({
        label: storeLocations[0].name,
        value: storeLocations[0].id,
      })
    } else {
      setSelectedLocation(null)
    }
  }, [storeLocations])

  useEffect(() => {
    setFormFields({
      ...formFields,
      isAcceptedContract: false,
    })
  }, [selectedOwnerId])

  const onFormSubmit = () => {
    if (formFields?.contractPdfLink && !formFields?.isAcceptedContract && !isAcceptedContract) {
      updateStateValue('modalType', ModalType.CONSIGNMENT_CONTRACT)
      return
    }
    const selectedLocationId = selectedLocation ? selectedLocation.value : null
    p.onCreateBatchSubmit({selectedOwnerId, selectedLocationId})
    p.onCloseModal()
  }

  useEnterKeyListener({querySelectorToExecuteClick: '#onFormSubmit'})

  return (
    <Box padding="0 !important">
      <Box className="modal-title">
        <Box className="modal__heading" mx="0px !important">
          <Typography variant="h1">Select Consignment Shop</Typography>
        </Box>
      </Box>
      <Box className="modal-body" padding={0} my={2}>
        {completedApplications?.length === 0 ? (
          <EmptyData title="No Consignment Shop">
            In order to consign a batch, you must first apply to and be approved by a store. Apply to a
            new store
            <span
              onClick={() => history.push('/admin/platforms/management')}
              className="pink-text ml-1 cursor-pointer"
            >
              here
            </span>
            .
          </EmptyData>
        ) : (
          <>
            <Stack px={2} spacing={2}>
              <CopytLabel sx={{fontSize: '0.9rem'}}>
                {' '}
                Select the Enterprise you would like to consign with.
              </CopytLabel>
              <Stack direction="row" spacing={1} pl={2}>
                <Stack
                  spacing={1}
                  sx={{
                    padding: 2,
                    overflow: 'auto',
                    maxHeight: '300px',
                    width: '100%',
                  }}
                >
                  <RadioGroup>
                    {completedApplications?.map((s, i) => (
                      <FormControlLabel
                        key={`control-label-shop-${i}`}
                        value={s?.platform?.id}
                        control={
                          <Radio
                            size="small"
                            sx={{
                              marginRight: '10px !important',
                              '&.Mui-checked': {
                                color: pink[600],
                              },
                            }}
                            key={`consignment-shop-${i}`}
                            onChange={(e) => {
                              setSelecedPlatformOwnerId(s.platform.owner.id)
                              setSelectedOwnerId(e.target.value)
                              setIsAcceptedContract(
                                completedApplications.find((a) => a.platform.id === e.target.value)
                                  ?.isAcceptedContract
                                  ? true
                                  : false,
                              )
                              setFormFields({
                                ...formFields,
                                contractPdfLink: s?.platform?.contract_pdf_link || '',
                              })
                              storeLocationsRefetch()
                            }}
                          />
                        }
                        label={s?.platform?.owner?.businessName || s?.platform.name}
                        sx={{
                          '& .MuiTypography-root': {
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            maxWidth: selectedOwnerId ? '250px' : '100%',
                            fontSize: '0.9rem',
                          },
                        }}
                      />
                    ))}
                  </RadioGroup>
                </Stack>
                {selectedOwnerId && (
                  <FormControl fullWidth>
                    <CopytLabel>Store Location</CopytLabel>
                    <Select
                      className="react-select info"
                      classNamePrefix="react-select"
                      onChange={(e) => {
                        setSelectedLocation(e)
                      }}
                      options={options}
                      value={selectedLocation}
                    />
                  </FormControl>
                )}
              </Stack>
            </Stack>

            <Stack
              direction="row"
              justifyContent="flex-end"
              pr={1}
              pt={1}
              mt={5}
              borderTop="1px solid #eee"
              spacing={2}
            >
              <CButton variant="outline" color="primary" onClick={p.onCloseModal}>
                Cancel
              </CButton>
              <CButton
                disabled={!selectedOwnerId}
                variant="contained"
                color="primary"
                onClick={onFormSubmit}
              >
                Submit
              </CButton>
            </Stack>
          </>
        )}
      </Box>
      <InventoryModals
        {...{
          formFields,
          setFormFields,
          setModalType,
          modalType: state.modalType,
          updateStateValue,
        }}
        setPreviewState={(e) => {
          updateStateValue('previewState', e)
        }}
      />
    </Box>
  )
}

export default MdlCreateBatch
