import React from 'react'
import {Box, Typography, Paper, Stack} from '@mui/material'

const PromoCode = ({
  discount,
  canUseDiscount,
  isApplyingShipmentDiscount,
  setSelectedDiscount,
  selectedDiscount,
  isApplyingDiscount,
}) => {
  return (
    <Paper
      elevation={3}
      disabled={isApplyingShipmentDiscount}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: 'white',
        borderRadius: '10px',
        position: 'relative',
        overflow: 'hidden',
        flexGrow: 1,
        '&:hover, &:focus, &:active': {
          outline: canUseDiscount && !isApplyingShipmentDiscount && '4px solid var(--light)',
        },
        cursor: canUseDiscount && !isApplyingShipmentDiscount && 'pointer',
        outline: canUseDiscount && selectedDiscount?.id === discount?.id && '4px solid var(--light)',
      }}
      onClick={() => {
        if (isApplyingShipmentDiscount || !setSelectedDiscount) return
        setSelectedDiscount(discount)
      }}
    >
      <Stack width="100%">
        <Box
          sx={{
            background: 'linear-gradient(45deg, var(--success), var(--primary))',
            paddingX: 2,
            paddingY: 1,
          }}
        >
          <Typography
            color="white !important"
            fontSize={14}
            fontWeight={600}
            padding={0.5}
            textAlign="center"
          >
            {discount.name}
          </Typography>
        </Box>
      </Stack>
    </Paper>
  )
}

export default PromoCode
