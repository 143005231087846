import React from 'react'
import classnames from 'classnames'

const PanelView = ({ isHidden, scrollable = false, children, style = null }) => {
  const className = classnames(
    'p-detail-tab-wrapper__panel',
    scrollable ? 'scrollable' : null,
    isHidden ? 'hidden-panel' : null
  )
  return <div className={className} {...{ style }}>{children}</div>
}

export default PanelView
