import * as React from 'react'
import {
  Box,
  Menu,
  MenuItem,
  Divider,
  IconButton,
  Typography,
  Link
} from '@mui/material'
import {
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@mui/icons-material'
import { styled } from '@mui/material/styles'

export const FooterItem = styled(MenuItem)(() => ({
  padding: '1px !important',
  width: '100% !important',
  fontSize: '0.9rem !important',
  minHeight: '0 !important',
  justifyContent: 'space-around !important',
  borderBottom: '1px solid #eee !important'
}))

export const AvatarIcon = styled(IconButton)(() => ({
  margin: '0 8px !important',
  padding: '3px !important',
  fontSize: '1.125rem',
  backgroundColor: 'lightgray',
  size: 'small'
}))

const MobileScreen = (p) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)
  const goTo = (url) => window.location.replace(url)

  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          justifyContent:'flex-end',
          marginTop: 0.9,
          position: 'absolute',
          bottom: 0,
          borderTop: '1px solid #eee',
          width: '100%',
          alignItems: 'center'
          }}>
          <Typography fontSize="0.8rem" display="flex" justifyContent="space-around" width="100%">
            <Link href="https://www.copyt.io/" target="_blank" sx={{textDecoration: 'none'}} color="inherit">
              © {new Date().getFullYear()} Copyt Inc.
            </Link>
          </Typography>
          <AvatarIcon
            onClick={handleClick}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup
            aria-expanded={open ? 'true' : undefined}
          >
            <KeyboardArrowUpIcon />
          </AvatarIcon>
      </Box>
      <Menu
        {...{ anchorEl, open }}
        id="footer-menu"
        onClose={handleClose}
        onClick={handleClose}
        MenuListProps= {{
          style: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: '5px',
          },
        }}
      >
        <FooterItem onClick={() => goTo('https://www.copyt.io')}>
          Copyt
        </FooterItem>
        <FooterItem onClick={() => goTo('https://www.copyt.io/about')}>
          About Us
        </FooterItem>
        <FooterItem onClick={() => goTo('https://www.copyt.io/support')}>
          Change Log
        </FooterItem>
      </Menu>
      
    </React.Fragment>
  );
}

export default MobileScreen