import React from 'react';
import {Grid} from '@mui/material';
import {Autorenew as AutorenewIcon, Cancel as CancelIcon} from '@mui/icons-material';

import {ModalType} from 'enums';
import CustomButton from './CustomButton';

const ProcessedItemBtn = ({tabPanel, markAsProcessed, setModalType, isLoading}) => {
  return (
    <Grid container gap={1} justifyContent="flex-end">
      <Grid item>
        <CustomButton
          icon={<CancelIcon />}
          disabled={isLoading}
          title={`Cancel ${tabPanel === 'sold' ? 'sale' : tabPanel}`}
          onClick={() => setModalType(ModalType.CANCEL_ITEM)}
          color="error"
        />
      </Grid>

      {tabPanel === 'sold' && (
        <Grid item>
          <CustomButton disabled={isLoading} icon={<AutorenewIcon />} title="Process" onClick={markAsProcessed} />
        </Grid>
      )}
    </Grid>
  );
};

export default ProcessedItemBtn;
