import {useQuery} from 'react-query'
import axios from 'axios'
import {useAuth0} from '@auth0/auth0-react'
import * as Sentry from '@sentry/react'
import {paths} from 'constant/api'
import {createRequestOptions} from 'util/network'

const getConsignorProfile = async (getAccessTokenSilently, consignorId) => {
  try {
    const token = await getAccessTokenSilently()
    const response = await axios.get(
      `${paths.consign}/profile/${consignorId}`,
      createRequestOptions(token),
    )
    return response?.data
  } catch (error) {
    Sentry.captureException(error)
    console.error('Error retrieving invoices', error)
    throw error
  }
}

export const useConsignorProfile = (consignorId) => {
  const {getAccessTokenSilently} = useAuth0()

  const consignorProfile = useQuery(
    [`consignorProfile`, consignorId],
    () => getConsignorProfile(getAccessTokenSilently, consignorId),
    {
      keepPreviousData: true,
    },
  )
  return consignorProfile
}

export default useConsignorProfile
