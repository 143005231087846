import React, { useCallback, useEffect, useState } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import { apiConfig } from 'config.js'
import { Button } from 'reactstrap'
import { useRedirCallback } from 'hooks/use_redir_callback'
import { usePlatformMarket } from 'service/hook/use_market'

const SquareCallback = (props) => {
  const history = useHistory()
  const [state, setState] = useState({ message: '', state: 'loading' })
  const { squareCallback, squareSetup } = usePlatformMarket()

  const process = useCallback(async () => {
    try {
      const queryParams = new URLSearchParams(history.location.search)
      setState((s) => ({ ...s, state: 'loading' }))
      const data = await squareCallback(history.location.search)
      if (!data) {
        throw new Error('Faild to process input.')
      }
      // console.log(data)
      setState((s) => ({ ...s, message: 'Setting up Square webhooks...' }))
      const setupResult = await squareSetup({
        accessToken: data.accessToken,
        expiresAt: data.expiresAt,
        merchantId: data.merchantId,
        refreshToken: data.refreshToken,
        api: apiConfig.api_url,
      })
      if (!setupResult) {
        throw new Error('Faild to setup credentails.')
      }
      setState((s) => ({ ...s, message: 'Success', state: 'success' }))
    } catch (e) {
      setState((s) => ({ ...s, state: 'failed', message: e.message }))
    }
  }, [history, squareCallback, setState])

  useRedirCallback(process)
  // useEffect(() => {
  //   process()
  // }, [process])

  if (state.state === 'loading') {
    return <div className="content">Processing Square Auth...</div>
  }

  if (state.state === 'failed') {
    return (
      <div className="content">
        Failed.
        <hr />
        <Button onClick={history.goBack}>Back</Button>
      </div>
    )
  }

  if (state.state === 'success') {
    return (
      <div className="content">
        Success.
        <Redirect to="/admin/platforms/d076073f-c763-430b-aa78-13a8f967c92d" />
      </div>
    )
  }

  return <div className="content">Loading...</div>
}

export default SquareCallback