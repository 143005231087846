import { useContext } from "react"
import { ToastMesageContext } from "./constant"

export const useToastMessage = function () {
  const c = useContext(ToastMesageContext)
  if (!c) {
    throw new Error('useToastMessage should be wrap on ToastMesage Provider.')
  }

  return { showToast: c.showToast }
}