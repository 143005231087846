import React from 'react'
import clx from 'classnames'
import _ from 'lodash'
import { Button, Box, Typography, Container } from '@mui/material'
import { toMoney } from 'util/string_utils'
import { getMinimumFee } from 'util/model'
import { useGlobalStore } from 'provider/global_store/hook'
import mw from 'views_v2/modules/Orders/middleware'
import { getConsignmentPayout } from 'util/model/consignment'

const ItemRow = ({ solds }) => {
  const { user, isEnterprise } = useGlobalStore()
  const minFee = getMinimumFee(user)

  return (
    <Box className="item-row">
      <Box display="flex" borderBottom="1px solid">
        <Box textAlign="center" borderRight="1px solid black" width="55%">
          <Typography>Item</Typography>
        </Box>
        <Box className="text-center quarter-width" borderRight="1px solid black">
          <Typography>Size</Typography>
        </Box>
        <Box textAlign="center" width="20%">
          <Typography>Payout</Typography>
        </Box>
      </Box>
      <Container sx={{ maxHeight: '500px', overflowY: 'auto', padding: '0 !important' }}>
        {solds?.length > 0 && (
          solds?.map((s, i) => {
            const payout = getConsignmentPayout(s)
            return (
              <Box display="flex" borderBottom="1px solid #eee" key={`solds-item-${i}`}>
                <Box textAlign="center" borderRight="1px solid black" width="55%" >
                  <Typography component='span'>{s?.listing?.product?.title || '--'}</Typography>
                </Box>
                <Box borderRight="1px solid black" alignItems="center" textAlign="center" justifyContent="space-around" display="flex" width="25%">
                  {s?.listing?.product?.size || '--'}
                </Box>
                <Box alignItems="center" textAlign="center" justifyContent="space-around" display="flex" width="20%">
                  {toMoney(payout) || '--'}
                </Box>
              </Box>
            )
          }
          )
        )}
      </Container>

    </Box>
  )
}

const MdlCreateBatch = (p) => {
  const { consignor, solds } = p?.data || {}

  return (
    <Box>
      <Box className="modal-title">
        <Box className="modal__heading">
          <Typography variant="h1">Batch Payout</Typography>
        </Box>
      </Box>
      <Box className="text-center" mt={2}>
        <Typography sx={{ fontWeight: 'fontWeightBold' }}>{consignor?.email || 'Consignor Name'}</Typography>
      </Box>
      <Box className="modal-body" style={{ padding: '24px 24px 0' }}>
        <ItemRow {...{ solds }} />
        <Box display="flex" justifyContent="space-evenly" mt={3}>
          <Button mt={2} className={clx('btn-header', 'secondary')} onClick={() => p.onCloseModal()}>
            Finish
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default MdlCreateBatch
