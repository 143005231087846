export const ListingStatusStr = {
  SOLD: 'sold',
  LISTING: 'listing',
  PROCESSED: 'processed',
}

export const ApplicationStatusStr = {
  PENDING: 'pending',
  APPROVED: 'approved',
  DENIED: 'denied',
  ERROR: 'error',
}

export const ApplicationShippingTypeStr = {
  SHIPPED: 'Shipped',
  DROP_OFF: 'Drop Off',
}

export const SupportedPlatformsStr = {
  SQUARE: 'Square',
  SHOPIFY: 'Shopify',
  EBAY: 'Ebay',
}

export const tabSelectionStr = {
  ADD_ITEM: 'addItem',
  UNLISTED: 'unlisted',
  LISTED: 'listed',
  SOLD: 'sold',
  PROCESSED: 'processed',
  VIEW_ITEM: 'viewItem',
  VIEW_LISTING: 'viewListing'
}

export const validationErrorStr = {
  NAME_ERROR: 'Name is required',
  NUMBER_SHOES_ERROR: 'Number is required',
  SHIPPING_TYPE: 'Shipping type is required',
  WEBSITE_FORMAT: 'Please enter correct website format',
}

export const MarketPlatformsList = [
  SupportedPlatformsStr.SHOPIFY,
  SupportedPlatformsStr.SQUARE,
  SupportedPlatformsStr.EBAY,
]
