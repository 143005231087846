import React from 'react'
import classnames from 'classnames'
import { Row, Col } from 'reactstrap'

const TableCell = ({ accessories, children, className = null, isActive = false, onSelect }) => {
  return (
    <Row className={className}>
      <Col sm={12} xs={12}>
        <div className={classnames('cell', isActive ? 'active' : null)}>
          <div onClick={() => onSelect ? onSelect() : null} className="content-view">
            {children}
          </div>
          {
            accessories ? (
              <div className="accessory">
                {accessories}
              </div>
            ) : null
          }
        </div>
      </Col>
    </Row>
  )
}

export default TableCell
