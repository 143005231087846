import { useEffect, useState } from 'react'

export const useWindowSize = () => {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    })
    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            })
        }
        // Add event listener
        window.addEventListener('resize', handleResize)
        // Call handler right away so state gets updated with initial window size
        handleResize()
        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize)
    }, []) // Empty array ensures that effect is only run on mount
    return windowSize
}

export const sortAlphabetical = (a, b) => {
    var nameA = a.toLowerCase() // ignore upper and lowercase
    var nameB = b.toLowerCase() // ignore upper and lowercase
    if (nameA < nameB) {
        return -1
    }
    if (nameA > nameB) {
        return 1
    }

    // names must be equal
    return 0
}

export const sortNumeric = (a, b) => {
    return parseFloat(b) - parseFloat(a)
}

export const delay = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}