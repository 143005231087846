import {useQuery, useMutation} from 'react-query'
import {useAuth0} from '@auth0/auth0-react'
import axios from 'axios'
import {createRequestOptions} from 'util/network'
import {paths} from 'constant/api'

const getCurrentDescriptionWildcardList = async (getAccessTokenSilently) => {
  try {
    const token = await getAccessTokenSilently()
    const response = await axios.get(
      `${paths.siteSettings}/getCurrentDescriptionWildcardList`,
      createRequestOptions(token),
    )
    return response?.data?.data
  } catch (error) {
    console.log(error)
  }
}

export const useSiteSettings = () => {
  const {getAccessTokenSilently} = useAuth0()
  const {
    status,
    isLoading,
    isError,
    isFetched,
    refetch,
    data: currentDescriptionWildcardList = [],
    error,
  } = useQuery(
    'descriptionWildcardList',
    () => getCurrentDescriptionWildcardList(getAccessTokenSilently),
    {
      //enabled: false,
      staleTime: Infinity
    },
  )

  return {
    status,
    isLoading,
    refetch,
    isError,
    isFetched,
    error,
    currentDescriptionWildcardList,
  }
}

export default useSiteSettings
