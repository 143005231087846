import React, { useState } from 'react'
import {Standard} from 'components'
import {useGlobalStore} from 'provider/global_store/hook'
import PaywallIndividual from './paywall/PaywallIndividual'
import PaywallEnterprise from './paywall/PaywallEnterprise'
import PaywallUpgradeAccount from './paywall/PaywallUpgradeAccount'
import PaywallSteps from './paywall/PaywallSteps'
import './paywall/Paywall.scss'

export const ContentType = {
  UNDEFINED: -1,
  STANDARD: 0,
  ENTERPRISE: 1,
  UPGRADE_ACCOUNT: 2,
  STEPS: 3
}

const MdlPaywall = (p) => {
  const {isEnterprise} = useGlobalStore()
  const {onCloseModal} = p
  const [contentType, setContentType] = useState(p?.contentType || !isEnterprise && ContentType.STANDARD)

  if (isEnterprise) {
    return <PaywallEnterprise {...{ onCloseModal }} />
  }

  return (
    <Standard>
      <PaywallIndividual
        {...{ setContentType, onCloseModal }}
        visible={contentType === ContentType.STANDARD}
      />
      <PaywallUpgradeAccount
        {...{ setContentType, onCloseModal }}
        visible={contentType === ContentType.UPGRADE_ACCOUNT}
      />
      <PaywallSteps
        {...{ setContentType, onCloseModal }}
        visible={contentType === ContentType.STEPS}
      />
    </Standard>
  )  
}

export default MdlPaywall
