import EditListing from 'views/listings/EditListing'
import ViewSold from 'views/listings/ViewSold'
import Platforms from 'views/platforms'
import {Market} from 'views/market'
import {
  PieChartOutlineRounded,
  LocalMallOutlined,
  Storefront,
  Diversity1,
  Inventory2Outlined,
  HomeWorkOutlined,
  FactCheckOutlined,
  LocalShippingOutlined,
  DocumentScanner,
} from '@mui/icons-material'

import {
  AccountSettings,
  AddItem,
  Audit,
  Consignments,
  Dashboard,
  InventoryImport,
  Ledger,
  Network,
  Orders,
  Profile,
  Shipping,
  ViewInventory_v2,
  ViewItem,
} from 'views_v2/modules'

import {CreateListing, ViewListing} from 'views_v2/modules/Listings'
import {Batches, ClaimableItems, PendingCodes} from 'views_v2/modules/Consignments/component'
import {
  EnterprisePlatform,
  IndividualPlatform,
} from 'views_v2/modules/Consignments/component/consignment_application'
import {MaintenancePage} from 'views_v2/lib/components'
import BulkScanIn from 'views_v2/modules/BulkScanIn'

const routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    rtlName: 'ساحر',
    mini: 'W',
    rtlMini: 'ث',
    icon: 'tim-icons icon-chart-pie-36',
    IconMui: PieChartOutlineRounded,
    component: Dashboard,
    layout: '/admin',
  },
  {
    path: '/audit',
    name: 'Audit',
    rtlName: 'ساحر',
    mini: 'W',
    rtlMini: 'ث',
    icon: 'tim-icons icon-bullet-list-67',
    IconMui: FactCheckOutlined,
    component: Audit,
    layout: '/admin',
    enterpriseOnly: true,
  },
  {
    path: '/network',
    name: 'Network',
    rtlName: 'ساحر',
    mini: 'W',
    rtlMini: 'ث',
    icon: 'tim-icons business-network',
    IconMui: Diversity1,
    component: Network,
    layout: '/admin',
    proOnly: true,
  },
  {
    path: '/market',
    name: 'Market',
    rtlName: 'ساحر',
    mini: 'W',
    rtlMini: 'ث',
    icon: 'tim-icons icon-puzzle-10',
    component: Market,
    hidden: true,
    layout: '/admin',
  },
  {
    name: 'Inventory',
    rtlName: 'رد فعل الطاولة',
    icon: 'tim-icons inventory-icon',
    IconMui: Inventory2Outlined,
    state: 'inventoryCollapse',
    path: '/inventory/viewInventory',
    layout: '/admin',
    component: ViewInventory_v2,
    hasChildren: true,
    views: [
      {
        path: '/inventory/bulk-scan-in',
        name: 'Bulk Scan-in',
        parent: 'Inventory',
        rtlName: 'ساحر',
        mini: 'UP',
        rtlMini: 'ث',
        icon: 'tim-icons icon-chart-pie-36',
        IconMui: null,
        component: BulkScanIn,
        layout: '/admin',
        isVisible: true,
      },
      {
        path: '/inventory/viewInventory',
        name: 'View Inventory',
        rtlName: 'عالتسعير',
        mini: 'P',
        rtlMini: 'ع',
        component: ViewInventory_v2,
        layout: '/admin',
      },
      {
        path: '/inventory/import/:id',
        name: 'Import',
        component: InventoryImport,
        layout: '/admin',
        // redirect: true
      },
      {
        path: '/inventory/viewItem/:id',
        name: 'View Item',
        rtlName: 'عالتسعير',
        mini: 'P',
        rtlMini: 'ع',
        component: ViewItem,
        layout: '/admin',
        redirect: true,
      },
      {
        path: '/inventory/addItem/:id',
        name: 'Inventory Item',
        rtlName: 'صودعم رتل',
        mini: 'RS',
        rtlMini: 'صو',
        component: AddItem,
        layout: '/admin',
      },
      {
        path: '/inventory/addItem',
        name: 'Inventory Item',
        rtlName: 'صودعم رتل',
        mini: 'RS',
        rtlMini: 'صو',
        component: AddItem,
        layout: '/admin',
      },
      {
        path: '/listings/createListing/*',
        name: 'Create Listing',
        component: CreateListing,
        layout: '/admin',
        redirect: true,
      },
      {
        path: '/listings/viewListing/:id',
        name: 'View Listing',
        component: ViewListing,
        layout: '/admin',
        redirect: true,
      },
      {
        path: '/listings/editListing/:id',
        name: 'Edit Listing',
        component: EditListing,
        layout: '/admin',
        redirect: true,
      },
      {
        path: '/listings/viewSold/:id',
        name: 'View Sold',
        component: ViewSold,
        layout: '/admin',
        redirect: true,
      },
    ],
  },
  {
    path: '/consignments/applications-on-individual',
    name: 'Consignments',
    hasChildren: true,
    rtlName: 'ملف تعريفي للمستخدم',
    mini: 'UP',
    rtlMini: 'شع',
    component: IndividualPlatform,
    icon: 'tim-icons icon-tag',
    IconMui: HomeWorkOutlined,
    layout: '/admin',
    individualOnly: true,
    views: [
      {
        path: '/consignments/applications-on-individual',
        name: 'Applications',
        parent: 'Consignments',
        rtlName: 'ملف تعريفي للمستخدم',
        mini: 'UP',
        rtlMini: 'شع',
        component: IndividualPlatform,
        icon: 'tim-icons',
        IconMui: null,
        layout: '/admin',
        isVisible: true,
      },
      {
        path: '/consignments/submitted-items',
        name: 'Submitted Items',
        parent: 'Consignments',
        rtlName: 'ملف تعريفي للمستخدم',
        mini: 'UP',
        rtlMini: 'شع',
        component: Consignments,
        icon: 'tim-icons',
        IconMui: null,
        layout: '/admin',
        isVisible: true,
      },
      {
        path: '/consignments/batches',
        name: 'Batches',
        parent: 'Consignments',
        rtlName: 'ملف تعريفي للمستخدم',
        mini: 'UP',
        rtlMini: 'شع',
        component: Batches,
        icon: 'tim-icons',
        IconMui: null,
        layout: '/admin',
        isVisible: true,
      },
      {
        path: '/consignments/claimable-items',
        name: 'Claimable Items',
        parent: 'Consignments',
        rtlName: 'ملف تعريفي للمستخدم',
        mini: 'UP',
        rtlMini: 'شع',
        component: ClaimableItems,
        icon: 'tim-icons',
        IconMui: null,
        layout: '/admin',
        isVisible: true,
      },
    ],
  },
  {
    path: '/consignments/applications-on-enterprise',
    name: 'Consignments',
    hasChildren: true,
    rtlName: 'ملف تعريفي للمستخدم',
    mini: 'UP',
    rtlMini: 'شع',
    component: EnterprisePlatform,
    icon: 'tim-icons icon-tag',
    IconMui: HomeWorkOutlined,
    layout: '/admin',
    isVisible: true,
    enterpriseOnly: true,
    views: [
      {
        path: '/consignments/applications-on-enterprise',
        name: 'Applications',
        parent: 'Consignments',
        rtlName: 'ملف تعريفي للمستخدم',
        mini: 'UP',
        rtlMini: 'شع',
        component: EnterprisePlatform,
        icon: 'tim-icons',
        IconMui: null,
        layout: '/admin',
        isVisible: true,
      },
      {
        path: '/consignments/submitted-items',
        name: 'Submitted Items',
        parent: 'Consignments',
        rtlName: 'ملف تعريفي للمستخدم',
        mini: 'UP',
        rtlMini: 'شع',
        component: Consignments,
        icon: 'tim-icons',
        IconMui: null,
        layout: '/admin',
        isVisible: true,
      },
      {
        path: '/consignments/batches',
        name: 'Batches',
        parent: 'Consignments',
        rtlName: 'ملف تعريفي للمستخدم',
        mini: 'UP',
        rtlMini: 'شع',
        component: Batches,
        icon: 'tim-icons',
        IconMui: null,
        layout: '/admin',
        isVisible: true,
      },
      {
        path: '/consignments/ledger',
        name: 'Ledger',
        parent: 'Consignments',
        rtlName: 'ملف تعريفي للمستخدم',
        mini: 'UP',
        rtlMini: 'شع',
        component: Ledger,
        icon: 'tim-icons',
        IconMui: null,
        layout: '/admin',
        isVisible: true,
      },
      {
        path: '/consignments/pending-codes',
        name: 'Pending Codes',
        parent: 'Consignments',
        rtlName: 'ملف تعريفي للمستخدم',
        mini: 'UP',
        rtlMini: 'شع',
        component: PendingCodes,
        icon: 'tim-icons',
        IconMui: null,
        layout: '/admin',
        isVisible: true,
      },
    ],
  },
  {
    path: '/orders',
    name: 'Orders',
    rtlName: 'ملف تعريفي للمستخدم',
    mini: 'UP',
    rtlMini: 'شع',
    component: Orders,
    icon: 'tim-icons icon-bag-16',
    IconMui: LocalMallOutlined,
    layout: '/admin',
    enterpriseOnly: true,
  },
  {
    path: '/shipping',
    name: 'Shipping',
    rtlName: 'ساحر',
    mini: 'W',
    rtlMini: 'ث',
    icon: 'tim-icons icon-chart-pie-36',
    IconMui: LocalShippingOutlined,
    component: Shipping,
    layout: '/admin',
  },
  {
    path: '/platforms',
    name: 'Platforms',
    rtlName: 'ساحر',
    mini: 'W',
    rtlMini: 'ث',
    icon: 'tim-icons icon-puzzle-10',
    IconMui: Storefront,
    component: Platforms,
    layout: '/admin',
  },
  {
    path: '/settings',
    name: 'Settings',
    rtlName: 'ملف تعريفي للمستخدم',
    mini: 'UP',
    rtlMini: 'شع',
    component: AccountSettings,
    icon: 'tim-icons icon-settings-gear-63',
    layout: '/admin',
    hidden: true,
  },
  {
    path: '/profile',
    name: 'Profile',
    rtlName: 'ملف تعريفي للمستخدم',
    mini: 'UP',
    rtlMini: 'شع',
    component: Profile,
    icon: 'tim-icons icon-single-02',
    layout: '/admin',
    hidden: true,
  },
]

export default routes
