import React from 'react'
import {ar} from 'variables/empty'
import {Card, CardHeader, CardBody, CardTitle, Label, Row} from 'reactstrap'
import {ReactComponent as EmptyMyImages} from '../../../../assets/img/EmptyMyImages.svg'
import {getAssetUrl} from 'util/model/product_asset'
import {orderBy} from 'lodash'

export function ListingAssets({assets = ar}) {
  return (
    <Card>
      <CardHeader>
        <CardTitle tag="div">Images</CardTitle>
      </CardHeader>
      <CardBody>
        <Row>
          <div style={{paddingRight: '0px'}}>
            <Label
              className="my-images-label"
              style={{
                textAlign: 'left',
                width: '100%',
                marginTop: '4px',
              }}
            >
              My Images
              <div className="file-count">{assets.length > 0 ? assets.length + '/10' : ''}</div>
            </Label>

            <Card style={{padding: '4px'}}>
              <div className="my-images" style={{width: '100%'}}>
                {assets.length < 1 ? (
                  <div className="empty-img-container">
                    <EmptyMyImages className="empty-img-svg"></EmptyMyImages>
                    <div className="no-photos">No Photos Yet</div>
                    <div className="when-upload">When you upload your photos, they'll appear here.</div>
                  </div>
                ) : (
                  orderBy(assets, 'order', 'asc').map((pa) => {
                    return (
                      <Card className="image-cards" key={pa.id}>
                        <img src={getAssetUrl(pa)}></img>
                      </Card>
                    )
                  })
                )}
              </div>
            </Card>
          </div>
        </Row>
      </CardBody>
    </Card>
  )
}
