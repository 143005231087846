import {create} from 'zustand'

export const useModifier = create((set) => ({
  addItemPage: {
    isSaveButtonDisabled: false,
  },
  setAddItemPage: {
    isSaveButtonDisabled: (isSaveButtonDisabled) => {
      set((state) => ({
        addItemPage: {
          ...state.addItemPage,
          isSaveButtonDisabled,
        },
      }))
    },
  },
}))
