import React, {memo, useState} from 'react'
import clx from 'classnames'
import {useGlobalStore} from 'provider/global_store/hook'
import {Box, Button, Stepper, Step, StepLabel} from '@mui/material'
import {RocketLaunch as RocketLaunchIcon} from '@mui/icons-material'
import {useAuth0} from '@auth0/auth0-react'
import * as Sentry from '@sentry/react'
import {useStripe, useElements, CardNumberElement} from '@stripe/react-stripe-js'
import axios from 'axios'
import {apiConfig} from 'config'
import {FieldGroup} from 'views_v2/lib/snippets'
import {useCompany, useUser} from 'service/hook'
import {createRequestOptions} from 'util/network'
import {checkObjIfEmpty, getPredefinedObject} from 'util/string_utils'
import PaywallPaymentInfo from './PaywallPaymentInfo'

const steps = ['Company Name', 'Payment Information']

const userArr = [
  'businessName',
  'createdAt',
  'email',
  'firstName',
  'id',
  'isAcceptingApplications',
  'isActive',
  'lastName',
  'phoneNumber',
  'type',
  'updatedAt',
]

export const PaywallSteps = (p) => {
  // temporarily disabled the data for payment information
  const {hasMop} = useGlobalStore() 
  const [activeStep, setActiveStep] = useState(0)
  const {user, updateUser} = useUser()
  const {getAccessTokenSilently} = useAuth0()
  const [skipped, setSkipped] = useState(new Set())
  const [stripeError, setStripeError] = useState(null)
  const [cardError, setCardError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [finish, setFinish] = useState(false)
  const [billingDetails, setBillingDetails] = useState({
    name: '',
    address: '',
    city: '',
    state: '',
    zip: '',
  })

  const stripe = useStripe()
  const elements = useElements()

  const [formFields, setFormFields] = useState(null)
  const {company, updateCompany} = useCompany()
  const userObj = getPredefinedObject(user, userArr)

  hasMop && steps.pop()

  const addBusinessName = () => {
    const companyObj = Object.assign(company[0], {
      name: formFields.businessName,
    })
    Object.assign(userObj, {
      type: 'enterprise',
      businessName: formFields.businessName,
    })

    if (Array.isArray(company) && company?.length > 0) {
      updateCompany({id: company[0]?.id, value: companyObj})
    }
    updateUser(userObj)
  }

  const onFormSubmit = async () => {
    setLoading(true)
    if (!hasMop) {
      setCardError(null)
      setStripeError(false)
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return
      }

      const cardElement = elements.getElement(CardNumberElement)

      let formError = null
      if (
        !billingDetails.name ||
        !billingDetails.address ||
        !billingDetails.city ||
        !billingDetails.state ||
        !billingDetails.zip ||
        !cardElement
      ) {
        formError = 'is required'
      } else {
        formError = null
      }

      setCardError(formError)
      if (!formError) {
        try {
          const paymentMethod = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
              address: {
                line1: billingDetails.address,
                city: billingDetails.city,
                state: billingDetails.state,
                postal_code: billingDetails.zip,
              },
              name: billingDetails.name,
            },
          })

          if (paymentMethod.error) {
            setCardError(paymentMethod.error.message)
            setStripeError(true)
          } else {
            setStripeError(false)
          }

          await (async () => {
            addBusinessName()
            // make sure business name and user is updated
            await new Promise((resolve) => setTimeout(resolve, 500))
          })()

          const token = await getAccessTokenSilently()

          await axios.post(
            `${apiConfig.api_url}/payment/subscription`,
            {
              paymentMethodId: paymentMethod.paymentMethod.id,
              name: billingDetails.name,
              email: user.email,
            },
            createRequestOptions(token),
          )

        } catch (e) {
          Sentry.captureException(e)
          console.error('error', e, e.data)
        }
      }
    } else {
      const token = await getAccessTokenSilently()
      try {
        const response = await axios.post(
          `${apiConfig.api_url}/payment/subscription`,
          {
            paymentMethodId: user?.private?.paymentMethodId,
            name: billingDetails.name || user?.company?.name,
            email: user.email,
            customerId: user?.private?.customerId
          },
          createRequestOptions(token),
        )
        
        if (response.status === 201) {
          addBusinessName()
        }
      } catch (err) {
        Sentry.captureException(err)
        console.error('## Error ', err)
      }
    }
    
    setFinish(true)
    setLoading(false)
    
  }

  const isStepSkipped = (step) => skipped.has(step)

  const handleNext = () => {
    const step = hasMop ? steps?.length : 1
    if (!hasMop) {
      let newSkipped = skipped
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values())
        newSkipped.delete(activeStep)
      }
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
      setSkipped(newSkipped)
    }
    
    if (activeStep === step) {
      onFormSubmit()
    }
  }

  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1)

  let submitBtnText = hasMop ? 'Finish' : 'Next'
  let disabled = formFields?.businessName === '' || !formFields
  if (!finish && loading) {
    submitBtnText = 'Please Wait'
    disabled = true
  } else if (activeStep === steps.length - 1 && !loading) {
    submitBtnText = 'Finish'
    disabled = checkObjIfEmpty(billingDetails)
  }

  return (
    <div className={clx('paywall-wrapper', !p?.visible && 'hide')}>
      <div className="paywall-wrapper--body">
        <Box sx={{width: '100%'}}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps = {}
              const labelProps = {}
              if (isStepSkipped(index)) {
                stepProps.completed = false
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              )
            })}
          </Stepper>
          {activeStep === steps.length && finish ? (
            <div className="welcome-success">
              <RocketLaunchIcon />
              <div className="welcome-title">Your account has been upgraded!</div>
              <div className="welcome-subtitle">
                Your account has been upgraded to Enterprise. You can start using the enterprise
                features for your Buy-Sell-Trade or Consignment Store.
              </div>
            </div>
          ) : (
            <div className="welcome-step-container">
              <div className="welcome-step-container--form-group">
                {!activeStep ? (
                  <FieldGroup
                    onChange={(e) => setFormFields({businessName: e?.target.value})}
                    sLabel="Company Name"
                    name="businessName"
                    value={formFields?.businessName || ''}
                  />
                ) : (
                  <PaywallPaymentInfo {...{cardError, stripeError, billingDetails, setBillingDetails}} />
                )}
              </div>
              <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                <Button
                  color="inherit"
                  className={clx(activeStep === 0 && 'hide', 'btn-primary')}
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{mr: 1}}
                >
                  Back
                </Button>
                <Box sx={{flex: '1 1 auto'}} />
                <Button disabled={disabled} onClick={handleNext} className="btn-primary">
                  {submitBtnText}
                </Button>
              </Box>
            </div>
          )}
        </Box>
      </div>
    </div>
  )
}

export default memo(PaywallSteps)
