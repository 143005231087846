import {get} from 'lodash'
import moment from 'moment'

export const getFee = (consignment) => {
  return Number(get(consignment, 'application.fee.value', 0))
}

export const getPrice = (consignment) => {
  return Number(get(consignment, 'product.price', 0))
}

export const getDesiredReturn = (consignment) => {
  return Number(get(consignment, 'product.desiredReturn', 0))
}

export const getLedgerValue = (consignment) => {
  if (!consignment?.ledger) {
    return ''
  }

  const ledger = consignment.ledger

  return `${moment(ledger.createdAt).format('MM/DD/YYYY')} - ${ledger.method}`
}

export const getConsignmentPayout = (sold) => {
  return Number(sold?.consignActualReturn) || 0
}
