import React from 'react'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import {Box, Typography, Stack, CircularProgress} from '@mui/material'

import {CopytStyledButton} from 'views_v2/lib/snippets'

const MdlDeleteLabel = (p) => {
  const {isLoading, onCloseModal, onSaveChanges} = p

  return (
    <Box padding="0 !important">
      <Box className="modal-title">
        <Box className="modal__heading" mx="0px !important">
          <Typography variant="h1" textAlign="left">
            Delete Label
          </Typography>
        </Box>
      </Box>

      <Box className="modal-body" padding={0} my={2}>
        {isLoading ? (
          <Stack justifyContent="center" alignItems="center" my={7}>
            <CircularProgress size={24} />
            <Typography color="var(--gray) !important">Please wait...</Typography>
          </Stack>
        ) : (
          <Stack direction="row" alignItems="center" justifyContent="center" p={2} spacing={2} my={5}>
            <InfoOutlinedIcon sx={{fontSize: '3rem !important', color: 'var(--success)'}} />
            <Stack direction="column" alignItems="center">
              <Typography sx={{fontWeight: 'fontWeightBold'}}>
                Are you sure you want to delete this?
              </Typography>
              <Typography fontStyle="italic" fontSize="0.85rem" color="red !important">
                Warning! This action cannot be undone.
              </Typography>
            </Stack>
          </Stack>
        )}

        <Stack
          direction="row"
          justifyContent="flex-end"
          pr={2}
          pt={2}
          borderTop="1px solid #eee"
          spacing={2}
        >
          <CopytStyledButton
            variant="outline"
            color="primary"
            disabled={isLoading}
            onClick={onCloseModal}
          >
            Cancel
          </CopytStyledButton>
          <CopytStyledButton
            variant="contained"
            color="primary"
            disabled={isLoading}
            onClick={onSaveChanges}
          >
            Yes
          </CopytStyledButton>
        </Stack>
      </Box>
    </Box>
  )
}

export default MdlDeleteLabel
