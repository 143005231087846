import {Box} from '@mui/material'
import React from 'react'

const Container = ({children, ...props}) => {
  return (
    <Box
      {...props}
      borderRadius={2}
      bgcolor="white"
      sx={{boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)'}}
      padding={2}
    >
      {children}
    </Box>
  )
}

export default Container
