import React from 'react'
import clx from 'clsx'

import {CopytTooltip} from 'views_v2/lib/snippets'
import {ListItem, ListItemButton, ListItemIcon, ListItemText, Badge} from '@mui/material'

export const ListItemContent = ({
  route,
  isMobileScreen,
  rtlActive,
  pendingCount,
  pending,
  shouldHide,
  isActive = false,
}) => {
  const isParent = route?.hasOwnProperty('parent') || false
  return (
    <CopytTooltip title={isMobileScreen && (rtlActive ? route.rtlName : route.name)} placement="left">
      <ListItem
        className={clx(isActive && 'active')}
        disablePadding
        sx={{
          color: 'var(--darker) !important',
          borderBottom: route?.hasChildren ? 0 : '1px solid var(--lighter)',
          display: 'inline-flex !important',
          alignItems: 'baseline',
          flexDirection: 'column',
          borderLeft: '5px solid white',
          '&:hover': {
            backgroundColor: 'var(--primary-light)',
          },
          '&.active': {
            backgroundColor: 'var(--primary-light)',
            borderLeft: '5px solid var(--darker)',
          },
        }}
      >
        <ListItemButton
          sx={{
            width: '100%',
            minHeight: 48,
            justifyContent: 'center',
            px: isParent ? '30px !important' : '16px !important',
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: isParent ? 5 : 3,
              justifyContent: 'center',
              color: isParent ? 'var(--primary) !important' : 'var(--darker) !important',
            }}
          >
            {route?.IconMui && <route.IconMui />}
            {route.icon.includes('icon-tag') && (pendingCount > 0 || pending > 0) && !shouldHide && (
              <Badge color="error" badgeContent={pendingCount} max={99} />
            )}
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{fontWeight: isParent ? 500 : 600, lineHeight: '14px'}}
            sx={{
              color: isParent ? 'var(--primary) !important' : 'var(--darker) !important',
            }}
            primary={isMobileScreen ? '' : rtlActive ? route.rtlName : route.name}
          />
        </ListItemButton>
      </ListItem>
    </CopytTooltip>
  )
}

export default ListItemContent
