import {get} from 'lodash'

export default function getPayout(sold, consignMinFee, isEnterprise) {
  const cFee = Number(consignMinFee || 0)
  const price = Number(sold.price || 0)
  if (isEnterprise) {
    const sfee = Number(getFee(sold)) * 0.01 * price
    const fee = cFee > sfee ? cFee : sfee
    return price - fee
  } else {
    const sfee = getFee(sold)
    const fee = cFee > sfee ? cFee : sfee
    return price - (fee / 100) * price
  }
}

const getFee = (order) => {
  return get(order, 'listing.product.feeStructure')
}
