
const queryString = require('query-string');

export let parsed_query_string_search = (location = null, prop, condition) => {
  if (location) {
    const parsed = queryString.parse(location.search);
    return parsed[prop] === condition
  }

  return false
}

export default {
  parsed_query_string_search
}