import React, { useState } from 'react'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  TextField,
  Typography
} from '@mui/material'
import CopytModal from 'components/CopytModal'
import {
  LoadingBar
} from 'views_v2/lib/snippets'
import './CopytProModal.scss'
import { validateReferralCode } from 'service/hook/use_platform'

const CopytProModal = (props) => {
  const {
    copytNetworkPlatform,
    handleNextClick,
    isLoading,
  } = props
  const [referralCode, setReferralCode] = useState('')
  const [referralCodeError, setReferralCodeError] = useState(false)

  const handleClick = async () => {
    // check if referral code is valid
    const validate = await validateReferralCode({ 
      referral_code: referralCode.replace(/^,+|,+$/g, ''), 
      platformId: copytNetworkPlatform.id 
    })
    if (referralCode && validate?.valid) {
      localStorage?.setItem('hasRoutes', true)
      handleNextClick()
    } else {
      setReferralCodeError(true)
    }
  }

  const referralCodeContent = () => (
    <Box>
      <Typography className="header">
        If you were invited to join Copyt Network by a Copyt Partner Group, enter the Group Referral Code
        below.
      </Typography>
      <Card>
        <CardContent>
          <TextField
            error={referralCodeError}
            helperText={referralCodeError ? 'Code is wrong' : false}
            label="Referral Code"
            variant="standard"
            onChange={(e) => {
              setReferralCode(e.currentTarget.value)
            }}
          />
        </CardContent>
        <CardActions className="justify-content-end">
          <Button
            sx={{ color: '#fff', backgroundColor: '#d5239e', borderColor: 'green' }}
            size="small"
            variant="contained"
            onClick={() => handleClick()}
          >
            Submit
          </Button>
        </CardActions>
      </Card>
    </Box>
  )

  return (
    <CopytModal className="copytPro-modal" closeButton={{ hidden: true }} show={true} size={'md'}>
      {isLoading ? (
        <Box sx={{height: '20rem'}}><LoadingBar /></Box>
      ) : (
        <Box>
          <Typography className="copytPro-modal-header">
            Welcome to <Typography component="span" sx={{ fontSize: '2.5rem', fontWeight: 900 }}>Copyt Network</Typography>
          </Typography>
          {referralCodeContent()}
        </Box>
      )}

    </CopytModal>
  )
}

export default CopytProModal
