import {useQueryClient, useQuery} from 'react-query'
import axios from 'axios'
import {useAuth0} from '@auth0/auth0-react'
import * as Sentry from '@sentry/react'
import {createRequestOptions} from 'util/network'
import {paths} from 'constant/api'
import {ar} from 'variables/empty'
import Qs from 'querystring'

const getProductConnections = async (getAccessTokenSilently, filter = {}, queries = {}) => {
  try {
    const token = await getAccessTokenSilently()
    const response = await axios.get(
      `${paths.connection}/product?${Qs.stringify({...filter, ...queries})}`,
      createRequestOptions(token),
    )

    return response?.data
  } catch (error) {
    Sentry.captureException(error)
    throw error
  }
}

export const useConnectionProduct = ({filter = {}, queries = {}}) => {
  const {getAccessTokenSilently} = useAuth0()
  const queryClient = useQueryClient()
  const {isLoading, isError, isFetched, isFetching, refetch, data, error, status} = useQuery(
    [`product-connections`, filter, queries],
    () => getProductConnections(getAccessTokenSilently, filter, queries),
    {
      keepPreviousData: true,
    },
  )

  const reinvalidateProductConnections = () => {
    queryClient.invalidateQueries('product-connections')
  }

  return {
    isProductConnectionsLoading: isLoading,
    isFetching,
    isError,
    isConnectionsFetch: isFetched,
    isProductConnectionsFetching: isFetching,
    error,
    productConnections: data || ar,
    status,
    refetchProductConnection: refetch,
    reinvalidateProductConnections,
  }
}

export default useConnectionProduct
