export default {
  PENDING: 'pending',
  APPROVED: 'approved',
  REJECTED: 'rejected',
  RETURNED: 'returned',
  SOLD: 'sold',
  PROCESSED: 'processed',
  LISTED: 'LISTED',
  WITHDRAW_APPROVED: 'withdraw_approved',
  WITHDRAW_DENIED: 'withdraw_denied',
  WITHDRAW_PENDING: 'withdraw_pending',
}
