import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const CopytLabel = styled(Typography)(({props}) => {
  return {
    ...props,
    fontSize: '12px',
    fontWeight: 600,
    color: props && props?.color ? `${props.color}` : props?.error ? '#d3103f !important' : 'var(--success) !important',
    marginBottom: '2px',
  };
});

export default CopytLabel;
