export const InventoryQueryType = {
  UNLISTED: 'unlisted',
  LISTED: 'listed',
  SOLD: 'sold',
  PROCESSED: 'processed',
  AUDIT: 'audit',
  UNVERIFIED: 'unverified',
  VERIFIED: 'verified',
}

export const MembershipType = {
  ECG: 'ECG',
  COPYT: 'Copyt',
}
