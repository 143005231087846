import React, {useEffect, useState} from 'react'
import {Toast, ToastHeader, ToastBody} from 'reactstrap'

const ToastMessage = ({header, body, toggle}) => {
  const [show, setShow] = useState(true)

  // On componentDidMount set the timer
  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 2 seconds set the show value to false
      setShow(false)
      toggle()
    }, 2000)

    return () => {
      clearTimeout(timeId)
    }
  }, [])

  // If show is false the component will return null and stop here
  if (!show) {
    return null
  }

  // If show is true this will be returned
  return (
    <Toast>
      <ToastHeader toggle={toggle}>{header}</ToastHeader>
      <ToastBody>{body}</ToastBody>
    </Toast>
  )
}

ToastMessage.defaultPros = {
  header: 'Title',
  body: '',
  toggle: () => null,
}

export default ToastMessage
