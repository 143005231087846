import {styled} from '@mui/material/styles'
import CopytButton from './CopytButton'

const CopytStyledButton = styled(CopytButton)(({theme, ...props}) => ({
  width: '20%',
  height: 35,
  borderRadius: '6px !important',
  ...props.sx,
}))

export default CopytStyledButton
