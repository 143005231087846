import {useMutation, useQuery} from 'react-query'
import axios from 'axios'
import {useAuth0} from '@auth0/auth0-react'
import {captureException} from '@sentry/react'

import {useToastMessage} from 'components'
import {paths} from 'constant/api'
import {createRequestOptions} from 'util/network'

const getDiscounts = async (getAccessTokenSilently) => {
  try {
    const token = await getAccessTokenSilently()
    const response = await axios.get(`${paths.discount}/all`, createRequestOptions(token))
    return response?.data?.data
  } catch (error) {
    captureException(error)
    console.error('Error retrieving invoices', error)
    throw error
  }
}

export const useDiscounts = () => {
  const {getAccessTokenSilently} = useAuth0()
  const {showToast} = useToastMessage()

  const {data: discounts, refetch} = useQuery(
    ['discounts'],
    () => getDiscounts(getAccessTokenSilently),
    {
      keepPreviousData: true,
    },
  )

  const applyDiscount = useMutation(
    async (payload) => {
      const token = await getAccessTokenSilently()
      return await axios.post(`${paths.discount}/apply`, {...payload}, createRequestOptions(token))
    },
    {
      onSuccess: async (res, variables, context) => {
        showToast({variant: 'success', title: 'Discount applied', body: ''})
        refetch()
      },
      onError: (e) => {
        showToast({variant: 'danger', title: 'Error', body: e.message})
        refetch()
      },
    },
  )

  return {
    discounts,
    applyDiscount: applyDiscount.mutateAsync,
    isApplyingDiscount: applyDiscount.isLoading
  }
}

export default useDiscounts
