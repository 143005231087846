import React, { useEffect } from 'react'

export const useScreenSize = () => {
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
  
    const handleResize = () => setScreenWidth(window.innerWidth);
  
    useEffect(() => {
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  
    return screenWidth;
}

export const urlWithoutParam = (newPage) => {
  const currentUrl = window.location.href

  const urlWithoutParam = currentUrl.replace(
    new RegExp(`[?&]page=([^&]+)(&?)`),
    (_, value, separator) => (separator === '&' ? separator : '')
  )

  return `${urlWithoutParam}${urlWithoutParam.includes('?') ? '&' : '?'}page=${newPage}`
}

export const getParams = (history) => {
  const urlParams = new URLSearchParams(history?.location?.search)

  return Object.fromEntries(urlParams)
}