import React, {useCallback, useMemo, useState, useEffect} from 'react'
import {ToastMesageContext, ToastMessageVariant, defualtValue} from './constant'
import {ToastMessage} from './ui'
import {Stack} from '@mui/material'

export const ToastMessageProvider = function ({children}) {
  const [isShow, setShow] = useState(false)
  const [toast, setToast] = useState(defualtValue)

  const showToast = useCallback(
    ({body = '', title = '', variant = ToastMessageVariant.SUCCESS}) => {
      setToast({body, title, variant})
      setShow(true)
      setTimeout(() => {
        // After 2 seconds set the show value to false
        setShow(false)
      }, 10000)
    },
    [setShow, setToast],
  )

  const onClose = useCallback(() => {
    //defualts
    setToast(defualtValue)
    setShow(false)
  }, [setShow, setToast])

  const value = useMemo(() => ({showToast}), [showToast])

  return (
    <ToastMesageContext.Provider value={value}>
      {children}
      <Stack zIndex={9999} position="relative">
        <ToastMessage toast={toast} onClose={onClose} isShow={isShow} />
      </Stack>
    </ToastMesageContext.Provider>
  )
}
