import axios from 'axios'
import {useAuth0} from '@auth0/auth0-react'
import {useCallback, useState} from 'react'
import {paths} from '../../constant/api'
import {createRequestOptions} from '../../util/network'

// helper hook for upload item
export const useUploader = () => {
  const [state, setState] = useState({loading: false, message: ''})
  const {getAccessTokenSilently} = useAuth0()

  const onUpload = useCallback(
    async (file, onSuccess = () => {}) => {
      try {
        setState((s) => ({...s, loading: true}))
        const fd = new FormData()
        fd.append('file', file)

        // todo: shift to service
        const token = await getAccessTokenSilently()
        const response = await axios.post(`${paths.asset}/upload`, fd, createRequestOptions(token))
        setState((s) => ({...s, message: '', loading: false}))
        onSuccess(response.data.data)
        return response.data.data
      } catch (e) {
        setState((s) => ({...s, message: 'failed to upload', loading: false}))
      }
    },
    [setState],
  )

  return {
    onUpload,
    loading: state.loading,
  }
}
