import React from 'react'
import {ReactComponent as EmptyState} from 'assets/img/mobile-inventory-empty.svg'

const EmptyData = ({title, children}) => {
  return (
    <div className="empty-state-card" style={{ marginBottom: 0, height: '100%' }}>
      <EmptyState className="empty-state-svg" />
      <div className="empty-state-text">
        <p className="empty-state-header">{title}</p>
        <p className="empty-state-body">{children} </p>
      </div>
    </div>
  )
}

export default EmptyData
