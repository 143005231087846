import React from 'react'
import {useAuth0} from '@auth0/auth0-react'
import {ListItemIcon} from '@mui/material'
import {AccountItem} from './Navbars/AccountMenu'

import {Logout as Logout} from '@mui/icons-material'
import Intercom from '@intercom/messenger-js-sdk'
import {useBulkScanInStore} from 'views_v2/modules/BulkScanIn/useBulkScanInStore'

const LogoutDropdownItem = () => {
  const {logout} = useAuth0()
  const bulkScanInReset = useBulkScanInStore((state) => state.reset)

  return (
    <AccountItem
      onClick={() => {
        bulkScanInReset()
        logout({returnTo: window.location.origin})
        localStorage.clear()

        Intercom.logout()
      }}
    >
      <ListItemIcon>
        <Logout fontSize="small" />
      </ListItemIcon>
      Logout
    </AccountItem>
  )
}

export default LogoutDropdownItem
