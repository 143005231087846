import React from 'react'
import {Box} from '@mui/material'
import SearchBar from 'material-ui-search-bar'
import {FileDownload} from '@mui/icons-material'

import {CopytSwitch} from 'views_v2/lib/snippets'
import {CopytButton} from './'

const SearchToolbar = (p) => {
  const {
    onSearch,
    onExport,
    onCustomDate,
    onSelectApprovedItems = () => null,
    onIsPendingItems = () => null,
    isPendingItems = false,
    hasCustomDate = false,
    hasCreateBox = false,
    showStoreFilter = false,
    showPending = false,
    labelStart = '',
    search = {
      value: '',
      onSearch: () => null,
      disabled: false,
    },
    style,
  } = p || {}

  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
      }}
      style={{
        backgroundColor: 'var(--primary-light)',
        borderBottom: '1px solid #a5709b',
        display: 'flex',
        justifyContent: 'space-between',
        paddingLeft: '10px',
        ...style,
      }}
    >
      <Box>
        <CopytButton
          onClick={onExport}
          variant="outlined"
          style={{
            padding: '12px 0',
            height: '35px',
            color: 'var(--darker)',
          }}
        >
          <FileDownload /> Export
        </CopytButton>
      </Box>
      <Box display="flex" gap={1}>
        {hasCreateBox && (
          <CopytButton
            onClick={onSelectApprovedItems}
            variant="contained"
            color="primary"
            style={{
              borderRadius: '6px',
              padding: '12px 16px',
              height: '35px',
            }}
          >
            Create Batch
          </CopytButton>
        )}
        {hasCustomDate && (
          <CopytButton
            onClick={onCustomDate}
            variant="contained"
            color="primary"
            style={{
              borderRadius: '6px',
              padding: '12px 16px',
              height: '35px',
            }}
          >
            Custom Date
          </CopytButton>
        )}
        {showPending && (
          <CopytSwitch {...{labelStart}} checked={isPendingItems} onChange={onIsPendingItems} />
        )}
        <SearchBar
          value={search.value}
          disabled={search.disabled}
          style={{
            height: '35px',
            marginBottom: '6px',
          }}
          autoFocus={search?.autoFocus}
          placeholder="Search..."
          cancelOnEscape
          onCancelSearch={search?.onSearch}
          onRequestSearch={(s) => {
            onSearch(s)
            search?.onSearch(s)
          }}
        />
      </Box>
    </Box>
  )
}

export default SearchToolbar
