import React, {useState, useEffect, useRef} from 'react'
import axios from 'axios'
import {useAuth0} from '@auth0/auth0-react'
import {Box, FormControlLabel, Grid, InputLabel, Radio, RadioGroup, Typography} from '@mui/material'
import {styled} from '@mui/material/styles'
import Select, {components} from 'react-select'
import useEnterKeyListener from 'hooks/useEnterKeyListener'
import {createRequestOptions} from 'util/network'
import {paths} from 'constant/api'
import {CopytButton} from 'views_v2/lib/snippets'

export const getSneakData = async (getAccessTokenSilently, ctgry, val) => {
  const token = await getAccessTokenSilently()
  const sneak = await axios.get(
    `${paths.sneak}/category/${ctgry}/keyword/${val}`,
    createRequestOptions(token),
  )

  return sneak?.data?.data || []
}

const searchArr = [
  {value: 'title', label: 'Keyword'},
  {value: 'barcode', label: 'Scan UPC'},
]

export const SearchLabel = styled(InputLabel)(() => ({
  color: '#2f052a',
  fontSize: '0.9rem',
  marginBottom: '0',
}))

const MdlSkuUpc = (p) => {
  const {getAccessTokenSilently} = useAuth0()
  const [data, setData] = useState([])
  const [value, setValue] = useState(null)
  const [searchBy, setSearchBy] = useState({value: 'title', label: 'Keyword'})
  const [searchValue, setSearchValue] = useState()
  const [options, setOptions] = useState([])
  const [shouldSubmit, setShouldSubmit] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setSearchValue(null)
    setOptions([])
    setValue(null)
    setShouldSubmit(false)
  }, [searchBy])

  const innerRef = useRef()
  useEffect(() => innerRef.current && innerRef.current.focus())

  const onFormSubmit = () => {
    if (value?.label?.length > 0) {
      let key = searchBy?.value === 'barcode' ? 'upc' : 'styleID'
      if (searchBy?.value === 'title') key = 'name'

      let findData = {}
      if (data?.length === 1) {
        findData = data[0]
      } else if (data?.length > 1) {
        findData = data?.find(
          (d) =>
            d[key]?.toLowerCase() === value?.value?.toLowerCase() ||
            d[key]?.toLowerCase() === value?.label?.toLowerCase(),
        )
      }

      setIsLoading(false)
      setShouldSubmit(false)

      p.action?.processSearchBy(findData)
      setTimeout(() => {
        p.onCloseModal()
      }, 1000)
    }
  }

  useEnterKeyListener({
    querySelectorToExecuteClick: '#onFormSubmit',
  })

  const onChange = async () => {
    let data = await getSneakData(getAccessTokenSilently, searchBy?.value, searchValue)

    let sneakData = searchBy?.value === 'title' ? data : data?.map((s) => s?.meta || s) || []
    let arr = []

    if (Array?.isArray(sneakData) && sneakData?.length && sneakData[0]) {
      if (searchBy?.value === 'barcode') {
        sneakData = sneakData?.map((s) => {
          return {
            ...s?.item_attributes,
            price: s?.Stores?.length > 0 && s?.Stores[0]?.price,
          }
        })
      }

      arr = sneakData?.map((s) => {
        if (searchBy?.value === 'sku') {
          return {
            label: s?.styleID,
            value: searchValue,
          }
        } else if (searchBy?.value === 'barcode') {
          return {
            label: s?.upc,
            value: s?.mode,
          }
        } else {
          return {
            label: s?.name || s?.styleID,
            value: s?.styleID || s?.style_id,
          }
        }
      })
    }

    setData(sneakData)
    setOptions(arr)
    setIsLoading(false)
    setShouldSubmit(true)
  }

  const NoOptionsMessage = (props) => {
    let noMsgLabel = 'Please hit the enter to start searching'
    if (options?.length === 0 && !isLoading && shouldSubmit) {
      const label = searchBy.value === 'barcode' ? 'UPC' : 'Keyword/SKU'
      noMsgLabel = `${label} not found. Please enter the item’s details manually.`
    } else if (isLoading && !shouldSubmit) {
      noMsgLabel = 'Please wait while we load the data'
    }

    return (
      <components.NoOptionsMessage {...props}>
        <span>{noMsgLabel}</span>
      </components.NoOptionsMessage>
    )
  }

  useEnterKeyListener({
    querySelectorToExecuteClick: '#skuSubmit',
  })

  return (
    <Box>
      <Box className="modal-title">
        <Box className="modal__heading">
          <Typography variant="h1">Search Item Details or Scan UPC</Typography>
        </Box>
      </Box>
      <Box className="text-center" sx={{mt: 2}}>
        Let us auto-fill some information for you!
      </Box>
      <Box className="modal-body" style={{padding: '24px 24px 0'}}>
        <Box p="10px 10px 15px" border="1px solid #eee" borderRadius={2}>
          <SearchLabel>Search By:</SearchLabel>
          <RadioGroup
            sx={{
              marginBottom: '20px',
              borderBottom: '1px solid #eee',
              paddingBottom: '10px',
            }}
            row
            aria-labelledby="row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={searchBy?.value}
            onChange={(e) => setSearchBy(searchArr.find((s) => s.value === e.target.value))}
          >
            {searchArr.map((s, i) => (
              <FormControlLabel
                sx={{mr: 5, mb: 0}}
                key={i}
                value={s?.value}
                control={<Radio />}
                label={s?.label}
              />
            ))}
          </RadioGroup>
          <SearchLabel>Item Detail:</SearchLabel>
          <Select
            {...{value, options}}
            ref={innerRef}
            defaultMenuIsOpen
            closeMenuOnSelect
            isClearable
            instanceId="searchValue"
            className="react-select search-value"
            classNamePrefix="react-select"
            placeholder="Enter item detail"
            components={{NoOptionsMessage}}
            onKeyDown={(event) => {
              if (event?.key === 'Enter' && !shouldSubmit && !isLoading && value === null) {
                setIsLoading(true)
                onChange(searchValue)
              }
            }}
            onInputChange={(e) => {
              if (shouldSubmit) {
                setIsLoading(false)
                setShouldSubmit(false)
              }
              setSearchValue(e)
            }}
            onChange={(e) => setValue(e)}
            filterOption={() => true}
          />
        </Box>
        <Grid container gap={2} mt={4} justifyContent="center">
          <Grid item xs={12} sm={5} display="flex" flexDirection="column" alignItems="center">
            <CopytButton
              variant="outline"
              color="primary"
              onClick={() => p.onCloseModal()}
              style={{width: '100%'}}
            >
              Skip
            </CopytButton>
          </Grid>
          <Grid item xs={12} sm={5} display="flex" flexDirection="column" alignItems="center">
            <CopytButton
              variant="contained"
              color="primary"
              onClick={onFormSubmit}
              id="skuSubmit"
              style={{width: '100%'}}
            >
              Submit
            </CopytButton>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default MdlSkuUpc
