import React, {useState, useEffect} from 'react'
import {Box} from '@mui/material'
import Select from 'react-select'

import {currency_symbols} from 'assets/data'
import CopytCard from 'components/CopytCard'
import InventoryToast from 'views_v2/modules/Inventory/components/InventoryToast'

const CurrencySettings = () => {
  const initialValue = currency_symbols.find((c) => c.value === localStorage.getItem('currency'))
  const [currency, setCurrency] = useState(initialValue)
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setShow(false)
      }, 1000)
    }
  }, [show])

  return (
    <CopytCard className="copyt-card currency-settings">
      <CopytCard.Header>
        <CopytCard.Text tag="div">
          Currency Symbol
          <Box>
            <Select
              className="react-select info"
              classNamePrefix="react-select"
              onChange={(e) => {
                setCurrency(e)
                localStorage.setItem('currency', e.value)
                setTimeout(() => {
                  setShow(true)
                }, 500)
              }}
              options={currency_symbols}
              value={currency}
            />
          </Box>
        </CopytCard.Text>
      </CopytCard.Header>
      <CopytCard.Body className="hide" />
      {show && <InventoryToast />}
    </CopytCard>
  )
}

export default CurrencySettings
