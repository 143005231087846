import React, {useRef, useReducer, useState, useCallback, useEffect} from 'react'
import {useHistory, useLocation, Prompt} from 'react-router-dom'
import {isEmpty, isEqual} from 'lodash'
import axios from 'axios'
import {useAuth0} from '@auth0/auth0-react'
import {Box, Tabs, Tab, LinearProgress} from '@mui/material'
import * as Sentry from '@sentry/react'
import {ModalType, TabPanel, ToastType} from 'enums'
import {apiConfig} from 'config'
import {paths} from 'constant/api'
import {createRequestOptions} from 'util/network'
import DetailView from 'components/DetailView'
import useEnterKeyListener from 'hooks/useEnterKeyListener'
import {useDescriptionTemplate, useProductTemplate, useSiteSettings} from 'service/hook'
import {useGlobalStore} from 'provider/global_store/hook'
import {initialFormFields, initialProductTemplate} from 'views_v2/lib/assets/item'
import {MenuHeader} from 'views_v2/modules/Inventory/snippets'
import {getSneakData} from 'views_v2/modules/Inventory/components/modal-contents/MdlSkuUpc'
import {
  capitalizeFirstLetter,
  getPredefinedObject,
  _validateFormFields,
  _replaceWildCards,
  _replaceWithWildcards,
  _processSKU,
} from 'util/string_utils'
import {isConsigned} from 'util/model/product'
import InventoryToast from './InventoryToast'
import InventoryModals from './InventoryModals'
import {CustomTabPanel} from './tabs'
import {tabSelectionStr} from 'resources/constants'
import {useModifier} from 'stores/useModifier'

const a11yProps = (index) => {
  return {
    id: `add-item-tab__${index}`,
    'aria-controls': `add-item-tabpanel-${index}`,
  }
}

const initialState = {
  editMode: false,
  modalType: ModalType.UNDEFINED,
  tab: TabPanel.ADD_ITEM_EXPANDED,
  isDisabled: false,
  isLoading: false,
  isFormDirty: false,
  imageArr: [],
  isSubmitting: false,
  item: initialFormFields,
  deleteImages: [],
  stockXPrice: null,
  stockXPriceArr: [],
  flightClubPrice: null,
  flightClubPriceArr: [],
  errorMessage: null,
  productTemplate: initialProductTemplate,
  previewState: false,
  postResponse: null,
  toastType: ToastType.UNDEFINED,
}

const AddItem = (p) => {
  const {pathname} = useLocation()
  let id = pathname.replace('/admin/inventory/addItem', '')
  id = id.replace('/', '')
  const {getAccessTokenSilently} = useAuth0()
  const {isEnterprise} = useGlobalStore()
  const history = useHistory()
  const {descriptionTemplate} = useDescriptionTemplate()
  const {template} = useProductTemplate()
  const {currentDescriptionWildcardList} = useSiteSettings()

  const [state, setState] = useState(initialState)
  const [formFields, setFormFields] = useState(initialFormFields)
  const [bypassBeforeUnload, setBypassBeforeUnload] = useState(false)
  const [hasConsignorCode, setHasConsignorCode] = useState(false)

  const wrapperRef = useRef(null)
  const [, forceUpdate] = useReducer((x) => x + 1, 0)
  const {data} = p.location

  const {
    editMode,
    errorMessage,
    flightClubPriceArr,
    isDisabled,
    isFormDirty,
    isLoading,
    isSubmitting,
    item,
    modalType,
    previewState,
    productTemplate,
    postResponse,
    stockXPriceArr,
    tab,
    toastType,
  } = state

  const addItemPage = useModifier((state) => state.addItemPage)

  useEffect(() => {
    const loadData = async () => {
      try {
        updateStateValue('isFormDirty', true)
        updateStateValue('isLoading', true)

        let item = null

        if (!data) {
          const token = await getAccessTokenSilently()

          if (id && id.indexOf('addItem') === -1) {
            const response = await axios.get(
              `${apiConfig.api_url}/product/${id}`,
              createRequestOptions(token),
            )

            item = response.data.data
            updateStateValue('item', item)
            setFormFields(item)
          }

          if (item && item?.images) {
            const {tempArr, tempIndex} = processImages(item.images)
            updateStateValue('imgIndex', tempIndex)
            updateStateValue('imageArr', tempArr)
          }
        } else {
          setFormFields(data)
          updateStateValue('item', data)

          if (data?.images) {
            const {tempArr, tempIndex} = processImages(data.images)
            updateStateValue('imgIndex', tempIndex)
            updateStateValue('imageArr', tempArr)
          }
        }

        updateStateValue('isLoading', false)
      } catch (e) {
        Sentry.captureException(e)
      }
    }

    const processImages = (images) => {
      const tempArr = []
      let tempIndex = 0

      for (const image of images) {
        if (tempIndex < parseInt(image.index)) {
          tempIndex = parseInt(image.index)
        }
      }

      return {tempArr, tempIndex}
    }

    loadData()
  }, [getAccessTokenSilently, data, id])

  useEffect(() => {
    updateStateValue('editMode', !!formFields?.id)
  }, [formFields?.id])

  useEffect(() => {
    const cacheTab = localStorage.getItem('addItemTab')
    if (cacheTab) {
      updateStateValue('tab', Number(cacheTab))
    } else {
      localStorage.setItem('addItemTab', '0')
      updateStateValue('tab', 0)
    }

    if (id.length < 1) {
      updateStateValue('modalType', ModalType.SKU_UPC)
    }
  }, [])

  const handleBeforeUnload = (event) => {
    if (bypassBeforeUnload) return

    event.preventDefault()
    event.returnValue = 'Are you sure you want to leave? Your changes may not be saved.'
  }

  const handleButtonClick = () => {
    setBypassBeforeUnload(true)
    setModalType(ModalType.WITHDRAW)
    updateStateValue('isDisabled', true)
  }

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [bypassBeforeUnload])

  useEffect(() => {
    if (Array.isArray(descriptionTemplate) && !isEmpty(descriptionTemplate)) {
      if (!formFields?.id) {
        updateFormFields('description', descriptionTemplate[0]?.description || 'SKU: [SKU]\n')
      } else {
        if (!!data?.description) {
          const description = _replaceWithWildcards(
            data?.description,
            data,
            currentDescriptionWildcardList,
          )
          updateFormFields('description', description)
        }
      }
    }
  }, [template, formFields?.id])

  useEnterKeyListener({
    querySelectorToExecuteClick: '#skuSubmit',
  })

  const updateStateValue = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }))
  }

  const updateFormFields = (key, value) => setFormFields((prevState) => ({...prevState, [key]: value}))

  const routeChange = (route, data) => history.push({pathname: route, data: data})

  const processSearchBy = async (data) => {
    let item = data
    if (item?.upc) {
      const res = await getSneakData(getAccessTokenSilently, 'title', encodeURIComponent(item?.title))
      item = res[0]
    }

    const shoeName = item?.shoeName || item?.name || item?.title
    const brand = item?.brand
    const lowestResellPrice = item?.lowestResellPrice
    const styleID = item?.styleID || item?.style_id || item?.model
    const releaseDate = item?.releaseDate || item?.release_date
    const resellPrices = item?.resellPrices
    const retailPrice = item?.retailPrice || item?.price
    const thumbnail = item?.thumbnail || item?.thumbnail_url || item?.image
    const subcategory = item?.category || 'High-Top'
    const color = item?.color
    const size = item?.size
    const category =
      item?.parent_category ||
      (item?.product_category === 'handbags' && 'Accessories') ||
      (item?.product_category !== 'sneakers' && 'Apparel') ||
      (item?.gender?.toLowerCase().includes('child') && `Kid's Shoes`) ||
      ((item?.gender?.toLowerCase()?.includes('preschool') ||
        item?.gender?.toLowerCase()?.includes('toddler')) &&
        `Toddler's`) ||
      `${capitalizeFirstLetter(item?.gender)}'s Shoes` ||
      "Men's Shoes"

    updateStateValue('lowestResellPrice', lowestResellPrice)

    // todo: should compute percentage
    setFormFields({
      ...formFields,
      title: shoeName,
      brand,
      sku: styleID,
      releaseDate,
      category,
      subcategory,
      retailPrice,
      color,
    })

    //set image if there is one
    if (thumbnail) {
      // new asset record
      const nAsset = {
        new: true,
        asset: {
          url: thumbnail,
        },
      }
      setFormFields((s) => ({
        ...s,
        assets: [nAsset],
      }))
    }

    if (resellPrices?.stockX) {
      updateStateValue('stockXPriceArr', resellPrices.stockX)
      if (lowestResellPrice?.stockX) {
        updateStateValue('stockXPrice', lowestResellPrice.stockX)
      }
    } else {
      updateStateValue('stockXPriceArr', [])
      updateStateValue('stockXPrice', null)
    }
    if (resellPrices?.flightClub) {
      updateStateValue('flightClubPriceArr', resellPrices.flightClub)
      if (lowestResellPrice?.flightClub) {
        updateStateValue('flightClubPrice', lowestResellPrice.flightClub)
      }
    } else {
      updateStateValue('flightClubPriceArr', [])
      updateStateValue('flightClubPrice', null)
    }
  }

  const updateDescriptionTemplate = (field, value, description) => {
    if (description) {
      if (field == 'sku' && description.includes('SKU: ')) {
        return description.replace(/[SKU]+/gm, value + '\n')
      } else if (field == 'size' && description.includes('size ')) {
        return description.replace(/size .*\n/, 'size ' + value + '\n')
      } else if (field == 'condition') {
        if (value == 'Brand New') {
          return description.replace(
            /Worn .*x, check pictures for condition\n/,
            'Brand new, never worn\n',
          )
        } else if (value == 'Pre-Owned') {
          return description.replace(
            /Brand new, never worn\n/,
            'Worn _x, check pictures for condition\n',
          )
        }
      }
    } else if (formFields && formFields.description) {
      if (field === 'size') {
        const searchSize = '[SIZE]'
        const replaceWithSize = formFields?.size || ''
        updateFormFields('description', formFields?.description?.replaceAll(searchSize, replaceWithSize))
      }
    }

    return formFields.description
  }

  const prepareProductRequest = (formFields) => {
    if (!formFields.releaseDate) delete formFields.releaseDate
    if (!formFields.acquiredDate) delete formFields.acquiredDate

    // replace wildcards in description
    formFields.description = _replaceWildCards(
      formFields,
      descriptionTemplate[0]?.description || formFields.description,
      currentDescriptionWildcardList,
    )
  }

  const canSaveAndConsign = !isEnterprise && formFields?.consignorEmail

  const onFormSubmit = async () => {
    if (formFields?.contractPdfLink && !formFields?.isAcceptedContract && canSaveAndConsign) {
      updateStateValue('modalType', ModalType.CONSIGNMENT_CONTRACT)
      return
    }

    updateStateValue('isFormDirty', false)

    const requiredProperties = [
      'brand',
      'title',
      'category',
      'size',
      'desiredReturn',
      ...(isEnterprise ? ['location'] : []),
      'sku',
    ]
    if (/Apparel|Accessories/.test(formFields?.category)) requiredProperties.pop()

    const formError = _validateFormFields(formFields, requiredProperties)
    updateStateValue('errorMessage', formError)
    updateStateValue('isSubmitting', true)

    if (!formError) {
      updateStateValue('isLoading', true)
      const token = await getAccessTokenSilently()
      try {
        let response = null
        prepareProductRequest(formFields)
        if (formFields?.id && formFields?.id !== 'addItem') {
          const updatedForm = getPredefinedObject(formFields, [
            'acquiredDate',
            'assets',
            'boxCondition',
            'boxDimensions',
            'boxWeight',
            'brand',
            'category',
            'color',
            'comments',
            'condition',
            'consignorEmail',
            'description',
            'desiredReturn',
            'flaws',
            'id',
            'internalSku',
            'location',
            'price',
            'quantity',
            'releaseDate',
            'retailPrice',
            'shippingMethod',
            'size',
            'sku',
            'subLocation',
            'subcategory',
            'tagStatus',
            'title',
            'feeStructure',
          ])

          response = await axios.patch(
            `${apiConfig.api_url}/product/${formFields.id}`,
            {
              ...updatedForm,
            },
            createRequestOptions(token),
          )
          setFormFields({...formFields, ...updatedForm})
        } else if (Array?.isArray(formFields?.size) && formFields?.size?.length > 0) {
          response = await Promise.all(
            formFields?.size.flatMap(async (f) => {
              const apiCalls = []
              for (let i = 0; i < f.quantity; i++) {
                apiCalls.push(
                  axios.post(
                    `${apiConfig.api_url}/product`,
                    {
                      ...formFields,
                      ...f,
                    },
                    createRequestOptions(token),
                  ),
                )
              }
              return Promise.all(apiCalls)
            }),
          )
        }

        updateStateValue('isLoading', false)
        updateStateValue('toastType', ToastType.SUCCESS)
        updateStateValue('postResponse', null)

        if (Array.isArray(response) && response?.length > 0) {
          setTimeout(() => {
            const redirectUrl = canSaveAndConsign ? 'viewInventory/listed' : 'viewInventory'
            routeChange(`/admin/inventory/${redirectUrl}`)
          }, 1000)
        } else if (response.status == 200 || (response.status == 201 && !tempImageError)) {
          setTimeout(() => {
            routeChange(`/admin/inventory/viewItem/${response?.data?.data.id}`)
          }, 1000)
        } else if (response?.status != 201 || response?.status != 200) {
          updateStateValue('toastType', ToastType.ERROR)
          updateStateValue('postResponse', response?.data?.data?.error || response?.data?.data)
        }
      } catch (err) {
        Sentry.captureException(err)
        updateStateValue('isLoading', false)
        updateStateValue('toastType', ToastType.ERROR)
        updateStateValue(
          'postError',
          err?.response?.data?.data?.error || err?.response?.data?.data || err?.message,
        )
      }
    }
  }

  const deleteItem = async () => {
    updateStateValue('isLoading', true)
    updateStateValue('isFormDirty', false)
    try {
      // Remove any listings
      let delistResponse
      if (formFields?.listing?.id) {
        const delToken = await getAccessTokenSilently()
        delistResponse = await axios.delete(
          `${apiConfig.api_url}/listings/batch/${formFields.listing.id}`,
          {},
          createRequestOptions(delToken),
        )
      }

      if (!delistResponse || delistResponse.status === 200) {
        // update item deleted flag
        const token = await getAccessTokenSilently()

        var response = await axios.delete(
          `${paths.product}/${formFields.id || urlParams.id}`,
          createRequestOptions(token),
        )
        updateStateValue('toastType', ToastType.SUCCESS)
        setTimeout(() => {
          routeChange('/admin/inventory/viewInventory')
        }, 1000)
      } else {
        updateStateValue('toastType', ToastType.ERROR)
        updateStateValue('postResponse', 'Failed to delist item')
      }
    } catch (err) {
      Sentry.captureException(err)
      updateStateValue('toastType', ToastType.ERROR)
      updateStateValue('postResponse', 'Failed to delete item')
      updateStateValue('disableLoading', false)
    }
  }

  const deleteImage = useCallback(
    (img) => {
      if (img?.asset) {
        const {id} = img.asset

        // Add the image id to deleteImages
        updateStateValue('deleteImages', (prevDeleteImages) => [...prevDeleteImages, id])

        setFormFields((prevFields) => {
          // Filter out the image to be deleted
          const remainingAssets = prevFields.assets.filter((a) => a.asset.id !== id)

          // Create the new asset with deletedAt
          const updatedAsset = {
            ...img,
            asset: {
              ...img.asset,
              deletedAt: new Date().toISOString(),
            },
            deletedAt: new Date().toISOString(),
          }

          // Return the updated formFields
          return {
            ...prevFields,
            assets: [...remainingAssets, updatedAsset],
          }
        })
      }
    },
    [setFormFields],
  )

  const handleSizeChange = (size) => {
    const isSizeEmpty = size === ''
    const parsedSize = parseFloat(size)
    updateFormFields('size', size)
    updateStateValue('stockXPrice', isSizeEmpty ? lowestResellPrice?.stockX : stockXPriceArr[parsedSize])
    updateStateValue(
      'flightClubPrice',
      isSizeEmpty ? lowestResellPrice?.flightClub : flightClubPriceArr[parsedSize],
    )
  }

  const handleInputChange = (e) => {
    const {name, value} = e.target
    updateFormFields(name, value)
  }

  const shouldProceed = (isClicked) => {
    if (isClicked) {
      const url = routes || '/admin/inventory/viewInventory'
      routeChange(url)
    }
  }

  const hasError = isSubmitting && errorMessage
  const hasSkuIssue =
    hasError &&
    !formFields.sku &&
    (formFields.category?.includes('Shoes') || formFields.category?.includes('Toddler'))
  const noChanges = isEqual(formFields, item) || false
  const setModalType = (modalType) => updateStateValue('modalType', modalType)

  return (
    <Box ref={wrapperRef} className="p-detail-flex inventory addItem">
      {isLoading && <LinearProgress color="secondary" />}
      <MenuHeader
        {...{
          editMode,
          isDisabled,
          isLoading: isLoading || addItemPage.isSaveButtonDisabled,
          modalType,
          routeChange,
          onFormSubmit,
          updateStateValue,
          isConsigned: isConsigned(formFields),
        }}
        label={canSaveAndConsign ? 'Save & Consign' : 'Save'}
        canSaveAndConsign={canSaveAndConsign && isEmpty(formFields?.location)}
        onResetAll={editMode ? deleteItem : () => setFormFields(initialFormFields)}
        withdrawItem={handleButtonClick}
      />
      <Box borderBottom={1} borderColor="divider" boxShadow="0 1px 5px #bebdbdab">
        <Tabs
          value={tab}
          onChange={(e, tab) => {
            updateStateValue('tab', tab)
            localStorage.setItem('addItemTab', tab)
          }}
          textColor="secondary"
          indicatorColor="secondary"
          sx={{px: 2}}
        >
          <Tab label="Simplified" {...a11yProps(1)} />
          <Tab label="Expanded" {...a11yProps(0)} />
        </Tabs>
      </Box>
      <DetailView.PanelDetail>
        <CustomTabPanel
          {...state}
          {...{
            formFields,
            forceUpdate,
            deleteImage,
            descriptionTemplate,
            hasSkuIssue,
            hasError,
            handleInputChange,
            handleSizeChange,
            errorMessage,
            isLoading,
            isEnterprise,
            setFormFields,
            setModalType,
            template,
          }}
          setPreviewState={(e) => {
            updateStateValue('previewState', e)
          }}
        />

        <InventoryModals
          {...{
            errorMessage,
            formFields,
            handleInputChange,
            hasError,
            hasSkuIssue,
            isEnterprise,
            modalType,
            productTemplate,
            previewState,
            setFormFields,
            setModalType,
            template,
            updateDescriptionTemplate,
            updateStateValue,
            hasConsignorCode,
            setHasConsignorCode,
            data: [item],
          }}
          setPreviewState={(e) => {
            updateStateValue('previewState', e)
          }}
          setProductTemplate={(productTemplate) => updateStateValue('productTemplate', productTemplate)}
          action={{
            routeChange: shouldProceed,
            processSearchBy: processSearchBy,
            updateStateValue: updateStateValue,
          }}
          fromWhere={tabSelectionStr.ADD_ITEM}
        />
        <InventoryToast
          {...{toastType}}
          content={postResponse}
          setToastType={(e) => updateStateValue('toastType', e)}
        />
      </DetailView.PanelDetail>
      <Prompt
        when={!noChanges && isFormDirty}
        message="Are you sure you want to leave? Your changes may not be saved."
      />
    </Box>
  )
}

export default AddItem
