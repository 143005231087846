import * as React from 'react'
import {Box, Menu, MenuItem, ListItemIcon, Divider, IconButton, Tooltip, Stack} from '@mui/material'
import {
  SupportAgent as SupportAgentIcon,
  Settings as Settings,
  ManageAccounts as ManageAccountsIcon,
  AccountCircle as AccountCircleIcon,
} from '@mui/icons-material'
import {styled} from '@mui/material/styles'
import LogoutDropdownItem from 'components/logout-dropdown-item'
import {useGlobalStore} from 'provider/global_store/hook'

export const AccountItem = styled(MenuItem)(() => ({
  padding: '6px 16px !important',
}))

export const AvatarIcon = styled(IconButton)(() => ({
  margin: '0 8px !important',
  padding: '3px !important',
  fontSize: '1.125rem',
}))

const AccountMenu = (p) => {
  const {history, toggleModal} = p || {}
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const {user} = useGlobalStore()
  const {logo_url} = user?.platform || ''
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const isAddItem = window.location.href.indexOf('addItem') === -1

  return (
    <React.Fragment>
      <Stack direction="row" alignItems="center" textAlign="center">
        <Tooltip title="Account settings">
          <AvatarIcon
            onClick={handleClick}
            size="small"
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            {logo_url ? (
              <Box
                component="img"
                sx={{
                  borderRadius: '100%',
                  width: '28px',
                  minHeight: '28px',
                  padding: '2px',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center center',
                  backgroundColor: 'transparent',
                  border: '2px solid var(--success)',
                }}
                src={logo_url}
              />
            ) : (
              <AccountCircleIcon
                className="account-circle"
                sx={{fontSize: '2rem !important', color: 'var(--success)'}}
              />
            )}
          </AvatarIcon>
        </Tooltip>
      </Stack>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        MenuListProps={{
          style: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: '5px',
          },
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
          },
        }}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
      >
        <AccountItem onClick={() => history.push('/admin/profile')}>
          <ListItemIcon>
            <ManageAccountsIcon fontSize="small" />
          </ListItemIcon>
          Profile
        </AccountItem>
        <AccountItem onClick={() => history.push('/admin/settings')}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </AccountItem>
        <AccountItem onClick={toggleModal}>
          <ListItemIcon>
            <SupportAgentIcon fontSize="small" />
          </ListItemIcon>
          Support
        </AccountItem>
        <Divider width="100%" />
        <LogoutDropdownItem />
      </Menu>
    </React.Fragment>
  )
}

export default AccountMenu
