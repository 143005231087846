import React, {memo, useState} from 'react'
import {Stack, Typography} from '@mui/material'
import SearchBar from 'material-ui-search-bar'
import styled from 'styled-components'
import {isEmpty} from 'lodash'

import DetailView from 'components/DetailView'
import CopytCard from 'components/CopytCard'
import {useLink} from 'service/hook/useLink'
import {ErrorMessage} from 'views_v2/lib/components'
import {CopytTooltip, EmptyData, LoadingBar} from 'views_v2/lib/snippets'

const TextValue = styled(Typography)(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}))

const PendingCodes = () => {
  const [searchQuery, setSearchQuery] = useState('')
  const {link, isLoading, isError} = useLink()

  const handleSearch = (query) => {
    setSearchQuery(query)
  }

  const filteredLinks = link?.filter((l) =>
    [l.name, l.code].some((field) => field.toLowerCase().includes(searchQuery.toLowerCase())),
  )

  if (isLoading) return <LoadingBar />

  return (
    <DetailView.Panel style={{height: '95%'}}>
      <CopytCard style={{height: '100%', flex: 1}}>
        <CopytCard.Header>
          <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
            <CopytCard.Text tag="div"> Pending Codes</CopytCard.Text>
            <SearchBar
              style={{
                height: '35px',
              }}
              autoFocus
              placeholder="Search..."
              disabled={isError || isEmpty(link)}
              cancelOnEscape
              onCancelSearch={() => handleSearch('')}
              onRequestSearch={(e) => handleSearch(e)}
            />
          </Stack>
        </CopytCard.Header>
        <CopytCard.Body style={{overflowY: 'auto', height: '100%'}}>
          {isError ? (
            <ErrorMessage />
          ) : isEmpty(link) ? (
            <EmptyData title="Sorry! There is no pending codes." />
          ) : (
            <Stack
              direction="row"
              flexWrap="wrap"
              alignItems="center"
              justifyContent="flex-start"
              width="100%"
              rowGap={2}
              columnGap={2}
            >
              {filteredLinks.map((i, index) => (
                <Stack
                  key={index}
                  p={1}
                  sx={{
                    borderRadius: '5px',
                    border: '1px solid var(--lighter)',
                    '&:hover': {
                      backgroundColor: 'lightgray',
                    },
                  }}
                  width={{xl: '15%', md: '15%', sm: '45%', xs: '100%'}}
                >
                  <TextValue fontWeight="fontWeightBold">
                    code:&nbsp;
                    <TextValue component="span">{i.code}</TextValue>
                  </TextValue>
                  <TextValue fontWeight="fontWeightBold">
                    name:&nbsp;
                    <CopytTooltip title={i?.name}>
                      <TextValue component="span">{i.name}</TextValue>
                    </CopytTooltip>
                  </TextValue>
                </Stack>
              ))}
            </Stack>
          )}
        </CopytCard.Body>
      </CopytCard>
    </DetailView.Panel>
  )
}

export default memo(PendingCodes)
