import React, {memo} from 'react'
import clx from 'classnames'
import CopytModal from 'components/CopytModal'
import {Enterprise, Standard} from 'components'
import {useGlobalStore} from 'provider/global_store/hook'
import {developerConfig} from 'config'
import PaywallEnterprise from './PaywallEnterprise'
import PaywallIndividual from './PaywallIndividual'
import './Paywall.scss'

export const Paywall = (p) => {
  const {modalState, modalToggle, skipPaywall, setSkipPaywall} = p
  const {isEnterprise} = useGlobalStore()

  // disabled paywall on dev mode
  if (developerConfig.noPaywall) {
    return null
  }

  const classNames = clx('premium-modal', !isEnterprise && 'standard')

  return (
    <CopytModal
      className={classNames}
      size="lg"
      show={modalState && !skipPaywall}
      closeButton={{
        onClick: () => {
          modalToggle(!modalState)
          setSkipPaywall(true)
        },
      }}
    >
      <Enterprise>
        <PaywallEnterprise />
      </Enterprise>
      <Standard>
        <PaywallIndividual />
      </Standard>
    </CopytModal>
  )
}

export default memo(Paywall)
