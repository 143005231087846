import React, {useState} from 'react'
import {useHistory} from 'react-router-dom'
import {useCredential} from 'service/hook/use_credential'
import {usePlatform} from 'service/hook/use_platform'
import CopytProModal from './component/CopytProModal'

const CopytPro = () => {
  const history = useHistory()
  const {onCreateCredential} = useCredential()
  const {platforms} = usePlatform()
  const copytNetworkPlatform = platforms.find((platform) => platform.name === 'Copyt Network')
  const [isLoading, setIsLoading] = useState(false)

  const handleNextClick = () => {
    setIsLoading(true)
    onCreateCredential({ platformId: copytNetworkPlatform.id, value: {} })

    setTimeout(() => {
      history.push('/admin/platforms/49385495-025f-457b-9b20-502b18564d9b')
    }, 5000)
  }

  return (
    <CopytProModal
      {...{
        copytNetworkPlatform,
        isLoading,
        handleNextClick,
      }}
    />
  )
}

export default CopytPro
