import React, { useState } from 'react'
import moment from 'moment'
import _ from 'lodash'
import Datetime from 'react-datetime'
import { Box, Grid, Typography } from '@mui/material'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import styled from 'styled-components'
import CopytButton from './CopytButton'
import CopytLabel from './CopytLabel'
import './scss/date-picker.scss'
import DateUtils from 'util/date_utils'

const FooterBtn = styled(CopytButton)(() => ({
  minWidth: "180px",
  borderRadius: "6px",
  marginTop: '20px',
  padding: 5
}))

const CopytDatePicker = ({
  date,
  handleDateRange = () => null,
}) => {
  const [startDate, setStartDate] = useState(date?.startDate ? new Date(date?.startDate) : null)
  const [endDate, setEndDate] = useState(date?.endDate ? new Date(date?.endDate) : null)
  const [error, setError] = useState(null)

  const handleShortcutClick = (start, end) => {
    const startTime = new Date(start).setHours(0, 0, 0, 0)
    const endTime = new Date(end).setHours(23, 59, 59, 59)
    setStartDate(startTime)
    setEndDate(endTime)
  }

  const onDateChange = (momentObj, type = null) => {
    const dateTime = new Date(momentObj.toDate().setHours(0, 0, 0, 0))
    if (type) {
      setStartDate(dateTime)
    } else {
      dateTime.setHours(23, 59, 59, 59)
      setEndDate(dateTime)
    }
  }

  const shortcuts = [
    {
      label: 'Today',
      start: new Date().setHours(0, 0, 0, 0),
      end: new Date().setHours(23, 59, 59, 59),
    },
    {
      label: 'This Week',
      start: moment().clone().startOf('isoWeek'),
      end: moment().clone().endOf('isoWeek'),
    },
    // {
    //   label: 'Last Week',
    //   start: moment().subtract(1, 'weeks').startOf('isoWeek'),
    //   end: moment().subtract(1, 'weeks').endOf('isoWeek')
    // },
    {
      label: 'Last 7 Days',
      start: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
      end: new Date(),
    },
    {
      label: 'This Month',
      start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      end: new Date(new Date().getFullYear(), new Date().getMonth(), 30),
    },
    {
      label: 'Previous Month',
      start: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
      end: new Date(new Date().getFullYear(), new Date().getMonth(), 0),
    },
  ];

  return (
    <Grid container display="flex" alignItems="center">
      {error && <Typography variant="caption" color="red">{error}</Typography>}
      <Grid item sm={12} md={8} pr={2}>
        <Box>
          <CopytLabel>Start Date:</CopytLabel>
          <CalendarMonthIcon sx={{ position: 'absolute', mt: 1, ml: 0.5 }} />
          <Datetime
            renderInput={(props, openCalendar) => {
              return <input {...props} readOnly onClick={openCalendar} className="form-control format-date-time" />;
            }}
            value={startDate}
            onChange={(e) => onDateChange(e, 'start')}
            timeFormat={false}
            closeOnSelect
          />
        </Box>
        <Box mt={1}>
          <CopytLabel>End Date:</CopytLabel>
          <CalendarMonthIcon sx={{ position: 'absolute', mt: 1, ml: 0.5 }} />
          <Datetime 
            renderInput={(props, openCalendar) => {
              return <input {...props} readOnly onClick={openCalendar} className="form-control format-date-time" />;
            }}
            value={endDate}
            onChange={(e) => onDateChange(e)}
            timeFormat={false}
            closeOnSelect
          />
        </Box>
      </Grid>
      <Grid item sm={12} md={4} display="flex" flexDirection="column" alignItems="flex-end" gap={0.5}>
        {shortcuts.map((shortcut, i) => (
          <CopytButton
            key={i}
            onClick={() => handleShortcutClick(shortcut.start, shortcut.end)}
            variant="outline"
            color="primary"
            sx={{
              minWidth: '8rem',
              p: 2,
            }}
          >
            {shortcut.label}
          </CopytButton>
        ))}
      </Grid>
      <Grid item sm={12} md={5} gap={2} display="flex" ml={2.5}>
        <FooterBtn
          variant="outline"
          color="primary"
          onClick={() => {
            handleDateRange({ startDate: null, endDate: DateUtils.now() })
          }}
        >
          Reset
        </FooterBtn>
        <FooterBtn
          variant="contained"
          color="primary"
          onClick={() => {
            if (startDate > endDate) {
              setError('Start Date cannot be greater than End Date')
            } else {
              setError(null)
              const startDateWithTime = moment(startDate).set({ hour: 0, minute: 0, second: 0 })
              const endDateWithTime = moment(endDate).set({ hour: 23, minute: 59, second: 59 })
              
              handleDateRange({
                startDate: startDateWithTime.utc(),
                endDate: endDateWithTime.utc()
              })
            }
          }}
        >
          Update
        </FooterBtn>
      </Grid>
    </Grid>
  )
}

export default CopytDatePicker
