import React, { memo } from 'react'
import clx from 'classnames'
import UpgradeBody from './UpgradeBody'

export const PaywallUpgradeAccount = ({ withoutBody = false, setContentType, visible = false }) => {
  return (
    <div className={clx(!withoutBody && 'paywall-wrapper', !visible && 'hide')}>
      {withoutBody ? <UpgradeBody {...{ setContentType }} /> : (
        <div className="paywall-wrapper--body">
          <UpgradeBody {...{ setContentType }} />
        </div>
      )}

    </div>
  )
}

export default memo(PaywallUpgradeAccount)
