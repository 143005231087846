import React, {useState} from 'react'
import {Box, Typography, Stack, CircularProgress, LinearProgress} from '@mui/material'
import Select from 'react-select'
import {includes, isEmpty, values} from 'lodash'

import {metricSize, weightSize} from 'assets/data/options/dimension_options'
import {_replaceWildCards} from 'util/string_utils'
import {CopytLabel, CopytStyledButton, FieldGroup} from 'views_v2/lib/snippets'
import {useShipmentParcelPreset} from 'service/hook/useShipments'

const defaultFormData = {
  name: null,
  isPinned: false,
  weight: null,
  length: null,
  width: null,
  height: null,
  distanceUnit: 'in',
  massUnit: 'lb',
}

const MdlCreateBoxPreset = (props) => {
  const {onCloseModal} = props
  const {
    parcelPreset,
    isFetching,
    createParcelPreset,
    updateParcelPreset,
    updatingParcelPreset,
    creatingParcelPreset,
    deletingParcelPreset,
    deleteParcelPreset,
  } = useShipmentParcelPreset()
  const [selectedShipmentParcelPresetId, setSelectedShipmentParcelPresetId] = useState()
  const [formData, setFormData] = useState(defaultFormData)
  const [error, setError] = useState()

  const onSaveChanges = async () => {
    const isDuplicate =
      !isEmpty(parcelPreset) &&
      parcelPreset?.some((p) => p?.name?.toLowerCase() === formData.name?.toLowerCase())

    if (isDuplicate) {
      setError('Parcel is already available. Please choose another parcel name.')
    } else {
      await createParcelPreset(formData)
    }
  }

  return (
    <Box padding="0 !important">
      <Box className="modal-title">
        <Box className="modal__heading" mx="0px !important">
          <Typography variant="h1" textAlign="left">
            Create Box Preset
          </Typography>
        </Box>
      </Box>
      <Stack direction="row">
        <Box width="50%" p={2}>
          <Stack direction="column">
            <Stack spacing={1}>
              <CopytLabel style={{fontSize: '0.9rem', marginTop: '15px'}}>Preset Name *</CopytLabel>
              <FieldGroup
                disabled={updatingParcelPreset || creatingParcelPreset || deletingParcelPreset}
                onChange={(e) => {
                  if (error) setError()
                  setFormData({...formData, name: e.target.value})
                }}
                hasError={!!error}
                errorMessage={error}
                value={formData?.name}
              />
            </Stack>

            <CopytLabel style={{fontSize: '0.9rem', marginTop: '20px'}}>Dimensions</CopytLabel>
            <Stack direction="row" gap={2} alignItems="flex-end">
              <Stack width="80%" direction="row" alignItems="end" gap={1}>
                <FieldGroup
                  disabled={updatingParcelPreset || creatingParcelPreset || deletingParcelPreset}
                  sLabel="Length *"
                  id="numeric"
                  fixedDecimalScale={false}
                  decimalScale={4}
                  value={formData?.length}
                  onChange={(e) => setFormData({...formData, length: e.target.value})}
                />
                <Typography style={{marginBottom: '15px'}}>×</Typography>
                <FieldGroup
                  disabled={updatingParcelPreset || creatingParcelPreset || deletingParcelPreset}
                  sLabel="Width *"
                  id="numeric"
                  fixedDecimalScale={false}
                  decimalScale={4}
                  value={formData?.width}
                  onChange={(e) => setFormData({...formData, width: e.target.value})}
                />
                <Typography style={{marginBottom: '15px'}}>×</Typography>
                <FieldGroup
                  disabled={updatingParcelPreset || creatingParcelPreset || deletingParcelPreset}
                  sLabel="Height *"
                  id="numeric"
                  fixedDecimalScale={false}
                  decimalScale={4}
                  value={formData?.height}
                  onChange={(e) => setFormData({...formData, height: e.target.value})}
                />
              </Stack>
              <Box width="20%" marginBottom="5px">
                <Select
                  isDisabled={updatingParcelPreset || creatingParcelPreset || deletingParcelPreset}
                  className="react-select info"
                  classNamePrefix="react-select"
                  closeMenuOnSelect
                  value={metricSize.find((ms) => ms.value === formData.distanceUnit)}
                  onChange={(e) => {
                    if (e) {
                      setFormData({...formData, distanceUnit: e.value})
                    }
                  }}
                  styles={{
                    control: (base) => ({
                      ...base,
                      borderColor: 'var(--lighter) !important',
                      marginBottom: '5px',
                    }),
                  }}
                  options={metricSize}
                />
              </Box>
            </Stack>
            <CopytLabel style={{fontSize: '0.9rem', marginTop: '15px'}}>Package Weight</CopytLabel>
            <Stack direction="row" spacing={2} alignItems="flex-end" width="50%">
              <FieldGroup
                disabled={updatingParcelPreset || creatingParcelPreset || deletingParcelPreset}
                sLabel="Weight *"
                id="numeric"
                fixedDecimalScale={false}
                decimalScale={4}
                value={formData?.weight}
                onChange={(e) => setFormData({...formData, weight: e.target.value})}
              />
              <Box width="100%">
                <Select
                  isDisabled={updatingParcelPreset || creatingParcelPreset || deletingParcelPreset}
                  className="react-select info"
                  classNamePrefix="react-select"
                  closeMenuOnSelect
                  options={weightSize}
                  value={weightSize.find((ms) => ms.value === formData.massUnit)}
                  onChange={(e) => {
                    if (e) {
                      setFormData({...formData, massUnit: e.value})
                    } else {
                    }
                  }}
                  styles={{
                    control: (base) => ({
                      ...base,
                      borderColor: 'var(--lighter) !important',
                      marginBottom: '10px',
                    }),
                  }}
                />
              </Box>
            </Stack>
          </Stack>
        </Box>

        <Box bgcolor="var(--secondary)" width="50%">
          {isFetching || updatingParcelPreset || creatingParcelPreset || deletingParcelPreset ? (
            <LinearProgress color="secondary" />
          ) : (
            <Stack height="4px"></Stack>
          )}
          <Stack
            sx={{
              gap: 1,
              height: '500px',
              overflowY: 'auto',
              padding: '12px',
              pointerEvents:
                updatingParcelPreset || creatingParcelPreset || deletingParcelPreset ? 'none' : 'auto',
            }}
          >
            {parcelPreset?.map((preset) => (
              <Box
                key={preset.id}
                px={1}
                border="2px solid"
                bgcolor={
                  preset?.id === selectedShipmentParcelPresetId ? 'var(--primary-light)' : 'white'
                }
                borderColor={
                  preset?.id === selectedShipmentParcelPresetId ? 'var(--default)' : 'var(--lighter)'
                }
                borderRadius="6px"
                onClick={() => {
                  if (updatingParcelPreset || creatingParcelPreset || deletingParcelPreset) return
                  setSelectedShipmentParcelPresetId(preset?.id)
                  setFormData({
                    name: preset.name,
                    isPinned: preset.isPinned,
                    weight: preset.weight,
                    length: preset.length,
                    width: preset.width,
                    height: preset.height,
                    distanceUnit: preset.distanceUnit,
                    massUnit: preset.massUnit,
                  })
                }}
                sx={{
                  '&:hover, &:active': {
                    outline: '2px solid',
                    background: 'var(--lighter)',
                  },
                  cursor: 'pointer',
                }}
              >
                <Stack padding={1} direction="row" alignItems="center" justifyContent="space-between">
                  <Typography fontSize={14} fontWeight={600}>
                    {preset?.name}
                  </Typography>
                  <Stack>
                    <Typography color="black" fontWeight={600} fontSize="14px" textAlign="right">
                      {`${preset?.weight} ${preset?.massUnit}`}
                    </Typography>
                    <Typography color="var(--gray)" fontWeight={500} fontSize="13px" textAlign="right">
                      {preset?.length} x {preset?.width} x {preset?.height}
                      {preset?.distanceUnit}
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
            ))}
          </Stack>
          <Stack
            direction="row"
            justifyContent="flex-end"
            pr={2}
            pt={2}
            mb={2}
            borderTop="1px solid #eee"
            spacing={2}
          >
            <CopytStyledButton
              disabled={
                !selectedShipmentParcelPresetId ||
                updatingParcelPreset ||
                creatingParcelPreset ||
                deletingParcelPreset
              }
              variant="outline"
              color="error"
              onClick={async () => {
                await deleteParcelPreset(selectedShipmentParcelPresetId)
                setSelectedShipmentParcelPresetId(undefined)
              }}
            >
              {deletingParcelPreset ? 'Deleting' : 'Delete'}
            </CopytStyledButton>
            <CopytStyledButton
              disabled={
                !selectedShipmentParcelPresetId ||
                updatingParcelPreset ||
                creatingParcelPreset ||
                deletingParcelPreset
              }
              variant="outline"
              color="primary"
              onClick={async () => {
                updateParcelPreset({id: selectedShipmentParcelPresetId, ...formData})
              }}
            >
              {updatingParcelPreset ? 'Updating' : 'Update'}
            </CopytStyledButton>
          </Stack>
        </Box>
      </Stack>

      <Stack
        direction="row"
        justifyContent="flex-end"
        pr={2}
        pt={2}
        mb={2}
        borderTop="1px solid #eee"
        spacing={2}
      >
        <CopytStyledButton
          disabled={updatingParcelPreset || creatingParcelPreset || deletingParcelPreset}
          variant="outline"
          color="primary"
          onClick={onCloseModal}
        >
          BACK
        </CopytStyledButton>
        <CopytStyledButton
          variant="contained"
          color="primary"
          onClick={onSaveChanges}
          disabled={
            includes(values(formData), null) ||
            creatingParcelPreset ||
            updatingParcelPreset ||
            deletingParcelPreset
          }
          style={{
            minWidth: '100px',
          }}
        >
          {creatingParcelPreset ? 'Creating' : 'Create'}
        </CopytStyledButton>
      </Stack>
    </Box>
  )
}

export default MdlCreateBoxPreset
