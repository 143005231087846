import React, {useState} from 'react'
import {Box, Typography, Stack, CircularProgress, Divider} from '@mui/material'
import {isEmpty} from 'lodash'

import {CopytStyledButton, FieldGroup, PromoCode} from 'views_v2/lib/snippets'
import EmptyData from 'views_v2/lib/components/EmptyData'

const MdlDiscountCode = (p) => {
  const {
    onCloseModal,
    discounts,
    canUseDiscount,
    shipment,
    applyShipmentDiscount,
    isApplyingShipmentDiscount,
    applyDiscount,
    isApplyingDiscount,
  } = p

  const [selectedDiscount, setSelectedDiscount] = useState(shipment?.discount)
  const [discountCodeInput, setDiscountCodeInput] = useState('')

  return (
    <Box padding="0 !important">
      <Box className="modal-title">
        <Box className="modal__heading" mx="0px !important">
          <Typography variant="h1" textAlign="left">
            Discount Code
          </Typography>
        </Box>
      </Box>

      <Box className="modal-body" padding={0} my={2}>
        {false ? (
          <Stack justifyContent="center" alignItems="center" my={7}>
            <CircularProgress size={24} />
            <Typography color="var(--gray) !important">Please wait...</Typography>
          </Stack>
        ) : (
          <Stack>
            <Stack direction="row" spacing={2} px={2} alignItems="center" pb={2}>
              <FieldGroup
                disabled={isApplyingShipmentDiscount || isApplyingDiscount}
                placeholder="Please enter discount code"
                value={discountCodeInput}
                onChange={(e) => setDiscountCodeInput(e.target.value)}
              />
              <CopytStyledButton
                disabled={isApplyingShipmentDiscount || isApplyingDiscount}
                variant="contained"
                color="primary"
                style={{marginBottom: '10px'}}
                onClick={async () => {
                  await applyDiscount({code: discountCodeInput})
                  setDiscountCodeInput('')
                  setSelectedDiscount(shipment?.discount)
                }}
              >
                Apply
              </CopytStyledButton>
            </Stack>

            <Divider>Available Discounts</Divider>
            {isEmpty(discounts) ? (
              <EmptyData title="No available discount code" />
            ) : (
              <Stack
                direction="row"
                flexWrap="wrap"
                px={2}
                pt={2}
                pb={5}
                gap={2}
                maxHeight="500px"
                sx={{overflowY: 'auto'}}
              >
                {discounts?.map((discount) => {
                  return (
                    <PromoCode
                      key={discount?.id}
                      {...{
                        discount,
                        canUseDiscount,
                        isApplyingShipmentDiscount,
                        setSelectedDiscount,
                        selectedDiscount,
                        isApplyingDiscount,
                      }}
                    />
                  )
                })}
              </Stack>
            )}
          </Stack>
        )}

        <Stack
          direction="row"
          justifyContent="flex-end"
          pr={2}
          pt={2}
          borderTop="1px solid #eee"
          spacing={2}
        >
          <CopytStyledButton
            disabled={isApplyingShipmentDiscount || isApplyingDiscount}
            variant="outline"
            color="primary"
            onClick={onCloseModal}
          >
            Close
          </CopytStyledButton>
          {canUseDiscount && (
            <CopytStyledButton
              disabled={isApplyingShipmentDiscount || !selectedDiscount || isApplyingDiscount}
              onClick={async () => {
                if (isApplyingShipmentDiscount) return
                if (selectedDiscount?.id === shipment?.discount?.id) {
                  await applyShipmentDiscount({
                    shipmentId: shipment.id,
                    discountId: null,
                  })
                } else {
                  await applyShipmentDiscount({
                    shipmentId: shipment.id,
                    discountId: selectedDiscount.id,
                  })
                }
                onCloseModal()
              }}
              variant="contained"
              color="primary"
            >
              {shipment?.discount && selectedDiscount?.id === shipment?.discount?.id
                ? 'Remove'
                : 'Select'}
            </CopytStyledButton>
          )}
        </Stack>
      </Box>
    </Box>
  )
}

export default MdlDiscountCode
