import React from 'react'
import PropTypes from 'prop-types'
import clx from 'classnames'
import {Button, ButtonGroup} from '@mui/material'

export const TabPanel = {
  UNDEFINED: -1,
  MANAGEMENT: 0,
  CONSIGNMENT_SHOP: 1,
  CLAIMABLE_ITEMS: 2,
  PENDING_CODES: 3,
  BATCHES: 4,
}

const TabPanels = ({size, disabled, tabItems, tabPanel, onSelect, isEnterprise}) => {
  return (
    <div className="segments">
      <ButtonGroup>
        {tabItems.map((tab, i) => {
          let hide = false
          if (isEnterprise) {
            if (tab.eventKey === TabPanel.CLAIMABLE_ITEMS) {
              hide = true
            } else if (tab.eventKey === TabPanel.CONSIGNMENT_SHOP) {
              Object.assign(tab, {title: 'Applications'})
            } else if (tab.eventKey === TabPanel.MANAGEMENT) {
              Object.assign(tab, {title: 'Submitted Items'})
            }
          } else if (tab.eventKey === TabPanel.PENDING_CODES && !isEnterprise) hide = true

          const isActive = tab.eventKey === tabPanel
          const color = isActive ? 'primary' : 'secondary'
          const style = clx(tab.title, isActive && 'active', hide ? 'hide' : 'show')
          return (
            <Button
              className={style}
              key={tab.title}
              disabled={disabled || tab.disabled || false}
              onClick={() => onSelect(tab.eventKey)}
              variant={isActive ? 'contained' : 'outlined'}
              {...{size, color}}
            >
              {tab.title}
            </Button>
          )
        })}
      </ButtonGroup>
    </div>
  )
}

TabPanels.propTypes = {
  size: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.any,
  tabPanel: PropTypes.oneOf(Object.values(TabPanel)),
  tabItems: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
}

TabPanels.defaultProps = {
  size: 'small',
  disabled: false,
  id: null,
  tabPanel: TabPanel.MANAGEMENT,
  tabItems: [
    {eventKey: TabPanel.MANAGEMENT, title: 'Submitted Items'},
    {eventKey: TabPanel.BATCHES, title: 'Batches'},
    {eventKey: TabPanel.CONSIGNMENT_SHOP, title: 'Applications'},
    {eventKey: TabPanel.CLAIMABLE_ITEMS, title: 'Claimable Items'},
    {eventKey: TabPanel.PENDING_CODES, title: 'Pending Codes'},
  ],
}

export default TabPanels
