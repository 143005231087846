import React from 'react'
import {Box, Typography, Stack} from '@mui/material'
import {styled} from '@mui/material/styles'
import {CardNumberElement, CardExpiryElement, CardCvcElement} from '@stripe/react-stripe-js'
import {includes, values} from 'lodash'

import {ModalType} from 'enums'
import {_replaceWildCards} from 'util/string_utils'
import {useGlobalStore} from 'provider/global_store/hook'
import {CopytLabel, CopytStyledButton, FieldGroup} from 'views_v2/lib/snippets'

const CardStyle = styled(Box)(({props}) => ({
  ...props,
  border: '1px solid var(--gray)',
  boxSizing: 'border-box',
  borderRadius: 6,
  height: 40,
  padding: 12,
}))

const MdlPaymentMethod = (props) => {
  const {billingDetails, shipmentType, setBillingDetails, onSaveChanges, setModalType} = props
  const {hasMop} = useGlobalStore()

  const onContinue = () => {
    switch (shipmentType) {
      case 'consignment':
        setModalType(ModalType.SELECT_APPROVED_ITEMS)
        break
      case 'order-fulfillment':
        setModalType(ModalType.ORDER_FULFILLMENT)
        break
      case 'purchase':
        setModalType(ModalType.PURCHASE)
        break
      default:
        setModalType(ModalType.PACKAGE_DETAILS)
        break
    }
  }

  return (
    <Box padding="0 !important">
      <Box className="modal-title">
        <Box className="modal__heading" mx="0px !important">
          <Typography variant="h1" textAlign="left">
            {hasMop ? 'Update' : 'Add'} Payment Method
          </Typography>
        </Box>
      </Box>

      <Box className="modal-body" padding={0} my={2}>
        <Stack direction="column" px={2} spacing={1} mb={4}>
          <CopytLabel style={{fontSize: '0.9rem', marginBottom: '10px'}}>
            Fill out your card information
          </CopytLabel>

          <Stack spacing={2}>
            <Stack>
              <CopytLabel>Card Number</CopytLabel>
              <CardStyle>
                <CardNumberElement options={{showIcon: true}} />
              </CardStyle>
            </Stack>

            <Stack direction="row" spacing={2}>
              <Stack flex={1}>
                <CopytLabel>Card Number</CopytLabel>
                <CardStyle>
                  <CardExpiryElement />
                </CardStyle>
              </Stack>

              <Stack flex={1}>
                <CopytLabel>CVC</CopytLabel>
                <CardStyle>
                  <CardCvcElement />
                </CardStyle>
              </Stack>
            </Stack>

            <FieldGroup
              sLabel="Name on Card"
              placeholder="Enter name"
              value={billingDetails?.name}
              onChange={(e) => {
                setBillingDetails({
                  ...billingDetails,
                  name: e.target.value,
                })
              }}
            />
            <FieldGroup
              sLabel="Billing Address"
              placeholder="Enter billing address"
              value={billingDetails?.address}
              onChange={(e) => {
                setBillingDetails({
                  ...billingDetails,
                  address: e.target.value,
                })
              }}
            />
            <Stack direction="row" spacing={2}>
              <FieldGroup
                sLabel="City"
                placeholder="Enter city"
                value={billingDetails?.city}
                onChange={(e) => {
                  setBillingDetails({
                    ...billingDetails,
                    city: e.target.value,
                  })
                }}
              />
              <FieldGroup
                sLabel="State"
                placeholder="Enter state"
                value={billingDetails?.state}
                onChange={(e) => {
                  setBillingDetails({
                    ...billingDetails,
                    state: e.target.value,
                  })
                }}
              />
              <FieldGroup
                sLabel="Zip Code"
                placeholder="Enter zip code"
                value={billingDetails?.zip}
                onChange={(e) => {
                  setBillingDetails({
                    ...billingDetails,
                    zip: e.target.value,
                  })
                }}
              />
            </Stack>
          </Stack>
        </Stack>

        <Stack
          direction="row"
          justifyContent="flex-end"
          pr={2}
          pt={2}
          borderTop="1px solid #eee"
          spacing={2}
        >
          <CopytStyledButton
            variant="outline"
            color="primary"
            onClick={() => setModalType(ModalType.PURCHASE_LABEL)}
          >
            Back
          </CopytStyledButton>
          <CopytStyledButton
            disabled={includes(values(billingDetails), null)}
            variant="contained"
            color="primary"
            onClick={async () => {
              await onSaveChanges({paymentMethod: true})
              setModalType(ModalType.PURCHASE_LABEL)
            }}
          >
            Continue
          </CopytStyledButton>
        </Stack>
      </Box>
    </Box>
  )
}

export default MdlPaymentMethod
