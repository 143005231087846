import {ar} from 'variables/empty'

//
// note: this function should be remove since we have `platform/accepted` to get accepted application
//
// depracated
//
export const getEnablePlatforms = (supportedPlatforms = ar, userSettings = ar) => {
  // note: i dont know how this process. but i only get this directly on ViewInventory.js
  // this will filter supportedPlatforms to have only enabled platforms for unlisted items tobe listed
  if (supportedPlatforms?.length > 0) {
    for (var i = 0; i < supportedPlatforms.length; i++) {
      let index = userSettings.findIndex(
        (settings) => settings.key === `platform-${supportedPlatforms[i].id}`,
      )
      if (index !== -1) {
        supportedPlatforms[i].settings = userSettings[index].value
      }
      if (!supportedPlatforms[i].settings) {
        supportedPlatforms[i].settings = {
          enabled: false,
        }
      }
    }

    // get sold listing platforms
    const platforms = supportedPlatforms
      .filter((p) => p?.settings?.enabled && !p.consignmentId)
      .map((p) => {
        return {
          value: p.name.toLowerCase(),
          label: p.name,
          id: p.id,
        }
      })

    return platforms
  }

  return ar
}

export function getCurrency(userCurrency) {
  let currency = userCurrency || localStorage.getItem('currency') || 'USD'
  if (currency === 'AED') currency = 'dhs'

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'narrowSymbol',
    minimumFractionDigits: 2,
  })
}
