import React, {useEffect, useState} from 'react'
import {Box, Grid, Typography} from '@mui/material'
import {Cancel as CancelIcon, Check as CheckIcon} from '@mui/icons-material'
import {CopytLabel} from 'views_v2/lib/snippets'
import {divide} from 'lodash'

const StockData = ({stockData, selectedSizes, selectedLocation}) => {
  const inStockSizes = selectedSizes?.filter((selectedSize) =>
    Object.keys(stockData).includes(selectedSize.size),
  )
  const outOfStockSizes = selectedSizes?.filter(
    (selectedSize) => !Object.keys(stockData).includes(selectedSize.size),
  )

  return (
    <div style={{paddingTop: '16px'}}>
      <Grid container justifyContent="space-between" marginBottom={1} fontWeight={500}>
        <Grid item xs>
          <Typography>Size</Typography>
        </Grid>
        <Grid item xs>
          <Typography>Quantity</Typography>
        </Grid>
        <Grid item xs>
          <Typography>Status</Typography>
        </Grid>
      </Grid>
      {Object.keys(stockData).map((size, i) => {
        const stock = stockData[size]
        const isInStock = stock.cheapestProduct !== null
        const quantity = stock.quantity
        const retailPrice = isInStock ? stock.cheapestProduct.desiredReturn : 0
        const backgroundColor = i % 2 === 0 ? '#f2f2f2' : 'white'

        return (
          <Box key={i} style={{backgroundColor}} padding={1} borderRadius={2}>
            <Grid container key={i} justifyContent="space-between" alignItems="center">
              <Grid item xs>
                <CopytLabel>{size}</CopytLabel>
              </Grid>
              <Grid item xs>
                <CopytLabel>{quantity}</CopytLabel>
              </Grid>
              <Grid item xs>
                <Typography
                  style={{whiteSpace: 'nowrap'}}
                  color={isInStock ? 'green !important' : 'red  !important'}
                >
                  {` @ $${retailPrice}`}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        )
      })}
      {outOfStockSizes?.map((selectedSize, i) => {
        const alternateIndex = inStockSizes.length + i
        let backgroundColor = alternateIndex % 2 !== 0 ? 'white' : '#f2f2f2'

        return (
          <Box key={alternateIndex} style={{backgroundColor}} padding={1} borderRadius={2}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs>
                <CopytLabel>{selectedSize.size}</CopytLabel>
              </Grid>
              <Grid item xs>
                <CopytLabel>{0}</CopytLabel>
              </Grid>
              <Grid item xs>
                <Typography style={{whiteSpace: 'nowrap'}} color="red  !important">
                  Out of stock
                </Typography>
              </Grid>
            </Grid>
          </Box>
        )
      }) ?? null}
    </div>
  )
}

export default StockData
