import React, {useState} from 'react'
import {Box, Typography} from '@mui/material'
import {FormGroup, Label, Input} from 'reactstrap'

const MdlConsigmentContract = (p) => {
  const {onCloseModal, formFields, setFormFields} = p || {}
  const [isAcceptedContract, setIsAcceptedContract] = useState(false)

  return (
    <Box>
      <Box className="modal-title">
        <Box className="modal__heading">
          <Typography variant="h1">Consignment Contract</Typography>
        </Box>
      </Box>
      <FormGroup check className="pull-left" style={{marginBottom: '20px'}}>
        <Label check>
          <Input
            type="checkbox"
            checked={isAcceptedContract}
            onChange={(e) => {
              setIsAcceptedContract(e.target.checked)
            }}
          />
          <span className="form-check-sign" />I agree to the terms of this{' '}
          <a href={formFields.contractPdfLink || ''} target="_blank" rel="noopener noreferrer">
            <span style={{cursor: 'pointer', fontWeight: '500'}}>Contract</span>
          </a>
        </Label>

        <button
          style={{
            marginTop: '20px',
          }}
          disabled={!isAcceptedContract}
          className="btn-primary ripple"
          onClick={() => {
            setFormFields({...formFields, isAcceptedContract: true})
            onCloseModal()
          }}
        >
          Continue
        </button>
      </FormGroup>
    </Box>
  )
}

export default MdlConsigmentContract
