import React, {useState} from 'react'
import {CircularProgress, Grid} from '@mui/material'
import {
  Upload as UploadIcon,
  QrCodeScanner as QrCodeScannerIcon,
  SwipeRight as SwipeRightIcon,
  FilterListOff as FilterListOffIcon,
  Autorenew as AutorenewIcon,
  DisplaySettings as DisplaySettingsIcon,
} from '@mui/icons-material'
import {isEmpty, some} from 'lodash'

import {ModalType} from 'enums'
import {useGlobalStore} from 'provider/global_store/hook'
import {ListingPlatformStatus} from 'constant/listing_platform_status'
import DateUtils from 'util/date_utils'
import {CopytSwitch} from 'views_v2/lib/snippets'
import {ProcessedItemBtn, CustomButton} from '../../snippets/headers'

const InventoryHeader = (p) => {
  const {
    delistSelected,
    listSelections,
    tabPanel,
    routeChange,
    markAsProcessed,
    setModalType,
    setShowCard,
    showCard,
    isDisabled,
    isConsigned,
    isLoading,
    selectedCriteria,
    onTableView,
  } = p
  const {isEnterprise} = useGlobalStore()

  const onProcessListing = some(listSelections, (selection) => {
    return (
      !isEmpty(selection.listings) &&
      some(
        selection.listings,
        (listing) =>
          !isEmpty(listing.listingPlatforms) &&
          (some(
            listing.listingPlatforms.filter((lp) => lp.platform.type !== 'payment'),
            {status: ListingPlatformStatus.PENDING},
          ) ||
            some(
              listing.listingPlatforms.filter((lp) => lp.platform.type !== 'payment'),
              {status: ListingPlatformStatus.PROCESSING},
            )),
      ) &&
      !DateUtils.calculateDateDifference(selection.listings).isToOld
    )
  })

  const disabled =
    p?.isEmpty ||
    listSelections?.size === 0 ||
    isEmpty(listSelections) ||
    (listSelections.some((s) => s?.onQueue) && onProcessListing) ||
    isDisabled

  const [isDelistItems, setIsDelistItems] = useState(false)

  if (tabPanel === 'processed' || tabPanel === 'sold') {
    return <ProcessedItemBtn {...{tabPanel, markAsProcessed, setModalType, isLoading}} />
  }

  return (
    <Grid container gap={1} justifyContent="space-between">
      <Grid item>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <CustomButton
              disabled={isDisabled}
              icon={isDisabled ? null : <UploadIcon />}
              title={isDisabled ? <CircularProgress sx={{color: 'white'}} size={24} /> : 'Import'}
              onClick={() => routeChange('/admin/inventory/import/-1')}
              color="secondary"
              style={{minWidth: '100px'}}
            />
          </Grid>
          <Grid item>
            <CopytSwitch
              labelStart="Table View"
              labelEnd="Card View"
              _textStart={{color: 'black'}}
              _textEnd={{color: 'black'}}
              onChange={(e) => {
                if (!showCard) setModalType(ModalType.CARD_OPTIONS)
                onTableView()
              }}
              checked={showCard}
            />
          </Grid>
          {showCard && (
            <Grid item>
              <CustomButton
                disabled={p?.isEmpty}
                icon={<DisplaySettingsIcon />}
                title="Grouped By"
                onClick={() => setModalType(ModalType.CARD_OPTIONS)}
                variant="outline"
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item>
        <Grid container gap={1}>
          {isEnterprise && tabPanel <= 1 && (
            <CustomButton
              disabled={disabled}
              icon={isDisabled ? null : <QrCodeScannerIcon />}
              title={
                isDisabled ? <CircularProgress sx={{color: 'white'}} size={24} /> : 'Generate Barcodes'
              }
              onClick={() => setModalType(ModalType.BARCODE_TEMPLATE)}
              color="error"
              style={{minWidth: '180px'}}
            />
          )}
          <CustomButton
            disabled={disabled}
            icon={<SwipeRightIcon />}
            title="Consign"
            onClick={() => setModalType(ModalType.CREATE_BATCH)}
            variant="outline"
            style={{
              display: (isEnterprise || tabPanel > 0) && 'none',
            }}
          />
          <CustomButton
            disabled={disabled || isDelistItems || (isConsigned && !isEnterprise) || onProcessListing}
            title="Delist Items"
            onClick={() => {
              setIsDelistItems(true)
              delistSelected()
              setTimeout(() => {
                setIsDelistItems(false)
              }, 5000)
            }}
            color="error"
            icon={<FilterListOffIcon />}
            style={{
              display: tabPanel !== 1 && 'none',
            }}
          />
          <CustomButton
            icon={<AutorenewIcon />}
            title="Process All"
            onClick={() => setModalType(ModalType.PROCESS_ALL)}
            variant="outline"
            style={{
              display: (!disabled || tabPanel !== 2) && 'none',
            }}
          />
          <CustomButton
            disabled={isDisabled}
            title="Add Item"
            onClick={() => routeChange('/admin/inventory/addItem')}
            style={{
              display: tabPanel === 1 && 'none',
              minWidth: '130px',
            }}
            data-testid="add-item"
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default InventoryHeader
