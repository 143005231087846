import React, {memo, useCallback} from 'react'
import {useHistory} from 'react-router-dom'
import {
  ContentCopy as ContentCopyIcon,
  Edit as EditIcon,
  QrCodeScanner as QrCodeScannerIcon,
  ShoppingCart as ShoppingCartIcon,
  ViewList as ViewListIcon,
} from '@mui/icons-material'
import Loader from 'react-loader-spinner'

import {Either} from 'components'
import {isProductListed, getListingId} from 'util/model/product'
import {useGlobalStore} from 'provider/global_store/hook'
import {ModalType} from 'enums'
import {CopytToolbar} from 'views_v2/lib/snippets'
import {CustomButton} from 'views_v2/modules/Inventory/snippets/headers'
import {isEmpty} from 'lodash'
import {Tooltip} from '@mui/material'

export const ViewItemTopBar = (props) => {
  const {
    formFields,
    id,
    setModalType,
    marketPlatformsAccepted,
    hasMop,
    platformSelection,
    cloverSelectedStoreLocation,
    addLoading,
    onFormSubmit,
    isListItemDisabled,
  } = props
  const isListed = isProductListed(formFields)
  const listingId = getListingId(formFields)
  const {isEnterprise} = useGlobalStore()
  const history = useHistory()

  const onHandleClick = useCallback(
    (path) => {
      history.push({pathname: path, data: formFields})
    },
    [history, formFields],
  )

  const disabledListItem =
    (!cloverSelectedStoreLocation?.toLowerCase()?.includes('clover') && isEmpty(platformSelection)) ||
    formFields?.onQueue ||
    isListItemDisabled ||
    !formFields?.sku

  return (
    <CopytToolbar style={{justifyContent: 'flex-end'}}>
      {isEnterprise && (
        <CustomButton
          disabled={!formFields}
          icon={<QrCodeScannerIcon />}
          title="Generate Barcode"
          onClick={() => setModalType(ModalType.BARCODE_TEMPLATE)}
          color="error"
          style={{
            marginRight: 10,
          }}
        />
      )}
      <CustomButton
        icon={<ContentCopyIcon />}
        title="Duplicate Item"
        disabled={formFields?.onQueue}
        onClick={() => setModalType(ModalType.DUPLICATE_ITEM)}
        variant="outline"
        style={{
          marginRight: 10,
        }}
      />
      <CustomButton
        icon={<EditIcon />}
        title="Edit Item"
        disabled={!formFields || formFields?.onQueue}
        onClick={() => onHandleClick(`/admin/inventory/addItem/${id}`)}
        variant="outline"
        style={{
          marginRight: 10,
        }}
      />
      {isListed && (
        <CustomButton
          icon={<ViewListIcon />}
          title="View Listing"
          onClick={() => onHandleClick(`/admin/listings/viewListing/${listingId}`)}
        />
      )}
      <Either
        condition={!marketPlatformsAccepted.length || !hasMop}
        True={
          <CustomButton
            tooltipTitle="Add a payment method in your Settings to list."
            disabled={disabledListItem}
            icon={<ViewListIcon />}
            title="List Item"
            onClick={() => onHandleClick('/admin/settings/general')}
            sx={{marginRight: 0.5, opacity: '0.5'}}
          />
        }
        Else={
          <Tooltip
            title={
              addLoading || disabledListItem ? 'Items must be assigned SKU/Style IDs to be listed.' : ''
            }
            PopperProps={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -10],
                  },
                },
              ],
            }}
          >
            <span>
              <CustomButton
                disabled={addLoading || disabledListItem}
                icon={<ViewListIcon />}
                title={
                  addLoading ? (
                    <Loader
                      type="TailSpin"
                      color="white"
                      height={20}
                      width={20}
                      className="import-button"
                    />
                  ) : (
                    'List Item'
                  )
                }
                onClick={onFormSubmit}
                sx={{marginRight: 0.5}}
              />
            </span>
          </Tooltip>
        }
      />
      <CustomButton
        disabled={formFields?.onQueue}
        icon={<ShoppingCartIcon />}
        title="Mark as sold"
        onClick={() => setModalType(ModalType.MARK_AS_SOLD)}
      />
    </CopytToolbar>
  )
}

export default memo(ViewItemTopBar)
