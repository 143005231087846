import {Table} from 'reactstrap'
import {
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'

import Container from '../container'

const Pendings = ({pendings}) => {
  const tasks = [
    {
      id: 1,
      task: 'Pending Sold Items to be Processed',
      quantity: pendings.data?.process.count || 0,
    },
    {
      id: 2,
      task: 'Pending Item Submissions',
      quantity: pendings.data?.submission.count || 0,
    },
    {
      id: 3,
      task: 'Pending Consignor Applications',
      quantity: pendings.data?.application.count || 0,
    },
    {
      id: 4,
      task: 'Pending Payouts',
      quantity: pendings.data?.payout.count || 0,
    },
  ]

  return (
    <Container width="100%" height="100%">
      <Stack spacing={2}>
        <Typography fontWeight={800} fontSize={20} color="black">
          Pending Tasks
        </Typography>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Task</TableCell>
                <TableCell>Quantity</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tasks.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.task}</TableCell>
                  <TableCell>{row.quantity}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </Container>
  )
}

export default Pendings
