import React, { memo } from 'react'
import {
  Box,
  Button,
  Chip,
  Link,
  Typography
} from '@mui/material'
import {
  Circle as CircleIcon,
  PriceCheck as PriceCheckIcon
} from '@mui/icons-material'
import { ContentType } from '../MdlPaywall'

const accessArr = [
  'Consignment Management',
  'Next-Level Inventory Management Engine',
  'Integration with Shopify',
  'Integration with Clover',
  'Integration with Square',
  'Integration with Checkbook',
  'And more!'
]

export const UpgradeBody = (p) => {
  return (
    <Box maxHeight="55vh" overflow="hidden auto">
      <Typography
        textAlign='center'
        fontWeight={600}
        fontSize="1.3rem"
        sx={{
          color: '#525f7f !important',
          textShadow: '2px 2px #fee8ff'
        }}
      >
        Ready to take your business to the next level?
      </Typography>
      <Typography textAlign='center' fontWeight={600}>Upgrade to an Enterprise Account!</Typography>
      <Typography fontWeight={600} fontSize="0.9rem" sx={{ mt: 2 }}>Get access to:</Typography>
      {accessArr.map((item, index) => (
        <Typography key={index} fontSize="0.85rem" fontWeight={600} sx={{ml: 2}}><CircleIcon sx={{ width: '0.4rem' }} /> {item}</Typography>
      ))}
      <Chip
        label="Check out the full list and pricing here"
        variant="contained"
        color="secondary"
        icon={<PriceCheckIcon />}
        onClick={() => window.open('https://copyt.io/enterprise/')}
        sx={{
          fontWeight: '600',
          fontSize: '0.8rem',
          textTransform: 'uppercase',
          margin: '0 15%'
        }}
      />
      <Button
        variant="contained"
        onClick={() => p?.setContentType(ContentType.STEPS)}
        sx={{
          backgroundColor: '#d5239e',
          fontWeight: 600,
          margin: '30px 32% 10px',
          '&:hover': {
            backgroundColor: '#fbc6ed',
            color: '#5d0953',
          },
        }}
      >
        Let's Get Started
      </Button>
      <Box
        sx={{
          height: 'auto',
          background: 'white',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-around',
          opacity: 0.9,
          borderRadius: '100px',
          padding: '10px',
        }}>
        <Typography
          textAlign='center'
          fontSize='0.85rem'
          fontStyle='italic'
          fontWeight={600}
        >
          Email&nbsp;
          <Link
            component='span'
            fontSize='0.85rem'
            onClick={() => window.location.href = 'mailto:support@copyt.io'}
            sx={{
              cursor: 'pointer'
            }}
          >
            support@copyt.io
          </Link>
          &nbsp;to cancel subscription at any time!
        </Typography>
      </Box>
    </Box>
  )
}

export default memo(UpgradeBody)
