import React from "react";
import { Toast, ToastBody, ToastHeader } from "reactstrap";
import { fn } from "variables/empty";
import { ToastMessageVariant, ToastMessageVariantText } from "./constant";

function getText(variant) {
  return ToastMessageVariantText[variant] || ToastMessageVariantText[ToastMessageVariant.SUCCESS]
}

export const ToastMessageUi = function ({
  title,
  body,
  onClose = fn,
  variant = ToastMessageVariant.SUCCESS
}) {
  const config = getText(variant)

  return (
    <div className={`${config.className} my-2 rounded fixed-toast`} >
      <Toast>
        <ToastHeader toggle={onClose}>{title || config.title}</ToastHeader>
        <ToastBody>{body || config.body}</ToastBody>
      </Toast>
    </div>
  )
}

export const ToastMessage = function ({ toast, isShow, onClose }) {

  if (!isShow) {
    return null
  }
  return <ToastMessageUi variant={toast.variant} title={toast.title} body={toast.body} onClose={onClose} />
}



