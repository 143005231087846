import React, {useMemo, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {get, isEmpty, sumBy, uniq} from 'lodash'
import {
  Box,
  Button,
  FormControlLabel,
  RadioGroup,
  Radio,
  Stack,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
} from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

import {useGlobalStore} from 'provider/global_store/hook'
import DateUtils from 'util/date_utils'
import {capitalizeFirstLetter, toMoney} from 'util/string_utils'
import {getMinimumFee} from 'util/model'
import {getCurrency} from 'util/model/setting'
import {usePlatformAccepted} from 'service/hook/use_platform'
import {PaymentType} from 'enums'
import {CopytTooltip, CopytStyledButton, CopytLabel, FieldGroup} from 'views_v2/lib/snippets'
import mw from '../middleware'
import {getConsignmentPayout} from 'util/model/consignment'

const payoutArr = [
  {
    id: PaymentType.VENMO,
    src: 'https://images.macrumors.com/t/gmW1swDlxjnRG_ZCugFTVqLD4vo=/1600x/article-new/2017/10/paypal-venmo-logos.jpg',
    width: '140px',
  },
  {id: PaymentType.CHECKBOOK, src: 'https://checkbook.io/img/Checkbook_logo_blue.svg', width: '170px'},
]

const FinishPayout = (p) => {
  const {minFee, isEnterprise, setPaymentType, paymentType, otherPaymentType, setOtherPaymentType} = p
  const [paymentMethod, setPaymentMethod] = useState('automatically')
  const total = sumBy(p?.data, (sold) => getConsignmentPayout(sold))
  const currency = getCurrency()
  const paymentTypeString = capitalizeFirstLetter(paymentType)
  const {data} = usePlatformAccepted()
  const history = useHistory()
  const routeChange = (route, data) => {
    history.push({pathname: route, data: data})
  }

  const isPaypalVenmoDisabled = useMemo(() => {
    const paypalVenmo = data?.find(
      (s) => s?.name?.toLowerCase()?.includes('paypal') || s?.name?.toLowerCase()?.includes('venmo'),
    )

    if (!isEmpty(paypalVenmo?.credentials)) {
      return !paypalVenmo?.credentials[0]?.value?.hasOwnProperty('scope')
    }
    return true
  }, [data])

  return (
    <TableContainer sx={{maxHeight: '70vh', marginTop: '10px !important'}}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {['Consigner Name', 'Payout', 'Date'].map((column, i) => (
              <TableCell
                key={column}
                width={i ? '20%' : '60%'}
                sx={{bgcolor: 'var(--lighter)', fontWeight: 600, lineHeight: '0.9rem'}}
              >
                {column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {p?.data.map((row, i) => {
            const payout = getConsignmentPayout(row)
            const {createdAt, product, totalSoldPrice} = row?.listing || {}
            const {consignor} = product?.consign || {}
            const consignorEmail = consignor?.email || '--'
            return (
              <TableRow hover tabIndex={-1} key={i}>
                <TableCell width="60%">{consignorEmail}</TableCell>
                <TableCell width="20%">{toMoney(payout) || '--'}</TableCell>
                <TableCell width="20%">{DateUtils.toShortDateFormat(createdAt) || '--'}</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
        <TableFooter sx={{position: 'sticky', zIndex: 1, bottom: 0, bgcolor: 'white'}}>
          <TableRow>
            <TableCell
              sx={{
                fontWeight: 600,
                textAlign: 'right',
                fontSize: '0.875rem',
                color: 'black',
                borderTop: '2px solid var(--lighter)',
                borderBottom: 0,
              }}
            >
              Total Payout:
            </TableCell>
            <TableCell
              colSpan={2}
              sx={{
                fontWeight: 600,
                fontSize: '0.875rem',
                color: 'black',
                borderTop: '2px solid var(--lighter)',
                borderBottom: 0,
              }}
            >
              {currency.format(total)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={3} sx={{borderBottom: 0, padding: 0}}>
              <Stack pl={2}>
                <Stack direction="row" alignItems="center" spacing={3}>
                  <Typography color="var(--default) !important" fontWeight={600}>
                    Payout Method
                  </Typography>
                  <RadioGroup
                    row
                    size="small"
                    value={paymentMethod}
                    onChange={(e) => {
                      setPaymentMethod(e.target.value)
                      if (e.target.value === 'automatically') setPaymentType(PaymentType.CHECKBOOK)
                      else setPaymentType('Check')
                    }}
                  >
                    <FormControlLabel
                      value="automatically"
                      control={<Radio size="small" />}
                      label="Automatic"
                      componentsProps={{
                        typography: {
                          sx: {
                            marginLeft: '4px',
                          },
                        },
                      }}
                    />
                    <FormControlLabel
                      value="manually"
                      control={<Radio size="small" />}
                      label="Manual"
                      sx={{ml: 3}}
                      componentsProps={{
                        typography: {
                          sx: {
                            marginLeft: '4px',
                          },
                        },
                      }}
                    />
                  </RadioGroup>
                </Stack>
              </Stack>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={3} sx={{borderBottom: 0, padding: 0}}>
              <CopytLabel pl={2}>Sending {paymentMethod} through:</CopytLabel>
              <Stack
                direction="row"
                justifyContent="space-evenly"
                mt={1}
                mb={paymentType?.toLowerCase() === 'other' ? 0 : 2}
              >
                {paymentMethod === 'manually' ? (
                  <RadioGroup
                    row
                    size="small"
                    value={paymentTypeString}
                    onChange={(e) => {
                      setPaymentType(e.target.value)
                    }}
                  >
                    {['Check', 'Cash', 'Other'].map((s) => {
                      return (
                        <FormControlLabel
                          ml={0}
                          sx={{ml: 0}}
                          key={s}
                          value={s}
                          control={<Radio size="small" color="secondary" />}
                          label={s}
                          componentsProps={{
                            typography: {
                              sx: {
                                marginLeft: '4px',
                              },
                            },
                          }}
                        />
                      )
                    })}
                  </RadioGroup>
                ) : (
                  payoutArr.map((s) => {
                    const isPaypal = isPaypalVenmoDisabled && s?.id === PaymentType.VENMO
                    return (
                      <CopytTooltip
                        title={
                          isPaypal
                            ? 'To send payouts through this platform, enable it from your Platforms page'
                            : ''
                        }
                      >
                        <Button
                          key={s.id}
                          onClick={() =>
                            isPaypal
                              ? routeChange('/admin/platforms/0b354dc5-ce78-4a04-882f-20bc505c5396')
                              : setPaymentType(s.id)
                          }
                          disabled={paymentMethod === 'manual'}
                          style={{
                            opacity: isPaypal ? 0.3 : 1,
                          }}
                        >
                          <Box
                            component="img"
                            sx={{
                              width: s.width,
                              height: 65,
                              backgroundSize: 'contain',
                              backgroundRepeat: 'no-repeat',
                              backgroundPosition: 'center center',
                              border: '2px solid',
                              borderColor: s.id === paymentType ? 'var(--primary)' : 'var(--lighter)',
                              p: 1,
                              filter: s.id === paymentType ? 'var(--primary)' : 'grayscale(1)',
                            }}
                            src={s.src}
                          />
                        </Button>
                      </CopytTooltip>
                    )
                  })
                )}
              </Stack>
              {paymentType?.toLowerCase() === 'other' && (
                <Box width="50%" ml="25%" mb={2}>
                  <FieldGroup
                    value={otherPaymentType}
                    style={{width: '50% !important'}}
                    placeholder="Enter Other Payment Type"
                    onChange={(e) => setOtherPaymentType(e.target.value)}
                  />
                </Box>
              )}
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  )
}

const ItemRow = (p) => {
  const {data, minFee, isEnterprise} = p
  return (
    <TableContainer sx={{maxHeight: 440, marginTop: '10px !important'}}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {['Item', 'Payout'].map((column, i) => (
              <TableCell
                key={column}
                width={i ? '30%' : '70%'}
                sx={{bgcolor: 'var(--lighter)', fontWeight: 600}}
              >
                {column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, i) => {
            const payout = getConsignmentPayout(row)
            const {listing} = row || {}
            return (
              <TableRow hover tabIndex={-1} key={i}>
                <TableCell width="70%">{listing?.product?.title}</TableCell>
                <TableCell width="30%">{toMoney(payout)}</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const MdlCreateBatch = (p) => {
  const [batch, setBatch] = useState(0)
  const {data, onSaveChanges, setPaymentType, paymentType, otherPaymentType, setOtherPaymentType} = p
  const {owner} = (data?.length > 0 && data[0]?.listing) || {}
  const {email} = owner || {}
  const {isEnterprise, user} = useGlobalStore()
  const minFee = getMinimumFee(user)

  let consignor = isEmpty(data) ? 'Consignor Name' : mw.getConsigner(data[0])
  const hasInfoIcon = !isEmpty(data) && data?.length > 1

  if (hasInfoIcon) {
    consignor = 'Multiple Consignors'
  }

  const emailString = hasInfoIcon
    ? uniq(data.map((item) => get(item, 'listing.product.consign.consignor.email'))).join('\n')
    : null

  return (
    <Box padding="0 !important">
      <Box className="modal-title">
        <Box className="modal__heading" mx="0px !important">
          <Typography variant="h1" textAlign="left">
            Batch Payout
          </Typography>
        </Box>
      </Box>
      <Box className="modal-body" padding={0} my={2}>
        <Stack spacing={2.5}>
          <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
            <Typography color="var(--default) !important" fontWeight={600}>
              {consignor}
            </Typography>
            {hasInfoIcon && (
              <CopytTooltip title={emailString}>
                <InfoOutlinedIcon sx={{fill: 'var(--primary) !important'}} />
              </CopytTooltip>
            )}
          </Stack>

          {batch ? (
            <FinishPayout
              {...{
                data,
                minFee,
                isEnterprise,
                setPaymentType,
                paymentType,
                otherPaymentType,
                setOtherPaymentType,
              }}
            />
          ) : (
            <ItemRow {...{data, minFee, isEnterprise}} />
          )}
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-end"
          pr={1}
          pt={1}
          mt={data?.length > 7 ? 0 : 5}
          borderTop="1px solid var(--lighter)"
          spacing={2}
        >
          <CopytStyledButton
            variant="contained"
            color="primary"
            disabled={
              (paymentType?.toLowerCase() === 'other' && isEmpty(otherPaymentType)) ||
              (batch && !paymentType)
            }
            onClick={() => {
              batch ? onSaveChanges() : setBatch(1)
            }}
          >
            {batch ? 'Finish' : 'Next'}
          </CopytStyledButton>
        </Stack>
      </Box>
    </Box>
  )
}

export default MdlCreateBatch
