import React, {useState, useEffect, useRef} from 'react'
import clx from 'classnames'
import {useHistory} from 'react-router-dom'
import {isEmpty} from 'lodash'

import {useGlobalStore} from 'provider/global_store/hook'
import {ToastType} from 'enums'
import {useConsignments, useExport} from 'service/hook'
import {LoadingBar} from 'views_v2/lib/snippets'
import {SearchContext} from 'views_v2/lib/classes'
import {urlWithoutParam, getParams} from 'util/window_utils'
import {ConsignmentOnEnterprise, ConsignmentOnIndividual} from './component'
import InventoryToast from '../Inventory/components/InventoryToast'
import './scss/parent.scss'

const Consignments = () => {
  const [filter, setFilter] = useState({
    skip: 0,
    take: 100,
    page: 0,
  })
  const timerRef = useRef(null)

  const [mount, setMount] = useState(false)
  const [currentPage, setCurrentPage] = useState(filter?.page)
  const {isEnterprise} = useGlobalStore()
  const [searchText, setSearchText] = useState('')
  const history = useHistory()
  const [toastType, setToastType] = useState(ToastType.UNDEFINED)

  const {
    consignments,
    isLoading,
    isFetching,
    isApprovedConsignmentSuccess,
    isRejectConsignmentSuccess,
    approveConsignment,
    rejectConsignment,
    onWithdrawItems,
  } = useConsignments(filter, searchText)

  const {isErrorExport, isSuccessExport, invalidateExportQueries, refetchExport} = useExport({
    filter: {...filter, take: consignments?.total || 10000, limit: consignments?.total || 10000},
    exportType: 'consign',
  })

  useEffect(() => {
    return () => {
      if (timerRef?.current) {
        clearTimeout(timerRef.current)
      }
    }
  }, [])

  useEffect(() => {
    if (!mount) {
      const params = getParams(history)

      if (params?.hasOwnProperty('page')) {
        const pageNumber = Number(params?.page)
        if (pageNumber !== filter?.page && pageNumber > 0) {
          setFilter((f) => ({...f, page: pageNumber, skip: pageNumber * f.take}))
        }
      }
      setMount(true)
    }
  }, [mount])

  useEffect(() => {
    setCurrentPage(currentPage)
    onPageChange(currentPage)
  }, [currentPage])

  useEffect(() => {
    if (currentPage !== filter?.page) {
      setCurrentPage(filter?.page)
      onPageChange(filter?.page)
    }
  }, [filter?.page])

  useEffect(() => {
    if (isSuccessExport) {
      setToastType(ToastType.EXPORTED)
    } else if (isErrorExport) {
      setToastType(ToastType.ERROR)
    }
    invalidateExportQueries()
  }, [isErrorExport, isSuccessExport])

  const onPageChange = (newPage) => {
    if (timerRef?.current) {
      clearTimeout(timerRef?.current)
    }

    timerRef.current = setTimeout(() => {
      setFilter((f) => ({...f, page: newPage, skip: newPage * f.take}))

      const newUrl = urlWithoutParam(newPage)
      window.history.pushState({path: newUrl}, '', newUrl)
    }, 1000)
  }

  if (isLoading) return <LoadingBar />

  const routeChange = (route) => {
    history.push({pathname: route})
  }

  const onExport = () => refetchExport()
  return (
    <div className={clx('p-detail-flex', 'consignment', !isEmpty(consignments?.rows) && 'empty')}>
      <SearchContext.Provider
        value={{
          searchText,
          setSearchText,
        }}
      >
        {isEnterprise ? (
          <ConsignmentOnEnterprise
            {...{
              routeChange,
              setFilter,
              filter,
              consignments,
              isFetching,
              isLoading,
              isApprovedConsignmentSuccess,
              isRejectConsignmentSuccess,
              approveConsignment,
              rejectConsignment,
              onWithdrawItems,
              currentPage,
              setCurrentPage,
              onExport,
            }}
            visible={isEnterprise}
          />
        ) : (
          <ConsignmentOnIndividual
            {...{
              filter,
              setFilter,
              consignments,
              isFetching,
              isLoading,
              currentPage,
              setCurrentPage,
              onExport,
            }}
            visible={!isEnterprise}
          />
        )}
      </SearchContext.Provider>
      {toastType > ToastType.UNDEFINED && <InventoryToast {...{toastType, setToastType}} />}
    </div>
  )
}

export default Consignments
