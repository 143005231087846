import React from 'react'
import {MenuItem} from '@mui/material'
import {isEmpty} from 'lodash'

import {_getStatus} from 'util/string_utils'
import {StyledMenu} from './ConsignmentFilter'

const StoreMenuFilter = ({onClose, handleFilterClick, open, anchorEl, filterItems}) => {
  return (
    <StyledMenu
      id="customized-menu"
      MenuListProps={{
        'aria-labelledby': 'customized-button',
        className: 'options-list',
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
    >
      {!isEmpty(filterItems) &&
        filterItems?.map((item, idx) => (
          <MenuItem
            key={idx}
            onClick={(e) => {
              e.stopPropagation()
              handleFilterClick(item)
            }}
          >
            {item?.label}
          </MenuItem>
        ))}
    </StyledMenu>
  )
}

export default StoreMenuFilter
