import React from 'react'

import {Card, CardHeader, CardBody, CardTitle, Label, FormGroup, Input} from 'reactstrap'
import {fn} from 'variables/empty'

export function ListingShipping({product, handleUpdate = fn, isReadOnly = false}) {
  return (
    <Card style={{height: 'calc(100% - 30px)'}}>
      <CardHeader>
        <CardTitle tag="div">Shipping</CardTitle>
      </CardHeader>
      <CardBody>
        <FormGroup>
          <Label className={isReadOnly ? 'read-only-label' : ''}>Shipping method</Label>
          <Input
            type="text"
            name="shippingMethod"
            onChange={handleUpdate}
            value={product?.shippingMethod || ''}
            readOnly={isReadOnly}
          />
        </FormGroup>

        <FormGroup>
          <Label className={isReadOnly ? 'read-only-label' : ''}>Box dimensions</Label>
          <Input
            type="text"
            required
            name="boxDimensions"
            onChange={handleUpdate}
            value={product?.boxDimensions || ''}
            readOnly={isReadOnly}
          />
        </FormGroup>

        <FormGroup>
          <Label className={isReadOnly ? 'read-only-label' : ''}>Box weight</Label>
          <Input
            type="text"
            name="boxWeight"
            onChange={handleUpdate}
            value={product?.boxWeight || ''}
            readOnly={isReadOnly}
          />
        </FormGroup>
      </CardBody>
    </Card>
  )
}
