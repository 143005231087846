import React, {useState, useRef} from 'react'
import {Box, Divider, Stack} from '@mui/material'
import TextareaAutosize from '@mui/base/TextareaAutosize'

import CopytCard from 'components/CopytCard'
import {useDescriptionTemplate} from 'service/hook'
import {CopytButton, CopytLabel, CopytTooltip} from 'views_v2/lib/snippets'
import {CopytBtn} from './ProductTemplate'

const DescriptionTemplate = () => {
  const {descriptionTemplate, addDescriptionTemplate} = useDescriptionTemplate()
  const textAreaRef = useRef(null)
  const [formFields, setFormFields] = useState({})

  const submit = (e) => {
    addDescriptionTemplate(formFields)
  }

  const insertText = (text) => {
    let cursorPosition = textAreaRef.current.selectionStart
    let textBeforeCursorPosition = textAreaRef.current.value.substring(0, cursorPosition)
    let textAfterCursorPosition = textAreaRef.current.value.substring(
      cursorPosition,
      textAreaRef.current.value.length,
    )
    textAreaRef.current.value = textBeforeCursorPosition + text + textAfterCursorPosition
    setFormFields({...formFields, description: textAreaRef.current.value})
  }

  return (
    <CopytCard className="copyt-card">
      <CopytCard.Header>
        <CopytCard.Text tag="div">Description Template</CopytCard.Text>
      </CopytCard.Header>
      <CopytCard.Body>
        <Stack spacing={2}>
          <Stack spacing={1}>
            <CopytLabel style={{lineHeight: '14px', fontWeight: 400}}>
              Click to insert variables like SKU, Size, Box Condition, Item Condition, and Name into your
              description. They will automatically update with the entered details when creating the
              item.
            </CopytLabel>
            <Stack direction="row" spacing={2}>
              <CopytButton
                onClick={() => insertText('[SKU]')}
                variant="outline"
                color="primary"
                style={{lineHeight: '14px'}}
              >
                SKU
              </CopytButton>
              <CopytButton onClick={() => insertText('[SIZE]')} variant="outline" color="primary">
                SIZE
              </CopytButton>
              <CopytButton
                onClick={() => insertText('[BOX CONDITION]')}
                variant="outline"
                color="primary"
                style={{lineHeight: '14px'}}
              >
                BOX CONDITION
              </CopytButton>
              <CopytButton
                onClick={() => insertText('[ITEM CONDITION]')}
                variant="outline"
                color="primary"
                style={{lineHeight: '14px'}}
              >
                ITEM CONDITION
              </CopytButton>
              <CopytButton
                onClick={() => insertText('[NAME]')}
                variant="outline"
                color="primary"
                style={{lineHeight: '14px'}}
              >
                NAME
              </CopytButton>
            </Stack>
          </Stack>

          <Divider />
          <Stack spacing={1}>
            <CopytLabel>Description</CopytLabel>
            <CopytTooltip
              title='NOTE: The "Size" will not be updated when duplicating an item with different sizes- the description will be copied exactly as the original. Also, when listing to Shopify and Square, listing additional quantities of a pre-existing product with a different description will result in a new product listing being created rather than adding a new variant.'
              placement="bottom"
            >
              <TextareaAutosize
                ref={textAreaRef}
                aria-label="description template textarea"
                placeholder="Enter description template"
                minRows={10}
                style={{width: '100%', height: '100%'}}
                name="description"
                id="description"
                onChange={(e) => {
                  setFormFields({
                    ...formFields,
                    description: e.target.value,
                  })
                }}
                defaultValue={descriptionTemplate[0]?.description || ''}
                value={formFields?.description}
              />
            </CopytTooltip>
          </Stack>
          <Box gap={1} display="flex" justifyContent="flex-end">
            <CopytBtn
              onClick={(e) => {
                setFormFields({
                  ...formFields,
                  description: '',
                })
              }}
              variant="contained"
              color="error"
            >
              Clear
            </CopytBtn>
            <CopytBtn
              disabled={
                formFields.description === undefined ||
                JSON.stringify(formFields.description) ===
                  JSON.stringify(descriptionTemplate[0]?.description)
              }
              onClick={() => {
                submit()
              }}
              variant="contained"
              color="primary"
            >
              Save
            </CopytBtn>
          </Box>
        </Stack>
      </CopytCard.Body>
    </CopytCard>
  )
}

export default DescriptionTemplate
