import {useEffect, useState} from 'react'
import * as Sentry from '@sentry/react'
import {useItem} from 'service/hook/useItem'
import {useGetSneak} from 'service/hook/useSneak'
import {codeToIndex} from 'util/image'

export const useSneak = (item) => {
  const [prices, setPrice] = useState({})
  const getSneak = useGetSneak()
  const sku = item && item.sku

  useEffect(() => {
    if (sku) {
      getSneak(
        {sku},
        (data) => {
          setPrice(data.resellPrices.stockX)
        },
        (e) => {},
      )
    }
  }, [getSneak, sku, setPrice])

  return prices
}

export const useLoadData = ({ id, setValue, data }) => {
  const { getItem } = useItem();

  useEffect(() => {
    const fetchData = async () => {
      if (data) {
        setValue(data)
        return
      }

      try {
        const item = await getItem(id);
        if (!item) {
          throw new Error('Can\'t find record')
        }

        const images = item.images ? codeToIndex(item.images) : []
        setValue((prevValue) => ({ ...prevValue, ...item, images, quantity: 1 }))
      } catch (error) {
        Sentry.captureException(error)
      }
    };

    fetchData();
  }, [id, getItem, setValue, data])
};
