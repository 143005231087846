import React from 'react'
import {Box, Typography, Stack} from '@mui/material'
import {Error as ErrorIcon} from '@mui/icons-material'

import {CopytButton} from 'views_v2/lib/snippets'

const MdlCancelItem = (p) => {
  return (
    <Box>
      <Box className="modal-title">
        <Box className="modal__heading" mx="0px !important">
          <Typography variant="h1" textAlign="left">
            Confirm Action
          </Typography>
        </Box>
      </Box>
      <Box className="modal-body" padding={0} mb={2}>
        <Stack p={2} gap={1.5}>
          <Stack direction="row" alignItems="center" gap={1.5}>
            <ErrorIcon sx={{height: '50px', fontSize: '2rem'}} />
            <Typography sx={{fontWeight: '600'}} lineHeight={1.2}>
              {`Are you sure you want to cancel this${p?.isProcessed ? ' processed' : ''} sale?`}
            </Typography>
          </Stack>
          <Typography
            fontStyle="italic"
            fontSize="0.85rem"
            color="var(--success) !important"
            lineHeight={1.2}
          >
            If you do, the sale will be canceled in Copyt ONLY and the item will be moved back to your
            Unlisted tab. This action will not cancel the sale on the platform on which it was sold.
          </Typography>
        </Stack>

        <Stack
          direction="row"
          justifyContent="flex-end"
          pr={2}
          pt={1.5}
          mt={3}
          borderTop="1px solid var(--lighter)"
          spacing={2}
        >
          <CopytButton variant="outline" color="primary" onClick={() => p.onCloseModal()}>
            Cancel
          </CopytButton>
          <CopytButton variant="contained" color="primary" onClick={() => p?.cancelSale()}>
            Yes, Proceed
          </CopytButton>
        </Stack>
      </Box>
    </Box>
  )
}

export default MdlCancelItem
