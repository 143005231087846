import React, {useState} from 'react'
import {Box, Grid, InputLabel, TextField, Typography} from '@mui/material'
import {useConsignmentShops} from 'service/hook'
import {PlatformsModalType} from 'views_v2/modules/Consignments/component/PlatformsModal'

const MdlEcgReferralCode = (p) => {
  const {onCloseModal} = p || {}
  const [referralCode, setReferralCode] = useState('')
  const [error, setError] = useState('')

  const handlePostSubmit = (returnedData) => {
    if (returnedData?.data === 'MEMBERSHIP: Invalid Membership Referral Code | ECG') {
      setError('MEMBERSHIP: Invalid Membership Referral Code | ECG')
    }
    if (returnedData?.data?.type === 'ECG') {
      onCloseModal()
      p.setModalType(PlatformsModalType.APPLY)
      setError('')
    }
  }

  const {submitApplication, data, isLoading} = useConsignmentShops({
    options: {},
    postSubmitCallback: handlePostSubmit,
  })

  const handleSubmit = async (e) => {
    e.preventDefault()

    const requestBody = {
      type: 'ECG',
      code: referralCode,
    }

    try {
      await submitApplication(requestBody)
    } catch (error) {
      console.error('Submission failed:', error)
    }
  }

  const handleInputChange = (e) => {
    setReferralCode(e.target.value)
  }

  return (
    <Box>
      <Box className="modal-title">
        <Box className="modal__heading">
          <Typography variant="h1">Enter Referral Code</Typography>
        </Box>
      </Box>
      <Box className="text-bold" sx={{mt: 2}}>
        Hold on, this is an ECG-exclusive store.
      </Box>
      <Box className="" sx={{mt: 4}}>
        If you are a member of Elevate Consulting Group, enter your personalized referral code to unlock
        access to all ECG-exclusive stores.
      </Box>
      <Box sx={{mt: 4}}>
        <Grid container gap={2} mt={4} justifyContent="space-between">
          <InputLabel htmlFor="referral-code">Referral Code</InputLabel>
          <InputLabel htmlFor="referral-code">Mandatory</InputLabel>
        </Grid>
        <TextField
          id="referral-code"
          variant="outlined"
          size="small"
          sx={{
            marginBottom: '16px',
            width: '100%',
            '& .MuiInputBase-input': {
              padding: '5px',
            },
          }}
          placeholder=" Code"
          value={referralCode}
          onChange={handleInputChange}
        />
        {/* Error Display */}
        {error && <p style={{color: 'red', fontSize: '10px'}}>{error}</p>}
      </Box>
      <Box className="modal-body" style={{padding: '24px 24px 0'}}>
        <Grid container gap={2} mt={4} justifyContent="center">
          <Grid item xs={12} sm={5} display="flex" flexDirection="column" alignItems="center">
            <button
              type="button"
              className="btn-primary ripple"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              Submit Application
            </button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default MdlEcgReferralCode
