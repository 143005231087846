
import {useQueryClient, useQuery} from 'react-query'
import axios from 'axios'
import {useAuth0} from '@auth0/auth0-react'
import * as Sentry from '@sentry/react'
import {createRequestOptions} from 'util/network'
import {paths} from 'constant/api'

const getItemToRetry = async (getAccessTokenSilently, id, skip, take) => {
    try {

      if (id === "-1") {
        return { data: [] }
      }
      const token = await getAccessTokenSilently()
      const response = await axios.get(`${paths.product}/import/retry/${id}?skip=${skip}&take=${take}`, createRequestOptions(token))
      return response.data
    } catch (error) {
      Sentry.captureException(error)
      console.log('Error retrieving invoices', error)
      throw error
    }
  }

export const useImportRetry = (id, skip, take) => {
    const {getAccessTokenSilently} = useAuth0()
    const queryClient = useQueryClient()

    const {
        status,
        isLoading,
        isError,
        isFetched,
        isFetching,
        refetch,
        data: items = { data: [], total: 0 },
        error,
    } = useQuery( ['importRetry', id, skip, take], () => getItemToRetry(getAccessTokenSilently, id, skip, take))


    const reinvalidate = () => {
      queryClient.invalidateQueries('importRetry')
    }

    return {
        status,
        isLoading,
        isFetching,
        refretchImportRetry: refetch,
        isError,
        isFeesFetched: isFetched,
        error,
        items: items || { data: [], total: 0 },
        reinvalidate
    }
}