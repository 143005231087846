import {Box, Stack, Typography} from '@mui/material'
import SelectDropdown from 'react-select'
import {SizeDropdown} from 'views_v2/lib/snippets'
import {Input as InputField} from 'reactstrap'
import {memo} from 'react'
import {CopytButton} from 'views_v2/lib/snippets'
import {useGlobalStore} from 'provider/global_store/hook'
import CreatableSelect from 'react-select/creatable'

const Scanned = memo(({product, children}) => {
  const {isEnterprise} = useGlobalStore()

  return (
    <Stack
      sx={{
        display: 'grid',
        gridTemplateColumns: isEnterprise
          ? '320px auto 1fr 1fr 1fr 180px 180px 350px 100px 80px'
          : '320px auto 1fr 1fr 180px 180px 80px 80px',
        gap: '1rem',
        cursor: 'pointer',
        alignItems: 'center',
        ':hover': {
          background: 'linear-gradient(to right, var(--primary-light), transparent)',
          '& .field-sku, & .field-sku *': {
            color: 'var(--primary) !important',
          },
        },
      }}
    >
      <Box
        sx={{
          position: 'sticky',
          left: 0,
          backgroundColor: 'white',
          zIndex: 1,
          padding: '1rem 1rem',
        }}
      >
        <Box>
          <Stack direction="row" gap={4} alignItems="center">
            <Stack
              width={64}
              overflow="hidden"
              borderRadius={2}
              bgcolor="white"
              position="relative"
              flexShrink={0}
            >
              {product.assets?.length ? <img src={product.assets[0].asset.url} /> : null}
            </Stack>
          </Stack>
          <Typography className="field-sku" fontSize={16} fontWeight={600} color="black">
            {product.sku}
          </Typography>
          <Typography className="field-sku" fontSize={14} color="black">
            {product.title}
          </Typography>
        </Box>
      </Box>
      {children}
    </Stack>
  )
})

const Select = memo(({placeholder, options, isLoading, value, onChange, isFull}) => {
  return (
    <Box minWidth={!isFull ? '200px' : '80%'} bgcolor="white" borderRadius={2}>
      <CreatableSelect
        className="react-select"
        id="location-autocomplete"
        classNamePrefix="react-select"
        placeholder={placeholder}
        closeMenuOnSelect
        isClearable
        isLoading={isLoading}
        onCreateOption={(value) => {
          onChange(value)
        }}
        onChange={(e) => {
          onChange(e?.label)
        }}
        options={options}
        value={value}
      />
    </Box>
  )
})

Select.Size = memo(({value, onChange, category}) => {
  return (
    <Box width="150px" bgcolor="white" borderRadius={2}>
      <SizeDropdown category={category} value={value} onChange={onChange} />
    </Box>
  )
})

export const Input = memo(({type, placeholder, value, onChange}) => {
  let state = value || ''

  const handleChange = (event) => {
    const newValue = event.target.value

    if (type === 'number') {
      if (!/^\d*\.?\d*$/.test(newValue)) return
      onChange(newValue)
    }
  }

  return (
    <Box>
      <InputField
        type="text"
        placeholder={placeholder}
        value={state}
        onChange={handleChange}
        style={{
          color: 'var(--primary)',
        }}
      />
    </Box>
  )
})

const Button = memo(({children, color = 'base', onClick}) => {
  let styles = {
    danger: 'error',
    base: 'secondary',
  }

  return (
    <CopytButton onClick={onClick} variant="text" color={styles[color]}>
      {children}
    </CopytButton>
  )
})

Scanned.Select = Select
Scanned.Input = Input
Scanned.Button = Button

export default Scanned
