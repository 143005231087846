import React from 'react'
import { useDrag, useDrop } from 'react-dnd'
import CopytCard from 'components/CopytCard'
import {Box} from '@mui/material'
import {debounce} from 'lodash'

const IMAGE_DRAG_TYPE = 'IMAGE'

const DraggableImage = ({ showDelete = false, src, index, moveImage, deleteImage }) => {
  const ref = React.useRef(null)

  const [, drop] = useDrop({
    accept: IMAGE_DRAG_TYPE,
    hover: debounce((draggedItem) => {
      if (draggedItem.index !== index) {
        moveImage(draggedItem.index, index)
        draggedItem.index = index
      }
    }, 200),
  })

  const [{isDragging}, drag] = useDrag({
    type: IMAGE_DRAG_TYPE,
    item: {index},
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  drag(drop(ref))

  return (
    <div ref={ref} style={{opacity: isDragging ? 0 : 1}}>
      <CopytCard className="myimages--card">
        <Box className="img-x" onClick={() => deleteImage()} display={showDelete && 'none'} />
        <Box component="img" src={src} sx={{cursor: 'pointer'}} />
      </CopytCard>
    </div>
  )
}

export default DraggableImage
