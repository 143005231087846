import { filter } from 'lodash'

export const CLOVER = 'bfb51ea9-6d61-46ae-ac86-c5843af5e490'

export const getMerchant = (credential) => {
  return filter(credential?.data, (credential) =>
    credential?.platform &&
    credential.platform?.name?.toLowerCase() === 'clover' &&
    credential.refId !== '7KWPZ96KFBHJ1'
  );
}

export default getMerchant
