import React, { useCallback, useState, useEffect } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import { Button } from 'reactstrap'
import { useRedirCallback } from 'hooks/use_redir_callback'
import { usePlatformMarket } from 'service/hook/use_market'

const CheckBookCallback = () => {
  const history = useHistory()
  const [state, setState] = useState({ message: '', state: 'loading' })
  const [isMounted, setIsMounted] = useState(false);

  const { checkBookCallback } = usePlatformMarket()

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  const process = useCallback(async () => {
    if (!isMounted) return;

    try {
      const queryParams = new URLSearchParams(`${window.location.search}`);
      const code = queryParams.get("code");
      const data = await checkBookCallback(code);
      if (!data) {
        throw new Error('Faild to process input.')
      }
      setState((s) => ({ ...s, message: 'Success', state: 'success' }))
    } catch (e) {
      setState((s) => ({ ...s, state: 'failed', message: e.message }));
    }
  }, [isMounted, checkBookCallback, setState]);

  useRedirCallback(process)

  if (state.state === 'loading') {
    return <div className="content">Processing Checkbook Auth...</div>
  } else if (state.state === 'failed') {
    return (
      <div className="content">
        Failed.
        <hr />
        <Button onClick={history.goBack}>Back</Button>
      </div>
    )
  } else if (state.state === 'success') {
    return (
      <div className="content">
        Success.
        <Redirect to="/admin/platforms" />
      </div>
    )
  }

  return <div className="content">Loading...</div>
}

export default CheckBookCallback
