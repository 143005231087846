import React from 'react'
import {isEmpty} from 'lodash'
import {Box, Typography, Container, Stack} from '@mui/material'
import {Help as HelpIcon, Close as CloseIcon} from '@mui/icons-material'
import {styled} from '@mui/material/styles'

import {capitalizeFirstLetter} from 'util/string_utils'
import {useConsignments} from 'service/hook'
import {useUnlistedItemInventory} from 'service/hook/useItem'
import {useGlobalStore} from 'provider/global_store/hook'
import {tabSelectionStr} from 'resources/constants'
import {CopytStyledButton} from 'views_v2/lib/snippets'

export const HeaderText = styled(Typography)(() => ({
  fontWeight: 600,
  fontSize: '0.9rem',
}))

export const BodyText = styled(Typography)(() => ({
  fontSize: '0.9rem',
}))

const ItemRow = ({data, removeId, hasCloseButton, isEnterprise}) => {
  const thirdColumn = isEnterprise ? 'Consignor' : 'Store'
  return (
    <Stack direction="column">
      <Stack
        direction="row"
        alignItems="center"
        pl={3}
        textAlign="center"
        borderBottom="1px solid"
        borderTop="1px solid"
        bgcolor="var(--lighter)"
      >
        <Box display="flex" width="95%">
          <Box width="50%" borderRight="1px solid">
            <HeaderText>Item Name</HeaderText>
          </Box>
          <Box width="20%" borderRight="1px solid">
            <HeaderText>Size</HeaderText>
          </Box>
          <Box width="25%">
            <HeaderText textAlign="center">{thirdColumn}</HeaderText>
          </Box>
        </Box>

        <Box width="5%" />
      </Stack>
      <Container sx={{maxHeight: '500px', overflowY: 'auto', padding: '0 !important'}}>
        {data?.length > 0 &&
          data?.map((s, i) => {
            const {product, consignee, consign, listingPlatforms} = s || {}
            let storeName = '--'
            if (isEnterprise) {
              storeName = consign?.consignor?.email
            } else if (hasCloseButton) {
              storeName = consignee?.businessName || consign?.consignee?.businessName
            } else {
              storeName =
                product?.consign?.consignee?.businessName ||
                consign?.consignee?.businessName ||
                (listingPlatforms?.length > 0 && listingPlatforms[0]?.platform?.name) ||
                '--'
            }
            return (
              <Stack
                direction="row"
                alignItems="center"
                pl={3}
                textAlign="center"
                borderBottom="1px solid"
                key={i}
              >
                <Box display="flex" width="95%">
                  <Box
                    textAlign="center"
                    borderRight="1px solid black"
                    width="50%"
                    lineHeight="normal"
                    py={1}
                  >
                    <Typography component="span" sx={{fontSize: '0.85rem'}}>
                      {product?.title || s?.title || '--'}
                    </Typography>
                  </Box>
                  <Box
                    fontSize="0.85rem"
                    borderRight="1px solid black"
                    alignItems="center"
                    textAlign="center"
                    justifyContent="space-around"
                    display="flex"
                    width="20%"
                  >
                    {product?.size || s?.size || '--'}
                  </Box>
                  <Box
                    alignItems="center"
                    textAlign="center"
                    justifyContent="space-around"
                    display="flex"
                    width="30%"
                  >
                    {capitalizeFirstLetter(storeName)}
                  </Box>
                </Box>
                <Box width="5%">{hasCloseButton && <CloseIcon onClick={() => removeId(s.id)} />}</Box>
              </Stack>
            )
          })}
      </Container>
    </Stack>
  )
}

const MdlWithdraw = (p) => {
  const {data, removeId, hasCloseButton = false, fromWhere = null} = p || {}
  const {onWithdrawItems} = useConsignments()
  const {refetch} = useUnlistedItemInventory()
  const {isEnterprise} = useGlobalStore()

  const onSubmit = () => {
    if (!isEmpty(data)) {
      const consignIds = data.map((d) => {
        return d?.product?.consign?.id || d?.consign?.id || d?.id
      })

      onWithdrawItems({consignIds, action: isEnterprise ? 'approve' : ''})
      setTimeout(
        () => {
          if (isEnterprise) {
            switch (fromWhere) {
              case tabSelectionStr.UNLISTED:
                refetch()
                break
              case tabSelectionStr.VIEW_ITEM:
                window.location.replace('/admin/inventory/viewInventory')
                break
              case tabSelectionStr.VIEW_LISTING:
                window.location.replace('/admin/inventory/listed')
                break
              case tabSelectionStr.ADD_ITEM:
                window.location.replace('/admin/inventory/viewInventory')
                break
            }
          }
        },
        fromWhere === tabSelectionStr.ADD_ITEM ? 2000 : 500,
      )
    }
  }

  return (
    <Box>
      <Box className="modal-title">
        <Box className="modal__heading" mx="0px !important">
          <Typography variant="h1" textAlign="left">
            Consigned Item Withdraw
          </Typography>
        </Box>
      </Box>
      <Box className="modal-body" padding={0} mb={2}>
        <Stack direction="row" alignItems="center" p={2} textAlign="center">
          <HelpIcon sx={{fontSize: '3rem !important', color: 'var(--success)'}} />
          <Stack direction="column" alignItems="center">
            <Typography sx={{fontWeight: 'fontWeightBold'}}>
              Are you sure you want to submit a request to withdraw the selected item
              {isEnterprise && '(s)'}?
            </Typography>
            <Typography fontStyle="italic" fontSize="0.85rem" color="red !important">
              Warning! This action cannot be undone.
            </Typography>
          </Stack>
        </Stack>
        {isEmpty(data) ? (
          <Typography textAlign="center">No Data Available</Typography>
        ) : (
          <ItemRow {...{data, removeId, hasCloseButton, isEnterprise}} />
        )}
        <Stack
          direction="row"
          justifyContent="flex-end"
          pr={2}
          pt={1.5}
          mt={4}
          borderTop="1px solid var(--lighter)"
          spacing={2}
        >
          <CopytStyledButton variant="outline" color="primary" onClick={() => p.onCloseModal()}>
            Cancel
          </CopytStyledButton>
          <CopytStyledButton
            variant="contained"
            color="primary"
            disabled={data?.length === 0}
            onClick={() => {
              if (p?.updateStateValue) p?.updateStateValue('isDisabled', true)
              onSubmit()
              p.onCloseModal()
            }}
          >
            Confirm
          </CopytStyledButton>
        </Stack>
      </Box>
    </Box>
  )
}

export default MdlWithdraw
