import React, { memo, useCallback, useRef, useState } from "react";
import { Alert, Box, Divider, Typography, Input, Stack } from '@mui/material';
import { useGlobalStore } from 'provider/global_store/hook';
import enterpriseFile from 'files/templateEnterprise.xlsx';
import individualFile from 'files/templateIndividual.xlsx';
import { CopytButton } from "views_v2/lib/snippets";
import CopytCard from "components/CopytCard";
import {
  CloudDownload as CloudDownloadIcon,
  Add as AddIcon
} from "@mui/icons-material"

const ImportContent = ({ onSubmit, onUpload, hasChanges }) => {
  const { isEnterprise } = useGlobalStore();
  const ref = useRef(null);
  const [fileError, setFileError] = useState('');

  const onDownload = useCallback(() => {
    const link = document.createElement("a");
    link.href = isEnterprise ? enterpriseFile : individualFile;
    link.download = isEnterprise ? "Copyt Import Template (Enterprise).xlsx" : "Copyt Import Template (Individual).xlsx";
    document.body.appendChild(link); 
    link.click();
    document.body.removeChild(link);
  }, [isEnterprise]);

  const onHandleChange = useCallback((e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 10485760) {
        setFileError('File size should not exceed 10MB');
        return;
      } else if (file.type !== "text/csv" && file.type !== "application/vnd.ms-excel") {
        setFileError('Please upload a CSV file');
        return;
      } else {
        setFileError('');
        onUpload(file);
      }
    }
  }, [onUpload]);

  const onClick = useCallback(() => {
    ref.current.click();
  }, []);

  return (
    <CopytCard style={{ height: '100%', overflowY: 'auto' }}>
      <Typography p={4} textTransform="uppercase" color="inherit !important" fontWeight={600} fontSize="1.2rem">Import Inventory</Typography>
      <Stack direction="column" alignItems="center">
        <Stack direction="column" spacing={1} pb={4} alignItems="center">
          <Typography color="inherit !important">Download the Inventory Template</Typography>
          <CopytButton variant="outline" color="primary" onClick={onDownload} sx={{ mb: 2 }}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Box
                component="img"
                sx={{
                  width: '28px',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center center',
                }}
                src="https://www.freeiconspng.com/uploads/excel-png-office-xlsx-icon-3.png"
              />
              <Typography sx={{ marginRight: "40px !important", textTransform: 'capitalize !important' }}>Download Template</Typography>
              <CloudDownloadIcon />
            </Stack>
          </CopytButton>
        </Stack>
        <Divider width="100%" />
        <Stack direction="column" spacing={2} py={4}>
          {/* <Stack direction="column" spacing={2} alignItems="center"> */}
          <Alert severity="info">Single upload file should not be more than 10MB, only the .csv file types are allowed</Alert>
          {/* </Stack> */}
          <Stack direction="column" spacing={1} alignItems="center">
            <Typography color="inherit !important">Upload file</Typography>
            <Stack backgroundColor="white" maxWidth="500px">
              <CopytButton sx={{ padding: 5, border: "2px dotted #6c757d" }} onClick={onClick} >
                <Stack direction="column" alignItems="center" spacing={2}>
                  <Box
                    component="img"
                    sx={{
                      width: '60px',
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center center',
                    }}
                    src="https://cdn-icons-png.flaticon.com/512/9159/9159105.png"
                  />
                  <Typography sx={{ padding: 1, backgroundColor: "#d5239e", color: "white !important", borderRadius: 1, textTransform: 'capitalize' }}>
                    <AddIcon />Select File to upload
                  </Typography>
                  <Typography color="#6c757d" variant="caption" display="block" fontWeight={600} gutterBottom fontSize="0.9rem" textTransform="uppercase">
                    * Once file is successfully uploaded, return to your inventory page to see the progress *
                  </Typography>
                </Stack>

              </CopytButton>
              <Input
                inputRef={ref}
                type="file"
                accept=".csv, text/csv"
                onChange={onHandleChange}
                sx={{ display: 'none' }}
              />
            </Stack>
          </Stack>
          <Box width="100%" height="50px" mb={2}>
          {fileError ? (<Alert severity="error">{fileError}</Alert>) : null}
          </Box>
        </Stack>
        {hasChanges && (
          <CopytButton sx={{ width: '150px' }} variant="contained" color="primary" onClick={onSubmit}>
            Submit
          </CopytButton>
        )}
      </Stack>
    </CopytCard>
  )
};

export default memo(ImportContent);
