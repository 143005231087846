import React from 'react'
import {COLORS} from './constants'

const Button = ({isActive, onClick = () => {}, children}) => {
  return (
    <button
      style={{
        backgroundColor: isActive ? COLORS.PRIMARY : COLORS.LIGHT,
        border: 'none',
        color: isActive ? 'white' : 'black',
        padding: '4px 16px',
        fontWeight: 'bold',
        borderRadius: 4,
        '&:hover': {
          backgroundColor: isActive ? COLORS.PRIMARY : '#333333',
        },
      }}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export default Button
