import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import TableBody from './TableBody'
import TableFooter from './TableFooter'
import TableHeader from './TableHeader'
import TableMessage from './TableMessage'
import TableCell from './TableCell'
import TableSection from './TableSection'

class TableView extends Component {
  static Body = TableBody
  static Footer = TableFooter
  static Header = TableHeader
  static Message = TableMessage
  static Cell = TableCell
  static Section = TableSection

  renderBody() {
    return this.props.children
  }

  render() {
    const {classNames, data} = this.props

    return (
      <div className={classnames('master-table', classNames)} {...{data}}>
        {this.renderBody()}
      </div>
    )
  }
}

TableView.propTypes = {
  children: PropTypes.node,
  classNames: PropTypes.string
}
export default TableView
