import React from 'react'
import CopytCard from 'components/CopytCard'
import onKeyPress from 'views_v2/lib/common/onKeyPress'
import { FieldGroup } from 'views_v2/lib/snippets'

const ContactInformation = (props) => {
	const {
		firstName,
		lastName,
		email,
		phoneNumber,
		handleInputChange
	} = props

	return (
		<CopytCard className="copyt-card">
			<CopytCard.Header>
				<CopytCard.Text tag="div">
					Contact Information
				</CopytCard.Text>
			</CopytCard.Header>
			<CopytCard.Body>
				<FieldGroup
					sLabel="First Name"
					placeholder="Enter first name"
					maxLength={20}
					name="firstName"
					value={firstName || ''}
					onKeyPress={(e) => onKeyPress(e, 'text')}
					onChange={handleInputChange}
				/>
				<FieldGroup
					sLabel="Last Name"
					placeholder="Enter last name"
					maxLength={20}
					name="lastName"
					value={lastName || ''}
					onKeyPress={(e) => onKeyPress(e, 'text')}
					onChange={handleInputChange}
				/>
				<FieldGroup
					readOnly
					sLabel="Email Address"
					placeholder="Enter email address"
					name="email"
					value={email || ''}
					onChange={handleInputChange}
				/>
				<FieldGroup
					sLabel="Phone Number"
					placeholder="Enter phone number"
					name="phoneNumber"
					value={phoneNumber || ''}
					onChange={handleInputChange}
				/>
			</CopytCard.Body>
		</CopytCard>
	)
}

export default ContactInformation
