import {ListingMethod} from 'enums'
import {useState, useEffect} from 'react'

export const useListingMethod = () => {
  const [listingMethod, setListingMethod] = useState(() => {
    const savedMethod = localStorage.getItem('listing-method')
    return savedMethod ? JSON.parse(savedMethod) : {shopify: ListingMethod.VENDING_STYLE}
  })

  const updateListingMethod = (platform, method) => {
    setListingMethod({...listingMethod, [platform]: method})
  }

  useEffect(() => {
    localStorage.setItem('listing-method', JSON.stringify(listingMethod))
  }, [listingMethod])

  return [listingMethod, updateListingMethod]
}

export default useListingMethod
