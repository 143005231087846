import {useCallback, useMemo} from 'react'
import {useQuery, useMutation, useQueryClient} from 'react-query'
import axios from 'axios'
import {captureException} from '@sentry/react'
import {isEmpty, map, orderBy} from 'lodash'
import {useAuth0} from '@auth0/auth0-react'

import {paths} from 'constant/api'
import {createRequestOptions} from 'util/network'
import {apiConfig} from 'config'
import {ar, fn} from 'variables/empty'
import {useToastMessage} from 'components/index'
import {InventoryQueryType} from 'constant/query_type'
import {sortAlphabetical, sortNumeric} from 'util/util'
import {_getValue} from 'util/string_utils'
import {useFilter} from 'stores/useFilter'

const parseNumeric = (value) => {
  return parseFloat(!isNaN(value) ? value : 0) || 0
}

export const getInventory = async ({
  filter,
  searchText,
  type = InventoryQueryType.UNLISTED,
  tokenGetter,
  queries,
}) => {
  try {
    const token = await tokenGetter()
    // todo: encode url
    if (!searchText) searchText = ''

    const queryString = map(
      {...filter, search: searchText, ...queries},
      (value, key) => `${key}=${encodeURIComponent(value).replace(/%2F/g, '/')}`,
    ).join('&')

    const response = await axios.get(
      `${paths.product}/${type}?${queryString}`,
      createRequestOptions(token),
    )
    // const data = orderBy(response.data?.data, ['createdAt'], ['desc']) || ar
    const data = response.data?.data || ar

    return {
      ...response.data,
      data: data.map((item) => ({
        ...item,
        price: parseNumeric(item.price),
        desiredReturn: parseNumeric(item.desiredReturn),
      })),
    }
  } catch (e) {
    captureException(e)
    console.log('Error retrieving Inventories', e.message)
    throw e.message
  }
}

const putVerifiedItem = async (getAccessTokenSilently, value) => {
  const token = await getAccessTokenSilently()
  const response = await axios.patch(
    `${apiConfig.api_url}/product/${value.id}`,
    {
      ...value,
    },
    createRequestOptions(token),
  )

  return response
}

const auditCSVExport = async (getAccessTokenSilently, value) => {
  const token = await getAccessTokenSilently()

  const fetchData = async (status) => {
    try {
      const response = await axios.get(
        `${paths.product}?status=${status}&responseType=csv&withExport=${!isEmpty(value)}`,
        {
          headers: {Authorization: `Bearer ${token}`},
        },
      )
      return {success: true, data: response.data}
    } catch (error) {
      captureException(error)
      return {success: false}
    }
  }

  const newStatus = isEmpty(value) ? ['unverified'] : ['unverified', 'verified']
  await Promise.all(newStatus.map(fetchData))
}

export function useItemInventories(
  type,
  tokenGetter,
  filter,
  {searchText, limit = 10},
  queries,
  option = {},
) {
  const {getAccessTokenSilently} = useAuth0()
  const queryClient = useQueryClient()
  const {showToast} = useToastMessage()
  const {
    isLoading,
    isError,
    isFetched,
    isFetching,
    refetch,
    data: items = ar,
    error,
  } = useQuery(
    ['inventory', type, filter, searchText, queries],
    () =>
      getInventory({
        type,
        filter,
        searchText,
        tokenGetter,
        queries,
      }),
    {...option, keepPreviousData: true, refetchOnWindowFocus: false},
  )

  const filterValues = useMemo(() => getFilters(items), [items])

  const refreshTable = () => {
    queryClient.invalidateQueries('inventory')
  }

  const onVerifiedItem = useMutation((value) => putVerifiedItem(getAccessTokenSilently, value), {
    onSuccess: async (data, variables, context) => {
      refetch()
    },
    onError: async (error) => {
      refetch()
    },
  })

  const onAuditCSVExport = useMutation((value) => auditCSVExport(getAccessTokenSilently, value), {
    onSuccess: async (data, variables, context) => {
      refetch()
    },
    onError: async (error) => {
      refetch()
    },
  })

  const {
    mutate,
    isLoading: isExportLoading,
    isError: isExportError,
    isSuccess: isExportSuccess,
  } = onAuditCSVExport

  return {
    isLoading,
    isError,
    isItemsFetched: isFetched,
    isFetching,
    refetch,
    error,
    items: items || [],
    filterValues,
    total: items?.total || 0,
    averageCost: _getValue(items?.averageCost) || 0,
    averagePrice: _getValue(items?.averagePrice) || 0,
    totalCost: _getValue(items?.totalCost) || 0,
    totalSoldPrice: _getValue(items?.totalSoldPrice) || 0,
    totalGrossSales: _getValue(items?.totalGrossSales) || 0,
    totalActualReturn: _getValue(items?.totalActualReturn) || 0,
    totalDesiredReturn: _getValue(items?.totalDesiredReturn) || 0,
    totalEstimatedPayout: _getValue(items?.totalEstimatedPayout) || 0,
    totalConsignActualReturn: _getValue(items?.totalConsignActualReturn) || 0,
    totalProfit: _getValue(items?.totalProfit) || 0,
    refreshTable,
    onVerifiedItem: onVerifiedItem.mutate,
    onAuditCSVExport: mutate,
    isExportLoading,
    isExportError,
    isExportSuccess,
  }
}

// todo: since this is group. this should go to view/inventory module
export function useUnlistedItemInventory(
  type = InventoryQueryType.UNLISTED,
  filter,
  searchText,
  queries = {},
  option = {},
) {
  const {getAccessTokenSilently} = useAuth0()
  return useItemInventories(
    type,
    getAccessTokenSilently,
    filter,
    {searchText} || {searchText: ''},
    queries,
    option,
  )
}

export function useAuditItemInventory(type, filter, searchText, queries = {}, option = {}) {
  const {getAccessTokenSilently} = useAuth0()
  const verified = useItemInventories(
    (type = InventoryQueryType.VERIFIED),
    getAccessTokenSilently,
    filter,
    {searchText} || {searchText: ''},
    queries,
    option,
  )

  const auditFilter = useFilter((state) => state.audit.filter)

  const unverified = useItemInventories(
    (type = InventoryQueryType.UNVERIFIED),
    getAccessTokenSilently,
    filter,
    {searchText} || {searchText: ''},
    {...queries, ...auditFilter},
    option,
  )

  return {
    unverified,
    verified,
  }
}

export function useItem() {
  const {getAccessTokenSilently} = useAuth0()

  const getItem = useCallback(
    async (id, onSuccess = fn, onFailure = fn) => {
      try {
        const token = await getAccessTokenSilently()
        var response = await axios.get(`${paths.product}/${id}`, createRequestOptions(token))
        onSuccess(response.data.data)
        return response.data.data
      } catch (e) {
        captureException(e)
        console.log(e)
        onFailure(e.message)
      }
    },
    [getAccessTokenSilently],
  )

  const postItemDuplicate = useCallback(
    async (args, onSuccess = fn, onFailure = fn) => {
      try {
        const token = await getAccessTokenSilently()
        var response = await axios.post(`${paths.item}/duplicate`, args, createRequestOptions(token))
        onSuccess(response.data)
        return response.data
      } catch (e) {
        captureException(e)
        console.error(e)
        onFailure(e.message)
      }
    },
    [getAccessTokenSilently],
  )

  const postItemImport = useCallback(
    async (args, onSuccess = fn, onFailure = fn) => {
      try {
        const token = await getAccessTokenSilently()
        var response = await axios.post(`${paths.product}/import`, args, createRequestOptions(token))
        onSuccess(response.data)
        return response.data
      } catch (e) {
        captureException(e)
        console.error(e)
        onFailure(e.message)
      }
    },
    [getAccessTokenSilently],
  )

  const getUnconsignedUnlistedCount = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently()
      var response = await axios.get(
        `${apiConfig.api_url}/product/getUnconsignedUnlistedCount`,
        createRequestOptions(token),
      )

      return response.data.result
    } catch (e) {
      console.error(e)
      // onFailure(e.message)
    }
  }, [getAccessTokenSilently])

  return {getItem, postItemImport, postItemDuplicate, getUnconsignedUnlistedCount}
}

// todo: need to fix duplication to api problem on offset queries
function mergePages(pages = ar, type) {
  const store = {[type]: {}}
  // todo: this will not needed if ui can map all items
  const inserted = store[type]
  return (
    pages
      .reduce((c, n) => [...c, ...n.data], [])
      // remove item duplicate
      .filter((i) => (inserted.hasOwnProperty(i.id) ? false : (inserted[i.id] = true)))
  )
  // return pages.reduce((c, n,) => [...c, ...n.data], [])
}

//private
function getFilters(items = ar) {
  const sizes = [],
    conditions = [],
    brands = []
  items &&
    items.length > 0 &&
    items.forEach((item) => {
      if (item?.condition) {
        conditions.push(item.condition)
      }
      if (item?.size) {
        sizes.push(item.size)
      }
      if (item.item_type?.brand) {
        brands.push(item.item_type.brand)
      }
    })

  return {
    conditions: [...new Set(conditions)].sort(sortAlphabetical),
    sizes: [...new Set(sizes)].sort(sortAlphabetical),
    brands: [...new Set(brands)].sort(sortNumeric),
  }
}
