import React from 'react'

const StaticImage = React.forwardRef((p, ref) => {
  const props = Object.keys(p).reduce((obj, k) => {
    if (k === 'hideOnErr') return obj
    return { ...obj, [k]: p[k] }
  }, {})
  return (
    <img
      {...props}
      alt={p?.alt}
      ref={ref}
      src={p.src || null}
      onError={(e) => {
        if (p?.hideOnErr) e.target.style.display = 'none'
        if (p.onError) p.onError(e)
      }}
    />
  )
})

export default StaticImage
