import React from 'react'
import CopytCard from 'components/CopytCard'
import { FieldGroup, CopytLabel, } from 'views_v2/lib/snippets'
import { CopytBox } from 'views_v2/modules/Inventory/components/cards/InventoryDetails'
import TextareaAutosize from '@mui/base/TextareaAutosize'

export const ListingInventoryDetails = ({
  product,
  readOnly = false 
}) => {
  return (
    <CopytCard className="consignor-information">
      <CopytCard.Header>
        <CopytCard.Text tag="div">
          Inventory Details
        </CopytCard.Text>
      </CopytCard.Header>
      <CopytCard.Body>
        <CopytBox>
          <FieldGroup
            {...{ readOnly }}
            sLabel="Size"
            name="size"
            value={product?.size || ''}
            styles={{ width: '100% !important' }}
          />
          <FieldGroup
            {...{ readOnly }}
            sLabel="Tag Status"
            name="tagStatus"
            value={product?.tagStatus || ''}
          />
        </CopytBox>

        <CopytBox>
          <FieldGroup
            {...{ readOnly }}
            sLabel="Box Condition"
            name="boxCondition"
            value={product?.boxCondition || ''}
          />
          <FieldGroup
            {...{ readOnly }}
            sLabel="Item Condition"
            name="itemCondition"
            value={product?.condition || ''}
          />
        </CopytBox>
        <FieldGroup
          {...{ readOnly }}
          sLabel="Flaws"
          name="flaws"
          value={product?.flaws || ''}
        />
        <CopytLabel>Description</CopytLabel>
        <TextareaAutosize
          minRows={10}
          disabled
          readOnly
          style={{ width: '100%', height: '185px', borderRadius: 6, padding: '10px' }}
          name="description"
          id="description"
          value={product?.description}
        />
      </CopytCard.Body>
    </CopytCard>
  )
}

export default ListingInventoryDetails