import {useState, useEffect} from 'react'
import {Grid, Stack} from '@mui/material'

import {getCurrency} from 'util/model/setting'
import Inventory from './sections/inventory'
import Summary from './sections/summary'
import Breakdown from './sections/breakdown'
import Others from './sections/others'
import Trends from './sections/trends'
import Pendings from './sections/pendings'
import Menu from './sections/menu'
import {currency_symbols} from 'assets/data'
import {useDashboard} from 'stores/useDashboard'
import {useMetrics} from 'service/hook'

const Dashboard = () => {
  const currencySymbol = currency_symbols.find((c) => c.value === localStorage.getItem('currency'))
  const currency = getCurrency(currencySymbol?.value || 'USD')
  const [category, setCategory] = useState('all')

  const {filter} = useDashboard()
  const {inventory, sales, pendings, trends} = useMetrics({
    filter: {...filter.product, ...filter.sold},
    queries: {},
    options: {},
  })

  return (
    <Stack height="95%">
      <Menu inventory={inventory} sales={sales} filter={filter} />
      <Stack padding={2} gap={2} overflow="auto">
        <Grid container>
          <Grid item xs={9} padding={0}>
            <Stack spacing={2} marginRight={2} height="100%">
              <Summary currency={currency} inventory={inventory} sales={sales} />
              <Inventory
                currency={currency}
                category={category}
                setCategory={setCategory}
                inventory={inventory}
              />
            </Stack>
          </Grid>
          <Grid item xs={3} padding={0}>
            <Stack spacing={2}>
              <Others currency={currency} inventory={inventory} sales={sales} />
              <Breakdown category={category} inventory={inventory} />
            </Stack>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item padding={0} xs={4}>
            <Stack marginRight={2} height="100%">
              <Pendings pendings={pendings} />
            </Stack>
          </Grid>
          <Grid item padding={0} xs={8}>
            <Stack>
              <Trends currency={currency} trends={trends} />
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  )
}

export default Dashboard
