import React, { memo } from 'react'
import { Box, Grid } from '@mui/material'
import DetailView from 'components/DetailView'
import CopytCard from 'components/CopytCard'
import { FeeSettings } from './cards'

const Consignment = ({
  onSetFee,
  visible
}) => {
  return (
    <DetailView.Panel isHidden={!visible}>
      <Box display="flex" justifyContent="space-around">
        <CopytCard className="account-big-card card-layout">
          <Grid container gap={1} justifyContent="space-between">
            <Grid item xs={12} lg={5.9}>
              <FeeSettings {...{ onSetFee }} />
            </Grid>
          </Grid>
        </CopytCard>
      </Box>
    </DetailView.Panel>
  )
}

export default memo(Consignment)
