import React, {memo, useState, useEffect} from 'react'
import FieldGroup from 'views_v2/lib/snippets/FieldGroup'
import onKeyPress from 'views_v2/lib/common/onKeyPress'
import {useMinimumInputFee} from './hook'
import {getCurrency} from 'util/model/setting'

const MinimumInputFee = (p) => {
  const [errorMessage, setErrorMessage] = useState()
  const {onChange, value, onSave} = useMinimumInputFee()

  useEffect(() => {
    if (value && !p.noMinFee) {
      p.setNoMinFee(true)
    }
  }, [value])

  const updateFees = () => {
    if (value) {
      onSave()
      setErrorMessage()
      p.setNoMinFee(true)
    } else {
      setErrorMessage('is required')
      p.setNoMinFee(false)
    }
  }

  const currency = getCurrency()
  const currencySymbol = getCurrency().format().replace('NaN', '')

  return (
    <FieldGroup
      {...{onChange, value}}
      disabled={p?.disabled}
      onChange={(e) => {
        onChange(e)
        p?.setEnabled(true)
      }}
      sLabel={`Minimum Consignment Fee (${currencySymbol})`}
      name="MinimumConsignmentFee"
      hasError={errorMessage}
      errorMessage={`Minimum Consignment Fee ${errorMessage}`}
      id="numeric"
      fixedDecimalScale={false}
      placeholder={`(${currencySymbol})`}
      onBlur={updateFees}
    />
  )
}

export default memo(MinimumInputFee)
