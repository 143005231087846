export const ListingPlatformStatus = {
  CREATED: 'created', // request for acceptance on consign item
  LISTED: 'listed', // accepted on consign item
  PENDING: 'pending',
  HOLD_LISTED: 'hold_listed',
  REMOVED: 'removed',
  PROCESSING: 'processing',
  REMOVING: 'REMOVING',
  LISTING_FAILED: 'listing_failed',
  REMOVE_FAILED: 'remove_failed',
  REJECTED: 'rejected',
  SOLD: 'sold',
  PROCESSED: 'processed',
  RETURNED: 'returned',
  RELISTING: 'relisting',
}
