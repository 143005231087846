
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import "./error.css"
import Card from "reactstrap/lib/Card";

const queryString = require('query-string');

const Error = (props) => {
    const {logout} = useAuth0();
    function returnToLogin () {
        logout({  returnTo: window.location.origin });
    }
  
    const parsed = queryString.parse(props.location.search);
    return (
      <>
        <div className="error">
            <div className="error-container">
                <Card className="error-card">
                    <div className="top-section">
                        <div className="logo"></div>
                        <br></br>
                        Copyt
                    </div>
                    <div className="error-title">Login failed</div>
                    <div className="error-message">{parsed.error}</div>
                    <button className= "return-login" onClick={() => {returnToLogin()}}>RETURN TO LOGIN</button>
                </Card>
            </div>
        </div>
      </>
    );
  }


export default Error;
