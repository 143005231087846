import React, {useEffect, useState} from 'react'
import {Box, FormControlLabel} from '@mui/material'

import {useUser} from 'service/hook/useUser'
import {CopytTooltip} from 'views_v2/lib/snippets'
import CheckSwitch from 'views_v2/lib/snippets/CopytSwitch/CheckSwitch'

const ToggleButton = () => {
  const {updateUser, user, userLoading} = useUser()
  const [isToggle, setIsToggle] = useState(user?.isAcceptingApplications ?? false)

  useEffect(() => {
    if (!userLoading && user?.isAcceptingApplications !== undefined) {
      setIsToggle(user.isAcceptingApplications)
    }
  }, [userLoading, user])

  const handleSwitch = () => {
    setIsToggle((prev) => !prev)
    updateUser({isAcceptingApplications: !isToggle})
  }

  const title = `You're ${!isToggle ? 'NOT' : ''} accepting applications`

  return (
    <Box id="toggle-application-button" className="toggle-application-button">
      <CopytTooltip title={title} placement="bottom">
        <FormControlLabel
          sx={{
            m: 1,
            color: 'var(--success)',
          }}
          control={<CheckSwitch checked={isToggle} onChange={handleSwitch} />}
        />
      </CopytTooltip>
    </Box>
  )
}

export default ToggleButton
