import React, {useState, useEffect, useRef, createContext, useCallback} from 'react'
import axios from 'axios'
import {useHistory} from 'react-router-dom'
import {useReactToPrint} from 'react-to-print'
import clx from 'classnames'
import {useAuth0} from '@auth0/auth0-react'
import * as Sentry from '@sentry/react'
import {Box, Tabs, Tab, Grid, Typography, Tooltip} from '@mui/material'
import {
  SpeakerNotesOff as SpeakerNotesOffIcon,
  StickyNote2 as StickyNote2Icon,
  ShoppingBasket as ShoppingBasketIcon,
  ShoppingCartCheckout as ShoppingCartCheckoutIcon,
} from '@mui/icons-material'
import _, {isEmpty} from 'lodash'

import * as Apis from 'constant/api'
import {ListingStatus} from 'constant/listing_status'
import {apiConfig} from 'config'
import {ConsignStatus, ModalType, ToastType} from 'enums'
import {useGlobalStore} from 'provider/global_store/hook'
import {tabSelectionStr} from 'resources/constants'
import {getListingId, isConsigned as isProductConsigned} from 'util/model/product'
import {createRequestOptions} from 'util/network'
import {getParams} from 'util/window_utils'
import {getCurrency} from 'util/model/setting'
import {_getValue} from 'util/string_utils'
import DateUtils from 'util/date_utils'
import {useNavigator} from 'service/hook/use_navigator'
import {updateListingStatus, usePostListAll} from 'service/hook/use_listing'
import {useUnlistedItemInventory} from 'service/hook/useItem'
import {useExport, useUser} from 'service/hook'
import {useMetrics} from 'v2/hooks/use-metrics'
import {BarcodeLabels} from 'views_v2/lib/components'
import {InventoryHeader, InventoryBody} from './components/common'
import InventoryModals from './components/InventoryModals'
import InventoryToast from './components/InventoryToast'
import './scss/parent.scss'

export const TabPanel = {
  UNDEFINED: -1,
  UNLISTED: 0,
  LISTED: 1,
  SOLD: 2,
  PROCESSED: 3,
}

export const SearchContext = createContext()

const Inventory = (props) => {
  const {user} = useUser()
  const {paths, go} = useNavigator()
  const {getAccessTokenSilently} = useAuth0()
  const history = useHistory()
  let lastSegment = history?.location?.pathname.split('/').pop()
  if (lastSegment?.toLowerCase() === 'viewInventory') lastSegment = 'unlisted'
  lastSegment = TabPanel[lastSegment?.toUpperCase()]

  const [tabPanel, setTabPanel] = useState(Number(lastSegment) || TabPanel.UNLISTED)
  const [listSelections, setListSelections] = useState([])
  const [disableBtn, setDisableBtn] = useState(false)
  const [showWithdraw, setShowWithdraw] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const {isEnterprise, hasMop} = useGlobalStore()
  const [modalType, setModalType] = useState(ModalType.UNDEFINED)
  const [toastType, setToastType] = useState(ToastType.UNDEFINED)
  const [deletionCount, setDeletionCount] = useState()
  const [barcodeItems, setBarcodeItems] = useState([])
  const [isBarcodeLabelReady, setIsBarcodeLabelReady] = useState(false)
  const [unlistedSearchText, setUnlistedSearchText] = useState('')
  const [listedSearchText, setListedSearchText] = useState('')
  const [processedSearchText, setProcessedSearchText] = useState('')
  const [soldSearchText, setSoldSearchText] = useState('')
  const [barcodeTemplates, setBarcodeTemplates] = useState([])
  const [printSize, setPrintSize] = useState({width: 3.5, height: 1.2})
  const [queries, setQueries] = useState({})
  const [withdrawIds, setWithdrawIds] = useState([])
  const [mount, setMount] = useState(false)
  const [bulkEdit, setBulkEdit] = useState({value: 'buyPrice', label: 'Buy Price'})
  const [showCard, setShowCard] = useState(false)
  const [hasConsignorCode, setHasConsignorCode] = useState(false)
  const [filter, setFilter] = useState({
    skip: 0,
    take: 500,
    page: 0,
  })
  const [chunkIndex, setChunkIndex] = useState(0)
  const [date, setDate] = useState({startDate: null, endDate: null})
  const componentRef = useRef()
  const currency = getCurrency()
  const [selectedCriteria, setSelectedCriteria] = useState([
    'assets',
    'condition',
    'location',
    'size',
    'title',
  ])
  const [isConsigned, setIsConsigned] = useState(false)

  const tab = Object.keys(TabPanel)
    .find((key) => TabPanel[key] === tabPanel)
    ?.toLowerCase()

  const {items, refetch, fetchNextPage, hasNextPage, isFetching, total, isLoading} =
    useUnlistedItemInventory(
      tab,
      filter,
      unlistedSearchText || listedSearchText || soldSearchText || processedSearchText,
      queries,
    )

  const {isErrorExport, isSuccessExport, invalidateExportQueries, refetchExport} = useExport({
    type: tab,
    filter: {...filter, take: items?.total || 10000, limit: items?.total || 10000},
    queries,
  })

  const {query} = useMetrics({
    ...queries,
    search: unlistedSearchText || listedSearchText || soldSearchText || processedSearchText,
    type: tab,
  })

  const {mutate: onListAll, isLoading: postlistingAllLoading} = usePostListAll({
    onSuccess: refetch,
  })

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    suppressErrors: true,
    documentTitle: `Copyt - ${printSize.width}x${printSize.height}`,
  })

  const handleChange = (event, newValue) => {
    setTabPanel(newValue)
    const iTab = Object.keys(TabPanel).find((key) => TabPanel[key] === newValue)
    routeChange(`/admin/inventory/viewInventory/${iTab?.toLowerCase()}`)
  }

  const routeChange = (route) => {
    history.push(route)
  }

  useEffect(() => {
    if (!mount) {
      const inventoryCardView = localStorage.getItem('inventory_card_view')
      if ((inventoryCardView && !inventoryCardView.includes('hidden')) || !inventoryCardView) {
        localStorage.setItem('inventory_card_view', JSON.stringify({hidden: true, selectedCriteria}))
      }

      const params = getParams(history)

      if (params?.hasOwnProperty('page')) {
        const pageNumber = Number(params?.page)
        if (pageNumber !== filter?.page && pageNumber > 0) {
          setChunkIndex(parseInt(_.last(pageNumber?.toString())))
          setFilter((f) => ({...f, page: pageNumber, skip: Math.floor(pageNumber / 5) * f.take}))
        }
      }
      setMount(true)
    }
  }, [mount])

  useEffect(() => {
    if (isSuccessExport) {
      setToastType(ToastType.EXPORTED)
    } else if (isErrorExport) {
      setToastType(ToastType.ERROR)
    }
    invalidateExportQueries()
  }, [isErrorExport, isSuccessExport])

  useEffect(() => {
    const retrievedObject = localStorage.getItem('inventory_card_view')
    const cardObject = JSON.parse(retrievedObject)
    setShowCard(!cardObject.hidden)
    setSelectedCriteria(cardObject.selectedCriteria)
  }, [showCard])

  useEffect(() => {
    const isEveryProductConsigned = Array.from(listSelections)?.every(isProductConsigned)
    const isEveryProductNotConsigned = Array.from(listSelections)?.every((s) => !isProductConsigned(s))
    const tabStatus = {
      isUnlistedTab: tabPanel === TabPanel.UNLISTED,
      isListedTab: tabPanel === TabPanel.LISTED,
    }
    if (isEmpty(listSelections)) {
      setShowWithdraw(false)
      setShowDelete(false)
      return
    }

    setShowWithdraw(isEveryProductConsigned)
    if (isEnterprise) {
      setShowDelete(isEveryProductNotConsigned)
      if (tabStatus.isListedTab) setShowDelete(false)
    } else {
      setShowDelete(!tabStatus.isListedTab)
    }
  }, [listSelections])

  const markAsProcessed = async () => {
    if (items?.data) {
      const token = await getAccessTokenSilently()
      try {
        await Promise.all(
          items?.data.map((p) => {
            const i = getListingId(p)
            if (i) {
              return updateListingStatus({listingId: i, status: 'processed'}, token)
            }
          }),
        )
        setToastType(ToastType.SUCCESS)
        setTimeout(() => {
          // After 2 seconds redirect to viewInventory
          routeChange(`/admin/inventory/viewInventory`)
        }, 1000)
      } catch (e) {
        setToastType(ToastType.ERROR)
      }
    }
    setModalType(ModalType.UNDEFINED)
  }

  const delistSelected = async () => {
    try {
      const ids = _.chain(listSelections).flatMap('listings').flatMap('id').compact().value()
      const token = await getAccessTokenSilently()
      await axios.delete(`${Apis.paths.listing}/many`, {
        data: {
          ids,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      setToastType(ToastType.SUCCESS)
      setListSelections(new Set())
      refetch()
    } catch (err) {
      setToastType(ToastType.ERROR)
      Sentry.captureException(err)
    }
  }

  const onCreateBatchSubmit = async ({selectedOwnerId, selectedLocationId}) => {
    try {
      const token = await getAccessTokenSilently()
      const ids = Array.from(listSelections, (x) => x.id)

      const response = await axios.post(
        `${apiConfig.api_url}/listing/consign-many`,
        {
          platformId: selectedOwnerId,
          locationId: selectedLocationId,
          items: ids.map((id) => ({productId: id})),
        },
        createRequestOptions(token),
      )
      if (response.status === 200 || response.status === 201) {
        if (response?.data?.data) {
          setListSelections(new Set())
          setToastType(ToastType.CONSIGN_SHOP)
          refetch()
        }
      }
    } catch (error) {
      Sentry.captureException(error)
      // Every status that is an error is considered a failure like 404, 500 etc
      setToastType(ToastType.ERROR)
    }
  }

  const onGenerateBarcodesClicked = async (e, isListed = true) => {
    setIsBarcodeLabelReady(true)
    setTimeout(() => {
      try {
        const barcodesList = isListed
          ? items?.data.filter((item) => listSelections.includes(item?.listings?.[0].id))
          : listSelections

        setBarcodeItems(barcodesList)
        handlePrint()
      } catch (err) {
        console.error(err)
        Sentry.captureException(err)
        if (err.response && err.response.status == 401) {
          setToastType(ToastType.ERROR)
        }
      }
    }, 500)
  }

  if (user) {
    if (
      Object.keys(user)?.length > 0 &&
      (!hasMop || !user?.private?.paymentMethodId) &&
      modalType === ModalType.UNDEFINED &&
      !disableBtn
    ) {
      // temporarily disable this paywall
      // setModalType(ModalType.PAYWALL)
      setDisableBtn(true)
    }
  }

  const changeCheckbox = (listing = [], isListingConsigned = false) => {
    if (tabPanel === 0 || tabPanel === 1) {
      let distinctCombinedArray = _.unionBy(listing, listSelections, 'id')

      const includedItem = _.filter(items?.data, (x) =>
        _.some(distinctCombinedArray, (y) => y?.id === x?.id),
      )
      if (includedItem?.length !== listing?.length) {
        const distinctItem = _.differenceBy(includedItem, listing, 'id')
        distinctCombinedArray = _.differenceBy(distinctCombinedArray, distinctItem, 'id')
      }

      const noChanges = _.isEqual(_.sortBy(listSelections, 'id'), _.sortBy(distinctCombinedArray, 'id'))

      if (!noChanges || _.isEmpty(listSelections)) {
        const unique = _.uniqBy(distinctCombinedArray, 'id')
        if (tabPanel === 0) setWithdrawIds(_.map(unique, (s) => _.get(s, 'id')))
        setListSelections(unique)
        setIsConsigned(isListingConsigned)
      }
    }
  }

  const onSaveChanges = (p) => {
    if (!p) return null
    if (p?.sort) {
      const {field, sort} = p?.sort || {}
      setQueries({
        ...queries,
        sortBy: field,
        sortDirection: sort,
      })
    } else if (p?.filter) {
      const filter = p?.filter
      setQueries({
        ...queries,
        ...filter,
      })
    } else if (p?.withdraw) {
      setModalType(ModalType.WITHDRAW)
    } else if (p?.onExport) {
      refetchExport()
    } else if (p?.onListMultiple) {
      const items = listSelections
        ?.filter((s) => s?.status !== ConsignStatus.WITHDRAW_APPROVED)
        .map((product) => {
          return {
            listingStatus: ListingStatus.CREATED,
            price: Number(product?.desiredReturn),
            productId: product?.id,
            quantity: product?.quantity,
          }
        })

      const payload = {
        ...p?.onListMultiple,
        items,
      }

      onListAll(payload)
      setListSelections([])
    }
  }

  const onNavigateUrl = useCallback(
    (type) => {
      go(paths.inventory[type])
    },
    [paths, go],
  )

  const isMobile = window.innerWidth <= 901
  const isUnlistedListed = tabPanel === TabPanel.UNLISTED || tabPanel === TabPanel.LISTED
  const isDisabled = isLoading || postlistingAllLoading

  return (
    <div className={clx('p-detail-flex', 'inventory', 'view-inventory')}>
      <Box className="p-detail-toolbar no-print v2">
        <InventoryHeader
          {...{
            chunkIndex,
            delistSelected,
            listSelections,
            fetchNextPage,
            hasNextPage,
            isFetching,
            routeChange,
            setModalType,
            total,
            tabPanel,
            isConsigned,
            setShowCard,
            showCard,
            selectedCriteria,
            setChunkIndex,
          }}
          isDisabled={isDisabled}
          isEmpty={_.isEmpty(items?.data)}
          onTableView={() => {
            setShowCard(false)
            localStorage.setItem('inventory_card_view', JSON.stringify({hidden: true, selectedCriteria}))
          }}
        />
      </Box>
      {isBarcodeLabelReady && (
        <div style={{display: 'none'}}>
          <BarcodeLabels
            {...{barcodeTemplates, hasConsignorCode}}
            items={barcodeItems}
            businessName={user?.businessName || 'Copyt'}
            logo={user?.platform?.logo_url}
            ref={componentRef}
          />
        </div>
      )}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-around"
        sx={{
          bgcolor: {xs: 'white', md: 'transparent'},
        }}
      >
        <Tabs
          value={tabPanel}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          <Tab
            icon={<SpeakerNotesOffIcon />}
            iconPosition="start"
            label={!isMobile && 'Unlisted'}
            disabled={isDisabled}
          />
          <Tab
            icon={<StickyNote2Icon />}
            iconPosition="start"
            label={!isMobile && 'Listed'}
            disabled={isDisabled}
          />
          <Tab
            icon={<ShoppingBasketIcon />}
            iconPosition="start"
            label={!isMobile && 'Sold'}
            disabled={isDisabled}
          />
          <Tab
            icon={<ShoppingCartCheckoutIcon />}
            iconPosition="start"
            label={!isMobile && 'Processed'}
            disabled={isDisabled}
          />
        </Tabs>
      </Box>
      <SearchContext.Provider
        value={{
          unlistedSearchText,
          setUnlistedSearchText,
          listedSearchText,
          setListedSearchText,
          soldSearchText,
          setSoldSearchText,
          processedSearchText,
          setProcessedSearchText,
        }}
      >
        <InventoryBody
          {...{
            changeCheckbox,
            disableBtn,
            handleChange,
            items,
            markAsProcessed,
            tabPanel,
            routeChange,
            setModalType,
            fetchNextPage,
            total,
            queries,
            filter,
            setFilter,
            onSaveChanges,
            isFetching,
            refetch,
            user,
            withdrawIds,
            setWithdrawIds,
            listSelections,
            date,
            selectedCriteria,
            showCard,
            onNavigateUrl,
            chunkIndex,
            setChunkIndex,
            isLoading,
            isDisabled,
          }}
          metricsQuery={query}
          tabString={tab}
          showWithdraw={showWithdraw}
          showDelete={showDelete}
        />
        {isMobile && (
          <>
            <Grid container gap={1} justifyContent="space-evenly">
              <Grid item>
                {isUnlistedListed && (
                  <Box fontWeight={600}>
                    Average Cost:{' '}
                    <Box component="span">
                      {currency.format(_getValue(query?.data?.averageCost || 0))}
                    </Box>
                  </Box>
                )}
              </Grid>
              <Grid item>
                {isUnlistedListed && (
                  <Box fontWeight={600}>
                    Average Price:{' '}
                    <Box component="span">
                      {currency.format(_getValue(query?.data?.averagePrice || 0))}
                    </Box>
                  </Box>
                )}
              </Grid>
              {tabPanel !== TabPanel.LISTED && (
                <Grid item>
                  <Typography fontSize="0.9rem" fontWeight="bold" ml={1.2}>
                    Total {isUnlistedListed ? 'List Price' : 'Actual Return'} :
                    {currency.format(
                      isUnlistedListed
                        ? query?.data?.totalListPrice || 0
                        : query?.data?.totalActualReturn || 0,
                    )}
                  </Typography>
                </Grid>
              )}
            </Grid>
            {tabPanel === TabPanel.LISTED && (
              <Grid container gap={1} justifyContent="space-evenly">
                <Grid item>
                  <Typography fontSize="0.9rem" fontWeight="bold" ml={1.2}>
                    Total {isUnlistedListed ? 'List Price' : 'Actual Return'} :
                    {currency.format(
                      isUnlistedListed
                        ? query?.data?.totalListPrice || 0
                        : query?.data?.totalActualReturn || 0,
                    )}
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography fontSize="0.9rem" fontWeight="bold" ml={1.2}>
                    <Tooltip title="This calculation is based only on consigned items">
                      Total Estimated Payout:
                      {currency.format(query?.data?.totalEstimatedPayout || 0)}
                    </Tooltip>
                  </Typography>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </SearchContext.Provider>
      <InventoryModals
        {...{
          date,
          isEnterprise,
          history,
          modalType,
          setModalType,
          setToastType,
          toastType,
          setDeletionCount,
          refetch,
          onCreateBatchSubmit,
          markAsProcessed,
          selectedCriteria,
          onGenerateBarcodesClicked,
          setBarcodeTemplates,
          printSize,
          setPrintSize,
          listSelections,
          bulkEdit,
          setBulkEdit,
          setListSelections,
          hasConsignorCode,
          setHasConsignorCode,
          onSaveChanges,
        }}
        handleCardList={(e) => {
          setSelectedCriteria(e)
          setShowCard(true)
          localStorage.setItem(
            'inventory_card_view',
            JSON.stringify({hidden: false, selectedCriteria: e}),
          )
        }}
        handleDateRange={(date) => {
          setDate(date)

          const dates = DateUtils.convertDatesToUTC(date.startDate, date.endDate)

          setQueries({
            ...queries,
            ...dates,
          })
          setModalType(ModalType.UNDEFINED)
        }}
        totalItems={items?.data?.filter((item) => item?.consign?.status !== 'approved')?.length || 0}
        data={Array.from(listSelections)?.filter((s) => s?.consign?.status)}
        hasCloseButton
        fromWhere={tabSelectionStr.UNLISTED}
        removeId={(id) => {
          if (id) {
            const listing = Array.from(listSelections)?.filter((l) => l?.id !== id)
            setWithdrawIds(withdrawIds.filter((w) => w !== id))
            setListSelections(Array.from(listing))
          }
        }}
      />
      {toastType > ToastType.UNDEFINED && (
        <InventoryToast {...{toastType, setToastType, deletionCount}} />
      )}
    </div>
  )
}

export default Inventory
