import {createTheme} from '@mui/material'
import {loadStripe} from '@stripe/stripe-js'

var location = window.location.href
location = location.replace('http://', '')
location = location.replace('https://', '')
location = location.split('/')[0]

export const apiConfig = {
  // todo: tobe removed
  api_prefix: process.env.REACT_APP_API_URL || `api.${location}`,

  api_url: process.env.REACT_APP_API_URL,
  listing_api: process.env.REACT_APP_LISTING_API || `copyt-platform-listing-api:3000`,
  auth_audience: process.env.REACT_APP_AUTH0_AUDIENCE,
}

export const AppConfig = {
  stripe_key: loadStripe(process.env.REACT_APP_STRIPE_PK),

  checkbook_app_url: process.env.REACT_APP_CHECKBOOK_APP_URL,
  checkbook_app_client_id: process.env.REACT_APP_CHECKBOOK_CLIENT_ID,
  checkbook_app_client_secret: process.env.REACT_APP_CHECKBOOK_CLIENT_SECRET,
  checkbook_app_redirect_url: process.env.REACT_APP_CHECKBOOK_REDIRECT_URI,
  barcodeSpiderURL: process.env.REACT_APP_BARCODE_SPIDER_URL,
  barcodeSpiderAPI_KEY: process.env.REACT_APP_BARCODE_SPIDER_API_KEY,
  paypal_api_url: process.env.REACT_APP_PAYPAL_API_URL,
  paypal_app_client_id: process.env.REACT_APP_PAYPAL_CLIENT_ID,
  paypal_app_client_secret: process.env.REACT_APP_PAYPAL_CLIENT_SECRET,
}

export const developerConfig = {
  noPaywall: process.env.REACT_APP_PAYMENT_NO_WALL === '1',
}

export const theme = createTheme({
  palette: {mode: 'light'},
  typography: {fontFamily: `"Poppins", sans-serif`},
})
