import _ from 'lodash'

function distinctBy(k, arr = []) {
  return arr.reduce((obj, x) => {
    return Object.assign({}, obj, {[x[k]]: [].concat(obj[x[k]] || [], x)})
  }, {})
}

function flatten(arr = []) {
  if (arr.length > 0) {
    return arr.reduce((x, y) => x.concat(y), [])
  }
  return []
}

function groupObjectBy(key, array = []) {
  if (array && array.length > 0) {
    return array.reduce((objectsByKeyValue, obj) => {
      const value = obj[key]
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj)
      return objectsByKeyValue
    }, {})
  }
  return null
}

/*
  array: => array to separate
  separator => separator that will be used e.g ', ' or ' - '
  placeHolderMessage => Custom message if array is empty
 */
function joinStringFromArrayWithSeparator(array, separator, placeholderMessage = '') {
  if (array.length > 0) return array.join(separator)

  return placeholderMessage ? placeholderMessage : ''
}

function compareValues(key, order = 'asc') {
  return function (a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      return 0
    }

    const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key]
    const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key]
    let comparison = 0

    if (varA > varB) comparison = 1
    else if (varA < varB) comparison = -1

    return order === 'desc' ? comparison * -1 : comparison
  }
}

function last(arr = []) {
  if (arr instanceof Array) {
    return arr.slice(-1)[0]
  }
  return null
}

export const sortBy = (key, arr = [], ascending = true) => {
  if (arr.length > 0) {
    return arr.sort(compareValues(key, ascending ? 'asc' : 'desc'))
  }
  return []
}

function removeDuplicateBy(arr, key) {
  if (arr.length > 0) {
    return Object.values(
      arr.reduce((r, o) => {
        r[o[key]] = o
        return r
      }, []),
    )
  }
  return []
}

export const getDuplicateValue = (arr1, arr2, arr1Key = null, arr2Key = null) => {
  if (arr2?.length === 0) return []
  if (arr1Key && !arr2Key) {
    return arr1?.filter((x) => arr2.some((y) => y === x[arr1Key])) || []
  } else if (!arr1Key && arr2Key) {
    return arr1?.filter((x) => arr2.some((y) => y[arr2Key] === x)) || []
  } else if (arr1Key && arr2Key) {
    return arr1?.filter((x) => arr2.some((y) => y[arr2Key] === x[arr1Key])) || []
  }
  return arr1?.filter((x) => arr2.some((y) => y === x)) || []
}

export const objectToArray = (obj = {}) => {
  const res = []
  if (Object.keys(obj)?.length > 0) {
    Object.keys(obj).map((key) => {
      const el = obj[key]
      res.push(...el)
    })
  }
  return res
}

export const compareObjAndArr = (arr, obj) =>
  arr.reduce((r, prop) => {
    obj.hasOwnProperty(prop) && (r[prop] = obj[prop])

    return r
  }, {})

export const getShopifyData = (arr = []) => {
  if (arr?.length === 0) return []
  return arr?.filter((a) => a?.value?.hasOwnProperty('shopifyToken'))
}

export const getShopifyBillingStatus = (arr = []) => {
  const shopifyData = arr?.filter((a) => a?.value?.hasOwnProperty('shopifyToken'))
  if (shopifyData?.length > 0) {
    const {shopify_billing_status} = shopifyData[0]?.value
    return shopify_billing_status
  }
  return true
}

export const convertUnderscoreToSpaceAndCapitalize = (inputString = null) => {
  const words = inputString.split('_')
  const capitalizedWords = _.map(words, (word) => {
    return _.capitalize(word)
  })
  return capitalizedWords.join(' ')
}

export const arraysOfObjectsAreEqual = (arr1, arr2) => {
  const sortedArr1 = _.sortBy(arr1, 'id')
  const sortedArr2 = _.sortBy(arr2, 'id')

  return _.isEqual(sortedArr1, sortedArr2)
}

export const _checkForClover = (platformOptions, platformSelection) => {
  return _.chain(platformOptions)
    .find({label: 'Clover'})
    .get('id')
    .thru((id) => _.includes(platformSelection, id))
    .value()
}

export default {
  distinctBy,
  flatten,
  groupObjectBy,
  joinStringFromArrayWithSeparator,
  last,
  objectToArray,
  sortBy,
  removeDuplicateBy,
  getDuplicateValue,
  compareObjAndArr,
  getShopifyData,
  getShopifyBillingStatus,
  convertUnderscoreToSpaceAndCapitalize,
  arraysOfObjectsAreEqual,
  _checkForClover,
}
