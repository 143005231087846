import React, {useState, useEffect} from 'react'
import {createBrowserHistory} from 'history'
import {Route, Switch, useLocation} from 'react-router-dom'
import {withAuthenticationRequired, useAuth0} from '@auth0/auth0-react'
import {DndProvider} from 'react-dnd'
import {HTML5Backend} from 'react-dnd-html5-backend'
import Intercom from '@intercom/messenger-js-sdk'
import {loadStripe} from '@stripe/stripe-js'
import * as Sentry from '@sentry/react'
import {BrowserTracing} from '@sentry/tracing'

import AuthLayout from 'layouts/Auth/Auth.js'
import AdminLayout from 'layouts/Admin/Admin.js'
import RTLLayout from 'layouts/RTL/RTL.js'
import ErrorLayout from 'layouts/Error/Error.js'
import RegisterShopify from 'views/Shopify/Register.js'
import {usePageViews} from 'service/hook/usePageViews'
import {CopytPro, ShopifyBilling} from './views_v2/modules'
import 'assets/css/nucleo-icons.css'
import 'assets/scss/copyt-platform-ui.scss?v=1.1.0'
import 'assets/demo/demo.css'
import 'react-notification-alert/dist/animate.css'

const {REACT_APP_STRIPE_PK, REACT_APP_ENVIRONMENT, REACT_APP_SENTRY_DSN} = process.env
const stripe = loadStripe(REACT_APP_STRIPE_PK)
const ProtectedRoute = ({component, ...args}) => (
  <Route
    component={withAuthenticationRequired(component, {
      returnTo: () => window.location.pathname + window.location.search,
    })}
    {...args}
  />
)

Sentry.init({
  dsn: window.location.href.includes('localhost') ? null : REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  environment: REACT_APP_ENVIRONMENT,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0,
})

export const hist = createBrowserHistory()
// should go to separate file to not encounter circular dependecy problem
// export const UserTypeContext = createContext()

const App = () => {
  const [userType, setUserType] = useState('')
  const {getAccessTokenSilently, user} = useAuth0()
  const [isTokenFound, setTokenFound] = useState(false)

  //Segment Page
  usePageViews()

  useEffect(() => {
    if (user) {
      const {sub, name, email, nickname} = user
      if (window.analytics) {
        window.analytics.identify(sub, {
          name,
          email,
          nickname,
        })
      }

      Intercom({
        app_id: 'm3pdm91q', //process.env.INTERCOM,
        user_id: user?.email,
        name: user?.name || 'Guest',
        email: user?.email,
        created_at: user?.updated_at,
      })
    }
  }, [user])

  return (
    <DndProvider backend={HTML5Backend}>
      <Switch>
        <Route path="/auth*" render={(props) => <AuthLayout {...props} />} />
        <Route path="/error*" component={(props) => <ErrorLayout {...props} />} />
        <Route path="/register/shopify*" component={(props) => <RegisterShopify {...props} />} />
        <ProtectedRoute path="/copytnetwork" component={(props) => <CopytPro {...props} />} />
        <ProtectedRoute path="/shopifybilling*" component={(props) => <ShopifyBilling {...props} />} />
        <ProtectedRoute path="/" component={(props) => <AdminLayout {...props} />} />
        <ProtectedRoute path="/rtl*" component={(props) => <RTLLayout {...props} />} />
      </Switch>
    </DndProvider>
  )
}

export default App
