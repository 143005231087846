import React, {memo} from 'react'
import {useGlobalStore} from 'provider/global_store/hook'
import {ReactComponent as PremiumBullet} from '../../assets/img/premium-bullet-point.svg'
import {usePaywall} from './hook'
import './Paywall.scss'

export const PaywallIndividual = (p) => {
  const {onNext} = usePaywall()

  return (
    <>
      <div className="white-bubble">
        <div className="premium-modal-title">
          <p>Listing and delisting is a paid feature</p>
        </div>
        <div className="premium-benefits">
          <p className="premium-benefits-description">
            You are able to add your payment information under Account Settings. You will only be charged
            for sales on platforms that are not in Beta.
          </p>
          <div className="premium-benefits-item">
            <PremiumBullet className="premium-icon" />
            <p className="premium-benefits-text">List and delist from all your connected platforms!</p>
          </div>
          <div className="premium-benefits-item">
            <PremiumBullet className="premium-icon" />
            <p className="premium-benefits-text">
              Listing and delisting will only cost a small transaction fee of 1.5% upon the sale of the
              item.
            </p>
          </div>
        </div>
      </div>

      <div className="paywall-footer">
        <button className="premium-button" onClick={onNext}>
          GO TO ACCOUNT SETTINGS
        </button>
      </div>
    </>
  )
}

export default memo(PaywallIndividual)
